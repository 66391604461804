import React, { Component, Fragment } from 'react'
import { Helmet } from "react-helmet";
import {Empty, Spin} from "antd";
import { Card, CardBody, Col } from 'reactstrap'
import 'react-tabs/style/react-tabs.css';
import {clear_auth, set_auth} from "../../../reducers/Auth";
import {connect} from "react-redux";
import Breadcrumb from "../../common/breadcrumb";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {pageNumber} from "../../../functions/functionGeneral";
import {IMG_HOST} from "../../../helper/host";
import i18n from "i18next";
import EmptyDataImage from "../../../assets/images/data-kosong.png"
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import ArrowRight from "../../../assets/icons/arrow-right.svg";

export class DetailPerusahaanUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            data: [],
            dataActivity: [],
            dataUser: [],
            paginate: 10,
            perPage: 0,
            currentPage: 0,
        }
        this.loadDetailUser = this.loadDetailUser.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    loadDetailUser(){
        this.setState({loading:true})
        let form = {
            id: this.props.match.params.id,
            paginate    : this.state.paginate,
            page : this.state.currentPage + 1
        }
        this.props.request("detail_perusahaan_user", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataUser    : response.data,
                        dataActivity  : response.activity.data,
                        perPage : response.activity.per_page,
                        totalData : response.activity.total,
                        pageCount   : response.activity.last_page,
                    })
                }
                this.setState({loading:false})
            })
    }

    handlePageClick(data){
        let selected = data.selected;
        this.setState({currentPage : selected, loading: true}, () => {
            this.loadDetailUser()
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            currentPage: 0,
            [name]: value,
        })
    }

    componentDidMount() {
        this.loadDetailUser()
    }

    render() {
        let no = 0;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Detail Perusahaan User - iPresens</title>
                    <meta name="Detail" content="Detail Perusahaan User"/>
                </Helmet>
                <Breadcrumb title="Detail Perusahaan User" parent="Settings" parentlink="/settings/user"/>
                <Col lg="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            {this.state.loading ?
                                <Spin size="large" tip="Sedang memuat data..." style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}/>
                                :
                                <div>
                                    <div className="profile-responsive card-border">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner">
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="row">
                                                            <h1 className="menu-header-title"
                                                                style={{color: "#143B5E"}}>{this.state.dataUser.p_user_name}</h1>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Email : </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{
                                                                            fontSize: 12,
                                                                            color: "#143B5E"
                                                                        }}>{this.state.dataUser && this.state.dataUser.p_user_email ? this.state.dataUser.p_user_email : '-'}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Wa : </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{
                                                                            fontSize: 12,
                                                                            color: "#143B5E"
                                                                        }}>{this.state.dataUser.p_user_wa && this.state.dataUser.p_user_wa ? this.state.dataUser.p_user_wa : '-'}</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className='col-md-4'>
                                                                        <p>Level : </p>
                                                                    </div>
                                                                    <div className='col-md-8'>
                                                                        <h6 style={{
                                                                            fontSize: 12,
                                                                            color: "#143B5E"
                                                                        }}>{this.state.dataUser.user_level?.level_name}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </CardBody>
                    </Card>
                    <Card className="main-card">
                        <div className="card-body">
                            {this.state.loading ?
                                <Spin size="large" tip="Loading..." style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }} />
                                :
                                this.state.dataActivity.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage}
                                           className="ipresens-image-empty mb-5"/>
                                    :
                                    <div>
                                        <div className="col-xl-12 mb-3">
                                            <h3>Daftar Aktivitas</h3>
                                        </div>
                                        <Table style={{
                                            borderCollapse: 'separate',
                                            borderSpacing: '0px 8px', borderTop: "5px"
                                        }}>
                                            <Thead>
                                                <Tr>
                                                    <Th width="30">#</Th>
                                                    <Th>User</Th>
                                                    <Th>Tipe</Th>
                                                    <Th width="200">Tanggal</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.dataActivity.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr>
                                                            <small>
                                                                <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                            </small>
                                                            <Td>{this.state.dataUser.p_user_name}</Td>
                                                            <Td>{item.type}</Td>
                                                            <Td>{item.created_at}</Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>
                                    </div>
                            }
                        </div>
                        <div className='row mx-0' style={{width: "100%"}}>
                            {this.state.dataActivity.length !== 0 &&
                                <>
                                    <div className='col font-14' style={{
                                        padding: "auto",
                                        margin: "auto",
                                        fontWeight: 500,
                                        lineHeight: "24px"
                                    }}>{i18n.t("menampilkan")} {this.state.dataActivity.length === 0 || this.state.dataActivity.length === null ? "0" : this.state.dataActivity.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}
                                    </div>
                                    <div className='col'>
                                        <ReactPaginate
                                            previousLabel={<img src={ArrowLeft} alt=''/>}
                                            nextLabel={<img src={ArrowRight} alt=''/>}
                                            pageCount={this.state.pageCount}
                                            forcePage={this.state.currentPage}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={3}
                                            onPageChange={this.handlePageClick}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link custom-page-link'}
                                            containerClassName={'pagination my-2'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link custom-page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link custom-prev-next-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link custom-prev-next-link'}
                                            activeClassName={'active custom-active'}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                    </Card>
                </Col>
            </Fragment>
        )
    }

}


const mapStateToProps = state =>
    ({
        user: state.Auth.user,
        api_token: state.Auth.api_token
    });
const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps,
    mapDispatchToProps)(DetailPerusahaanUser);