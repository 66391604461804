import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Empty, Modal, Spin } from 'antd';
import Paging from "../../components/paging";
import { BACKEND_API_HOST, IMG_HOST } from "../../helper/host";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import i18n from "i18next";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import EmptyDataImage from "../../assets/images/data-kosong.png";
import FilterIcon from "../../assets/icons/filter.svg";

export class List_absensi_bulan extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        this.state = {
            loading: true,
            datas: [],
            datagaji: [],
            idDel: '',
            filter_nama: "",
            year: tahun,
            month: namabulan[bulan],
            modalimport: false,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 10,
            data_lokasi: [],
            data_shift: [],
            data_divisi: [],
            filter_divisi: "",
            filter_lokasi: "",
            filter_status: "",
            filter_shift: "",
            filter_belum_keluar: "",
            guide: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.exportKaryawan = this.exportKaryawan.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, datas: [] }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }
    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            datas: [],
            loading: true
        }, () => this.loadData())
    }


    resetFilter(event) {
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        this.setState({
            filter_nama: "",
            year: tahun,
            month: namabulan[bulan],
            datas: [],
            filter_lokasi: "",
            filter_divisi: "",
            filter_shift: "",
            filter_belum_keluar: "",
            loading: true
        }, () => this.loadData())
    }

    handleOk = e => {
        this.setState({
            modalimport: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false,
        });
    };
    loadLokasi() {
        this.props.request("lokasi/list-lokasi", {}, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_lokasi: response.data
                    })
                }
            })
    }

    loadDivisi() {
        this.props.request("divisi/list-divisi", {}, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_divisi: response.data
                    })
                }
            })
    }
    loadShift() {
        this.props.request("shift/list-shift", {}, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_shift: response.data
                    })
                }
            })
    }
    exportKaryawan = () => {
        if (this.state.datas.length != 0) {
            this.setState({ loadingExport: true, }, async () => {
                var AuthKey = await localStorage.getItem('Presensi-Auth-Key')
                window.open(BACKEND_API_HOST + 'karyawan/export_laporan?token=' + AuthKey + '&filter_nama=' + this.state.filter_nama + '&month=' + this.state.month + '&year=' + this.state.year, "_blank")
                this.setState({ loadingExport: false })
            })
        } else {
            alert('Data Kosong..!!')
        }
    }

    loadData() {
        let form = {
            paging: this.state.paging,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            month: this.state.month,
            year: this.state.year,
            filter_lokasi: this.state.filter_lokasi,
            filter_divisi: this.state.filter_divisi,
            filter_shift: this.state.filter_shift,
            filter_belum_keluar: this.state.filter_belum_keluar,
        }
        this.props.request("karyawan/list_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        datas: response.absensi.data,
                        datagaji: response.data,
                        totalData: response.absensi.total,
                        pageCount: response.absensi.last_page,
                        perPage: response.absensi.per_page,
                        loading: false
                    })

                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.loadDivisi()
        this.loadShift()
        this.loadLokasi()

    }

    render() {

        let no = 0;
        const stylefoto = {
            height: '50px',
            width: '50px',
            objectFit: 'cover'
        }
        const steps = [
            {
                selector: '.tambah-karyawan',
                content: 'Anda dapat menambah Karyawan disini',
            },
            {
                selector: '.daftar-karyawan',
                content: 'Daftar Karyawan anda dapat dilihat disini',
            },
            {
                selector: '.filter-karyawan',
                content: 'Anda dapat mem-Filter daftar Karyawan anda lewat sini',
            },
        ];
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Presensi") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("laporanpresensibulanan")} - iPresens</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Breadcrumb title={i18n.t("laporanpresensibulanan")}  parent={i18n.t("presensi")}  setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid list-karyawan">
                        <div className="card">
                            <div className="card-header pb-2 pt-3" style={{ borderBottom: "none" }}>
                                <div className="row filter-karyawan mx-1">
                                    <div className="col-md-3 px-1 col-srch-absnbln">
                                        <input style={{ fontSize: '12px', fontFamily: "Arial, FontAwesome", backgroundColor: "#F0F0F0", height: "100%" }} name="filter_nama" className="form-control"
                                            placeholder={String.fromCharCode(0xF002) + " " + i18n.t("carinamakaryawan") + "..."}
                                            type="text" required=""
                                            value={this.state.filter_nama}
                                            onChange={this.handleChange}
                                            onKeyUp={(event) => {
                                                event.preventDefault()
                                                if (event.which == 13) {

                                                    this.setState({
                                                        page: 0,
                                                        paging: this.state.paging,
                                                        loading: true
                                                    }, () => {
                                                        this.loadData()
                                                    });
                                                }
                                            }} />
                                    </div>
                                    <div className="col-md-2 px-1 btn-bln-absnbln">
                                        <select style={{ fontSize: '12px' }} name="month" className="form-control" onChange={this.handleFilter} value={this.state.month}>
                                            <option value="1">Januari</option>
                                            <option value="2">Februari</option>
                                            <option value="3">Maret</option>
                                            <option value="4">April</option>
                                            <option value="5">Mei</option>
                                            <option value="6">Juni</option>
                                            <option value="7">Juli</option>
                                            <option value="8">Agustus</option>
                                            <option value="9">September</option>
                                            <option value="10">Oktober</option>
                                            <option value="11">November</option>
                                            <option value="12">Desember</option>
                                        </select>
                                    </div>
                                    <div className="col-md-3 px-1">
                                        <div className="input-group btn-slct-thn-absnbln">
                                            <select style={{fontSize: '12px'}} name="year" className="form-control mr-1" onChange={this.handleFilter} value={this.state.year}>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                            </select>
                                            <button style={{fontSize: '12px'}} className="btn ipresens-btn-reset border px-6" onClick={this.resetFilter}><span className="fa fa-refresh"></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-auto px-1 filter-ml'>
                                        <button className='btn ipresens-btn-filter px-2' style={{ width:"94px" }} align="center" onClick={() => this.setState({ filter: !this.state.filter })}><img src={FilterIcon} />Filter</button>
                                    </div>
                                    <div className="col-auto px-1 filter-ml pr-0">
                                        <button style={{ fontSize: '12px' }} className="btn ipresens-btn-reset border px-6" onClick={this.resetFilter}><span className="fa fa-refresh"></span></button>
                                    </div>
                                    <div className='col-auto px-1' style={{ marginLeft: '70px' }}>
                                        <button color="success"
                                                className="btn ipresens-export-btn pull-right btn-exp-absnbln"
                                                disabled={this.state.loadingExport}
                                            // style={{ float: "right" }}
                                                onClick={() => {
                                                    this.exportKaryawan()

                                                }}>
                                            {!this.state.loadingExport &&
                                                <span style={{fontSize: "12px"}}>
                                                    <i className="fa fa-share"></i> Export Data
                                                </span>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {this.state.filter &&
                                <div className="row mx-2 px-4">
                                    <div className='col-md-3 px-2'>
                                        <label className='form-label' style={{ fontSize: "12px" }}>Lokasi</label>
                                        <select className='form-control' name="filter_lokasi" value={this.state.filter_lokasi} onChange={this.handleFilter} style={{ fontSize: "12px" }}>
                                            <option value="">Pilih Lokasi</option>
                                            {this.state.data_lokasi.length !== 0 &&
                                                this.state.data_lokasi.map((item, index) => {
                                                    return (
                                                        <option value={item.lokasi_id}>{item.lokasi_nama}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-3 px-0 mb-2'>
                                        <label className='form-label' style={{ fontSize: "12px" }}>Divisi</label>
                                        <select name="filter_divisi" className='form-control' value={this.state.filter_divisi} style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                            <option value="">Pilih Divisi</option>
                                            {this.state.data_divisi.length !== 0 &&
                                                this.state.data_divisi.map((item, index) => {
                                                    return (
                                                        <option value={item.divisi_id}>{item.divisi_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-3 px-2 mb-2'>
                                        <label className='form-label' style={{ fontSize: "12px" }}>Shift</label>
                                        <select name="filter_shift" className='form-control' value={this.state.filter_shift} style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                            <option value="">Pilih Shift</option>
                                            {this.state.data_shift.length !== 0 &&
                                                this.state.data_shift.map((item, index) => {
                                                    return (
                                                        <option value={item.sift_id}>{item.sift_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-4 px-2 d-flex align-items-end justify-content-start mt-2'>
                                        <div className='btn-group width-filter'>
                                            <button className='btn text-capitalize ipresens-custom-btn-group filter-masuk' onClick={() => this.setState({ filter_belum_keluar: "", loading: true }, () => this.loadData())}>Masuk</button>
                                            <button className='btn text-capitalize ipresens-custom-btn-group filter-belum-keluar' onClick={() => this.setState({ filter_belum_keluar: "belum_keluar", loading: true }, () => this.loadData())}>Belum Keluar</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="card-body pt-3 daftar-karyawan">
                                {!this.state.loading && this.state.datas.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table className="table table-striped mb-5" style={{ fontSize: "14px", color: "#143B5E" }}>
                                        <Thead>
                                            <Tr>
                                                <Th style={{ border: "none" }}>#</Th>
                                                <Th style={{ border: "none" }} className="text-center">{i18n.t("foto")}</Th>
                                                <Th style={{ border: "none" }} className="text-center">{i18n.t("nama")}</Th>
                                                <Th style={{ border: "none" }} className="text-center">{i18n.t("presensi")}</Th>
                                                <Th style={{ border: "none" }} className="text-center">{i18n.t("izin")}/{i18n.t("cuti")}</Th>
                                                <Th style={{ border: "none" }} className="text-center">{i18n.t("terlambat")}</Th>
                                                <Th style={{ border: "none" }} className="text-center" >{i18n.t("jamkerja")}</Th>
                                                <Th style={{ border: "none" }} className="text-center">{i18n.t("jamaktual")}</Th>
                                                <Th style={{ border: "none" }} className="text-center">{i18n.t("jamistirahat")}</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={9}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.datas.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr>
                                                        <small>
                                                            <Th style={{ border: "none" }} scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                        </small>
                                                        <Td style={{ border: "none" }} align="center">{item.karyawan_foto !== null ? (
                                                            <img style={stylefoto}
                                                                src={IMG_HOST + 'frontapp/karyawan/' + item.karyawan_foto} />) : (
                                                            <img style={stylefoto}
                                                                src={IMG_HOST + "user-profile.png"} />)}</Td>
                                                        <Td align="left" style={{ color: "#5D6F80", border: "none" }}>
                                                            <a style={{
                                                                color: "#8c2bee"
                                                            }} href={"/karyawan/profil/detail/" + item.karyawan_slug}>
                                                                {item.karyawan_nama}
                                                            </a>
                                                            <br />
                                                            {item.karyawan_email}
                                                        </Td>
                                                        <Td style={{ border: "none" }} align="center">{this.state.datagaji[index].jumlah_presensi}</Td>
                                                        <Td style={{ border: "none" }} align="center">{this.state.datagaji[index].jumlah_izin}</Td>
                                                        <Td style={{ border: "none" }} align="center">{this.state.datagaji[index].jumlah_keterlambat.jam}j {this.state.datagaji[index].jumlah_keterlambat.menit}m</Td>
                                                        <Td style={{ border: "none" }} align="center">{this.state.datagaji[index].jam_kerja.jam}j {this.state.datagaji[index].jam_kerja.menit}m</Td>
                                                        <Td style={{ border: "none" }} align="center">{this.state.datagaji[index].jam_aktual.jam}j {this.state.datagaji[index].jam_aktual.menit}m</Td>
                                                        <Td style={{ border: "none" }} align="center">{this.state.datagaji[index].jam_istirahat.jam}j {this.state.datagaji[index].jam_istirahat.menit}m</Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                                <Modal
                                    title="Import Karyawan"
                                    visible={this.state.modalimport}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        this.state.importLoading ?
                                            <button className={'btn btn-success mt-2'} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                            :
                                            <>
                                                <button className={'btn btn-info mt-2'} onClick={this.exportKaryawan}>Download Template</button>
                                                <button className={'btn btn-success mt-2'} onClick={this.importExcel}>Import Data</button>
                                            </>
                                    ]}
                                >
                                    <div style={{ position: 'relative', width: '100%', height: '250px' }}>
                                        {/*<button className={'btn btn-info'} onClick={this.exportKaryawan()}>Download Template</button><br/>*/}
                                        <input type='file' className={'mt-2'}
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={(event) => {
                                                this.setState({
                                                    importFile: event.target.files[0],
                                                })
                                            }} /><br />
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(List_absensi_bulan);
