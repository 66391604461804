import React, { Component, Fragment } from 'react'
import { Empty, Spin } from "antd";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { pageNumber } from "../../functions/functionGeneral";
import Paging from "../../components/paging";
import { Col } from "reactstrap";
import { BACKEND_API_HOST } from "../../helper/host";
import ReactPaginate from "react-paginate";
import i18n from "i18next";
import ArrowRight from '../../assets/icons/arrow-right.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import ExportIcon from "../../assets/icons/download-white.svg";
import EmptyDataImage from "../../assets/images/data-kosong.png";

export class laporan_bulanan extends Component {
    constructor(props) {
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        super(props);

        this.state = {
            loading: false,
            data: [],
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            year: tahun,
            month: namabulan[bulan],
            filter_nama: "",
            short: '1',
        }
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleNama = this.handleNama.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleNama(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    resetFilter(event) {
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        this.setState({
            filter_nama: '',
            short: '1',
            year: tahun,
            month: namabulan[bulan],
            data: [],
            loading: true
        }, () => this.loadData())
    }

    exportLaporanKunjungan() {
        let AuthKey = localStorage.getItem('Presensi-Auth-Key')
        let exportDateStart;
        let exportDateEnd;

        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth();
        let nextMonth = new Date(year, month + 1, 0);
        let daysCount = nextMonth.getDate();

        exportDateStart = this.state.year + "-" + this.state.month + "-1"
        exportDateEnd = this.state.year + "-" + this.state.month + "-" + daysCount

        window.open(BACKEND_API_HOST + 'outlet/export?token=' + AuthKey + '&kunjunganStartDate=' + exportDateStart + '&kunjunganEndDate=' + exportDateEnd, "_blank")
    }

    loadData() {
        this.setState({ loading: true })
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
            type: 'bulan',
            year: this.state.year,
            month: this.state.month,
            filter_nama: this.state.filter_nama,
            short: this.state.short

        }
        this.props.request("outlet/laporan_kunjungan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        totalData: response.data.total
                    })
                }
                this.setState({ loading: false })
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className='row mb-4'>
                        <div className='col-md-3 lk-col-1'>
                            <div className="input-group btn-srch-knjgnbln lk-filter-nama">
                                <span className="input-group-text lk-filter-nama-span" id="basic-addon1">
                                    <img alt='' src={SearchIcon} />
                                </span>
                                <input type="text" value={this.state.filter_nama} className="form-control lk-filter-nama-input" placeholder="Cari nama karyawan ..." aria-label="Username" aria-describedby="basic-addon1" onChange={this.handleNama} onKeyUp={(event) => {
                                    event.preventDefault()
                                    if (event.which === 13) {

                                        this.setState({
                                            page: 0,
                                            paging: this.state.paging,
                                            loading: true
                                        }, () => {
                                            this.loadData()
                                        });
                                    }
                                }} name='filter_nama' />
                            </div>
                        </div>
                        <div className='col-md-3 lk-col-2'>
                            <div className='d-flex flex-nowrap'>
                                <div className='mr-2 flex-fill'>
                                    <select className='form-control lk-filter-sort' onChange={this.handleFilter} value={this.state.month} name='month'>
                                        <option className='lk-filter-sort-option' value="1">Januari</option>
                                        <option className='lk-filter-sort-option' value="2">Februari</option>
                                        <option className='lk-filter-sort-option' value="3">Maret</option>
                                        <option className='lk-filter-sort-option' value="4">April</option>
                                        <option className='lk-filter-sort-option' value="5">Mei</option>
                                        <option className='lk-filter-sort-option' value="6">Juni</option>
                                        <option className='lk-filter-sort-option' value="7">Juli</option>
                                        <option className='lk-filter-sort-option' value="8">Agustus</option>
                                        <option className='lk-filter-sort-option' value="9">September</option>
                                        <option className='lk-filter-sort-option' value="10">Oktober</option>
                                        <option className='lk-filter-sort-option' value="11">November</option>
                                        <option className='lk-filter-sort-option' value="12">Desember</option>
                                    </select>
                                </div>
                                <div className='flex-fill'>
                                    <select className='form-control lk-filter-sort' onChange={this.handleFilter} value={this.state.year} name='year'>
                                        <option className='lk-filter-sort-option' value="2020">2020</option>
                                        <option className='lk-filter-sort-option' value="2021">2021</option>
                                        <option className='lk-filter-sort-option' value="2022">2022</option>
                                        <option className='lk-filter-sort-option' value="2023">2023</option>
                                        <option className='lk-filter-sort-option' value="2024">2024</option>
                                        <option className='lk-filter-sort-option' value="2025">2025</option>
                                        <option className='lk-filter-sort-option' value="2026">2026</option>
                                        <option className='lk-filter-sort-option' value="2027">2027</option>
                                        <option className='lk-filter-sort-option' value="2028">2028</option>
                                        <option className='lk-filter-sort-option' value="2029">2029</option>
                                        <option className='lk-filter-sort-option' value="2030">2030</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='col-md lk-col-3'>
                            <div className='d-flex flex-nowrap lk-filter-sort-container' style={{ height: "100%" }}>
                                <div style={{ marginRight: "8px" }} className='flex-fill'>
                                    <select className='form-control lk-filter-sort' name="short" onChange={this.handleFilter} value={this.state.short}>
                                        <option className='lk-filter-sort-option' value="1">A - Z</option>
                                        <option className='lk-filter-sort-option' value="2">Z - A</option>
                                        <option className='lk-filter-sort-option' value="3">Kunjungan Terbanyak</option>
                                        <option className='lk-filter-sort-option' value="4">Kunjungan Tersedikit</option>
                                    </select>
                                </div>
                                <div>
                                    <button className='ipresens-btn-reset-filter' onClick={this.resetFilter} >
                                        <img alt='' src={RefreshIcon} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3 lk-col-4' style={{ textAlign: "right" }}>
                            <button className='ipresens-export-btn btn-exp-knjgnbln' disabled={this.state.data.length === 0} onClick={() => { this.exportLaporanKunjungan() }}><img alt='' src={ExportIcon} /> Export Excel</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {!this.state.loading && this.state.data.length === 0 ?
                            <Empty description={i18n.t("datakosong")}
                                   image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                            :
                            <div className='table-responsive'>
                                <div className='lk-border-table'>
                                    <table className="my-0 table table-borderless table-striped">
                                        <thead>
                                            <tr>
                                                <th className='lk-th-style'>#</th>
                                                <th className="lk-th-style">Nama</th>
                                                <th className="lk-th-style">Divisi</th>
                                                <th className="lk-th-style">Jabatan</th>
                                                <th className="lk-th-style">Jumlah Kunjungan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loading &&
                                                <tr>
                                                    <td colSpan={9}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </td>
                                                </tr>
                                            }
                                            {this.state.data.map((item, index) => {
                                                no += 1
                                                return (
                                                    <tr>
                                                        <td className='lk-td-style' scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                        <td className="lk-td-style">
                                                            <a className="irpesens-text-link" href={"/karyawan/profil/detail/" + item.karyawan_slug}>
                                                                {item.karyawan_nama}
                                                            </a>
                                                        </td>
                                                        <td className="lk-td-style">
                                                            {item.divisi.divisi_name}
                                                        </td>
                                                        <td className="lk-td-style">
                                                            {item.jabatan.jabatan_nama}
                                                        </td>
                                                        <td className="lk-td-style">
                                                            {item.jumlah_kunjungan} kunjungan
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td colSpan={5} style={{ padding: "0px" }}>
                                                    <div className='row mx-0' style={{ backgroundColor: "#FAFDFF", width: "100%", borderTop: "1px solid #DDDDDD" }}>
                                                        {this.state.data.length !== 0 &&
                                                            <>
                                                                <div className='col font-14' style={{ margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}</div>
                                                                <div className='col'>
                                                                    <ReactPaginate
                                                                        previousLabel={<img src={ArrowLeft} alt='' />}
                                                                        nextLabel={<img src={ArrowRight} alt='' />}
                                                                        pageCount={this.state.pageCount}
                                                                        forcePage={this.state.currentPage}
                                                                        marginPagesDisplayed={2}
                                                                        pageRangeDisplayed={3}
                                                                        onPageChange={this.handlePageClick}
                                                                        breakClassName={'page-item'}
                                                                        breakLinkClassName={'page-link custom-page-link'}
                                                                        containerClassName={'pagination my-2'}
                                                                        pageClassName={'page-item'}
                                                                        pageLinkClassName={'page-link custom-page-link'}
                                                                        previousClassName={'page-item'}
                                                                        previousLinkClassName={'page-link custom-prev-next-link'}
                                                                        nextClassName={'page-item'}
                                                                        nextLinkClassName={'page-link custom-prev-next-link'}
                                                                        activeClassName={'active custom-active'}
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default (laporan_bulanan)
