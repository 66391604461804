import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { MENUITEMS, MENUITEMSGUIDE } from '../../../constants/menu';
// image import
import logo from '../../../assets/images/dashboard/icon_ipresens.png'
import android from "../../../assets/images/android.png";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import i18n from "i18next";

export class sidebar extends Component {

    state = {
        selectedPath: "1",
        mainmenu: [],
    };
    onItemSelection = (arg, e) => {
        this.setState({ selectedPath: arg.path });
    };

    componentWillMount() {
        this.setState({
            mainmenu: MENUITEMS,
            menuguide: MENUITEMSGUIDE
        })
    }

    componentDidMount() {
        var currentUrl = window.location.pathname;

        // this.state.mainmenu.filter(items => {
        //     if (!items.children) {
        //         if (items.path === currentUrl)
        //             this.setNavActive(items)
        //         return false
        //     }
        //     items.children.filter(subItems => {
        //         if (subItems.path === currentUrl)
        //             this.setNavActive(subItems)
        //         if (!subItems.children) return false
        //         subItems.children.filter(subSubItems => {
        //             if (subSubItems.path === currentUrl)
        //                 this.setNavActive(subSubItems)
        //         })
        //     })
        // })
    }

    setNavActive(item) {

        MENUITEMS.filter(menuItem => {
            if (menuItem != item)
                menuItem.active = false
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true
            if (menuItem.children) {
                menuItem.children.filter(submenuItems => {
                    if (submenuItems != item) {
                        submenuItems.active = false
                    }
                    if (submenuItems.children) {
                        submenuItems.children.map(childItem => {
                            childItem.active = false;
                        })
                        if (submenuItems.children.includes(item)) {
                            submenuItems.active = true
                            menuItem.active = true
                        }
                    }
                })
            }
        })
        item.active = !item.active

        this.setState({
            mainmenu: MENUITEMS
        })

        if (item.type == "link" && window.innerWidth <= 768) {
            document.querySelector(".page-main-header").classList.add('open');
            document.querySelector(".page-sidebar").classList.add('open');
        }
    }

    setNavActiveGuide(item) {

        MENUITEMSGUIDE.filter(menuItem => {
            if (menuItem != item)
                menuItem.active = false
            if (menuItem.children && menuItem.children.includes(item))
                menuItem.active = true
            if (menuItem.children) {
                menuItem.children.filter(submenuItems => {
                    if (submenuItems != item) {
                        submenuItems.active = false
                    }
                    if (submenuItems.children) {
                        submenuItems.children.map(childItem => {
                            childItem.active = false;
                        })
                        if (submenuItems.children.includes(item)) {
                            submenuItems.active = true
                            menuItem.active = true
                        }
                    }
                })
            }
        })
        item.active = !item.active

        this.setState({
            menuguide: MENUITEMSGUIDE
        })


    }

    render() {
        const theme = {
            selectionColor: "#C51162"
        };

        if (this.props.user) {
            // give restriction on sidebar
            this.state.mainmenu.map((item, index) => {
                if (this.props.user && this.props.user.perusahaan.langganan && this.props.user.perusahaan.langganan.paket.paket_premium == "0") {
                    if (item.title === "Tugas (BETA)") {
                        item.title = "Tugas (Premium+)";
                    }
                }
                if (this.props.user.user_level && this.props.user.user_level.level_page_limited) {
                    let level_page_array = this.props.user.user_level.level_page_limited.split(',')
                    if (!level_page_array.includes("Monitor")) {
                        if (item.title === "Monitor") {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Pengumuman")) {
                        if (item.title === "Pengumuman") {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Shift")) {
                        if (item.title === "Master Data") {
                            this.state.mainmenu[index].children.map((item2, index2) => {
                                if (item2.title === "Shift") {
                                    this.state.mainmenu[index].children.splice(index2, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Jam Kerja")) {
                        if (item.title === "Master Data") {
                            this.state.mainmenu[index].children.map((item3, index3) => {
                                if (item3.title === "Jam Kerja") {
                                    this.state.mainmenu[index].children.splice(index3, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Divisi")) {
                        if (item.title === "Master Data") {
                            this.state.mainmenu[index].children.map((item3, index3) => {
                                if (item3.title === "Divisi") {
                                    this.state.mainmenu[index].children.splice(index3, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Jabatan")) {
                        if (item.title === "Master Data") {
                            this.state.mainmenu[index].children.map((item3, index3) => {
                                if (item3.title === "Jabatan") {
                                    this.state.mainmenu[index].children.splice(index3, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Lokasi Kunjungan")) {
                        if (item.title === "Master Data") {
                            this.state.mainmenu[index].children.map((item3, index3) => {
                                if (item3.title === "Lokasi Kunjungan") {
                                    this.state.mainmenu[index].children.splice(index3, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Lokasi Kerja")) {
                        if (item.title === "Master Data") {
                            this.state.mainmenu[index].children.map((item3, index3) => {
                                if (item3.title === "Lokasi Presensi") {
                                    this.state.mainmenu[index].children.splice(index3, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Daftar Istillah")) {
                        if (item.title === "Master Data") {
                            this.state.mainmenu[index].children.map((item3, index3) => {
                                if (item3.title === "Daftar Istillah") {
                                    this.state.mainmenu[index].children.splice(index3, 1);
                                }
                            })
                        }
                    }
                    if (item.title === "Master Data") {
                        if (this.state.mainmenu[index].children.length <= 0) {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Pengajuan Izin")) {
                        if (item.title === "Pengajuan Karyawan") {
                            this.state.mainmenu[index].children.map((item4, index4) => {
                                if (item4.title === "Pengajuan Izin/Cuti") {
                                    this.state.mainmenu[index].children.splice(index4, 1);
                                }
                                if (item4.title === "Pengajuan Revisi Data") {
                                    this.state.mainmenu[index].children.splice(index4, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Pengajuan Lembur")) {
                        if (item.title === "Pengajuan Karyawan") {
                            this.state.mainmenu[index].children.map((item5, index5) => {
                                if (item5.title === "Pengajuan Lembur") {
                                    this.state.mainmenu[index].children.splice(index5, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Pengajuan Kasbon")) {
                        if (item.title === "Pengajuan Karyawan") {
                            this.state.mainmenu[index].children.map((item6, index6) => {
                                if (item6.title === "Pengajuan Kasbon") {
                                    this.state.mainmenu[index].children.splice(index6, 1);
                                }
                            })
                        }
                    }
                    if (this.props.user.user_level.level_page_limited.search("Pengajuan Klaim Beban") < 0) {
                        if (item.title === "Pengajuan Karyawan") {
                            this.state.mainmenu[index].children.map((item7, index7) => {
                                if (item7.title === "Pengajuan Klaim Beban") {
                                    this.state.mainmenu[index].children.splice(index7, 1);
                                }
                            })
                        }
                    }
                    if (item.title === "Pengajuan Karyawan") {
                        if (this.state.mainmenu[index].children.length <= 0) {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Karyawan")) {
                        if (item.title === "Karyawan") {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Presensi")) {
                        if (item.title === "Presensi") {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Tugas")) {
                        if (item.title === "Tugas" || item.title === "Tugas (BETA)" || item.title === "Tugas (Premium+)") {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Kunjungan")) {
                        if (item.title === "Kunjungan") {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Daftar Gaji Karyawan")) {
                        if (item.title === "Payroll") {
                            this.state.mainmenu[index].children.map((item2, index2) => {
                                if (item2.title === "Daftar Gaji Karyawan") {
                                    this.state.mainmenu[index].children.splice(item2, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Daftar Istilah")) {
                        if (item.title === "Payroll") {
                            this.state.mainmenu[index].children.map((item2, index2) => {
                                if (item2.title === "Daftar Istilah") {
                                    this.state.mainmenu[index].children.splice(item2, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Daftar Payroll")) {
                        if (item.title === "Payroll") {
                            this.state.mainmenu[index].children.map((item2, index2) => {
                                if (item2.title === "Daftar Payroll") {
                                    this.state.mainmenu[index].children.splice(item2, 1);
                                }
                            })
                        }
                    }
                    if (!level_page_array.includes("Pengaturan Payroll")) {
                        if (item.title === "Payroll") {
                            this.state.mainmenu[index].children.map((item2, index2) => {
                                if (item2.title === "Pengaturan Payroll") {
                                    this.state.mainmenu[index].children.splice(item2, 1);
                                }
                            })
                        }
                    }
                    if (item.title === "Payroll") {
                        if (this.state.mainmenu[index].children.length <= 0) {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Pembayaran Kasbon")) {
                        if (item.title === "Pembayaran Kasbon") {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Langganan")) {
                        if (item.title === "Langganan") {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                    if (!level_page_array.includes("Pengaturan")) {
                        if (item.title === "Pengaturan") {
                            this.state.mainmenu.splice(index, 1);
                        }
                    }
                }
            })

            this.state.menuguide.map((item, index) => {
                if (this.props.user.perusahaan.perusahaan_bidang === null && this.props.user.perusahaan.perusahaan_alamat === null && this.props.user.perusahaan.perusahaan_telp === null) {
                    if (item.title === "Master Data") {
                        this.state.menuguide.splice(index, 1)
                    }
                    if (item.title === "Karyawan") {
                        this.state.menuguide.splice(index, 1)
                    }
                    if (item.title === "Langganan") {
                        this.state.menuguide.splice(index, 1)
                    }
                    if (item.title === "Pengaturan") {
                        this.state.menuguide.splice(index, 1)
                    }
                }

            })
        }

        const mainmenu = this.state.mainmenu.map((menuItem, i) =>
            <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                {(menuItem.sidebartitle) ?
                    <div className="sidebar-title">{menuItem.sidebartitle}</div>
                    : ''}
                {(menuItem.type === 'sub') ?
                    <a className="sidebar-header " href="javascript:void(0)" onClick={() => this.setNavActive(menuItem)}>
                        {(menuItem.iconType == 'svg') ?
                            <img src={menuItem.icon} style={{ marginRight: 14 }} alt="Menu Icon" />
                            :
                            <menuItem.icon />
                        }
                        <span>{i18n.t(menuItem.langid ? menuItem.langid : menuItem.title)}</span>
                        <i className="fa fa-angle-right pull-right"></i>
                    </a>
                    : ''}
                {(menuItem.type === 'link') ?
                    <Link
                        to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                        className={`sidebar-header ${menuItem.active ? 'active' : ''}`}

                        onClick={() => this.setNavActive(menuItem)}
                    >
                        {(menuItem.iconType == 'svg') ?
                            <img src={menuItem.icon} style={{ marginRight: 14 }} alt="Menu Icon" />
                            :
                            <menuItem.icon />
                        }
                        <span>{i18n.t(menuItem.langid ? menuItem.langid : menuItem.title)}</span>
                        {menuItem.children ?
                            <i className="fa fa-angle-right pull-right"></i> : ''}
                    </Link>
                    : ''}
                {menuItem.children ?
                    <ul
                        className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                        style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                    >
                        {menuItem.children.map((childrenItem, index) =>
                            <li key={index} className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}>
                                {(childrenItem.type === 'sub') ?
                                    <a href="javascript:void(0)" onClick={() => this.setNavActive(childrenItem)} >
                                        <i className="fa fa-circle"></i>{childrenItem.title} <i className="fa fa-angle-right pull-right"></i></a>
                                    : ''}

                                {(childrenItem.type === 'link') ?
                                    <Link
                                        to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                        className={childrenItem.active ? 'active' : ''}
                                        onClick={() => this.setNavActive(childrenItem)}
                                    >
                                        <i className="fa fa-circle"></i>{i18n.t(childrenItem.langid ? childrenItem.langid : childrenItem.title)} </Link>
                                    : ''}
                                {childrenItem.children ?
                                    <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                                        {childrenItem.children.map((childrenSubItem, key) =>
                                            <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                {(childrenSubItem.type === 'link') ?
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                        className={childrenSubItem.active ? 'active' : ''}
                                                        onClick={() => this.setNavActive(childrenSubItem)}
                                                    >
                                                        <i className="fa fa-circle"></i>{childrenSubItem.title}</Link>
                                                    : ''}
                                            </li>
                                        )}
                                    </ul>
                                    : ''}
                            </li>
                        )}
                    </ul>
                    : ''}
            </li>
        )

        const menuguide = this.state.menuguide.map((menuItem, i) =>
            <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
                {(menuItem.sidebartitle) ?
                    <div className="sidebar-title">{menuItem.sidebartitle}</div>
                    : ''}
                {(menuItem.type === 'sub') ?
                    <a className="sidebar-header " href="javascript:void(0)" onClick={() => this.setNavActiveGuide(menuItem)}>
                        {(menuItem.iconType == 'svg') ?
                            <img src={menuItem.icon} style={{ marginRight: 14 }} alt="Menu Icon" />
                            :
                            <menuItem.icon />
                        }
                        <span>{menuItem.title}</span>
                        <i className="fa fa-angle-right pull-right"></i>
                    </a>
                    : ''}
                {(menuItem.type === 'link') ?
                    <Link
                        to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                        className={`sidebar-header ${menuItem.active ? 'active' : ''}`}

                        onClick={() => this.setNavActiveGuide(menuItem)}
                    >
                        {(menuItem.iconType == 'svg') ?
                            <img src={menuItem.icon} style={{ marginRight: 14 }} alt="Menu Icon" />
                            :
                            <menuItem.icon />
                        }
                        <span>{menuItem.title}</span>
                        {menuItem.children ?
                            <i className="fa fa-angle-right pull-right"></i> : ''}
                    </Link>
                    : ''}
                {menuItem.children ?
                    <ul
                        className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
                        style={menuItem.active ? { opacity: 1, transition: 'opacity 500ms ease-in' } : {}}
                    >
                        {menuItem.children.map((childrenItem, index) =>
                            <li key={index} className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}>
                                {(childrenItem.type === 'sub') ?
                                    <a href="javascript:void(0)" onClick={() => this.setNavActiveGuide(childrenItem)} >
                                        <i className="fa fa-circle"></i>{childrenItem.title} <i className="fa fa-angle-right pull-right"></i></a>
                                    : ''}

                                {(childrenItem.type === 'link') ?
                                    <Link
                                        to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                        className={childrenItem.active ? 'active' : ''}
                                        onClick={() => this.setNavActiveGuide(childrenItem)}
                                    >
                                        <i className="fa fa-circle"></i>{childrenItem.title} </Link>
                                    : ''}
                                {childrenItem.children ?
                                    <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'}`}>
                                        {childrenItem.children.map((childrenSubItem, key) =>
                                            <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                                                {(childrenSubItem.type === 'link') ?
                                                    <Link
                                                        to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                                        className={childrenSubItem.active ? 'active' : ''}
                                                        onClick={() => this.setNavActiveGuide(childrenSubItem)}
                                                    >
                                                        <i className="fa fa-circle"></i>{childrenSubItem.title}</Link>
                                                    : ''}
                                            </li>
                                        )}
                                    </ul>
                                    : ''}
                            </li>
                        )}
                    </ul>
                    : ''}
            </li>
        )

        let menu;
        if (this.props.user) {
            if (this.props.user.perusahaan.status === "new") {
                menu = menuguide
            } else {
                menu = mainmenu
            }
        }

        return (
            <Fragment>
                <div className="page-sidebar">
                    <div className="main-header-left d-none d-lg-block">
                        <div className="logo-wrapper">
                            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                                <img className="blur-up lazyloaded" src={logo} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="sidebar custom-scrollbar">
                        <ul className="sidebar-menu">
                            {/* {mainmenu} */}
                            {menu}
                        </ul>
                        <ul className="sidebar-menu">
                            <div className="set-image mb-3">
                                <div className="sidebar-title mb-2">Download Presensi Mitra</div>
                                <a target='_blank' href={"https://play.google.com/store/apps/details?id=com.presensi_mitra"}><img
                                    style={{ maxWidth: '80%' }} src={android} alt="android" /></a>
                            </div>
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(sidebar);
