import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Swal from "sweetalert2";
import { set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import Switch from "react-switch";
import { Helmet } from "react-helmet";
import i18n from "i18next";
import CurrencyInput from 'react-currency-input-field';
import InfoIcon from "../../../assets/icons/info-icon-blue.svg";

export class SettingRecognition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: true,
            data: {},
            greet: '',
            quote: '',
            setting_r_status: '',
            setting_r_treshold: '',
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        // const target = event.target;
        // const value = e.target.checked
        const value = event.target.value;
        const name = event.target.name;

        this.setState({
            [name]: value
        })
    }

    saveSettingRecognition = () => {
        this.setState({
            loading: true,
        })

        let form = {
            setting_r_treshold: this.state.setting_r_treshold,
            setting_r_status: this.state.setting_r_status
        }

        this.props.request("setting-recognition/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    loadData() {
        this.setState({
            loaded: false,
            loading: true
        })
        let form = {}
        this.props.request("setting-recognition/list", form, 'POST')
            .then(response => {
                if (response) {
                    this.setState({
                        loaded: true,
                        setting_r_status: response.data.setting_r_status,
                        setting_r_treshold: response.data.setting_r_treshold
                    })
                }
            })
        this.setState({
            loading: false
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengaturan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Pengaturan Face Recognition</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Breadcrumb title="Pengaturan Face Recognition" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div>
                                            <div>
                                                {this.state.loaded ?
                                                    <div>
                                                        <div className="row mb-2">
                                                            <label className='form-label label-input-basic col-md-4 col-sm-6'>Status Fitur</label>
                                                            <Switch
                                                                id={'setting_r_status'}
                                                                name="setting_r_status"
                                                                checked={this.state.setting_r_status}
                                                                onChange={(value) => {
                                                                    this.setState({
                                                                        setting_r_status: value === false ? 0 : 1
                                                                    })
                                                                }}
                                                                onColor="#8c2bee"
                                                                offColor="#eb4034"
                                                                handleDiameter={18}
                                                                uncheckedIcon={false}
                                                                checkedIcon={false}
                                                                boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                height={24}
                                                                width={48}
                                                                className="ml-3 btn-switch-setting-recognition"
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <label className='form-label label-input-recognition col-md-4 col-sm-6'>Batas Minimal Pencocokan Wajah</label>
                                                            <div className="row ml-3">
                                                                <span className="input-group-recognition">{this.state.setting_r_treshold} %</span>
                                                                <input
                                                                    className='input-recognition'
                                                                    type='number'
                                                                    name='setting_r_treshold'
                                                                    onChange={(e) => this.handleChange(e)}
                                                                    value={this.state.setting_r_treshold}
                                                                    min="20" max="100" maxLength="3"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="pull-right mt-4">
                                            {this.state.loading == false ?
                                                <button type="button" className="btn ipresens-btn-color"
                                                        disabled={!this.state.loaded} onClick={() => {
                                                    this.saveSettingRecognition()
                                                }}>Simpan</button>
                                                :
                                                <button type="button" className="btn ipresens-btn-color"><i
                                                    className="fa fa-refresh fa-spin"/> Loading</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    user: state.Auth.user,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingRecognition)