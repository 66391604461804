import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import {APIKEY, IMG_HOST} from "../../helper/host";
import {Helmet} from "react-helmet";
import {Empty, Spin} from "antd";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    Row
} from 'reactstrap'
import i18n from "i18next";
import {GoogleApiWrapper} from 'google-maps-react';
import 'react-tabs/style/react-tabs.css';
import CurrencyInput from 'react-currency-input-field';
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {pageNumber} from "../../functions/functionGeneral";
import format from "format-number";
import Switch from "react-switch";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import EmptyDataImage from "../../assets/images/data-kosong.png";

export class DetailGajiKaryawan extends Component {
    constructor(props) {
        super(props);
        let tabIndex = 0;
        const pathArr = props.location.pathname.split('/')
        if (pathArr.length == 6) {
            tabIndex = parseInt(pathArr[pathArr.length - 1])
        }

        this.state = {
            loading             : false,
            data_karyawan       : [],
            divisi              : [],
            jabatan             : [],
            data_potongan       : [],
            data_term           : [],
            kompensasiKaryawan  : [],
            rows                : [{}],
            tabIndex             : tabIndex
        }
        this.setTabIndex = this.setTabIndex.bind(this)
        this.handleSubmitAmount     = this.handleSubmitAmount.bind(this);
        this.handleChangeStock      = this.handleChangeStock.bind(this);
    }

    setTabIndex(index){
        this.setState({
            tabIndex : index
        })
    }

    addClick(){
        this.setState({
            rows : [...this.state.rows, { k_kompensasi_type: "", p_term_id: "" ,k_kompensasi_amount:""}]
        })
    }

    handleSubmitAmount(event) {
        const target = event.target;
        const name = target.name;
        const value = parseInt(target.value.split(',').join(''));

        let formData = {
            karyawan_id     : this.props.match.params.id,
            form : { [name]: value }
        }

        this.props.request('karyawan/payroll_update', formData, 'POST')
            .then(response => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        loading           : true,
                        data: [],

                    })
                    this.loadDataKaryawan()
                }
            });
    }

    handlePotonganType= id => (e,maskedvalue) => {

        var { name,value } = e.target;
        var list          = [...this.state.data_potongan];

        list[id]    = {...list[id], [name]: value};

        this.setState({
            data_potongan     : list,
        })
    };

    handlePotonganAmount= id => (e,maskedvalue) => {

        var { name,value } = e.target;
        var list          = [...this.state.data_potongan];
        var str             = maskedvalue.split('Rp ').join('');
        var final           = str.split('.').join('');

        list[id]    = {...list[id], [name]: final};

        this.setState({
            data_potongan     : list,
        })
    };

    handleChangeStock= id => e => {

        const { name,value } = e.target;
        const list          = [...this.state.rows];

        list[id]    = {...list[id], [name]: value};

        this.setState({
            rows    : list,
        })
    };

    handleSubmitPotongan() {
        let formData = {
            data                : this.state.data_potongan,
        }

        this.props.request('karyawan_potongan/update', formData, 'POST')
            .then(response => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        loading           : true
                    })
                    this.loadDataPotongan()
                }
            });
    }

    handleSubmit(event) {
        this.setState({ loading: true })

        let formData = {
            rows                : this.state.rows,
            karyawan_id         : this.props.match.params.id,
        }
        this.props.request('kompensasi/add', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        loading           : false,
                        addDetail           : true,
                        rows                : [{}],

                    })
                    this.loadDataKompensasi()
                }
                else {
                    if (Array.isArray(response.message)) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                    this.setState({ loading: false })
                }

                this.setState({ loading: false })
            })
    }

    loadDataKaryawan() {
        this.setState({loading: true})
        let form = {
            slug : this.props.match.params.id,
        }
        this.props.request("karyawan/get_karyawan_by_slug", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan   : response.data,
                        divisi          : response.data.divisi,
                        jabatan         : response.data.jabatan
                    })
                }
                this.setState({loading: false})
            });
    }

    loadDataKompensasi() {
        this.setState({loading: true,visible:true})
        let form = {
            karyawan_id         : this.props.match.params.id,
            paginate            : 10,
            page                : this.state.currentPage + 1,
        }
        this.props.request("kompensasi/list_by_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        kompensasiKaryawan  : response.data.data,
                        pageCount           : response.data.last_page,
                        perPage             : response.data.per_page,
                    })
                }
                this.setState({loading: false})
            });
    }

    loadDataTerm(){
        this.setState({loading: true})

        this.props.request("kompensasi/list-by-payroll-term",  'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_term   : response.data,

                    })
                }
                this.setState({loading: false})
            });
    }

    loadDataPotongan(){
        this.setState({loading: true})
        let form = {
            karyawan_id         : this.props.match.params.id,
        }
        this.props.request("karyawan_potongan/list",form,  'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_potongan   : response.data,
                    })
                }
                this.setState({loading: false})
            });
    }

    componentDidMount() {
        this.loadDataKaryawan()
        this.loadDataKompensasi()
        this.loadDataTerm()
        this.loadDataPotongan()
    }

    render() {
        let no = 0;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Detail Karyawan - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title="Detail Karyawan" parent="Karyawan" parentLink='/employee/employee-list'/>
                <Col lg="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            {this.state.loading ?
                                <Spin size="large" tip="Sedang memuat data..." style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}/>
                                :
                                <div>
                                    {/*profil Karyawan*/}
                                    <Card className="card-shadow-primary profile-responsive card-border">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner bg-primary">
                                                <div className="menu-header-content btn-pane-right">
                                                    <div >
                                                        {this.state.data_karyawan.karyawan_foto !== null ? (
                                                            <img
                                                                id="avatar"
                                                                src={IMG_HOST + 'frontapp/karyawan/' + this.state.data_karyawan.karyawan_foto}
                                                                className="img-fluid blur-up lazyloaded"/>) : (
                                                            <img
                                                                id="avatar"
                                                                src={IMG_HOST + 'user-profile.png'}
                                                                className="img-fluid blur-up lazyloaded"/>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <h5 className="menu-header-title" style={{color:"#ffffff"}}>{this.state.data_karyawan.karyawan_nama}</h5>
                                                        <h6 style={{color:"#ffffff",fontSize: 12 }}>{this.state.data_karyawan.divisi && this.state.data_karyawan.divisi.divisi_name ? this.state.data_karyawan.divisi.divisi_name : '-'}</h6>
                                                        <h6 style={{color:"#ffffff",fontSize: 12 }}>{this.state.data_karyawan.jabatan && this.state.data_karyawan.jabatan.jabatan_nama ? this.state.data_karyawan.jabatan.jabatan_nama : '-'}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <div>
                                        <Row>
                                            <Col sm="12" md="8" lg="12">
                                                {this.state.loading && !this.state.data_karyawan ?
                                                    <Spin size="large" tip="Sedang memuat data..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }}/>
                                                    :
                                                    <div>
                                                        <Row form className="text-center col-md-12 mt-3"
                                                             style={{alignItems: "center", justifyContent: "center"}}>
                                                            <Col md={5} className="pl-5">
                                                                <FormGroup>
                                                                    <Label style={{fontSize: "12px", marginBottom: "1px"}}>
                                                                        (UPAH LEMBUR)
                                                                    </Label>
                                                                    <InputGroup className="mb-3">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <div className="input-group-text"
                                                                                 style={{height: '38px', backgroundColor: "#eef1f3"}}>
                                                                                <Label style={{marginTop: '5px'}}>Rp</Label>
                                                                            </div>
                                                                        </InputGroupAddon>
                                                                        <CurrencyInput
                                                                            name="karyawan_lembur"
                                                                            className="form-control text-center"
                                                                            defaultValue={this.state.data_karyawan.karyawan_lembur}
                                                                            id="karyawan_lembur"
                                                                            precision={0}
                                                                            onKeyPress={(event) => {
                                                                                if(event.which == 13) {
                                                                                    event.preventDefault()
                                                                                    this.handleSubmitAmount(event)
                                                                                }
                                                                            }}
                                                                            onBlur={(event) => {
                                                                                this.handleSubmitAmount(event)
                                                                            }}
                                                                        />
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <div className="input-group-text"
                                                                                 style={{height: '38px', backgroundColor: "#eef1f3"}}>
                                                                                <Label
                                                                                    style={{marginTop: '5px'}}>/Jam
                                                                                </Label>
                                                                            </div>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={5} className="pl-5">
                                                                <FormGroup>
                                                                    <Label style={{fontSize: "12px", marginBottom: "1px"}}>(GAJI KARYAWAN)</Label>
                                                                    <InputGroup className="mb-3">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <div className="input-group-text"
                                                                                 style={{height: '38px', backgroundColor: "#eef1f3"}}>
                                                                                <Label style={{marginTop: '5px'}}>Rp</Label>
                                                                            </div>
                                                                        </InputGroupAddon>
                                                                        <CurrencyInput
                                                                            name="karyawan_payroll_amount"
                                                                            className="form-control text-center"
                                                                            defaultValue={this.state.data_karyawan.karyawan_payroll_amount}
                                                                            id="karyawan_payroll_amount"
                                                                            precision={0}
                                                                            onKeyPress={(event) => {
                                                                                if(event.which == 13) {
                                                                                    event.preventDefault()
                                                                                    this.handleSubmitAmount(event)
                                                                                }
                                                                            }}
                                                                            onBlur={(event) => {
                                                                                this.handleSubmitAmount(event)
                                                                            }}
                                                                        />
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <div className="input-group-text"
                                                                                 style={{height: '38px', backgroundColor: "#eef1f3"}}>
                                                                                <Label
                                                                                    style={{marginTop: '5px'}}>/{this.state.divisi.divisi_payroll_type}
                                                                                </Label>
                                                                            </div>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        {/*form potongan*/}
                                                        <CardHeader style={{alignItems: "center",justifyContent: "center"}}>
                                                            <div className="card-title pl-0 mb-3 text-center pt-3">(Form Input Potongan) </div>
                                                        </CardHeader>
                                                        <Col lg="12">
                                                            {this.state.data_potongan.map((item, id) =>{
                                                                return(
                                                                    <Row>
                                                                        <Col className="text-center">
                                                                            {item.payroll_term.p_term_name}
                                                                        </Col>
                                                                        <Col>
                                                                            <FormGroup>
                                                                                <select
                                                                                    name="k_potongan_type"
                                                                                    id="k_potongan_type"
                                                                                    className="form-control"
                                                                                    onChange={this.handlePotonganType(id)}
                                                                                    placeholder="Type Potongan"
                                                                                    value={item.k_potongan_type}
                                                                                >
                                                                                    <option value={""}>Type Potongan</option>
                                                                                    <option value="jam">Jam</option>
                                                                                    <option value="hari">Hari</option>
                                                                                    <option value="bulan">Bulan</option>
                                                                                </select>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col>
                                                                            <FormGroup>
                                                                                <CurrencyInput
                                                                                    name="k_potongan_amount"
                                                                                    className="form-control"
                                                                                    thousandSeparator="."
                                                                                    defaultValue={item.k_potongan_amount}
                                                                                    prefix="Rp "
                                                                                    id="k_potongan_amount"
                                                                                    precision={0}
                                                                                    onChangeEvent={this.handlePotonganAmount(id)}
                                                                                    placeholder="Nominal potongan"
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })}
                                                            <Row form className="text-center col-md-12 mt-3 mb-3" style={{alignItems: "center", justifyContent: "center"}}>
                                                                <Button
                                                                    onClick={this.handleSubmitPotongan.bind(this)}
                                                                    color="success"
                                                                    className="btn-sm"
                                                                >
                                                                    Save Potongan
                                                                </Button>
                                                            </Row>
                                                        </Col>
                                                    </div>
                                                }
                                                {/*form Kompensasi*/}
                                                <Card>
                                                    <CardHeader style={{alignItems: "center",justifyContent: "center"}}>
                                                        <div className="card-title pl-0 mb-3 text-center pt-3">(Form Input Kompensasi) </div>
                                                    </CardHeader>
                                                    <Col lg="12">
                                                        {this.state.rows.map((data, id) =>{
                                                            return(
                                                                <Row className="row-md-12">
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Input bsSize="sm" type="select" name="p_term_id" value={data.p_term_id}
                                                                                   onChange={this.handleChangeStock(id)}>
                                                                                <option value="">- Payroll Istillah -</option>
                                                                                {
                                                                                    this.state.data_term.map((item, index) => {
                                                                                        return (
                                                                                            <option
                                                                                                key={index}
                                                                                                value={item.p_term_id}
                                                                                                selected={item.p_term_id == this.state.data_term.p_term_id}>
                                                                                                {item.p_term_name}
                                                                                            </option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <Input
                                                                                className="form-control text-center"
                                                                                name="k_kompensasi_amount"
                                                                                id="k_kompensasi_amount"
                                                                                type="number"
                                                                                onChange={this.handleChangeStock(id)}
                                                                                value={data.k_kompensasi_amount}
                                                                                placeholder="Nominal Kompensasi"
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <select
                                                                                name="k_kompensasi_type"
                                                                                id="k_kompensasi_type"
                                                                                className="form-control text-center"
                                                                                onChange={this.handleChangeStock(id)}
                                                                                placeholder="Type Kompensasi"
                                                                            >
                                                                                <option value={""}>Type Kompensasi</option>
                                                                                <option value="jam">Jam</option>
                                                                                <option value="hari">Hari</option>
                                                                                <option value="bulan">Bulan</option>
                                                                            </select>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="2">
                                                                        <ButtonGroup size="sm">
                                                                            <div>
                                                                                {this.state.rows.length !== 1 &&
                                                                                <Button
                                                                                    onClick={this.removeClick.bind(this,id)}
                                                                                    color='danger'
                                                                                >
                                                                                    <i className="fa fa-trash"></i>
                                                                                </Button>}
                                                                                &nbsp;
                                                                                {this.state.rows.length - 1 === id &&
                                                                                <Button
                                                                                    onClick={this.addClick.bind(this)}
                                                                                    color="info"
                                                                                >
                                                                                    <i className="fa fa-plus"></i>
                                                                                </Button>
                                                                                }
                                                                            </div>
                                                                        </ButtonGroup>
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        })}
                                                    </Col>
                                                    <Row form className="text-center col-md-12 mt-3 mb-3" style={{alignItems: "center", justifyContent: "center"}}>
                                                        <Button className="btn btn-success btn-sm"
                                                                onClick={(event) => {
                                                                    this.handleSubmit(event)
                                                                }}
                                                        >
                                                            SAVE
                                                        </Button>
                                                    </Row>
                                                </Card>
                                                {/*list kompensasi*/}
                                                <div>
                                                    {!this.state.loading && this.state.kompensasiKaryawan.length === 0 ?
                                                        <Empty description={i18n.t("datakosong")}
                                                               image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                        :
                                                        <Card>
                                                            <CardHeader style={{alignItems: "center",justifyContent: "center"}}>
                                                                <div className="card-title pl-0 mb-3 text-center pt-3">(Data Kompensasi) </div>
                                                            </CardHeader>
                                                            <div>
                                                                <Table striped className="mb-5">
                                                                    <Thead>
                                                                        <Tr>
                                                                            <Th>
                                                                                #
                                                                            </Th>
                                                                            <Th>Nama Kompensasi</Th>
                                                                            <Th>Nominal Kompensasi</Th>
                                                                            <Th>Type Kompensasi</Th>
                                                                            <Th>Status</Th>
                                                                            <Th>Aksi</Th>
                                                                        </Tr>
                                                                    </Thead>
                                                                    <Tbody>
                                                                        {this.state.loading &&
                                                                        <Tr>
                                                                            <Td colSpan={9}>
                                                                                <Spin size="large" tip="Loading..." style={{
                                                                                    width: '100%',
                                                                                    margin: 'auto',
                                                                                    marginBottom: '10px',
                                                                                    marginTop: '10px'
                                                                                }}/>
                                                                            </Td>
                                                                        </Tr>
                                                                        }
                                                                        {this.state.kompensasiKaryawan.map((item, index) => {
                                                                            no += 1
                                                                            return (
                                                                                <Tr>
                                                                                    <small>
                                                                                        <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                                    </small>
                                                                                    <Td>
                                                                                        {item.payroll_term.p_term_name}
                                                                                    </Td>
                                                                                    <Td>
                                                                                        {format({prefix: '', integerSeparator: "."})(item.k_kompensasi_amount)}
                                                                                    </Td>
                                                                                    <Td>
                                                                                        {item.k_kompensasi_type}
                                                                                    </Td>
                                                                                    <Td>
                                                                                        <Switch
                                                                                            id={`detail-${item.k_kompensasi_id}`}
                                                                                            name="k_kompensasi_status"
                                                                                            checked={
                                                                                                item.k_kompensasi_status === '1' ? true : false
                                                                                            }
                                                                                            onChange={(val) => {
                                                                                                this.setState({
                                                                                                    loading: true,
                                                                                                    data: []
                                                                                                })

                                                                                                let formData = {
                                                                                                    id: item.k_kompensasi_id,
                                                                                                }

                                                                                                this.props.request('kompensasi/ubah_status', formData, 'POST')
                                                                                                    .then((response) => {
                                                                                                        if (response.success) {
                                                                                                            Swal.fire({
                                                                                                                title: 'Berhasil!',
                                                                                                                text: response.message,
                                                                                                                icon: 'success',
                                                                                                                showConfirmButton: false,
                                                                                                                timer: 1500,
                                                                                                            })
                                                                                                        } else {
                                                                                                            if (Array.isArray(response.message)) {
                                                                                                                response.message.map(item => {
                                                                                                                    toast['error'](item)
                                                                                                                    return true
                                                                                                                })
                                                                                                            } else {
                                                                                                                toast['error'](response.message)
                                                                                                            }
                                                                                                        }
                                                                                                        this.loadDataKompensasi()
                                                                                                        this.setState({loading: false})
                                                                                                    })

                                                                                            }}
                                                                                            onColor="#1890ff"
                                                                                            offColor="#eb4034"
                                                                                            handleDiameter={16}
                                                                                            uncheckedIcon={
                                                                                                <div
                                                                                                    style={{
                                                                                                        display: "flex",
                                                                                                        justifyContent: "center",
                                                                                                        alignItems: "center",
                                                                                                        height: "100%",
                                                                                                        fontSize: 3,
                                                                                                        color: "white",
                                                                                                        paddingRight: -5
                                                                                                    }}
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faTimes}
                                                                                                                     style={{fontSize: 15}}/>
                                                                                                </div>
                                                                                            }
                                                                                            checkedIcon={
                                                                                                <div
                                                                                                    style={{
                                                                                                        display: "flex",
                                                                                                        justifyContent: "center",
                                                                                                        alignItems: "center",
                                                                                                        height: "100%",
                                                                                                        fontSize: 4,
                                                                                                        color: "white",
                                                                                                        paddingLeft: 3
                                                                                                    }}
                                                                                                >
                                                                                                    <FontAwesomeIcon icon={faCheck}
                                                                                                                     style={{fontSize: 15}}/>
                                                                                                </div>
                                                                                            }
                                                                                            boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                                            height={18}
                                                                                            width={40}
                                                                                            className="mr-1"

                                                                                        />
                                                                                    </Td>
                                                                                    <Td>
                                                                                        <a onClick={() => {
                                                                                            this.setState({
                                                                                                idDel: item.k_kompensasi_id
                                                                                            })
                                                                                            this.wantDelete()
                                                                                        }}><i className="fa fa-trash" style={{
                                                                                            width: 35,
                                                                                            fontSize: 20,
                                                                                            padding: 11,
                                                                                            color: 'rgb(255 37 37)'
                                                                                        }}
                                                                                        ></i></a>
                                                                                    </Td>
                                                                                </Tr>
                                                                            )
                                                                        })
                                                                        }
                                                                    </Tbody>

                                                                </Table>
                                                            </div>
                                                        </Card>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            }

                        </CardBody>
                    </Card>
                </Col>
            </Fragment>
        )
    }
}

export default  GoogleApiWrapper({
    apiKey: APIKEY
}) (DetailGajiKaryawan)
