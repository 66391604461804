import React, { Fragment } from "react";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import Progres from "./progres";
import { Empty, Spin, Tooltip } from 'antd';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Select } from "antd";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { pageNumber } from "../../functions/functionGeneral";
import { IMG_HOST } from "../../helper/host";
import Dropdown from 'react-bootstrap/Dropdown';
import i18n from "i18next";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg";
import ArrowRight from '../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import "./style.css";
import EmptyDataImage from "../../assets/images/data-kosong.png";

class KaryawanGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shift: [],
            lokasi: [],
            divisi: [],
            karyawan: [],
            jabatan: [],
            fileList: [],
            fileKtp: [],
            fileSelfie: [],
            kunci_pengaturan: [],
            progresModal: false,
            loading: false,
            modalAdd: false,
            totalData: 0,
            totalPerpage: 0,
            pageCount: 0,
            perPage: 0,
            currentPage: 0,
            idDel: 0,
            foto_karyawan: '',
            foto_ktp: '',
            foto_selfie: '',
            nama_karyawan: '',
            email_karyawan: '',
            no_hp_karyawan: '',
            alamat_karyawan: '',
            ttl_karyawan: '',
            gender_karyawan: 'Laki-laki',
            divisi_karyawan: '',
            jabatan_karyawan: '',
            lokasi_karyawan: null,
            filter_nama: ""
        }
        this.wantDelete = this.wantDelete.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(e) {
        const name = e.target.name
        const value = e.target.value

        this.setState({
            [name]: value
        })
    }

    simpanKaryawan = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            lokasi_karyawan: this.state.lokasi_karyawan,
            foto_karyawan: this.state.foto_karyawan,
            foto_selfie: this.state.foto_selfie,
            foto_ktp: this.state.foto_ktp,
            nama_karyawan: this.state.nama_karyawan,
            gender_karyawan: this.state.gender_karyawan,
            email_karyawan: this.state.email_karyawan,
            jabatan_karyawan: this.state.jabatan_karyawan,
            divisi_karyawan: this.state.divisi_karyawan,
            ttl_karyawan: this.state.ttl_karyawan,
            no_hp_karyawan: this.state.no_hp_karyawan,
            alamat_karyawan: this.state.alamat_karyawan,
        }

        this.props.request("karyawan/add_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.success,
                        icon: 'success'
                    })
                    this.setState({
                        modalAdd: false
                    }, () => this.loadData())
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus karyawan?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                        data: []
                    })
                    let form = {
                        karyawan_id: this.state.idDel
                    }
                    this.props.request("karyawan/delete_karyawan", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    loading: true,
                                    data: []
                                })
                                this.loadData()
                                this.loadCheck()
                            }
                        });
                }
            })
    }

    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift: response.shift,
                        lokasi: response.lokasi,
                        divisi: response.divisi,
                        karyawan: response.karyawan
                    }, () => {
                        if (this.state.shift.length !== 0 && this.state.lokasi.length !== 0 && this.state.divisi.length !== 0 && this.state.karyawan.length !== 0 && this.props.user.perusahaan.perusahaan_nama !== "") {
                            this.setState({
                                progresModal: true
                            })
                        }
                    })
                }
            })
    }

    loadJabatan() {
        let form = {}
        this.props.request("get_jabatan_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        jabatan: response.data,
                    })
                }
            });
    }

    loadData() {
        this.setState({
            loading: true
        })
        let form = {
            paginate: 20,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
        }
        this.props.request("karyawan/get_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })

                    const kunci_pengaturan = response.data.data.map(item => {
                        let kunci = "Kunci ";
                        if (item.kunci_lokasi === '1') {
                            kunci += 'Lokasi';
                        }

                        if (item.kunci_foto === '1') {
                            kunci += 'Foto'
                        }

                        if (item.kunci_kunjungan === '1') {
                            kunci += 'Kunjungan'
                        }

                        return kunci;
                    });

                    this.setState({ kunci_pengaturan });
                }
            });
    }

    componentDidMount() {
        if (this.props.user.perusahaan.shift.length === 0 || this.props.user.perusahaan.divisi_many.length === 0 || this.props.user.perusahaan.lokasi.length === 0) {
            Swal.fire({
                text: "Lengkapi master data terlebih dahulu!",
                icon: 'warning',
                confirmButtonText: 'OK'
            }).then((result) => {
                if (result.isConfirmed) {
                    if (this.props.user.perusahaan.shift.length === 0) {
                        this.props.history.push("/guide/working-hours")
                    } else if (this.props.user.perusahaan.divisi_many === 0) {
                        this.props.history.push("/guide/divisi")
                    } else {
                        this.props.history.push("/guide/lokasi")
                    }
                } else {
                    if (this.props.user.perusahaan.shift.length === 0) {
                        this.props.history.push("/guide/working-hours")
                    } else if (this.props.user.perusahaan.divisi_many === 0) {
                        this.props.history.push("/guide/divisi")
                    } else {
                        this.props.history.push("/guide/lokasi")
                    }
                }
            })
        }
        this.loadCheck()
        this.loadData()
        this.loadJabatan()
    }

    render() {
        const { Option } = Select;
        let no = 0;
        const stylefoto = {
            height: '50px',
            width: '50px',
            objectFit: 'cover'
        }
        const style = {
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px 24px",
                fontWeight: 700,
                fontSize: "24px",
                lineHeight: "36px",
                color: "#143B5E",
                border: "1px solid #DDDDDD",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px"
            },
            card_body: {
                border: "1px solid #DDDDDD",
                padding: "24px"
            },
            search_icon: {
                borderBottomRightRadius: "0px",
                borderTopRightRadius: "0px",
                backgroundColor: "#F9F9F9",
                borderRight: "0px"
            },
            search_input: {
                backgroundColor: "#F9F9F9",
                padding: "14px 10px 14px 16px",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
                color: "#5D6F80",
                borderLeft: "0px"
            },
            add_card_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px 24px",
            },
            add_font_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            add_card_body: {
                padding: "24px 24px 24px 37px"
            },
            add_card_footer: {
                backgroundColor: "#F5F5F5",
                padding: "16px 24px"
            }
        }

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Daftar Karyawan - iPresens</title>
                    <meta name="description" content="Daftar Jam Kerja" />
                </Helmet>
                <Progres
                    request={this.props.request}
                    history={this.props.history}
                    loadCheck={() => this.loadCheck()}
                    shift={this.state.shift}
                    lokasi={this.state.lokasi}
                    divisi={this.state.divisi}
                    karyawan={this.state.karyawan}
                    progresModal={this.state.progresModal}
                    setState={(name, val) => this.setState(name, val)}
                    set_auth={this.props.set_auth}
                    api_token={this.props.api_token}
                />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-0">
                            <div className="card">
                                <div className="card-header" style={style.card_header}>Karyawan</div>
                                <div className="card-body" style={style.card_body}>
                                    <div className="row">
                                        <div className="col-md-6 mb-2">
                                            <div className='d-flex flex-row'>
                                                <div className='flex-fill pr-2'>
                                                    <div className='input-group' style={{ height: "100%" }}>
                                                        <span className="input-group-text search-karyawan-img" id="basic-addon1">
                                                            <img alt='' src={SearchIcon} />
                                                        </span>
                                                        <input type="text" name='filter_nama' onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                this.setState({
                                                                    loading: true,
                                                                    data: []
                                                                }, () => this.loadData())
                                                            }
                                                        }} value={this.state.filter_nama} onChange={this.handleChange} className="form-control karyawan-search-karyawan-form" placeholder="Cari nama karyawan..." aria-label="Username" aria-describedby="basic-addon1" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='karyawan-refresh-button' onClick={() => {
                                                        this.setState({
                                                            filter_nama: "",
                                                            loading: true,
                                                            data: []
                                                        }, () => this.loadData())
                                                    }}>
                                                        <img alt='' src={RefreshIcon} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <Link className="btn ipresens-btn-color text-capitalize float-right button-w-full" to="/guide/add-karyawan"><img alt="" src={PlusWhiteIcon} /> {i18n.t("tambahkaryawan")}</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {!this.state.loading && this.state.data.length === 0 ?
                                                <Empty description={i18n.t("datakosong")}
                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                :
                                                <div className="table-responsive">
                                                    <table className="table table-striped mb-5">
                                                        <thead>
                                                            <tr>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E"
                                                                }} width="30">#</th>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E"
                                                                }} className="text-left">Foto</th>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E"
                                                                }} className="text-left">Nama Karyawan</th>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E",
                                                                    minWidth: "131px"
                                                                }} className="text-left">Jabatan-Divisi</th>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E",
                                                                    minWidth: "136px"
                                                                }} className="text-left">Lokasi Presensi</th>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E"
                                                                }} className="text-left">Ktp</th>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E"
                                                                }} className="text-left">Selfie</th>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E"
                                                                }} className="text-left">Kunci Pengaturan</th>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E"
                                                                }} className="text-left">Status</th>
                                                                <th style={{
                                                                    border: "none",
                                                                    fontWeight: 700,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E"
                                                                }} className="text-left"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.loading &&
                                                                <Tr>
                                                                    <Td colSpan={10}>
                                                                        <Spin size="large" tip="Loading..." style={{
                                                                            width: '100%',
                                                                            margin: 'auto',
                                                                            marginBottom: '10px',
                                                                            marginTop: '10px'
                                                                        }} />
                                                                    </Td>
                                                                </Tr>
                                                            }
                                                            {this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <tr>
                                                                        <small>
                                                                            <th style={{ border: "none" }} scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</th>
                                                                        </small>
                                                                        <td style={{ border: "none" }} align="center">{item.karyawan_foto !== null ? (
                                                                            <img alt="" style={stylefoto}
                                                                                src={IMG_HOST + 'frontapp/karyawan/' + item.karyawan_foto} />
                                                                        ) : (
                                                                            <img alt="" style={stylefoto}
                                                                                src={IMG_HOST + "user-profile.png"} />
                                                                        )}</td>
                                                                        <td style={{ border: "none" }} align="left"><span style={{ fontWeight: 500, color: "#248CE7", fontSize: "14px" }}><a href={"/karyawan/profil/detail/" + item.karyawan_slug}>{item.karyawan_nama}</a></span>
                                                                            <br />
                                                                            <div>
                                                                                <p className={'text-muted fs-5'} style={{ fontSize: 12 }}>{item.karyawan_email}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ border: "none" }} align="left">
                                                                            <span style={{ fontWeight: 500, fontSize: "14px" }}>{item.jabatan && item.jabatan.jabatan_nama ? item.jabatan.jabatan_nama : '-'}</span><br /><div><p className={'text-muted fs-5'} style={{ fontSize: 12 }}>{item.divisi && item.divisi.divisi_name ? item.divisi.divisi_name : '-'}</p></div>
                                                                        </td>
                                                                        <td style={{ border: "none" }} align="left">{item.lokasi.length > 0 ? item.lokasi.map((item2, index2) => {
                                                                            return (<span className="" style={{ marginRight: '2px', fontWeight: 500, fontSize: "14px" }}>{item2.lokasi_nama}{item.lokasi.length > (index2 + 1) && ", "}</span>)
                                                                        }) : (<div className="" style={{ marginRight: '2px', fontWeight: 500, fontSize: "14px" }}>Semua Lokasi</div>)}</td>
                                                                        <td style={{ border: "none" }}>
                                                                            {item.karyawan_ktp == null ? <i
                                                                                className="fa fa-minus" style={{
                                                                                    width: 35,
                                                                                    fontSize: 12,
                                                                                    padding: 11,
                                                                                    color: '#2d2d2d'
                                                                                }} /> :
                                                                                <i className="fa fa-check" style={{
                                                                                    width: 35,
                                                                                    fontSize: 12,
                                                                                    padding: 11,
                                                                                    color: '#6610f2'
                                                                                }} />}
                                                                        </td>
                                                                        <td style={{ border: "none" }}>
                                                                            {item.karyawan_selfie == null ? <i
                                                                                className="fa fa-minus" style={{
                                                                                    width: 35,
                                                                                    fontSize: 12,
                                                                                    padding: 11,
                                                                                    color: '#2d2d2d'
                                                                                }} /> :
                                                                                <i className="fa fa-check" style={{
                                                                                    width: 35,
                                                                                    fontSize: 12,
                                                                                    padding: 11,
                                                                                    color: '#6610f2'
                                                                                }} />}

                                                                        </td>
                                                                        <td style={{ border: "none" }} className="text-left">
                                                                            <Dropdown className='px-1'>
                                                                                <Dropdown.Toggle style={{ textTransform: "capitalize", fontSize: "14px" }} className="border border-info-subtle px-1" variant="outline" id="dropdown-basic">
                                                                                    {this.state.kunci_pengaturan[index] ? this.state.kunci_pengaturan[index] !== "Kunci " ? this.state.kunci_pengaturan[index].replace("LokasiFoto", "Lokasi, Foto").replace("FotoKunjungan", "Foto, Kunjungan").replace("LokasiKunjungan", "Lokasi, Kunjungan").substring(0, 11) + "..." : "Pilih Disini" : "Pilih Disini"}
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item style={{ fontSize: "14px" }} href="#/action-1" onClick={(val) => {
                                                                                        var data = this.state.data

                                                                                        data[index]['kunci_lokasi'] = (val) ? '1' : '0'

                                                                                        this.setState({
                                                                                            loading: true,
                                                                                            data: []
                                                                                        })

                                                                                        let formData = {
                                                                                            karyawan_id: item.karyawan_id,
                                                                                        }

                                                                                        this.props.request('karyawan/kunci-lokasi', formData, 'POST')
                                                                                            .then((response) => {
                                                                                                if (response.success) {
                                                                                                    Swal.fire({
                                                                                                        title: 'Berhasil!',
                                                                                                        text: response.message,
                                                                                                        icon: 'success',
                                                                                                        showConfirmButton: false,
                                                                                                        timer: 1500,
                                                                                                    })
                                                                                                }
                                                                                                else {
                                                                                                    if (Array.isArray(response.message)) {

                                                                                                        response.message.map(item => {
                                                                                                            toast['error'](item)
                                                                                                            return true
                                                                                                        })
                                                                                                    } else {
                                                                                                        toast['error'](response.message)
                                                                                                    }
                                                                                                }
                                                                                                this.loadData()
                                                                                                this.setState({ loading: false })
                                                                                            })

                                                                                    }}>Kunci Lokasi {item.kunci_lokasi === '1' ? <i className='fa fa-check pull-right' style={{ color: "#6610f2" }}></i> : ''}</Dropdown.Item>
                                                                                    <Dropdown.Item style={{ fontSize: "14px" }} href="#/action-2" onClick={(val) => {
                                                                                        var data = this.state.data


                                                                                        data[index]['kunci_foto'] = (val) ? '1' : '0'

                                                                                        this.setState({
                                                                                            loading: true,
                                                                                            data: []
                                                                                        })

                                                                                        let formData = {
                                                                                            karyawan_id: item.karyawan_id,

                                                                                        }

                                                                                        this.props.request('karyawan/kunci-foto', formData, 'POST')
                                                                                            .then((response) => {
                                                                                                if (response.success) {
                                                                                                    Swal.fire({
                                                                                                        title: 'Berhasil!',
                                                                                                        text: response.message,
                                                                                                        icon: 'success',
                                                                                                        showConfirmButton: false,
                                                                                                        timer: 1500,
                                                                                                    })
                                                                                                }
                                                                                                else {
                                                                                                    if (Array.isArray(response.message)) {

                                                                                                        response.message.map(item => {
                                                                                                            toast['error'](item)
                                                                                                            return true
                                                                                                        })
                                                                                                    } else {
                                                                                                        toast['error'](response.message)
                                                                                                    }
                                                                                                }
                                                                                                this.loadData()
                                                                                                this.setState({ loading: false })
                                                                                            })

                                                                                    }}>Kunci Foto {item.kunci_foto === '1' ? <i className='fa fa-check pull-right' style={{ color: "#6610f2" }}></i> : ''}</Dropdown.Item>
                                                                                    <Dropdown.Item style={{ fontSize: "14px" }} href="#/action-3" onClick={(val) => {
                                                                                        var data = this.state.data

                                                                                        data[index]['kunci_kunjungan'] = (val) ? '1' : '0'

                                                                                        this.setState({
                                                                                            loading: true,
                                                                                            data: []
                                                                                        })

                                                                                        let formData = {
                                                                                            karyawan_id: item.karyawan_id,
                                                                                        }

                                                                                        this.props.request('karyawan/kunci-kunjungan', formData, 'POST')
                                                                                            .then((response) => {
                                                                                                if (response.success) {
                                                                                                    Swal.fire({
                                                                                                        title: 'Berhasil!',
                                                                                                        text: response.message,
                                                                                                        icon: 'success',
                                                                                                        showConfirmButton: false,
                                                                                                        timer: 1500,
                                                                                                    })
                                                                                                }
                                                                                                else {
                                                                                                    if (Array.isArray(response.message)) {

                                                                                                        response.message.map(item => {
                                                                                                            toast['error'](item)
                                                                                                            return true
                                                                                                        })
                                                                                                    } else {
                                                                                                        toast['error'](response.message)
                                                                                                    }
                                                                                                }
                                                                                                this.loadData()
                                                                                                this.setState({ loading: false })
                                                                                            })

                                                                                    }}>Kunci Kunjungan {item.kunci_kunjungan === '1' ? <i className='fa fa-check' style={{ color: "#6610f2" }}></i> : ''}</Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </td>
                                                                        <td style={{ border: "none" }} align="center">{item.status_karyawan === 'active' ?

                                                                            <div style={{ color: "#569E4A", fontSize: "14px" }}><small>Aktif</small></div>
                                                                            :
                                                                            <div style={{ color: "#DB381A", fontSize: "14px" }}><small>Non-Aktif</small></div>
                                                                        }</td>
                                                                        <td style={{ border: "none" }} align="center">
                                                                            <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                                                <div className={'p-2'}>
                                                                                    <a href={"/guide/detail-karyawan/" + item.karyawan_id} style={{ marginRight: "50px" }} className={'text-dark'}>Detail</a><br />
                                                                                    <a href={"/guide/edit-karyawan/" + item.karyawan_id} className={'text-dark'}>Edit</a><br />
                                                                                    <a onClick={() => {
                                                                                        this.setState({
                                                                                            idDel: item.karyawan_id
                                                                                        })
                                                                                        this.wantDelete()
                                                                                    }} className={'text-danger'}>Hapus</a>
                                                                                </div>
                                                                            }>
                                                                                <a><i
                                                                                    className="fa fa-ellipsis-h" style={{
                                                                                        width: 35,
                                                                                        fontSize: 18,
                                                                                        padding: 11,
                                                                                        color: 'rgb(51 51 51)'
                                                                                    }}
                                                                                ></i></a>
                                                                            </Tooltip>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCount}
                                                forcePage={this.state.currentPage}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageClick}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(KaryawanGuide);
