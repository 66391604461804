import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import { Empty, Spin, Tooltip } from 'antd';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { IMG_HOST } from "../../helper/host";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import "./style.css"
import i18n from "i18next";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PinIcon from "../../assets/icons/pin.svg";
import {countDiffHourFromNow, dateFormat} from "../../helper/date";
import EmptyDataImage from "../../assets/images/data-kosong.png";

export class NotificationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            idDel: '',
            keyword: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {
        this.setState({
            keyword: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus pengumuman?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("pengumuman/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            paginate: 10,
            read_all: true,
            keyword: this.state.keyword,
            page: this.state.currentPage + 1,
        }
        this.props.request("get_notif_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0
        const stylefoto = {
            maxHeight: 60
        }
        const steps = [
            {
                selector: '.tambah-pengumuman',
                content: 'Anda dapat membuat Pengumuman disini',
            },
            {
                selector: '.daftar-pengumuman',
                content: 'Daftar Pengumuman anda dapat dilihat disini',
            },
        ];
        if (this.props.user && this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengumuman") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Notifikasi - iPresens</title>
                        <meta name="description" content="Daftar Notifikasi" />
                    </Helmet>
                    <Breadcrumb title="Daftar Notifikasi" parent="Notifikasi" setGuide={() => {
                        this.setState({ guide: true })
                    }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody}
                          onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid pt-4">
                        <div className="card">
                            <div className="card-header border-0 p-4">
                                <div className='notifikasi-col-filter'>
                                    <div className='input-group mr-1'>
                                        <span className='input-group-text notifikasi-filter-nama-input-text'>
                                            <img alt='' src={SearchIcon} />
                                        </span>
                                        <input className='form-control notifikasi-filter-nama-input mr-1' type='text' placeholder='Cari notifikasi ...' onKeyUp={(event) => {
                                            if (event.which === 13) {
                                                event.preventDefault()
                                                this.setState({
                                                    page: 0,
                                                    paging: this.state.paging,
                                                    loading: true,
                                                    data: []
                                                }, () => {
                                                    this.loadData()
                                                });
                                            }
                                        }} onChange={this.handleChange} name="keyword" value={this.state.keyword} />
                                        <button className='btn notifikasi-button-refresh' onClick={this.resetFilter}>
                                            <img alt='' src={RefreshIcon} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-notifikasi pt-3">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table className="table table-borderless mb-5">
                                        <Thead>
                                            <Tr>
                                                <Th className="notifikasi-th-table">#</Th>
                                                <Th className="notifikasi-th-table">Tipe</Th>
                                                <Th className="notifikasi-th-table">Pesan Notifikasi</Th>
                                                <Th className="notifikasi-th-table">Tanggal</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={5}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.data.map((item, index) => {
                                                let no = index + 1;
                                                let mulai = new Date(item.notif_created_at).toISOString().split('T')[0];
                                                let jangka_waktu = new Date(mulai);
                                                jangka_waktu.setDate(jangka_waktu.getDate() + parseInt(item.notif_pinned_duration));
                                                let akhir = jangka_waktu.toISOString().split('T')[0];

                                                return (
                                                    item.notif_pinned_duration > 0 && new Date().toISOString().split('T')[0] <= akhir ?
                                                        <Tr className="notifikasi-tr-table rounded-top">
                                                            <Td className="notifikasi-td-table-1">
                                                                {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                            </Td>
                                                            <Td className="notifikasi-td-table">{item.notif_type.toUpperCase()}</Td>
                                                            <Td className="notifikasi-td-table">
                                                                <a className="text-dark" href={item.notif_type === 'beban' ? "/submission/claim-expenses" : "/pengajuan/" + item.notif_type}>{item.notif_message}</a>
                                                            </Td>
                                                            <Td className="notifikasi-td-table-2">
                                                                {(countDiffHourFromNow(item.notif_created_at) > 24) ?
                                                                    dateFormat(item.notif_created_at, 'Do MMMM YYYY') :
                                                                    (countDiffHourFromNow(item.notif_created_at) + " Jam lalu")}
                                                                <span style={{ float:"right" }}>
                                                                    <img alt='' src={PinIcon}/>
                                                                </span>
                                                            </Td>
                                                        </Tr>
                                                        :
                                                        <Tr>
                                                            <Td className="notifikasi-td-table">
                                                                {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                            </Td>
                                                            <Td className="notifikasi-td-table">{item.notif_type.toUpperCase()}</Td>
                                                            <Td className="notifikasi-td-table">
                                                                <a className="text-dark" href={item.notif_type === 'beban' ? "/submission/claim-expenses" : "/pengajuan/" + item.notif_type}>{item.notif_message}</a>
                                                            </Td>
                                                            <Td className="notifikasi-td-table">
                                                                {(countDiffHourFromNow(item.notif_created_at) > 24) ?
                                                                    dateFormat(item.notif_created_at, 'Do MMMM YYYY') :
                                                                    (countDiffHourFromNow(item.notif_created_at) + " Jam lalu")}
                                                            </Td>
                                                        </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
