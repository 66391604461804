import React, { Component } from 'react'
import { Button, Drawer, Form, Select } from 'antd';
import Swal from "sweetalert2";
import i18n from "i18next";

class UserLevelAdd extends Component {
    constructor() {
        super();
        this.state = {
            level_name: '',
            level_page_limited: null,
            level_divisi_limited: null,
            loading: false
        }
    }

    levelHandler = () => {
        if (this.state.level_page_limited == null) {
            this.setState({
                level_page_limited: ''
            })
        } else {
            this.setState({
                level_page_limited: null
            })
        }
    }

    divisiHandler = () => {
        if (this.state.level_divisi_limited == null) {
            this.setState({
                level_divisi_limited: ''
            })
        } else {
            this.setState({
                level_divisi_limited: null
            })
        }
    }

    handleSubmit = () => {
        this.setState({
            loading: true
        })
        let form = {
            level_name: this.state.level_name,
            level_page_limited: this.state.level_page_limited,
            level_divisi_limited: this.state.level_divisi_limited,
        }

        this.props.request("userlevel/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({ level_name: '', level_page_limited: '' })
                    this.props.changeState({
                        level_name: '',
                        level_page_limited: '',
                        level_divisi_limited: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    loading: false
                })
            });
    }

    render() {
        const { Option } = Select;
        return (
            <Drawer
                title="Tambah User Level"
                width={350}
                onClose={() => {
                    this.props.changeState({ add_visible: false });
                }}
                visible={this.props.add_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({ add_visible: false, p_user_name: '' });
                        }}
                            style={{ marginRight: 8 }}>
                            Batal
                        </button>
                        <button type="button" className="btn ipresens-btn-color" onClick={this.handleSubmit} disabled={this.state.loading}>
                            {this.state.loading ?
                                <><i className={"fa fa-refresh fa-spin"}></i> Loading</>
                                :
                                "Simpan"
                            }
                        </button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama User Level</label>
                        <input name="level_name" className="form-control col-xl-12 col-md-12" placeholder="Nama User Level..."
                            type="text" required="" value={this.state.level_name}
                            onChange={(event) => {
                                const target = event.target;
                                const value = target.value;
                                const name = target.name;
                                this.setState({
                                    [name]: value
                                });
                            }} />
                    </div>
                    <div className="form-group">
                        <label>Akses Halaman Level User</label><br />
                        <label>
                            <input type="checkbox"
                                checked={this.state.level_page_limited === null}
                                onChange={() => this.levelHandler()} />
                            &nbsp;Seluruh Akses Halaman
                        </label>
                        {this.state.level_page_limited !== null &&
                            <Select name="level_page_limited"
                                style={{ width: '100%' }}
                                mode={"multiple"}
                                placeholder="Pilih Akses Halaman"
                                onChange={(event) => {
                                    this.setState({
                                        level_page_limited: event
                                    })
                                }}
                                options={[
                                    { label: 'Pengumuman', value: 'Pengumuman' },
                                    {
                                        label: 'Master Data',
                                        options: [
                                            { label: 'Shift', value: 'Shift' },
                                            { label: 'Jam Kerja', value: 'Jam Kerja' },
                                            { label: 'Divisi', value: 'Divisi' },
                                            { label: 'Jabatan', value: 'Jabatan' },
                                            { label: 'Lokasi Kunjungan', value: 'Lokasi Kunjungan' },
                                            { label: 'Lokasi Kerja', value: 'Lokasi Kerja' },
                                        ],
                                    },
                                    {
                                        label: 'Pengajuan Karyawan',
                                        options: [
                                            { label: 'Pengajuan Izin', value: 'Pengajuan Izin' },
                                            { label: 'Pengajuan Lembur', value: 'Pengajuan Lembur' },
                                            { label: 'Pengajuan Kasbon', value: 'Pengajuan Kasbon' },
                                            { label: 'Pengajuan Klaim beban', value: 'Pengajuan Klaim beban' },
                                        ],
                                    },
                                    { label: 'Karyawan', value: 'Karyawan' },
                                    { label: 'Presensi', value: 'Presensi' },
                                    { label: 'Kunjungan', value: 'Kunjungan' },
                                    { label: 'Tugas', value: 'Tugas' },
                                    {
                                        label: 'Payroll',
                                        options: [
                                            { label: 'Daftar Gaji Karyawan', value: 'Daftar Gaji Karyawan' },
                                            { label: 'Daftar Istilah', value: 'Daftar Istilah' },
                                            { label: 'Daftar Payroll', value: 'Daftar Payroll' },
                                            { label: 'Pengaturan Payroll', value: 'Pengaturan Payroll' },
                                        ],
                                    },
                                    { label: 'Pembayaran Kasbon', value: 'Pembayaran Kasbon' },
                                    { label: 'Langganan', value: 'Langganan' },
                                    { label: 'Pengaturan', value: 'Pengaturan' },
                                ]}
                            >
                            </Select>
                        }
                    </div>
                    <div className="form-group">
                        <label>Akses Divisi Karyawan</label><br />
                        <label>
                            <input type="checkbox"
                                checked={this.state.level_divisi_limited === null}
                                onChange={() => this.divisiHandler()} />
                            &nbsp;Seluruh Akses Divisi
                        </label>
                        {this.state.level_divisi_limited !== null &&
                            <Select name="level_divisi_limited"
                                style={{ width: '100%' }}
                                mode={"multiple"}
                                placeholder="Pilih Akses Divisi"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(event) => {
                                    this.setState({
                                        level_divisi_limited: event
                                    })
                                }}
                            >
                                {this.props.divisi.map((item, index) => {
                                    return (
                                        <Option value={item.divisi_id}>{item.divisi_name}</Option>
                                    )
                                })}
                            </Select>
                        }
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default UserLevelAdd
