import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";
import {set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import i18n from "i18next";

class Add extends Component {
    constructor() {
        super();
        this.state = {
            jabatan_nama: ''
        }
    }
    simpanJabatan = () => {
        let form = {
            name: this.state.jabatan_nama
        }

        this.props.request("jabatan/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({jabatan_nama: ''})
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                    this.props.changeState({
                        jabatan_nama: '',
                        add_visible: false,
                        data: [],
                        isLoading: true,
                    }, () => this.props.loadData())
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    render() {
        return (
            <Drawer
                title="Tambah Divisi"
                width={350}
                onClose={() => {
                    this.props.changeState({add_visible: false, divisi_name:''});
                }}
                visible={this.props.add_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <button type="button" className="btn ipresens-btn-cancel" onClick={() => {
                            this.props.changeState({add_visible: false, jabatan_nama:''});
                        }}
                                style={{ marginRight: 8 }}>
                            Batal
                        </button>
                        <button type="button" className="btn ipresens-btn-color" onClick={this.simpanJabatan}>
                            Simpan
                        </button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama Divisi</label>
                        <input name="jabatan_nama" className="form-control col-xl-12 col-md-12" placeholder="Nama Jabatan..."
                               type="text" required="" value={this.state.jabatan_nama}
                               onChange={(event) => {
                                   const target = event.target;
                                   const value  = target.value;
                                   const name   = target.name;
                                   this.setState({
                                       [name]: value
                                   });
                               }}/>
                    </div>
                </Form>
            </Drawer>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Add)
