import React, { Fragment, Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import "./style.css";
import CheckSvg from '../../../assets/icons/Check.svg';
import Arrowleft from '../../../assets/icons/arrow-left.svg';
import Ticket from '../../../assets/icons/ticket.svg';
import Rekomendasi from '../../../assets/icons/rekomendasi.svg';
import i18n from "i18next";
import CheckSvgBlue from '../../../assets/icons/Check Blue.svg';
import Swal from "sweetalert2";
import CurrencyFormat from 'react-currency-format'
import {
    Col,
    Input,
    InputGroup,
    InputGroupAddon,
    Modal as ModalRs,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import moment from "moment/moment";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import DatePicker from "react-datepicker";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import ReCAPTCHA from 'react-google-recaptcha'
const recaptchaRef = React.createRef();

class PilihPaket extends Component {
    constructor(props) {
        super(props)
        this.recaptcha = React.createRef()
        this.state = {
            paket: [],
            paket_id: 0,
            paket_month: 0,
            paket_harga: 0,
            list_paket: false,
            modalCustom: false,
            customPaket: 0,
            jumlah_karyawan_custom: 1,
            paket_harga_custom: 0,
            harga_finish: 0,
            buttonSubmit: true,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleRecaptchaChange = this.handleRecaptchaChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    submitForm = (event) => {
        event.preventDefault()
        const captchaValue = this.recaptcha.current.getValue()
        if (!captchaValue) {
            alert('Please verify the reCAPTCHA!')
        } else {
            // make form submission
            alert('Form submission successful!')
        }
    }

    incrementItem = () => {
        this.setState({ jumlah_karyawan_custom: this.state.jumlah_karyawan_custom + 1 });
    }
    decreaseItem = () => {
        this.setState({ jumlah_karyawan_custom: this.state.jumlah_karyawan_custom - 1 });
    }

    paketCustom() {
        let form = {
            jumlah_karyawan: this.state.jumlah_karyawan_custom,
            harga: this.state.jumlah_karyawan_custom * 10000
        }
        this.props.request("paket/paket_custom", form, "POST")
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalCustom: false,
                                loading: true,
                                list_paket: false,
                                buttonSubmit: true,
                            }, () => this.props.setState({
                                paket_selected: response.data,
                                hideStep: false,
                                selected_new_paket: true
                            }), () => this.loadData())
                        } else {
                            this.setState({
                                modalCustom: false,
                                loading: true
                            }, () => this.loadData())
                        }
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    handleRecaptchaChange() {
        this.setState({ buttonSubmit: false });
    }

    loadData() {
        let form = {}
        this.props.request("paket/first", form, "POST")
            .then(response => {
                if (response.success) {
                    this.setState({
                        paket: response.data,
                    })
                    if (Object.keys(this.props.paket_selected).length === 0) {
                        this.props.setState({
                            paket_selected: response.data[0]
                        })
                    }
                }
            })
    }

    prosesPaket = () => {
        this.setState({
            disabledButton: true
        })
        let form = {
            paket_id: this.state.paket_id,
            prosespaket: true
        }
        this.props.request("register_v2", form, 'POST')
            .then(response => {
                console.log("server :", response);
                if (response.success) {
                    this.props.setState({
                        paket_id: this.state.paket_id,
                        paketMonth: this.state.paket_month,
                        stepHeader: 1
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }
    paramValue;

    componentDidMount() {
        this.loadData()

    };
    render() {
        const modalOnShow = () => {
            try {
                window.grecaptcha.render('form-recaptcha', {
                    sitekey: "[site-key]",
                    callback: function(resp){}
                });
            } catch (error) {
                console.log(error)
            }
        }
        let page;
        if (this.state.list_paket === true) {
            page =
                <div className='row'>
                    <div className='col'></div>
                    <div className='col-md-11'>
                        <div className='row mb-4'>
                            <div className='col'>
                                <button className='btn text-capitalize px-3 back-btn-list-paket d-flex align-items-center' onClick={() => this.setState({ list_paket: false }, () => this.props.setState({ hideStep: false }))}><img src={Arrowleft} style={{ color: "#143B5E", height: "20px", width: "20px", paddingRight: "5px" }} /> {i18n.t('kembali')}</button>
                            </div>
                        </div>
                        <div className='row'>
                            {this.props.paket.slice(0, 8).map((item, index) => {
                                return (
                                    <div className='col-md-3 d-flex align-items-stretch'>
                                        <div className='card border pb-2' style={{ width: "100%" }}>
                                            <div className='card-header border-bottom pb-0' style={{ backgroundColor: "#F9F9F9", padding: "14px", minHeight: "22%" }}>
                                                <div className='row'>
                                                    <div className='col-2' style={{ paddingLeft: "14px", paddingRight: "14px" }}><img src={Ticket} /></div>
                                                    <div className='col' style={{ fontSize: "12px", paddingRight: "14px", paddingLeft: "12px" }}><b style={{ color: "#252B2B" }}>{item.paket_nama}</b> {item.paket_harga === 0 ? <img src={Rekomendasi} style={{ height: "18px" }} /> : ""}<br /><p style={{ fontSize: "12px", marginBottom: "0px", color: "#5D6F80" }}>{i18n.language == "id" ? item.paket_desc : item.paket_desc_english}</p></div>
                                                </div>
                                            </div>
                                            <div className='card-body px-3 pt-2'>
                                                <div className='row px-3 mb-3 d-flex align-items-end justify-content-start'>
                                                    <div className='col-auto px-0' style={{ fontSize: "24px", fontWeight: 700 }}>
                                                        <CurrencyFormat value={i18n.language == "id" ? item.paket_harga : item.paket_harga_usd} displayType={'text'} thousandSeparator={'.'} decimalSeparator={","} prefix={i18n.language == "id" ? 'IDR ' : 'USD '} />
                                                    </div>
                                                    <div className='col-auto px-0'>
                                                        <p>/ {i18n.t('bulan')}</p>
                                                    </div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <div className='col'>
                                                        <button style={{ borderRadius: "8px", paddingTop: "12px", paddingBottom: "12px" }} onClick={() => this.setState({ list_paket: false }, () => this.props.setState({ paket_selected: item, hideStep: false, selected_new_paket: true }))} className={item.paket_harga === 0 ? "btn ipresens-btn-color btn-block text-capitalize" : "btn btn-block back-btn-pilih-paket text-capitalize"}>{i18n.t('pilihpaket')}</button>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <ul className="list-group">
                                                            <li className="list-group-item border-0 px-0 mb-2" style={{ fontSize: "12px", color: "#B6B6B6" }}>{i18n.t('apaygandaperoleh')}</li>
                                                            {item.paket_harga === 0 ?
                                                                <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                    <div className='row flex-nowrap'>
                                                                        <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                        <div className='col'> Gratis! selama {item.paket_bulan} Bulan</div>
                                                                    </div>
                                                                </li>
                                                                : ""
                                                            }
                                                            {item.paket_premium === '1' ?
                                                                  <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                    <div className='row flex-nowrap'>
                                                                        <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                        <div className='col'> {i18n.t('aksespremium')}</div>
                                                                    </div>
                                                                </li>
                                                                : ""
                                                            }
                                                            <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                <div className='row flex-nowrap'>
                                                                    <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                    <div className='col'>{i18n.t('maksimal')} {item.paket_jumlah} {i18n.t('karyawan')}</div>
                                                                </div>
                                                            </li>
                                                            <li className="list-group-item border-0 px-0 py-1" style={{ fontSize: "12px" }}>
                                                                <div className='row flex-nowrap'>
                                                                    <div className='col-1'><img src={CheckSvgBlue} style={{ height: "14px", width: "14px" }} alt="check icon" /></div>
                                                                    <div className='col w-75'>{i18n.language == "id" ? item.paket_desc : item.paket_desc_english}</div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='row'>
                            <div className='col-md-3 d-flex align-items-stretch'>
                                <div className='card border pb-2' style={{width: "100%"}}>
                                    <div className='card-header border-bottom pb-0'
                                         style={{backgroundColor: "#F9F9F9", padding: "14px", minHeight: "22%"}}>
                                        <div className='row'>
                                            <div className='col-2' style={{paddingLeft: "14px", paddingRight: "14px"}}>
                                                <img src={Ticket}/></div>
                                            <div className='col'
                                                 style={{fontSize: "12px", paddingRight: "14px", paddingLeft: "12px"}}>
                                                <b style={{color: "#252B2B"}}>Paket Custom</b></div>
                                        </div>
                                    </div>
                                    <div className='card-body px-3 pt-2'>
                                        <div className='row'>
                                            <div className='col'>
                                                <p style={{
                                                    fontSize: "10px",
                                                    marginBottom: "10px",
                                                    color: "#5D6F80"
                                                }}>APA ITU PAKET CUSTOM?</p>
                                                <p style={{
                                                    fontSize: "12px",
                                                    marginBottom: "10px",
                                                    color: "#252B2B"
                                                }}>Kami menawarkan skema harga yang dapat disesuaikan dengan jumlah
                                                    karyawan yang ada di perusahaan Anda.</p>
                                            </div>
                                        </div>
                                        <button className="btn btn-custom-pkt"
                                                onClick={() => this.setState({modalCustom: true})}>Custom Paket Anda
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* paket custom, jangan dihapus */}

                        {/* <div className='row'>
                            <div className='col-md-5'>
                                <div className='card border'>
                                    <div className='card-header py-3' style={{backgroundColor: "#143B5E", fontSize: "20px", fontWeight: 700, color: "#FFFFFF"}}>Paket Custom</div>
                                    <div className='card-body px-3 pt-2 pb-4'>
                                        <div className='row'>
                                            <div className='col'>
                                                <ul className='list-group'>
                                                    <li className='list-group-item border-0 px-0 py-1' style={{fontSize: "10px", color: "#5D6F80"}}>APA ITU PAKET CUSTOM?</li>
                                                    <li className='list-group-item border-0 px-0 py-1' style={{fontSize: "14px", fontWeight: 400, lineHeight: "24px"}}>
                                                        Kami menawarkan skema harga yang dapat disesuaikan dengan jumlah karyawan yang ada di perusahaan Anda.
                                                    </li>
                                                    <li className='list-group-item border-0 px-0 pb-1 pt-4'>
                                                        <a href='https://wa.me/6285700102030' className='btn ipresens-btn-color'><img src={WhatsappIcon} className={{paddingTop: "8px", paddingRight: "14px", paddingBottom: "8px", paddingLeft: "14px"}}/> Hubungi Kami</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col'></div>
                            <div className='col'></div>
                        </div> */}
                    </div>
                    <div className='col'></div>
                </div>
        } else {
            page =
                <div className='row'>
                    <div className='col'></div>
                    <div className='col-md-4 px-4'>
                        <div className='row'>
                            <div className='card border' style={{ color: "#143B5E" }}>
                                <div className='card-header py-2 px-4' style={{ backgroundColor: "#FAFDFF" }}>
                                    <div style={{ fontSize: "18px", fontWeight: 700, color: "#143B5E", lineHeight: "27px", letterSpacing: "0px" }}>{i18n.t('pilihpaket')}</div>
                                </div>
                                <div className='card-body pb-0 pt-2'>
                                    <div className='row' style={{ padding: "0px 10px" }}>
                                        <div className='col px-0'>
                                            {this.state.paket.length === 0 ?
                                                <div className='mb-2'>Paket Kosong</div> :
                                                this.props.selected_new_paket === true ?
                                                    <label>
                                                        <input onClick={() => { this.setState({ paket_selected: this.state.paket_selected }) }} type="radio" name="product" checked={this.props.paket_selected.paket_id === this.props.paket_selected.paket_id} className="card-input-element" />

                                                        <div className='card card-default card-input my-0' style={{ borderRadius: "10px", boxShadow: "none" }}>
                                                            <div className="card-body py-3 container">
                                                                <div className='row flex-nowrap'>
                                                                    <div className='col-2' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        <img src={CheckSvgBlue} alt="check icon" style={{ width: 24, height: 24 }} color="#057CE4" />
                                                                    </div>
                                                                    <div className='col' style={{ color: "#143B5E" }}>
                                                                        <div className='card-title' style={{ fontSize: "14px", fontWeight: 700 }}>{this.props.paket_selected.paket_nama}</div>
                                                                        <div className='card-text'><p style={{ fontSize: "12px" }}>{i18n.language == "id" ? this.props.paket_selected.paket_desc : this.props.paket_selected.paket_desc_english}</p></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    :
                                                    this.state.paket.map((item, index) => {
                                                        return (
                                                            <label style={{ marginTop: "0px", marginBottom: "0px" }}>
                                                                <input onClick={() => { this.props.setState({ paket_selected: this.state.paket[index] }) }} type="radio" name="product" checked={this.props.paket_selected.paket_id === item.paket_id} className="card-input-element" />

                                                                <div className={this.props.paket_selected.paket_id === item.paket_id ? 'card card-default card-input mx-0 bg-selected-card' : 'card card-default card-input border mx-0 mt-0'} style={{ borderRadius: "10px", boxShadow: "none" }}>
                                                                    <div className="card-body py-3 container">
                                                                        <div className='row flex-nowrap'>
                                                                            <div className='col-2' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                <img src={this.props.paket_selected.paket_id === item.paket_id ? CheckSvgBlue : CheckSvg} alt="check icon" style={{ width: 24, height: 24 }} color={this.props.paket_selected.paket_id === item.paket_id ? "#057CE4" : "grey"} />
                                                                            </div>
                                                                            <div className='col' style={{ color: "#143B5E" }}>
                                                                                <div className='card-title my-1' style={{ fontSize: "14px", fontWeight: 700 }}>{item.paket_nama}</div>
                                                                                <div className='card-text my-1'><p style={{ fontSize: "12px", lineHeight: "15px" }}>{i18n.language == "id" ? item.paket_desc : item.paket_desc_english}</p></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        )
                                                    })
                                            }
                                            <div className='btn btn-block py-3 px-3 rounded text-capitalize text-left' onClick={() => {
                                                this.props.setState({ hideStep: true }, () => this.setState({ list_paket: true }))
                                            }} style={{ backgroundColor: "#F5F5F5", fontSize: "14px", fontWeight: 700, color: "#143B5E" }}>
                                                {i18n.t('lihatpaketlain')}
                                                <FontAwesomeIcon icon={faArrowRight} className="pull-right pt-1" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row' style={{ padding: "0px 10px" }}>
                                        <div className='col px-0'>
                                            <div className='card' style={{ width: "100%", boxShadow: "none" }}>
                                                <div className='card-body px-0 py-0' style={{ fontSize: "14px", backgroundColor: "transparant", marginTop: "14px", marginBottom: "26px" }}>
                                                    <hr className='dashed-line mb-4' />
                                                    <div className='row mb-1 px-3'>
                                                        <div className='col-md-6 px-0' style={{ fontWeight: 400 }}><p>{this.props.paket_selected.paket_nama}</p></div>
                                                        <div className='col px-0' style={{ textAlign: "right", color: "#143B5E", fontWeight: 500 }}><CurrencyFormat value={this.props.paket_selected.paket_harga} displayType={'text'} thousandSeparator={'.'} decimalSeparator={","} prefix={'IDR '} /></div>
                                                    </div>
                                                    <div className='row mb-1 px-3'>
                                                        <div className='col-md-6 px-0' style={{ fontWeight: 400 }}><p>{i18n.t('pajak')}</p></div>
                                                        <div className='col px-0' style={{ textAlign: "right", color: "#143B5E", fontWeight: 500 }}>IDR 0</div>
                                                    </div>
                                                    <hr className='dashed-line mt-4' />
                                                    <div className='row mb-1 px-3'>
                                                        <div className='col-md-6 px-0' style={{ fontWeight: 700, color: "#143B5E" }}>Total</div>
                                                        <div className='col px-0' style={{ textAlign: "right", color: "#143B5E", fontWeight: 500 }}><CurrencyFormat value={this.props.paket_selected.paket_harga + 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={","} prefix={'IDR '} /></div>
                                                    </div>
                                                </div>
                                                <div align="center">
                                                    <button className='btn ipresens-btn-color btn-block text-capitalize' onClick={() => this.setState({ paket_id: this.props.paket_selected.paket_id, paket_month: this.props.paket_selected.paket_bulan }, () => this.prosesPaket())} style={{ fontSize: "14px", weight: "100px", fontWeight: 700, paddingTop: "12px", paddingBottom: "12px", borderRadius: "8px" }}>{i18n.t('daftarakun')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div style={{ textAlign: "center" }}>
                                <p>{i18n.t("daftarterm")}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col'></div>
                </div>
        }
        return (
            <Fragment>
                <ModalRs toggle={() => this.setState({ modalCustom: false })} onEnter={modalOnShow} isOpen={this.state.modalCustom}>
                    <ModalHeader toggle={() => this.setState({ modalCustom: false })}>Custom Paket Anda</ModalHeader>
                    <ModalBody>
                        <div className="row justify-content-center">
                            <div className='col-10 px-0 mb-2'>
                                <div className='d-flex flex-nowrap' style={{width: "100%"}}>
                                    <button className='ph-filter-date-left' onClick={() => this.setState({
                                        jumlah_karyawan_custom: this.state.jumlah_karyawan_custom - 1
                                    })}>
                                        <img src={ArrowLeft} alt="" style={{height: "16px", width: "16px"}}/>
                                    </button>
                                    <input type="number" min="0" className="form-control" name="jumlah_karyawan_custom"
                                           id="jumlah_karyawan_custom" onChange={this.handleChange}
                                           value={this.state.jumlah_karyawan_custom}
                                           placeholder="masukkan jumlah karyawan disini"/>
                                    <button className='ph-filter-date-right' onClick={() => this.setState({
                                        jumlah_karyawan_custom: this.state.jumlah_karyawan_custom + 1
                                    })}>
                                        <img src={ArrowRight} alt="" style={{height: "16px", width: "16px"}}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mb-2">
                                <CurrencyFormat value={this.state.jumlah_karyawan_custom * 10000} displayType={'text'}
                                                thousandSeparator={'.'} decimalSeparator={","} prefix={'IDR '}/>
                        </div>
                            <div className="row justify-content-center">
                                <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY} onChange={this.handleRecaptchaChange}/>
                            </div>
                    </ModalBody>
                    <ModalFooter style={{backgroundColor: "#F5F5F5", borderTop: 0}}>
                        <button className="btn-batal-custom-paket text-capitalize"
                                onClick={() => this.setState({modalCustom: false})}>
                            Batalkan
                        </button>
                        {' '}
                        <button className={this.state.buttonSubmit ? "bg-transparent btn-done-cstm-pkt-dsbld text-capitalize" : "btn-done-cstm-pkt text-capitalize cursor-pointer"} onClick={() => this.paketCustom()} disabled={this.state.buttonSubmit}>
                            Simpan
                        </button>
                    </ModalFooter>
                </ModalRs>
                <main style={{marginTop: "100px"}}>
                    <div className='container'>
                        {page}
                    </div>
                </main>
            </Fragment>
        )
    }
}

export default PilihPaket
