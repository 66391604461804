import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import { Empty, Spin, Tooltip } from 'antd';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import moment from "moment";
import i18n from "i18next";
import EmptyDataImage from "../../assets/images/data-kosong.png";

export class FinishedTaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            idDel: '',
            filter_title: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {
        this.setState({
            filter_judul: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus pengumuman?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("pengumuman/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            title: this.state.filter_title,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("task/list-finished", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0
        const stylefoto = {
            maxHeight: 60
        }
        const steps = [
            {
                selector: '.tambah-pengumuman',
                content: 'Anda dapat membuat Pengumuman disini',
            },
            {
                selector: '.daftar-pengumuman',
                content: 'Daftar Pengumuman anda dapat dilihat disini',
            },
        ];
        if (this.props.user && this.props.user.perusahaan.langganan && this.props.user.perusahaan.langganan.paket.paket_premium == "1") {
            if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Tugas") >= 0) {
                return (
                    <Fragment>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>Daftar Tugas Terselesaikan - Presensi</title>
                            <meta name="description" content="Daftar Tugas Terselesaikan" />
                        </Helmet>
                        <Breadcrumb title="Daftar Tugas Terselesaikan" parent="Tugas" setGuide={() => {
                            this.setState({ guide: true })
                        }} />
                        <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody}
                              onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row filter-karyawan" style={{ marginLeft: '5px' }}>
                                        <div className="input-group btn-srch-tugas-done col-md-5">
                                            <div className="input-group-prepend">
                                            <span className="input-group-text input-group-text-custom"
                                                  id="basic-addon1"><span className="fa fa-search"></span></span>
                                            </div>
                                            <input name="filter_title"
                                                   className="form-control mr-1"
                                                   placeholder="Cari Tugas..."
                                                   type="text"
                                                   required=""
                                                   value={this.state.filter_title}
                                                   style={{display: "inline-block", boxSizing: 'border-box'}}
                                                   onChange={this.handleChange}
                                                   onKeyUp={(event) => {
                                                       if (event.which == 13) {
                                                           event.preventDefault()
                                                           this.setState({
                                                               page: 0,
                                                               paging: this.state.paging,
                                                               data: [],
                                                               loading: true
                                                           }, () => {
                                                               this.loadData()
                                                           });
                                                       }
                                                   }}/>
                                            <button className="btn btn-outline-" onClick={this.resetFilter}><span
                                                className="fa fa-refresh"></span></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body daftar-pengumuman">
                                    {!this.state.loading && this.state.data.length === 0 ?
                                        <Empty description={i18n.t("datakosong")}
                                               image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                        :
                                        <Table striped className="mb-5">
                                            <Thead>
                                                <Tr>
                                                    <Th width="50">#</Th>
                                                    <Th>Karyawan</Th>
                                                    <Th>Tugas</Th>
                                                    <Th>Tanggal Selesai</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={5}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                                }
                                                {this.state.data.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr>
                                                            <small>
                                                                <Th scope="row">
                                                                    <h5>{pageNumber(no, this.state.perPage, this.state.currentPage)}</h5>
                                                                </Th>
                                                            </small>
                                                            <Td>{item.karyawan && item.karyawan.karyawan_nama}</Td>
                                                            <Td>{item.task_finished_title}</Td>
                                                            <Td>{moment(item.task_finished_date).format('MMMM D YYYY, H:mm')} {this.props.user.perusahaan && this.props.user.perusahaan.perusahaan_timezone.toUpperCase()}</Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>
                                    }
                                    <Paging
                                        className="float-right"
                                        handlePageClick={this.handlePageClick}
                                        pageCount={this.state.pageCount}
                                        currentPage={this.state.currentPage}
                                    />
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                    </Fragment>
                )
            }
        }else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Fitur ini hanya tersedia untuk Paket Premium+.</h5>
                    <h5 className="text-center pt-4"><a href="/subscription">Upgrade ke Premium+.</a></h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(FinishedTaskList);
