import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";
import i18n from "i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class EditIstillah extends Component {

    constructor() {
        super();
        this.state = {
            p_term_type: ''
        }
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    handleEdit = () => {
        let form = {
            id: this.props.data.p_term_id,
            name: this.props.data.p_term_name,
            type: this.state.p_term_type,
        }
        this.props.request("payroll/edit_term", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        p_term_name : ''
                    },() =>this.props.changeState({
                        visable: false,
                        p_term_name : '',
                        data: [],
                        isLoading: true,
                    },() => {
                        this.props.loadData()
                        this.toggle()
                    }))
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    toggle() {
        this.props.setState({ modalEdit: !this.props.modalEdit })
    }

    componentWillReceiveProps(newProps) {
        this.setState({p_term_type: newProps.data.p_term_type})
    }
    render() {
        const style_add = {
            font_header: {
                "fontWeight": 700,
                "fontSize": "18px",
                "lineHeight": "27px",
                "color": "#143B5E"
            },
            font_label: {
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            font_time: {
                "padding": "16px",
                "textAlign": "center",
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            input: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E"
            }
        }
        return (
            <Modal
                isOpen={this.props.modalEdit}
                toggle={() => this.props.setState({
                    modalEdit: !this.props.modalEdit,
                    p_term_name:'',
                    p_term_type:''
                })}
                style={{
                    marginRight: "10px",
                    maxWidth: "419px",
                }}
            >
                <ModalHeader
                    toggle={() => this.props.setState({
                    modalEdit: !this.props.modalEdit,
                    p_term_name:'',
                    p_term_type:''
                })}
                >
                    <div style={style_add.font_header}>Edit Istilah Gaji
                    </div>
                </ModalHeader>
                <ModalBody
                style={{
                    height: "470px"
                }}
                >
                    <div className="form-group">
                        <label>Nama Istilah</label>
                        <input name="p_term_name" className="form-control col-xl-12 col-md-12" placeholder="Nama Istilah..."
                               type="text" required="" value={this.props.data.p_term_name}
                               onChange={(event) => {
                                   const target = event.target;
                                   const value  = target.value;
                                   const name   = target.name;
                                   this.props.changeState({
                                       [name]: value
                                   });
                               }}/>
                    </div>
                    <div className="form-group">
                        <label>Type Istilah</label>
                        <select
                            name="p_term_type"
                            className="form-control col-xl-12 col-md-12"
                            required=""
                            value={this.state.p_term_type}
                            onChange={(event) => this.handleChange(event)}>

                            <option value="">Pilih Type Istillah</option>
                            <option value="kompensasi">Kompensasi</option>
                            <option value="potongan">Potongan</option>
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter style={{
                    borderTop: 0,
                    backgroundColor: "#FFFFFF",
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                    justifyContent: "space-between"
                }}>
                    <button className='btn ipresens-btn-cancel text-capitalize' onClick={() => {
                        this.props.setState({
                            modalEdit: !this.props.modalEdit,
                            p_term_name:'',
                            p_term_type:''
                        })
                    }} style={{
                        width: "100%",
                        border: "solid 1px #DDDDDD"
                    }}
                    >
                        Batal
                    </button>{' '}
                    <button className='btn ipresens-btn-color text-capitalize' style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        width:"100%"
                    }} onClick={() => {
                        this.handleEdit()
                    }}>
                        Simpan
                    </button>
                </ModalFooter>
            </Modal>

        )
    }
}

export default EditIstillah
