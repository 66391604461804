import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Empty, Spin, Tooltip } from 'antd';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import ReactPaginate from 'react-paginate';
import { Helmet } from "react-helmet";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import moment from "moment";
import Edit from "./edit-lembur";
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Add from "./lembur/add";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import Select from "react-select";
import ArrowRight from '../../assets/icons/arrow-right.svg'
import ArrowLeft from '../../assets/icons/arrow-left.svg'
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ArrowUpIcon from "../../assets/icons/arrow-up.svg";
import ArrowDownIcon from "../../assets/icons/arrow-down.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import SearchIcon from "../../assets/icons/search.svg";
import ElipsisIcon from "../../assets/icons/ellipsis-vertical.svg";
import CheckIcon from "../../assets/icons/Check Blue.svg";
import CrossMarkIcon from "../../assets/icons/cross-mark.svg";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import "./style.css";
import FilterIcon from "../../assets/icons/filter.svg";
import { Modal, Modal as ModalRs} from 'reactstrap';
import { Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WarningIcon from '../../assets/icons/warning-icon.svg';
import { faCalendarAlt, } from '@fortawesome/free-solid-svg-icons';
import { DateRange } from 'react-date-range';
import { BACKEND_API_HOST } from "../../helper/host";
import DownloadIcon from '../../assets/icons/download-white.svg';
import EmptyDataImage from "../../assets/images/data-kosong.png";
import i18n from "i18next";
import ImportIcon from "../../assets/icons/import-icon.svg";

export class List_lembur extends Component {
    constructor(props) {
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        super(props);
        this.state = {
            loading: true,
            karyawan: [],
            data: [],
            dataEdit: [],
            dataHistory: [],
            idDel: '',
            filter_nama: "",
            filter_month: namabulan[bulan],
            filter_year: tahun,
            approvedLembur: '',
            rejectLembur: '',
            checkAll: false,
            indeterminate: true,
            checked: [],
            tgl_lembur: '',
            jam_lembur: '',
            menit_lembur: '',
            desc_lembur: '',
            pageCount: 0,
            currentPage: 0,
            currentPageHistory: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination: 2,
            guide: false,
            filter_status_lm: "",
            month: namabulan[bulan],
            year: tahun,
            monthNow: namabulan[bulan],
            yearNow: tahun,
            totalPerPage: 0,
            confirmApprove: false,
            confirmReject: false,
            confirmDelete: false,
            confirmApproveAll: false,
            confirmRejectAll: false,
            slice_karyawan_nama: true,
            checked_all: false,
            add_karyawan_id: 0,
            add_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
            add_jam_lembur: 0,
            add_menit_lembur: 0,
            add_deskripsi_lembur: "",
            edit_lembur_id: 0,
            edit_karyawan_id: 0,
            edit_karyawan_nama: "",
            edit_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
            edit_jam_lembur: 0,
            edit_menit_lembur: 0,
            edit_deskripsi_lembur: "",
            action: false,
            exportStartDate: "",
            exportEndDate: "",
            reactDateRange: false,
            valueDate: "",
            modalexport: false,
            modalimport: false,
            modalManagement: false,
            modalHistory: false,
            loadingHistory: false,
            start_date: new Date(),
            end_date: new Date(),
            cutiStartDate: '',
            cutiEndDate: '',
            approvedData: "",
            rejectData : "",
            filterExport : "",
            buttonFilter : "",
            editData: {}
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handlePaging = this.handlePaging.bind(this)
        this.handleCheckBox = this.handleCheckBox.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.exportLembur = this.exportLembur.bind(this)
        this.bulkApprovedLembur = this.bulkApprovedLembur.bind(this)
        this.bulkRejectLembur = this.bulkRejectLembur.bind(this)
        this.searchKaryawan = this.searchKaryawan.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            currentPage: 0,
            [name]: value,
        })
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
            currentPage: 0,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handlePaging(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        }, () => {
            this.loadData()
        })
    }

    handleCheckBox(id) {
        {
            this.state.checked.includes(id) == true ?
                this.setState({
                    checked: this.state.checked.slice(this.state.checked.indexOf(id), 1)
                })
                :
                this.state.checked.push(id)
        }
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            filter_status_lm: "",
            loading: true,
            month: this.state.monthNow,
            year: this.state.yearNow,
            cutiStartDate: '',
            cutiEndDate: '',
        }, () =>
            this.loadData())
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            modalexport: true,
            exportStartDate: start,
            exportEndDate: end,
            filterExport: true,
        }, () => {
            this.loadData()
        });
    }
    exportLembur() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'lembur/export?token=' + AuthKey + '&lemburStartDate=' + this.state.exportStartDate + '&lemburEndDate=' + this.state.exportEndDate + "&filterLembur=" + this.state.filterExport, "_blank")
    }

    handleCancel = e => {
        this.setState({
            modalexport: false,
            modalManagement: false,
            modalimport: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };


    bulkApprovedLembur = () => {
        this.setState({
            data: [],
            loading: true
        })
        let form = {
            cuti_ids: this.state.checked
        }
        this.props.request("lembur/bulk-approve", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            });
        this.setState({
            checked: [],
            checkAll: false,
            confirmApproveAll: false,
            checked_all: false
        })
    }

    bulkRejectLembur = () => {
        this.setState({
            data: [],
            loading: true
        })
        let form = {
            cuti_ids: this.state.checked
        }
        this.props.request("lembur/bulk-reject", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            });

        this.setState({
            checked: [],
            checkAll: false,
            confirmRejectAll: false,
            checked_all: false
        })
    }

    handleCheckBoxChange(id) {
        this.state.checked.includes(id) ?
            this.setState(prevState => ({
                checked: prevState.checked.filter(currId => currId !== id),
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
            :
            this.setState(prevState => ({
                checked: [...prevState.checked, id],
            }), () => {
                if (this.state.checked.length === this.state.totalData) {
                    this.setState({
                        checked_all: true
                    })
                } else {
                    this.setState({
                        checked_all: false
                    })
                }
            })
    }

    handleCheckAll(e) {
        let value = e.target.checked
        if (value) {
            this.state.data.map((item) => {
                if (!this.state.checked.includes(item.lembur_id)) {
                    this.setState(prevState => {
                        return {
                            ...prevState,
                            checked: [...prevState.checked, item.lembur_id]
                        }
                    }, () => {
                        this.setState({
                            checked_all: true
                        })
                    })
                }
            })
        } else {
            this.setState({
                checked_all: false,
                checked: []
            })
        }
    }

    approvedLembur = () => {
        this.setState({
            data: [],
            loading: true
        })
        let form = {
            id: this.state.approvedLembur
        }
        this.props.request("lembur/approved", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            });
        this.setState({
            confirmApprove: false
        })
    }

    rejectLembur = () => {
        this.setState({
            data: [],
            loading: true,
            rejectModal: false
        })
        let form = {
            id: this.state.rejectLembur,
            reason_reject: this.state.reason_reject
        }
        this.props.request("lembur/reject", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            });
        this.setState({
            confirmReject: false
        })
    }

    wantDelete() {
        this.setState({
            data: [],
            loading: true
        })
        let form = {
            id: this.state.idDel
        }
        this.props.request("lembur/delete", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        confirmDelete: false
                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            });
    }

    handleEdit = () => {
        let jam = this.state.edit_jam_lembur * 60;
        let menit = this.state.edit_menit_lembur;
        let durasi = jam + menit;
        let form = {
            id: this.state.edit_lembur_id,
            tanggal: this.state.edit_tanggal_lembur,
            durasi: durasi,
            desc: this.state.edit_deskripsi_lembur,
        }
        this.props.request("lembur/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        modalEdit: false,
                        edit_karyawan_id: 0,
                        edit_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                        edit_jam_lembur: 0,
                        edit_menit_lembur: 0,
                        edit_deskripsi_lembur: "",
                        karyawan_selected: undefined
                    })
                    this.loadData()
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    loadData() {
        let form = {
            filter_status_lm: this.state.filter_status_lm,
            filter_nama: this.state.filter_nama,
            page: this.state.currentPage + 1,
            month: this.state.filter_month,
            year: this.state.filter_year,
            paginate: 10,
        }
        this.props.request("lembur/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        totalPerPage: response.data.to,
                        loading: false
                    })
                }

            });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.lembur_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

        this.props.request("lembur/history", form, 'POST')
            .then(response => {
                this.setState({
                    loadingHistory: false
                })
                if (response.succes) {
                    this.setState({
                        dataHistory: response.data.data,
                        pageCountHistory: response.data.last_page,
                        totalDataHistory: response.data.total,
                        totalPerpageHistory: response.data.to,
                        perPageHistory: response.data.per_page,
                    });
                } else {
                    this.setState({
                        modalHistory: false,
                        lembur_id: ''
                    });
                }
            });
        })
    }

    searchKaryawan() {
        let form = {
            karyawan_name: this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    simpanLembur = () => {
        let jam = this.state.add_jam_lembur * 60;
        let menit = this.state.add_menit_lembur;
        let durasi = jam + menit;
        if (isNaN(durasi)) {
            Swal.fire({
                title: 'Gagal!',
                text: "Pastikan durasi berbentuk angka!",
                icon: 'warning',
                confirmButtonText: 'OK'
            })
        }
        this.setState({
            loading: true
        })
        let form = {
            karyawan_id: this.state.add_karyawan_id,
            tanggal: this.state.add_tanggal_lembur,
            desc: this.state.add_deskripsi_lembur,
            durasi: durasi
        }

        this.props.request("lembur/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        modalAdd: false,
                        add_karyawan_id: 0,
                        add_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                        add_jam_lembur: 0,
                        add_menit_lembur: 0,
                        add_deskripsi_lembur: "",
                        karyawan_selected: undefined
                    })
                    this.loadData()
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    sortabledate = (val) => {

        const copyArray = this.state.data

        copyArray.sort((a, b) => {
            return val === 0 ? new Date(a.lembur_tanggal) - new Date(b.lembur_tanggal) : new Date(b.lembur_tanggal) - new Date(a.lembur_tanggal);
        });

        this.setState({
            data: copyArray
        })
    }

    compareBy(key) {
        return function (a, b) {
            if (a[key] < (b[key])) return -1;
            if (a[key] > (b[key])) return 1;
            return 0;
        };
    }

    sortablestatus = (val) => {

        const copyArray = this.state.data

        if (val === 0) {
            copyArray.sort(this.compareBy("lembur_status"));
        } else {
            copyArray.reverse();
        }

        this.setState({
            data: copyArray
        })
    }

    componentDidMount() {
        this.loadData()
    }

    exportTemplateLembur = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'lembur/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'lembur/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    render() {
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        const style_add = {
            font_header: {
                "fontWeight": 700,
                "fontSize": "18px",
                "lineHeight": "27px",
                "color": "#143B5E"
            },
            font_label: {
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            font_time: {
                "padding": "16px",
                "textAlign": "center",
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            input: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E"
            }
        }
        let no = 0;
        let borderBottom = "1px solid #EFEFEF";
        const steps = [
            {
                selector: '.daftar-lembur',
                content: 'Daftar Lembur Karyawan anda dapat dilihat disini',
            },
        ];
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengajuan Lembur") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Pengajuan Lembur - Presensi</title>
                        <meta name="description" content="Detail Absensi" />
                    </Helmet>
                    <Modal centered isOpen={this.state.confirmRejectAll} toggle={() => {
                        this.setState({
                            confirmRejectAll: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmRejectAll: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CrossMarkIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Tolak pengajuan Lembur?</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} pengajuan terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='izin-tolak-button' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmRejectAll: false
                                        })
                                    }}>
                                        Batal
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn btn-primary text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.bulkRejectLembur}>
                                        Ya
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalimport}>
                        <ModalHeader onClick={this.handleCancel} toggle={() => this.setState({importFile: null})}>
                            <div>Import Lembur</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>Anda bisa mengunduh template yang
                                        sudah
                                        kami sediakan untuk mempermudah pengimportan.</a><br/>
                                    <a onClick={this.exportTemplateLembur} style={{color: "#057CE4"}}>Unduh template
                                        disini</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i> Sedang mengupload data</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>
                    <ModalRs size='lg' isOpen={this.state.modalexport} toggle={this.handleCancel}>
                                <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>Export Excel</ModalHeader>
                                <ModalBody>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='d-flex flex-row warning-info px-2 mb-2'>
                                                <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                                <div className='px-0' style={{ fontSize: "14px" }}>
                                                    Filter berdasarkan tanggal terlebih dahulu
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-2' style={{ width:"780px" }}>
                                        <div className='col-md-6'>
                                            <InputGroup className="form-tgl-lmbr">
                                                <InputGroupAddon addonType="prepend">
                                                    <div className="input-group-text">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                    </div>
                                                </InputGroupAddon>

                                                <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder="Filter Tanggal" value={this.state.valueDate} />
                                            </InputGroup>
                                        </div>
                                    </div>
                                    {this.state.reactDateRange === true &&
                                        <div className='shadow d-flex flex-column' style={{ maxWidth: "388px" }}>
                                            <div>
                                                <DateRange
                                                    onBlur={() => this.setState({ reactDateRange: false })}
                                                    ranges={[selectionRange]}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            start_date: e.selection.startDate,
                                                            end_date: e.selection.endDate,
                                                            exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                            exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                        })
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div>
                                                <div className='d-flex justify-content-end pr-2'>
                                                    <button className='btn ipresens-btn-cancel text-capitalize mb-2' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>Batal</button>
                                                    <button className='btn ipresens-btn-color mb-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                        () => this.setState({
                                                            valueDate: this.state.exportStartDate + " - " + this.state.exportEndDate,
                                                            reactDateRange: false
                                                        })
                                                    }>Terapkan</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.state.valueDate !== "" &&
                                        <>
                                        <div>
                                            <div className='row'>
                                                    <div className='col-auto d-flex justify-content-start mb-2'>
                                                        <div className='btn-group width-filter'>
                                                            <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filterExport === "new" ? ' ipresens-active-custom-btn-group' : ''? " ipresens-custom-btn-group" : "")} onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "new" ? '' : 'new',}))}>Menunggu</button>
                                                            <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filterExport === "approved" ? ' ipresens-active-custom-btn-group' : ''? " ipresens-custom-btn-group" : "")} onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "approved" ? '' : 'approved',}))}>Disetujui</button>
                                                            <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filterExport === "reject" ? ' ipresens-active-custom-btn-group' : ''? " ipresens-custom-btn-group" : "")} onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "reject" ? '' : 'reject',}))}>Ditolak</button>
                                                        </div>
                                                    </div>
                                            </div>
                                            <hr style={{ marginBottom: "40px" }} />
                                            <div className='row px-2'>
                                                <div className='col-md-4 px-1'>
                                                    <button className={'btn btn-block ipresens-btn-color px-3 py-2 text-capitalize mb-2'} style={{ fontSize: "14px", borderRadius: "11px" }} onClick={this.exportLembur}>Export Data Lembur</button>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    }
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn ipresens-btn-cancel text-capitalize" onClick={this.handleCancel}>
                                        Batalkan
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                    <Modal centered isOpen={this.state.confirmApproveAll} toggle={() => {
                        this.setState({
                            confirmApproveAll: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmApproveAll: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#E8F4FF",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CheckIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Setujui pengajuan lembur?</div>
                                    <div style={{
                                        color: "#5D6F80",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "24px"
                                    }}>{this.state.checked.length} pengajuan terpilih</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <button className='izin-tolak-button' onClick={() => {
                                this.setState({
                                    confirmApproveAll: false
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button className='btn btn-primary text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} onClick={this.bulkApprovedLembur}>
                                Setujui
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.confirmReject} toggle={() => {
                        this.setState({
                            confirmReject: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmReject: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CrossMarkIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Tolak pengajuan lembur?</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='izin-tolak-button' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmReject: false
                                        })
                                    }}>
                                        Batal
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn btn-primary text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.rejectLembur}>
                                        Ya
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal centered isOpen={this.state.confirmApprove} toggle={() => {
                        this.setState({
                            confirmApprove: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmApprove: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#E8F4FF",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={CheckIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Setujui pengajuan Lembur?</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <button className='izin-tolak-button' onClick={() => {
                                this.setState({
                                    confirmApprove: false
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button className='btn btn-primary text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} onClick={this.approvedLembur}>
                                Setujui
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Edit
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Modal centered isOpen={this.state.confirmDelete} toggle={() => {
                        this.setState({
                            confirmDelete: false
                        })
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                confirmDelete: false
                            })
                        }}>
                            <div className='d-flex'>
                                <div className='p-3 mr-4' style={{
                                    backgroundColor: "#FFECE8",
                                    borderRadius: "8px"
                                }}>
                                    <img alt='' src={TrashIcon} />
                                </div>
                                <div>
                                    <div style={{
                                        color: "#143B5E",
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        lineHeight: "27px"
                                    }}>Hapus pengajuan lembur?</div>
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalFooter style={{
                            borderTop: 0
                        }}>
                            <div className='row mx-0' style={{ width: "100%" }}>
                                <div className='col-md-6 px-1'>
                                    <button className='izin-tolak-button' style={{ width: "100%" }} onClick={() => {
                                        this.setState({
                                            confirmDelete: false
                                        })
                                    }}>
                                        Batal
                                    </button>
                                </div>
                                <div className='col-md-6 px-1'>
                                    <button className='btn btn-primary text-capitalize' style={{
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "24px",
                                        width: "100%"
                                    }} onClick={this.wantDelete}>
                                        Ya
                                    </button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalAdd} toggle={() => {
                        this.setState({
                            modalAdd: false,
                            add_karyawan_id: 0,
                            add_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                            add_jam_lembur: 0,
                            add_menit_lembur: 0,
                            add_deskripsi_lembur: "",
                            karyawan_selected: undefined
                        })
                    }} style={{
                        marginRight: "10px",
                        maxWidth: "419px",
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                modalAdd: false,
                                add_karyawan_id: 0,
                                add_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                                add_jam_lembur: 0,
                                add_menit_lembur: 0,
                                add_deskripsi_lembur: "",
                                karyawan_selected: undefined
                            })
                        }}>
                            <div style={style_add.font_header}>Tambah Lembur</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Nama Karyawan</label>
                                    <Select
                                        backspaceRemoves={true}
                                        deleteRemoves={true}
                                        classNames={{
                                            control: () => "form-control"
                                        }}
                                        styles={{
                                            control: (baseStyles) => ({
                                                ...baseStyles,
                                                padding: "8px 16px",
                                                borderRadius: "8px",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                lineHeight: "18px",
                                                color: "#B6B6B6"
                                            })
                                        }}
                                        placeholder="Ketik Nama Karyawan..."
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                        value={this.state.karyawan_selected}
                                        isLoading={this.state.karyawan_loading}
                                        onFocus={() => this.setState({ karyawan_loading: true }, () => this.searchKaryawan())}
                                        onBlur={() => this.setState({ karyawan_loading: false })}
                                        onInputChange={(val) => this.setState({ karyawan_name: val }, () => this.setState({ karyawan_loading: true }, () => this.searchKaryawan()))}
                                        options={
                                            this.state.karyawan != null ? (
                                                this.state.karyawan.map((item, index) =>
                                                (
                                                    {
                                                        key: index,
                                                        value: item.karyawan_id,
                                                        label: item.karyawan_nama,
                                                        shift: item.divisi_sift
                                                    }
                                                )
                                                ))
                                                : ("")
                                        }
                                        onChange={
                                            (karyawan_selected) => {
                                                if (!karyawan_selected) {
                                                    this.setState({
                                                        karyawan_selected: '',
                                                        add_karyawan_id: 0,
                                                    })
                                                } else {
                                                    this.setState({
                                                        karyawan_selected,
                                                        add_karyawan_id: karyawan_selected.value,
                                                    })
                                                }
                                            }
                                        }
                                        isClearable={() => {
                                            this.setState({
                                                isClearable: true,
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label for="tanggal_lembur" className="form-label" style={style_add.font_label}>Tanggal Lembur</label>
                                    <input type="date" onChange={this.handleChange} className="form-control" id="tanggal_lembur" name='add_tanggal_lembur' style={style_add.input} value={this.state.add_tanggal_lembur} placeholder='Pilih Tanggal...' />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-6'>
                                    <div className='d-flex flex-row'>
                                        <input type="number" className="form-control" id="jam" style={style_add.input} placeholder='000' onChange={(e) => {
                                            this.setState({
                                                add_jam_lembur: e.target.value !== "" ? parseInt(e.target.value) : e.target.value
                                            })
                                        }} name='add_jam_lembur' value={this.state.add_jam_lembur} onFocus={() => {
                                            if (this.state.add_jam_lembur === 0 || this.state.add_jam_lembur === "0") {
                                                this.setState({ add_jam_lembur: "" })
                                            }
                                        }} onBlur={() => {
                                            if (this.state.add_jam_lembur === "") {
                                                this.setState({
                                                    add_jam_lembur: 0
                                                })
                                            }
                                        }} />
                                        <label for="jam" type='number' className="form-label" style={style_add.font_time}>Jam</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='d-flex flex-row'>
                                        <input type="number" className="form-control" id="menit" style={style_add.input} placeholder='000' onFocus={() => {
                                            if (this.state.add_menit_lembur === 0 || this.state.add_menit_lembur === "0") {
                                                this.setState({ add_menit_lembur: "" })
                                            }
                                        }} onChange={(e) => {
                                            this.setState({
                                                add_menit_lembur: e.target.value !== "" ? parseInt(e.target.value) : e.target.value
                                            }, () => {
                                                let jam = Math.floor(this.state.add_menit_lembur / 60);
                                                let pengurangan_menit = 60 * jam;
                                                let menit = this.state.add_menit_lembur - pengurangan_menit;
                                                if (this.state.add_menit_lembur > 59) {
                                                    this.setState({
                                                        add_jam_lembur: this.state.add_jam_lembur + jam,
                                                        add_menit_lembur: menit
                                                    })
                                                }
                                            })
                                        }} name='add_menit_lembur'
                                            value={this.state.add_menit_lembur} onBlur={() => {
                                                if (this.state.add_menit_lembur === "") {
                                                    this.setState({
                                                        add_menit_lembur: 0
                                                    })
                                                }
                                            }} />
                                        <label for="menit" type='number' className="form-label" style={style_add.font_time}>Menit</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginBottom: "70px" }}>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Deskripsi Lembur</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Deskripsi Lembur...' onChange={this.handleChange} name='add_deskripsi_lembur'></textarea>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            borderTop: 0,
                            backgroundColor: "#F5F5F5",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                        }}>
                            <button className='btn ipresens-btn-cancel' onClick={() => {
                                this.setState({
                                    modalAdd: false,
                                    add_karyawan_id: 0,
                                    add_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                                    add_jam_lembur: 0,
                                    add_menit_lembur: 0,
                                    add_deskripsi_lembur: "",
                                    karyawan_selected: undefined
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button
                                className='btn ipresens-btn-color text-capitalize'
                                style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px"
                                }}
                                disabled={this.state.loading}
                                onClick={() => {
                                    this.simpanLembur()
                                }}
                            >
                                Simpan
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalEdit} toggle={() => {
                        this.setState({
                            modalEdit: false,
                            edit_karyawan_id: 0,
                            edit_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                            edit_jam_lembur: 0,
                            edit_menit_lembur: 0,
                            edit_deskripsi_lembur: "",
                            karyawan_selected: undefined
                        })
                    }} style={{
                        marginRight: "10px",
                        maxWidth: "419px",
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                modalEdit: false,
                                edit_karyawan_id: 0,
                                edit_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                                edit_jam_lembur: 0,
                                edit_menit_lembur: 0,
                                edit_deskripsi_lembur: "",
                                karyawan_selected: undefined
                            })
                        }}>
                            <div style={style_add.font_header}>Edit Lembur</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Nama Karyawan</label>
                                    <a style={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        lineHeight: "24px"
                                    }} href={"/karyawan/profil/detail/" + this.state.edit_karyawan_id} className='text-capitalize'>{this.state.edit_karyawan_nama}</a>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label for="tanggal_lembur" className="form-label" style={style_add.font_label}>Tanggal Lembur</label>
                                    <input type="date" onChange={this.handleChange} className="form-control" id="tanggal_lembur" name='edit_tanggal_lembur' style={style_add.input} value={this.state.edit_tanggal_lembur} placeholder='Pilih Tanggal...' />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-6'>
                                    <div className='d-flex flex-row'>
                                        <input type="number" className="form-control" id="jam" style={style_add.input} placeholder='000' onChange={(e) => {
                                            this.setState({
                                                edit_jam_lembur: e.target.value !== "" ? parseInt(e.target.value) : e.target.value
                                            })
                                        }} name='edit_jam_lembur' value={this.state.edit_jam_lembur} onFocus={() => {
                                            if (this.state.edit_jam_lembur === 0 || this.state.edit_jam_lembur === "0") {
                                                this.setState({ edit_jam_lembur: "" })
                                            }
                                        }} onBlur={() => {
                                            if (this.state.edit_jam_lembur === "") {
                                                this.setState({
                                                    edit_jam_lembur: 0
                                                })
                                            }
                                        }} />
                                        <label for="jam" type='number' className="form-label" style={style_add.font_time}>Jam</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='d-flex flex-row'>
                                        <input type="number" className="form-control" id="menit" style={style_add.input} placeholder='000' onFocus={() => {
                                            if (this.state.edit_menit_lembur === 0 || this.state.edit_menit_lembur === "0") {
                                                this.setState({ edit_menit_lembur: "" })
                                            }
                                        }} onChange={(e) => {
                                            this.setState({
                                                edit_menit_lembur: e.target.value !== "" ? parseInt(e.target.value) : e.target.value
                                            }, () => {
                                                let jam = Math.floor(this.state.edit_menit_lembur / 60);
                                                let pengurangan_menit = 60 * jam;
                                                let menit = this.state.edit_menit_lembur - pengurangan_menit;
                                                if (this.state.edit_menit_lembur > 59) {
                                                    this.setState({
                                                        edit_jam_lembur: this.state.edit_jam_lembur + jam,
                                                        edit_menit_lembur: menit
                                                    })
                                                }
                                            })
                                        }} name='edit_menit_lembur'
                                            value={this.state.edit_menit_lembur} onBlur={() => {
                                                if (this.state.edit_menit_lembur === "") {
                                                    this.setState({
                                                        edit_menit_lembur: 0
                                                    })
                                                }
                                            }} />
                                        <label for="menit" type='number' className="form-label" style={style_add.font_time}>Menit</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginBottom: "70px" }}>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Deskripsi Lembur</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Deskripsi Lembur...' onChange={this.handleChange} name='edit_deskripsi_lembur' value={this.state.edit_deskripsi_lembur}></textarea>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            borderTop: 0,
                            backgroundColor: "#F5F5F5",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                        }}>
                            <button className='izin-tolak-button' onClick={() => {
                                this.setState({
                                    modalEdit: false,
                                    edit_karyawan_id: 0,
                                    edit_tanggal_lembur: (new Date()).toISOString().substr(0, 10),
                                    edit_jam_lembur: 0,
                                    edit_menit_lembur: 0,
                                    edit_deskripsi_lembur: "",
                                    karyawan_selected: undefined
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button className='btn btn-primary text-capitalize' style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "24px"
                            }} onClick={() => {
                                this.handleEdit()
                            }}>
                                Simpan
                            </button>
                        </ModalFooter>
                    </Modal>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>Aktivitas</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px', paddingBottom: '16', paddingRight: '12px', paddingLeft: '12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Dibuat
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Diubah
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                                item.parent_history.lembur_desc != item.lembur_desc && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Deskripsi Lembur: Dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.lembur_desc
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: {item.lembur_desc ? item.lembur_desc : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.lembur_jumlah != item.lembur_jumlah && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Jumlah Lembur: Dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.lembur_jumlah
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: {item.lembur_jumlah ? item.lembur_jumlah : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.lembur_status != item.lembur_status && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 100,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Status Lembur : dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.lembur_status
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRight}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '1px',
                                                                                                height: '18px'
                                                                                            }}
                                                                                        /> jadi {item.lembur_status ? item.lembur_status : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.lembur_tanggal != item.lembur_tanggal && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Tanggal Lembur: Dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.lembur_tanggal
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: {item.lembur_tanggal ? item.lembur_tanggal : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>
                    <Breadcrumb title={i18n.t("daftarpengajuanlembur")} parent={i18n.t("pengajuan")} setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid">
                        <div className='card' style={{ border: "1px solid #DDDDDD" }}>
                            <div className='card-body'>
                                <div className='mb-4'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='d-flex flex-row mb-2'>
                                                <div className='flex-fill pr-2'>
                                                    <div className='input-group btn-srch-lmbr'>
                                                    <span className="input-group-text izin-search-karyawan-img"
                                                          id="basic-addon1">
                                                        <img alt='' src={SearchIcon}/>
                                                    </span>
                                                        <input type="text" name='filter_nama' style={{
                                                            lineHeight: "20px",
                                                            padding: "8px 8px"
                                                        }} onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                this.setState({
                                                                    loading: true,
                                                                    data: []
                                                                }, () => this.loadData())
                                                            }
                                                        }} value={this.state.filter_nama} onChange={this.handleChange}
                                                               className="form-control izin-search-karyawan-form"
                                                               placeholder="Cari nama karyawan..." aria-label="Username"
                                                               aria-describedby="basic-addon1"/>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='btn ipresens-btn-filter'
                                                        style={{
                                                            lineHeight: "20px",
                                                            padding: "6px 24px",
                                                            borderRadius: "8px"
                                                        }}
                                                        onClick={() => this.setState({filter: !this.state.filter})}>
                                                        <img
                                                            src={FilterIcon}/> Filter
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className='mx-1'>
                                                <button
                                                    className="mr-2 ipresens-import-button btn-import-krywn pull-left"
                                                    style={{
                                                        lineHeight: "20px",
                                                        padding: "8px 24px",
                                                        borderRadius: "8px"
                                                    }}
                                                    onClick={() => {
                                                        this.setState({modalimport: true})
                                                    }}><img src={ImportIcon} alt=''/> Import
                                                </button>
                                                <button
                                                    className='btn ipresens-btn-color px-3 text-capitalize pull-left width-filter tombol-export'
                                                    style={{lineHeight: "20px", fontSize: "14px", borderRadius: "8px", padding: "6px 18px",}}
                                                    onClick={() => {
                                                        this.setState({modalexport: true})
                                                    }}><img src={DownloadIcon}/> {i18n.t('eksporexcel')}
                                                </button>
                                            </div>
                                            <div>
                                                {!this.state.loading &&
                                                    <>
                                                        <button
                                                            className='btn ipresens-btn-color text-capitalize float-right ml-2 izin-btn-w100'
                                                            style={{
                                                                lineHeight: "20px",
                                                                padding: "6px 18px",
                                                                borderRadius: "8px",
                                                                fontSize: '14px',
                                                            }} onClick={() => {
                                                                this.setState({
                                                                    modalAdd: true
                                                                })
                                                            }}><img src={PlusWhiteIcon} alt=''/> {i18n.t("tambah")}
                                                        </button>

                                                        <button
                                                            className='btn ipresens-btn-color text-capitalize float-right ml-2 izin-btn-w100'
                                                            style={{
                                                                lineHeight: "20px",
                                                                padding: "8px 24px",
                                                                borderRadius: "8px"
                                                            }} onClick={() => {
                                                                this.setState({
                                                                    confirmApproveAll: true
                                                                })
                                                            }}
                                                            disabled={this.state.checked.length === 0}>{i18n.t("setujui")}
                                                        </button>
                                                        <button
                                                            className='lembur-tolak-button ipresens-btn-cancel text-capitalize float-right izin-btn-w100'
                                                            onClick={() => {
                                                                this.setState({
                                                                    confirmRejectAll: true
                                                                })
                                                            }} style={{
                                                                lineHeight: "20px",
                                                                padding: "8px 24px",
                                                                borderRadius: "8px"
                                                            }}
                                                            disabled={this.state.checked.length === 0}>{i18n.t("tolak")}
                                                        </button>
                                                        {this.state.checked.length !== 0 &&
                                                            <div className='float-right mr-2' style={{
                                                                height: "100%",
                                                                paddingTop: "8px"
                                                            }}>
                                                                <div style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 500,
                                                                    lineHeight: "24px",
                                                                    color: "#143B5E",
                                                                }}>
                                                                    {this.state.checked.length} pengajuan telah dipilih
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.filter &&
                                        <div className='row mx-0'>
                                            <div className='col-md-2 px-1 mb-2'>
                                                <label className='form-label' style={{fontSize: "12px"}}>Bulan</label>
                                                <select name="filter_month" className='form-control'
                                                        value={this.state.filter_month} style={{fontSize: "12px"}}
                                                        onChange={this.handleFilter}>
                                                    <option value="">Pilih Bulan</option>
                                                    <option value="1">Januari</option>
                                                    <option value="2">Februari</option>
                                                    <option value="3">Maret</option>
                                                    <option value="4">April</option>
                                                    <option value="5">Mei</option>
                                                    <option value="6">Juni</option>
                                                    <option value="7">Juli</option>
                                                    <option value="8">Agustus</option>
                                                    <option value="9">September</option>
                                                    <option value="10">Oktober</option>
                                                    <option value="11">November</option>
                                                    <option value="12">Desember</option>
                                                </select>
                                            </div>
                                            <div className='col-md-2 px-1 mb-2'>
                                                <label className='form-label' style={{fontSize: "12px"}}>Tahun</label>
                                                <select name="filter_year" className='form-control'
                                                        value={this.state.filter_year} style={{fontSize: "12px"}}
                                                        onChange={this.handleFilter}>
                                                    <option value="">Pilih Tahun</option>
                                                    <option value="2020">2020</option>
                                                    <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                            </select>
                                        </div>
                                        <div className='col-auto px-1 d-flex align-items-end justify-content-end mb-2'>
                                            <div className='btn-group width-filter'>
                                                <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filter_status_lm === "pending" ? ' ipresens-active-custom-btn-group' : '')} onClick={() => this.setState({ filter_status_lm: "pending", loading: true }, () => this.loadData())}>Menunggu</button>
                                                <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filter_status_lm === "approved" ? ' ipresens-active-custom-btn-group' : '')} onClick={() => this.setState({ filter_status_lm: "approved", loading: true }, () => this.loadData())}>Disetujui</button>
                                                <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filter_status_lm === "reject" ? ' ipresens-active-custom-btn-group' : '')} onClick={() => this.setState({ filter_status_lm: "reject", loading: true }, () => this.loadData())}>Ditolak</button>
                                            </div>
                                        </div>
                                        <div className='col-auto px-1 d-flex align-items-end justify-content-end pb-2'>
                                            <button className='btn text-capitalize px-2 width-filter ipresens-btn-reset-filter' onClick={
                                                () => {
                                                    this.setState({
                                                        loading: true,
                                                        filter_nama: "",
                                                        filter_month: ("1 2 3 4 5 6 7 8 9 10 11 12").split(" ")[new Date().getMonth()],
                                                        filter_year: new Date().getFullYear(),
                                                        filter_status_lm: "",
                                                    }, () => this.loadData())
                                                }
                                            }><img src={RefreshIcon} alt="icon refresh" /> Reset Filter</button>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className='table table-borderless'>
                                                <thead>
                                                    <tr>
                                                        <th className='lembur-th-style'>
                                                            <input className='form-control' type='checkbox' checked={this.state.checked_all} name="checked_all" onChange={(e) => this.handleCheckAll(e)} />
                                                        </th>
                                                        <th className='lembur-th-style'>#</th>
                                                        <th className='lembur-th-style' style={{ maxWidth: "115px" }}>{i18n.t('karyawan')}</th>
                                                        <th className='lembur-th-style' style={{ minWidth: "150px" }}>
                                                            <div className='d-flex'>
                                                                <div className='izin-th-style'>{i18n.t('tanggallembur')}</div>
                                                                <div className='ml-1 d-flex flex-column pt-1'>
                                                                    <img alt='' onClick={() => {
                                                                        this.sortabledate(1)
                                                                    }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} />
                                                                    <img alt='' onClick={() => {
                                                                        this.sortabledate(0)
                                                                    }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className='lembur-th-style' style={{ minWidth: "160px" }}>{i18n.t('jammasukkeluar')}</th>
                                                        <th className='lembur-th-style' style={{ minWidth: "135px" }}>{i18n.t('durasilembur')}</th>
                                                        <th className='lembur-th-style' style={{ minWidth: "155px" }}>{i18n.t('deskripsilembur')}</th>
                                                        <th className='lembur-th-style'>
                                                            <div className='d-flex'>
                                                                <div className='izin-th-style'>{i18n.t('status')} </div>
                                                                <div className='ml-1 d-flex flex-column pt-1'>
                                                                    <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} onClick={() => {
                                                                        this.sortablestatus(1)
                                                                    }} />
                                                                    <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} onClick={() => {
                                                                        this.sortablestatus(0)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th className='lembur-th-style'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.loading ?
                                                        <tr>
                                                            <td colSpan={10}>
                                                                <Spin size="large" tip="Loading..." style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }} />
                                                            </td>
                                                        </tr>
                                                        :
                                                        this.state.data.length === 0 ?
                                                            <tr>
                                                                <td colSpan={9}>
                                                                    <Empty description={i18n.t("datakosong")}
                                                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                                </td>
                                                            </tr>
                                                            :
                                                            this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <tr style={{
                                                                        borderBottom: no === this.state.data.length ? "" : borderBottom
                                                                    }}>
                                                                        <td className='lembur-td-style'>
                                                                            <input className='form-control' type='checkbox' name={"row-" + item.lembur_id} checked={this.state.checked.includes(item.lembur_id)} onChange={() => this.handleCheckBoxChange(item.lembur_id)} />
                                                                        </td>
                                                                        <td className='lembur-td-style'>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                        <td className='lembur-td-style' style={{ maxWidth: "115px" }}>
                                                                            <a href={"/karyawan/profil/detail/" + item.karyawan.karyawan_id}>
                                                                                {this.state.slice_karyawan_nama ? item.karyawan.karyawan_nama.slice(0, 14) : item.karyawan.karyawan_nama}
                                                                            </a>
                                                                            {item.karyawan.karyawan_nama.length >= 15 && this.state.slice_karyawan_nama === true ?
                                                                                <button className='izin-btn' style={{ color: "#6BA9FF", fontSize: "24px", fontWeight: 400, padding: "0px" }} onClick={() => {
                                                                                    this.setState({
                                                                                        slice_karyawan_nama: false
                                                                                    })
                                                                                }}>...</button> : ""}
                                                                            {item.karyawan.karyawan_nama.length >= 15 && this.state.slice_karyawan_nama === false ?
                                                                                <button className='izin-btn' style={{ color: "#6BA9FF", fontSize: "14px", fontWeight: 400, padding: "0px" }} onClick={() => {
                                                                                    this.setState({
                                                                                        slice_karyawan_nama: true
                                                                                    })
                                                                                }}>lebih sedikit</button> : ""}
                                                                        </td>
                                                                        <td className='lembur-td-style'>
                                                                            {moment(item.lembur_tanggal).format('ll')}
                                                                        </td>
                                                                        <td className='lembur-td-style'>
                                                                            {item.jam_masuk_absen !== null ? item.jam_masuk_absen : "__:__:__"} -
                                                                            {item.jam_keluar_absen !== null ? item.jam_keluar_absen : "__:__:__"}
                                                                        </td>
                                                                        <td className='lembur-td-style'>
                                                                            {parseInt(item.lembur_jumlah / 60)} Jam {item.lembur_jumlah - (parseInt(item.lembur_jumlah / 60) * 60)} Menit
                                                                        </td>
                                                                        <td className='lembur-td-style'>
                                                                            {item.lembur_desc}
                                                                        </td>
                                                                        <td className='lembur-td-style' style={{ color: item.lembur_status === "pending" || item.lembur_status === "draft" ? "#FF9C00" : item.lembur_status === "reject" ? "#E03616" : item.lembur_status === "approved" ? "#1E7E0E" : "" }}>
                                                                            {item.lembur_status === "pending" || item.lembur_status === "draft" ? "Menunggu" : item.lembur_status === "reject" ? "Ditolak" : item.lembur_status === "approved" ? "Disetujui" : ""}
                                                                        </td>
                                                                        <td className='lembur-td-style'>
                                                                            <Tooltip placement="bottom" trigger={"click"} zIndex={1000} color={'#fff'} title={
                                                                                <div className={'d-flex flex-column'}>
                                                                                    <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                                        this.setState({
                                                                                            modalHistory: true,
                                                                                            lembur_id: item.lembur_id
                                                                                        },()=>{
                                                                                            this.loadHistory(item.lembur_id);
                                                                                        })
                                                                                    }}>Riwayat</button>
                                                                                    <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                                        let jam = Math.floor(item.lembur_jumlah / 60)
                                                                                        let menit = item.lembur_jumlah - 60 * jam
                                                                                        this.setState({
                                                                                            modalEdit: true,
                                                                                            edit_lembur_id: item.lembur_id,
                                                                                            edit_karyawan_id: item.karyawan.karyawan_id,
                                                                                            edit_karyawan_nama: item.karyawan.karyawan_nama,
                                                                                            edit_tanggal_lembur: item.lembur_tanggal,
                                                                                            edit_jam_lembur: jam,
                                                                                            edit_menit_lembur: menit,
                                                                                            edit_deskripsi_lembur: item.lembur_desc,
                                                                                        })
                                                                                    }}>Edit</button>
                                                                                    {item.lembur_status !== "approved" &&
                                                                                        <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                                            this.setState({ approvedLembur: item.lembur_id, confirmApprove: true })
                                                                                        }}>Setujui</button>
                                                                                    }
                                                                                    {item.lembur_status !== "reject" &&
                                                                                        <button style={{ width: "84px" }} className='izin-btn izin-tooltip-menu px-2 py-1 mb-1' onClick={() => {
                                                                                            this.setState({ rejectLembur: item.lembur_id, confirmReject: true })
                                                                                        }}>Tolak</button>
                                                                                    }
                                                                                    <button style={{ width: "84px", color: "red" }} className='izin-btn izin-tooltip-menu px-2 py-1' onClick={() => {
                                                                                        this.setState({
                                                                                            idDel: item.lembur_id,
                                                                                            confirmDelete: true
                                                                                        })
                                                                                    }}>Hapus</button>
                                                                                </div>
                                                                            }>
                                                                                <button className='izin-btn'>
                                                                                    <img alt='' src={ElipsisIcon} />
                                                                                </button>
                                                                            </Tooltip>

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer p-0 border-0'>
                                <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                    {this.state.data.length !== 0 &&
                                        <>
                                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}</div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeftIcon} alt='' />}
                                                    nextLabel={<img src={ArrowRightIcon} alt='' />}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link lembur-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link lembur-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link lembur-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link lembur-prev-next-link'}
                                                    activeClassName={'active lembur-active'}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_lembur);
