import HomeIcon from '../assets/icons/home.svg'
import PengumumanIcon from '../assets/icons/pengumuman.svg'
import MasterIcon from '../assets/icons/master.svg'
import PengajuanIcon from '../assets/icons/pengajuan.svg'
import UserIcon from '../assets/icons/user.svg'
import CalendarIcon from '../assets/icons/calendar.svg'
import LocationIcon from '../assets/icons/location.svg'
import PayrollIcon from '../assets/icons/payroll.svg'
import WalletIcon from '../assets/icons/wallet.svg'
import TaskIcon from '../assets/icons/task.svg'
import MedalIcon from '../assets/icons/medal.svg'
import SettingIcon from '../assets/icons/settings.svg'
import MonitorIcon from '../assets/icons/monitor_company.svg'

export const MENUITEMS = [
    {
        path: '/dashboard', title: 'Dashboard', langid: "dashboard", icon: HomeIcon, iconType: 'svg', type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Monitor', langid: "monitor", path: '/monitor', icon: MonitorIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Pengumuman', langid: "pengumuman", path: '/announcement', icon: PengumumanIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Master Data', langid: "masterdata", icon: MasterIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/employee/shift', title: 'Shift', langid: "shift", type: 'link' },
            { path: '/employee/hour-work', title: 'Jam Kerja', langid: "jamkerja", type: 'link' },
            { path: '/employee/division-employee', title: 'Divisi', langid: "divisi", type: 'link' },
            { path: '/employee/employee-position', title: 'Jabatan', langid: "jabatan", type: 'link' },
            { path: '/visitation-location', title: 'Lokasi Kunjungan', langid: "lokasikunjungan", type: 'link' },
            { path: '/presensi-location', title: 'Lokasi Presensi', langid: "lokasipresensi", type: 'link' },
            { path: '/payroll-term-list', title: 'Daftar Istillah', langid: "daftaristillah", type: 'link' },
        ]
    },
    {
        title: 'Pengajuan Karyawan', langid: "pengajuankaryawan", icon: PengajuanIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/submission/permit', title: 'Pengajuan Izin/Cuti', langid: "pengajuanizin", type: 'link' },
            { path: '/submission/overtime', title: 'Pengajuan Lembur', langid: "pengajuanlembur", type: 'link' },
            { path: '/submission/casbon', title: 'Pengajuan Kasbon', langid: "pengajuankasbon", type: 'link' },
            { path: '/submission/claim-expenses', title: 'Pengajuan Klaim Beban', langid: "pengajuanklaimbeban", type: 'link' },
            // { path: '/pengajuan/revisi', title: 'Pengajuan Revisi Data', type: 'link' },
        ]
    },
    {
        title: 'Karyawan', langid: "karyawan", path: '/employee/employee-list/0', icon: UserIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Presensi', langid: "presensi", icon: CalendarIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/presence', title: 'Presensi Harian', langid: "presensiharian", type: 'link' },
            { path: '/presence-month', title: 'Presensi Bulanan', langid: "presensibulanan", type: 'link' },
            // { path: '/pengaturan/istirahat', title: 'Pengaturan Istirahat', langid: "pengaturanistirahat", type: 'link' },
        ]
    },
    {
        title: 'Agenda', path: '/agenda/list-agenda', icon: UserIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Kunjungan', langid: "kunjungan", icon: LocationIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/visit/list-visit', title: 'Daftar Kunjungan', langid: "daftarkunjungan", type: 'link' },
            { path: '/visit/visit-report', title: 'Laporan Kunjungan', langid: "laporankunjungan", type: 'link' }
        ]
    },

    {
        title: 'Tugas (BETA)', langid: "tugasbeta", icon: TaskIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/task', title: 'Daftar Tugas', langid: "daftartugas", type: 'link' },
            { path: '/task-completed', title: 'Tugas Terselesaikan', langid: "tugasterselesaikan", type: 'link' }
        ]
    },
    {
        title: 'BPJS', icon: PayrollIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/bpjs/setting', title: 'Pengaturan BPJS', type: 'link' },
        ]
    },
    {
        title: 'Payroll', langid: "payroll", icon: PayrollIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/employee-payroll', title: 'Daftar Gaji Karyawan', langid: "daftargajikaryawan", type: 'link' },
            { path: '/payroll-term-list', title: 'Daftar Istilah', langid: "daftaristilah", type: 'link' },
            { path: '/salary-list/list', title: 'Daftar Payroll', langid: "daftarpayroll", type: 'link' },
            { path: '/payroll/setting', title: 'Pengaturan Payroll', langid: "pengaturanpayroll", type: 'link' },
        ]
    },
    {
        title: 'Pembayaran Kasbon', langid: "pembayarankasbon", path: '/cashbon-payment/list', icon: WalletIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Langganan', langid: "langganan", path: '/subscription', icon: MedalIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Pengaturan', langid: "pengaturan", icon: SettingIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/settings/profile', title: 'Profile', langid: "profile", type: 'link' },
            { path: '/settings/mobile', title: 'Pengaturan Aplikasi', langid: "pengaturanaplikasi", type: 'link' },
            { path: '/settings/presensi', title: 'Pengaturan Presensi', langid: "pengaturanpresensi", type: 'link' },
            { path: '/settings/recognition', title: 'Pengaturan Recognition', langid: "pengaturanrecognition", type: 'link' },
            { path: '/settings/user', title: 'User Login', langid: "userlogin", type: 'link' },
            { path: '/settings/user/level', title: 'User Level', langid: "userlevel", type: 'link' },
        ]
    },
]

export const MENUITEMSGUIDE = [
    {
        path: '/dashboard', title: 'Dashboard', icon: HomeIcon, iconType: 'svg', type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Master Data', icon: MasterIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/guide/working-hours', title: 'Jam Kerja', type: 'link' },
            { path: '/guide/divisi', title: 'Divisi', type: 'link' },
            { path: '/guide/lokasi', title: 'Lokasi', type: 'link' },
        ]
    },
    {
        title: 'Karyawan', path: '/guide/karyawan', icon: UserIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Langganan', path: '/subscription', icon: MedalIcon, iconType: 'svg', type: 'link', active: false
    },
    {
        title: 'Pengaturan', icon: SettingIcon, iconType: 'svg', type: 'sub', children: [
            { path: '/settings/profile', title: 'Profile', type: 'link' },
            { path: '/settings/user', title: 'User Login', type: 'link' },
            { path: '/settings/user/level', title: 'User Level', type: 'link' },
        ]
    },
]
