import React from "react";

import Swal from "sweetalert2";
import InlineEdit from 'react-edit-inline2';
import { Spin } from "antd";
import i18n from "i18next";
import InfoIcon from '../../assets/icons/info-icon-blue.svg';
import EmptyDataImage from '../../assets/images/data-kosong.png';
import PlusIcon from '../../assets/icons/plus.svg';
import CloseIcon from '../../assets/icons/close_circle.svg';
import TrashIcon from '../../assets/icons/trash.svg';
import RefreshIcon from '../../assets/icons/refresh.svg';
import SearchIcon from '../../assets/icons/search.svg';
import "./style.css";

class JabatanDivisi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            view_add: false,
            view_add_list: false,
            edit_inline: false,
            filtered: false,
            data: [],
            name: "",
            filter_nama: "",
            jabatan_name: "",
            jabatan_id: 0
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value

        this.setState({
            [name]: value
        })
    }

    handleCheck(e, id) {
        const value = e.target.checked

        if (value) {
            this.props.setState({
                id_jabatan: [...this.props.id_jabatan, id]
            })
        } else {
            let deleted_value = this.props.id_jabatan.filter(function (item) {
                return item !== id
            })

            this.props.setState({
                id_jabatan: deleted_value
            })
        }
    }

    addJabatan() {
        let form = {
            name: this.state.name
        }
        this.props.request("jabatan/add", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                name: "",
                                view_add_list: false,
                                view_add: false
                            })
                            this.loadJabatan()
                        } else {
                            this.setState({
                                name: "",
                                view_add_list: false,
                                view_add: false
                            })
                            this.loadJabatan()
                        }
                    })
                } else {
                    Swal.fire({
                        title: "gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    deleteJabatan() {
        let form = {
            id: this.state.jabatan_id
        }
        this.props.request("jabatan/delete", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.loadJabatan()
                        } else {
                            this.loadJabatan()
                        }
                    })
                } else {
                    Swal.fire({
                        title: "gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    editJabatan(id) {
        let form = {
            name: this.state.jabatan_name,
            id: id
        }
        this.props.request("jabatan/update", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.loadJabatan()
                        } else {
                            this.loadJabatan()
                        }
                    })
                } else {
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.loadJabatan()
                        } else {
                            this.loadJabatan()
                        }
                    })
                }
            })
    }

    loadJabatan() {
        this.props.setState({
            loadingJabatan: true
        })
        let form = {
            filter_nama: this.state.filter_nama
        }
        this.props.request("divisi/list-divisi-jabatan-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.props.setState({
                        dataJabatan: response.data,
                        loadingJabatan: false
                    })
                }
            })
    }

    render() {
        var screen_width = window.innerWidth
        let width_input;
        if (screen_width <= 768) {
            width_input = "100%"
        } else {
            width_input = "50%"
        }
        const style = {
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        return (
            <div className="container-fluid" style={{ padding: "20px 0px" }}>
                <>
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div style={{ backgroundColor: "#E8F4FF", borderRadius: "6px", padding: "12px" }} className="d-flex">
                                <img className="mr-2" alt="" src={InfoIcon} />
                                <div style={{
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "24px",
                                    color: "#143B5E"
                                }}>Silahkan memilih Jabatan yang berlaku pada divisi ini</div>
                            </div>
                        </div>
                    </div>
                    {this.state.view_add ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row d-flex flex-nowrap" style={{ padding: "0px 15px" }}>
                                    <div className="col-md-6" style={{ backgroundColor: "#F9F9F9", paddingTop: "26px", paddingBottom: "26px" }}>
                                        <input className="form-control" name="name" type="text" onChange={this.handleChange} value={this.state.name} style={{ height: "100%" }} />
                                    </div>
                                    <div className="col-md-2 px-1" style={{ backgroundColor: "#F9F9F9", paddingTop: "26px", paddingBottom: "26px" }}>
                                        <button className="btn batalkan-add-jamker text-capitalize" onClick={() => this.addJabatan()}><img alt="" src={PlusIcon} /> {screen_width <= 768 ? "" : "Tambahkan"}</button>
                                    </div>
                                    <div className="col-md-4" style={{ backgroundColor: "#F9F9F9", paddingTop: "26px", paddingBottom: "26px" }}>
                                        <button className="hover-pointer pull-right" onClick={() => this.setState({ view_add: false })} style={{ height: "100%", border: "none", backgroundColor: "#F9F9F9" }} >
                                            <img src={CloseIcon} alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        this.props.dataJabatan.length === 0 && this.state.filtered === false ?
                            <div className='row' style={{ width: "100%" }}>
                                <div className='col' style={{ textAlign: "center" }}>
                                    <img src={EmptyDataImage} className="ipresens-image-empty" alt="icon data kosong" />
                                    <p className='mb-3'>Jabatan tidak tersedia, silahkan menambahkan jabatan baru</p>
                                    <button className="btn batalkan-add-jamker text-capitalize" onClick={() => this.setState({ view_add: true })}><img alt="" src={PlusIcon} />Tambahkan Jabatan</button>
                                </div>
                            </div>
                            :
                            <>
                                {this.state.view_add_list ?
                                    <div className="row mb-2">
                                        <div className="col-md-12">
                                            <div className="row d-flex flex-nowrap" style={{ padding: "0px 15px" }}>
                                                <div className="col-md-4" style={{ backgroundColor: "#F9F9F9", paddingTop: "18px", paddingBottom: "18px" }}>
                                                    <input className="form-control" name="name" type="text" onChange={this.handleChange} value={this.state.name} style={{ height: "100%" }} />
                                                </div>
                                                <div className="col-md-2 px-1" style={{ backgroundColor: "#F9F9F9", paddingTop: "18px", paddingBottom: "18px" }}>
                                                    <button className="btn batalkan-add-jamker text-capitalize" onClick={() => this.addJabatan()}><img alt="" src={PlusIcon} /> {screen_width <= 768 ? "" : "Tambahkan"}</button>
                                                </div>
                                                <div className="col-md-6" style={{ backgroundColor: "#F9F9F9", paddingTop: "18px", paddingBottom: "18px" }}>
                                                    <button className="hover-pointer pull-right" onClick={() => this.setState({ view_add_list: false })} style={{ height: "100%", border: "none", backgroundColor: "#F9F9F9" }} >
                                                        <img src={CloseIcon} alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row mb-2">
                                        <div className="col-md-4">
                                            <div className='input-group'>
                                                <div className='input-group-prepend'>
                                                    <div className='input-group-text border-right-0' style={{ backgroundColor: "#F9F9F9" }}>
                                                        <img alt='' src={SearchIcon} />
                                                    </div>
                                                </div>
                                                <input name="filter_nama" onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        this.setState({
                                                            filtered: true
                                                        }, () => this.loadJabatan())
                                                    }
                                                }} onChange={this.handleChange} type='text' className='form-control border-left-0' placeholder={i18n.t("carinamajabatan") + '...'} style={style.filter_nama} value={this.state.filter_nama} ></input>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <button className="hover-pointer" style={style.button_refresh} onClick={() => {
                                                this.setState({ filter_nama: "", filtered: false }, () => this.loadJabatan())
                                            }}><img alt="" src={RefreshIcon} /></button>
                                        </div>
                                        <div className="col-md-6">
                                            <button onClick={() => this.setState({ view_add_list: true, name: "" })} className='btn batalkan-add-jamker text-capitalize pull-right button-w-full'><img className='mr-2' alt='' src={PlusIcon} />{i18n.t("tambahjabatan")}</button>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped table-borderless">
                                            <tbody>
                                                {this.props.loadingJabatan ?
                                                    <tr>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Spin size="large" />
                                                        </td>
                                                    </tr>
                                                    :
                                                    this.state.filtered && this.props.dataJabatan.length === 0 ?
                                                        <div style={{ textAlign: "center" }}>{i18n.t("datakosong")}...</div>
                                                        :
                                                        this.props.dataJabatan.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td style={style.td_style} className="pr-0">
                                                                        <input type="checkbox" checked={this.props.id_jabatan.includes(item.jabatan_id)} onClick={(e) => this.handleCheck(e, item.jabatan_id)} />
                                                                    </td>
                                                                    <td style={style.td_style} className="pl-0">
                                                                        <InlineEdit
                                                                            validate={this.customValidateText}
                                                                            // className="form-control edit-inline-input"
                                                                            text={item.jabatan_nama}
                                                                            paramName="message"
                                                                            change={(e) => this.setState({ jabatan_name: e.message }, () => this.editJabatan(item.jabatan_id))}
                                                                        />
                                                                    </td>
                                                                    <td style={style.td_style}>
                                                                        <button className="pull-right hover-pointer" onClick={() => this.setState({ jabatan_id: item.jabatan_id }, () => this.deleteJabatan())} style={{ border: "none", background: "none" }}>
                                                                            <img alt="" src={TrashIcon} />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                    }
                </>
            </div>
        )
    }
}
export default JabatanDivisi;
