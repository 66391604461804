import React, { Component } from 'react';
import Swal from 'sweetalert2';
import validation from '../../../helper/validation';
import EyeIcons from '../../../assets/icons/eye.svg';
import EyeSlashIcon from "../../../assets/icons/slash-eye-blue.svg";
import CheckBlue from '../../../assets/icons/tick-check-blue.svg';
import i18n from "i18next";

export default class DaftarAkun extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errorsMessage: {},
            paketMonth: this.props.paketMonth,
            message: "",
            showPassword: false,
            showConfirmPassword: false,
        }

        this.validate = this.validate.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.handleFocus = this.handleFocus.bind(this)
    }

    sendOTP = () => {
        if (this.props.disableSendOTP) {
            return;
        }
        this.validate('no_whatsapp', this.props.no_whatsapp)

        let form = {
            perusahaan_no_wa: this.props.no_whatsapp,
            half: true
        }
        this.props.request("otp-wa", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.setState({ otpwaForm: true })
                    this.props.startTimer()
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.props.setState({
                    disabledButton: false
                })
            });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'no_whatsapp') {
            if (value !== '+' && isNaN(value)) {
                return;
            }
        }
        this.props.setState({
            [name]: value
        })
    }

    handleFocus(event) {
        const target = event.target;
        const name = target.name;
        const errorsMessage = this.state.errorsMessage

        if (name === 'password') {
            if (this.state.errorsMessage.hasOwnProperty('confirm_password')) {
                delete errorsMessage.passwordperusahaanconfirm
            }
        }

        if (this.state.errorsMessage.hasOwnProperty(name)) {
            delete errorsMessage[name]
        }

        this.setState({
            errorsMessage,
            disabledButtonErr: Object.keys(this.state.errorsMessage).length > 0 ? true : false
        })
    }

    handleBlur(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.validate(name, value)
    }

    validate(name, value) {
        let errorsMessage = this.state.errorsMessage

        switch (name) {
            case 'no_whatsapp':
                if (value.trim() === '') {
                    errorsMessage[name] = 'No. WhatsApp perusahaan harus diisi!'
                }
                else if (value.length < 9 || value.length > 12) {
                    errorsMessage[name] = 'No. WhatsApp perusahaan tidak valid!'
                } else {
                    delete errorsMessage[name]
                }
                break
            case 'code_otp':
                if (value.trim() === '') {
                    errorsMessage[name] = 'OTP WhatsApp harus diisi!'
                } else {
                    delete errorsMessage[name]
                }
                break
            case 'email':
                const val = validation(value)
                if (val === true) {
                    delete errorsMessage[name]
                } else {
                    errorsMessage[name] = val;
                }
                break
            case 'company_name':
                if (value === "") {
                    errorsMessage[name] = "Nama Perusahaan harus diisi!"
                } else {
                    delete errorsMessage[name]
                }
                break
            case 'password':
                if (value.trim() === '') {
                    errorsMessage[name] = 'Password harus diisi!'
                } else if (value.trim().length < 6) {
                    errorsMessage[name] = 'Password minimal adalah 6 karakter!'
                } else if (value.trim().length > 16) {
                    errorsMessage[name] = 'Password maksimal adalah 16 karakter!'
                } else if (this.props.password !== '') {
                    if (value !== this.props.password) {
                        errorsMessage.password = 'Password yang anda masukan berbeda!'
                    } else {
                        delete errorsMessage.password
                    }
                } else {
                    delete errorsMessage[name]
                }
                break
            case 'confirm_password':
                if (this.props.password.trim() !== '' && value.trim() === '') {
                    errorsMessage[name] = 'Ulangi Password harus diisi!'
                } else if (this.props.confirm_password.trim() !== '' && value !== this.props.password) {
                    errorsMessage[name] = 'Password yang anda masukkan berbeda!'
                } else {
                    delete errorsMessage[name]
                }
                break
            default:
                errorsMessage = this.state.errorsMessage
        }

        this.setState({
            errorsMessage,
            disabledButtonErr: Object.keys(this.state.errorsMessage).length > 0 ? true : false
        })
    }

    submitRegister = () => {
        this.validate('email', this.props.email)
        this.validate('password', this.props.password)
        this.validate('confirm_password', this.props.confirm_password)
        this.validate("company_name", this.props.company_name)

        if (Object.keys(this.state.errorsMessage).length > 0) {
            return
        } else {
            this.setState({
                disabledButton: true
            })
        }

        let form = {
            paket_id: this.props.paket_id,
            company_name: this.props.company_name,
            email: this.props.email,
            password: this.props.password,
            confirm_password: this.props.confirm_password,
            otp_code: this.props.otp_code,
            no_whatsapp: this.props.no_whatsapp,
            paket_month: this.props.paketMonth
        }

        this.props.request("register_v2", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.setState({ stepHeader: 2, message: response.message })
                }
                if (response.failed) {
                    const errorsMessage = this.state.errorsMessage
                    if (response.failed === 'Email sudah dipakai') {
                        errorsMessage.emailregister = 'Email sudah dipakai!'
                    } else {
                        Swal.fire({
                            title: 'Gagal!',
                            text: response.failed,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                    this.setState({
                        errorsMessage,
                        disabledButtonErr: true
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });
    }

    render() {
        return (
            <main style={{ backgroundColor: "#F9F9F9", minHeight: "90vh", marginTop: "110px" }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col'></div>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='card border'>
                                        <div className='card-header border py-2 px-3' style={{ backgroundColor: "#FAFDFF", fontSize: "18px", fontWeight: 700, color: "#143B5E", lineHeight: "24px", letterSpacing: "0px" }}>{i18n.t('daftarakun')}</div>
                                        <div className='card-body px-3 py-3' style={{ backgroundColor: "#FFFFFF" }}>
                                            <div style={{ fontSize: "12px" }}>
                                                <div class="form-group">
                                                    <label for="company_name" style={{ color: "#5D6F80", fontSize: "12px", fontWeight: 500 }}>{i18n.t('namaperusahaan')}</label>
                                                    <input name="company_name" style={{ fontSize: "12px" }} type="text" class={this.state.errorsMessage.company_name ? "form-control is-invalid" : "form-control"} id="company_name" aria-describedby="emailHelp" placeholder={i18n.t('namaperusahaandesc')} value={this.props.company_name} onChange={this.handleChange} />
                                                    <div style={{ marginTop: '3px' }} class="invalid-feedback"><strong>{this.state.errorsMessage.company_name}</strong></div>
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1" style={{ color: "#5D6F80", fontSize: "12px", fontWeight: 500 }}>Email</label>
                                                    <input name="email" style={{ fontSize: "12px" }} type="email" class={this.state.errorsMessage.emailregister ? "form-control is-invalid" : "form-control"} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email@gmail.com" value={this.props.email} onChange={this.handleChange} />
                                                    <div style={{ marginTop: '3px' }} class="invalid-feedback"><strong>{this.state.errorsMessage.emailregister}</strong></div>
                                                </div>
                                                <div class="form-group">
                                                    <div className='input-group'>
                                                        <label for="exampleInputPassword1" style={{ color: "#5D6F80", fontSize: "12px", fontWeight: 500 }}>Password</label>
                                                        <input name="password" style={{ fontSize: "12px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }} type={this.state.showPassword === true ? "text" : "password"} class={this.state.errorsMessage.password ? "form-control is-invalid" : "form-control border-right-0"} id="exampleInputPassword1" placeholder={i18n.t('passworddesc')} value={this.props.password} onChange={this.handleChange} />
                                                        <button onClick={() => this.setState({ showPassword: !this.state.showPassword })} class="btn input-group-text border-left-0 pr-2 pl-2" style={{ fontSize: "12px", backgroundColor: "#FFFFFF", borderRadius: "0px", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }} id="basic-addon2">
                                                            <img src={this.state.showPassword ? EyeSlashIcon : EyeIcons} alt='' style={{ width: "16px", height: "16px" }} />
                                                        </button>
                                                        <div style={{ marginTop: '3px' }} class="invalid-feedback"><strong>{this.state.errorsMessage.password}</strong></div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div className='input-group'>
                                                        <label for="exampleInputPassword1" style={{ color: "#5D6F80", fontSize: "12px", fontWeight: 500 }}>{i18n.t('confirmpassword')}</label>
                                                        <input name="confirm_password" style={{ fontSize: "12px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }} type={this.state.showConfirmPassword === true ? "text" : "password"} class={this.state.errorsMessage.confirm_password ? "form-control is-invalid" : "form-control border-right-0"} id="exampleInputPassword1" placeholder={i18n.t('confirmpassworddesc')} value={this.props.confirm_password} onChange={this.handleChange} />
                                                        {this.props.password === this.props.confirm_password && this.props.password !== "" && this.props.confirm_password !== "" ?
                                                            <span className='input-group-text border-left-0 border-right-0 px-2' style={{ borderRadius: "0px", backgroundColor: "#FFFFFF" }}>
                                                                <img src={CheckBlue} alt='' />
                                                            </span>
                                                            :
                                                            ""
                                                        }
                                                        <button onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })} class="btn input-group-text border-left-0 pr-2 pl-2" style={{ fontSize: "12px", backgroundColor: "#FFFFFF", borderRadius: "0px", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }} id="basic-addon2">
                                                            <img src={this.state.showConfirmPassword ? EyeSlashIcon : EyeIcons} alt='' style={{ width: "16px", height: "16px" }} />
                                                        </button>
                                                        <div style={{ marginTop: '3px' }} class="invalid-feedback"><strong>{this.state.errorsMessage.confirm_password}</strong></div>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group mb-3">
                                                        <label for="exampleInputEmail1" style={{ color: "#5D6F80", fontSize: "12px", fontWeight: 500 }}>{i18n.t('nomorwa')} <div className='pull-right'>{this.props.time.s ? this.props.time.s + "s" : ""}</div></label>
                                                        <input name="no_whatsapp" style={{ fontSize: "12px", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }} type="text" onChange={this.handleChange} class={this.props.no_whatsapp.length < 9 ? "form-control" : this.state.errorsMessage.no_whatsapp ? "form-control is-invalid" : "form-control border-right-0"} id="exampleInputEmail1" aria-describedby="emailHelp" value={this.props.no_whatsapp} placeholder={i18n.t('nomorwadesc')} />
                                                        {this.props.no_whatsapp.length >= 9 ?
                                                            <div class="input-group-append border-left-0">
                                                                <span class="input-group-text" style={{ fontSize: "12px", backgroundColor: "#FFFFFF" }} id="basic-addon2">
                                                                    <a style={{ fontSize: "12px", color: "#8c2bee" }} onClick={this.sendOTP} disabled={this.props.disableSendOTP}>{i18n.t('kirimkodeotp')}</a>
                                                                </span>
                                                            </div>
                                                            : ""}
                                                    </div>
                                                </div>
                                                {this.props.otpwaForm ?
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1" style={{ color: "#5D6F80", fontSize: "12px", fontWeight: 500 }}>{i18n.t('kodeotp')}</label>
                                                        <input name="otp_code" value={this.props.otp_code} style={{ fontSize: "12px" }} type="text" class={this.state.errorsMessage.otp_code ? "form-control is-invalid" : "form-control"} id="exampleInputPassword1" placeholder={i18n.t('kodeotpdesc')} onChange={this.handleChange} />
                                                    </div> : ""}
                                                <button style={{ borderRadius: "8px", paddingTop: "12px", paddingBottom: "12px" }} class={this.props.otp_code === "" ? "btn secondary-color btn-block text-capitalize" : "btn ipresens-btn-color btn-block text-capitalize"} onClick={() => {
                                                    this.setState({
                                                        errorsMessage: {}
                                                    }, () => this.submitRegister())
                                                }} disabled={this.props.otp_code === ""}>{i18n.t('daftar')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <div style={{ textAlign: "center" }}>
                                        <p>Punya kendala saat mendaftar akun? <br />
                                            <a href='https://wa.me/6285700102030' style={{ color: "#8c2bee" }}>Hubungi kami</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'></div>
                    </div>
                </div>
            </main>
        )
    }
}
