import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Swal from "sweetalert2";
import ImgCrop from "antd-img-crop";
import { Select, Upload } from "antd";
import { API_HOST, IMG_HOST } from "../../helper/host";
import { Settings, User } from "react-feather";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { CloudUpload } from "@material-ui/icons";
import eyeIcon from "../../assets/icons/eye.svg";
import eyeIconSlash from "../../assets/icons/eye-slash-grey.svg";
import i18n from "i18next";
import "../styling.css"
// import { Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import { Modal, Button, message } from 'antd'

export class Edit_karyawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            jabatan: [],
            lokasi: [],
            fileFoto: [],
            fileList: [],
            fileKtp: [],
            fileSelfie: [],
            fileIjazah: [],
            data_karyawan: [],
            divisi: [],
            bpjs_data: [],
            perusahaan_karyawan: '',
            foto_karyawan: '',
            foto_ktp: '',
            foto_selfie: '',
            foto_ijazah: '',
            nama_karyawan: '',
            no_induk_karyawan: '',
            gender_karyawan: '',
            jabatan_karyawan: '',
            divisi_karyawan: '',
            lokasi_karyawan: null,
            lokasi_karyawanData: '',
            email_karyawan: '',
            password_karyawan: '',
            ttl_karyawan: '',
            join_date_karyawan: '',
            no_hp_karyawan: '',
            alamat_karyawan: '',
            gaji_karyawan: '',
            no_npwp: '',
            no_bpjs: '',
            karyawan_bpjs: '',
            hidePassword: false,
            modalUpload: false

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handlePressChange(event) {


        let formData = {
            karyawan_slug: this.props.match.params.id,
        }

        this.props.request('karyawan/ubah-status', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message)
                    this.loadDataKaryawan()
                }
                else {
                    if (Array.isArray(response.message)) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
            })
    }

    loadJabatan() {
        let form = {}
        this.props.request("get_jabatan_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        jabatan: response.data,
                    })
                }
            });
    }



    loadLokasi() {
        let form = {}
        this.props.request("get_lokasi_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        lokasi: response.data,
                    })
                }
            });
    }


    loadBPJSSetting() {
        let form = {}
        this.props.request("bpjs/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: false,
                        bpjs_data: response.data
                    })
                }
            });
    }

    lokasiHandler = () => {
        if (this.state.lokasi_karyawan === null) {
            if (this.state.lokasi_karyawanData !== null) {
                this.setState({
                    lokasi_karyawan: this.state.lokasi_karyawanData
                })
            } else {
                this.setState({
                    lokasi_karyawan: "" + this.state.lokasi[0].lokasi_id + ""
                })
            }
        } else {
            this.setState({
                lokasi_karyawan: null
            })
        }
    }

    simpanKaryawan = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            karyawan_id: this.props.match.params.id,
            lokasi_karyawan: this.state.lokasi_karyawan,
            foto_karyawan: this.state.foto_karyawan === null ? this.state.data_karyawan.karyawan_foto : this.state.foto_karyawan,
            foto_ktp: this.state.foto_ktp === null ? this.state.data_karyawan.karyawan_ktp : this.state.foto_ktp,
            foto_selfie: this.state.foto_selfie === null ? this.state.data_karyawan.karyawan_selfie : this.state.foto_selfie,
            foto_ijazah: this.state.foto_ijazah === null ? this.state.data_karyawan.karyawan_selfie : this.state.foto_ijazah,
            nama_karyawan: this.state.nama_karyawan,
            no_induk_karyawan: this.state.no_induk_karyawan,
            gender_karyawan: this.state.gender_karyawan,
            email_karyawan: this.state.email_karyawan,
            jabatan_karyawan: this.state.jabatan_karyawan,
            divisi_karyawan: this.state.divisi_karyawan,
            ttl_karyawan: this.state.ttl_karyawan,
            join_date_karyawan: this.state.join_date_karyawan,
            no_hp_karyawan: this.state.no_hp_karyawan,
            alamat_karyawan: this.state.alamat_karyawan,
            no_npwp: this.state.no_npwp,
            no_bpjs: this.state.no_bpjs,
            karyawan_bpjs: this.state.karyawan_bpjs,
            outlet: this.state.pilih_outlet
        }
        this.props.request("karyawan/update_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.success,
                        icon: 'success'
                    })
                    this.props.history.push('/employee/employee-list/' + this.props.match.params.page)
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalUpload: false,
                    fileFoto: [],
                    foto_karyawan: null,
                }, () => this.loadDataKaryawan())
            });
    }

    simpanPasswordKaryawan = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id_karyawan: this.props.match.params.id,
            password_baru: this.state.password_baru,
            confirm_password: this.state.confirm_password,
        }

        this.props.request("karyawan/update_password_karyawan", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/employee/employee-list/' + this.props.match.params.page)
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadDivisi() {
        let form = {}
        this.props.request("get_divisi_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: false,
                        divisi: response.data
                    })
                }
            });
    }

    loadDataKaryawan() {
        let form = {
            slug: this.props.match.params.id
        }
        this.props.request("karyawan/get_karyawan_by_slug", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan: response.data,
                        jabatan_karyawan: response.data.jabatan_id,
                        divisi_karyawan: response.data.divisi_id,
                        lokasi_karyawan: response.data.lokasi_id,
                        lokasi_karyawanData: response.data.lokasi_id,
                        nama_karyawan: response.data.karyawan_nama,
                        no_induk_karyawan: response.data.karyawan_no_induk,
                        gender_karyawan: response.data.karyawan_gender,
                        foto_karyawan: response.data.karyawan_foto,
                        foto_ktp: response.data.karyawan_ktp,
                        foto_selfie: response.data.karyawan_selfie,
                        foto_ijazah: response.data.karyawan_ijazah,
                        email_karyawan: response.data.karyawan_email,
                        ttl_karyawan: response.data.karyawan_ttl,
                        join_date_karyawan: response.data.join_date_karyawan,
                        no_hp_karyawan: response.data.no_hp,
                        no_npwp: response.data.karyawan_no_npwp,
                        no_bpjs: response.data.karyawan_no_bpjs,
                        alamat_karyawan: response.data.karyawan_alamat,

                    })
                    if (response.data.karyawan_foto !== null) {
                        this.setState({
                            fileList: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_foto,
                                    status: "done",
                                    url: IMG_HOST + 'frontapp/karyawan/' + response.data.karyawan_foto
                                }
                            ],
                        })
                    }

                    if (response.data.karyawan_selfie !== null) {
                        this.setState({
                            fileSelfie: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_selfie,
                                    status: "done",
                                    url: IMG_HOST + 'frontapp/karyawan/' + response.data.karyawan_selfie
                                }
                            ],
                        })
                    }

                    if (response.data.karyawan_ktp !== null) {
                        this.setState({
                            fileKtp: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_ktp,
                                    status: "done",
                                    url: IMG_HOST + 'frontapp/karyawan/' + response.data.karyawan_ktp
                                }
                            ],
                        })
                    }
                    if (response.data.karyawan_ijazah !== null) {
                        this.setState({
                            fileIjazah: [
                                {
                                    uid: "-1",
                                    name: response.data.karyawan_ijazah,
                                    status: "done",
                                    url: IMG_HOST + 'frontapp/karyawan/' + response.data.karyawan_ijazah
                                }
                            ],
                        })
                    }
                }
            });
    }

    componentDidMount() {
        this.loadLokasi()
        this.loadJabatan()
        this.loadDivisi()
        this.loadBPJSSetting()
        this.loadDataKaryawan()
    }

    handlUpload = e => {
        this.setState({
            modalUpload: true
        })
    }

    handleOk = e => {
        this.setState({
            modalUpload: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalUpload: false
        });
    };


    render() {
        const { Option } = Select;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Edit Karyawan - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title="Edit Karyawan" parent="Karyawan" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Edit Karyawan</h5>
                                </div>
                                <div className="card-body">
                                    <Tabs>
                                        <TabList className="nav nav-tabs tab-coupon">
                                            <Tab className="nav-link"><User className="mr-2" /><b>Detail Karyawan</b></Tab>
                                            <Tab className="nav-link"><Settings className="mr-2" /><b>Ganti Password</b></Tab>
                                        </TabList>

                                        <TabPanel>
                                            <form className="needs-validation user-add" noValidate="">
                                                <div className="tab-pane fade show active">
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">Foto Karyawan</label>
                                                        <div className="col-xl-3 col-md-4">
                                                            <ImgCrop
                                                                aspect={75 / 100}
                                                                width={75}
                                                                height={100}>
                                                                <Upload
                                                                    openFileDialogOnClick={false}
                                                                    onClick={() => { this.setState({ modalUpload: true }) }}
                                                                    action={API_HOST}
                                                                    listType="picture-card"
                                                                    style={{ width: '200px !important'}}
                                                                    fileList={this.state.fileFoto}
                                                                    onRemove={() => {
                                                                        this.setState({foto_karyawan: null})
                                                                    }}
                                                                    onChange={(file) => {
                                                                        this.setState({fileFoto: file.fileList})

                                                                        if (file.fileList.length > 0) {
                                                                            let files = file.file.originFileObj
                                                                            let reader = new FileReader()

                                                                            reader.readAsDataURL(files)
                                                                            reader.onloadend = () => {
                                                                                this.setState({
                                                                                    foto_karyawan: {
                                                                                        name: file.file.name,
                                                                                        file: reader.result
                                                                                    }
                                                                                })
                                                                            }
                                                                        } else {
                                                                            this.setState({foto_karyawan: []})
                                                                        }
                                                                    }}
                                                                >
                                                                    {this.state.fileFoto.length >= 1 ? null :
                                                                        <div className="p-2">
                                                                            <span>
                                                                                <CloudUpload color="primary"
                                                                                             fontSize="large"/>
                                                                            </span><br/>
                                                                            <a className="btn btn-outline-custom tambah-karyawan" onClick={() => { this.setState({ modalUpload: true }) }}>Cari
                                                                                Gambar</a>
                                                                        </div>
                                                                    }
                                                                </Upload>
                                                            </ImgCrop>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">Foto Selfie Karyawan</label>
                                                        <div className="col-xl-3 col-md-4">
                                                            <ImgCrop
                                                                aspect={550 / 860}
                                                                width={550}
                                                                height={860}>
                                                                <Upload action={API_HOST}
                                                                    listType="picture-card"
                                                                    style={{ width: '200px !important' }}
                                                                    fileList={this.state.fileSelfie}
                                                                    onRemove={() => {
                                                                        this.setState({ foto_selfie: null })
                                                                    }}
                                                                    onChange={(file) => {
                                                                        this.setState({ fileSelfie: file.fileList })

                                                                        if (file.fileList.length > 0) {
                                                                            let files = file.file.originFileObj
                                                                            let reader = new FileReader()

                                                                            reader.readAsDataURL(files)
                                                                            reader.onloadend = () => {
                                                                                this.setState({
                                                                                    foto_selfie: {
                                                                                        name: file.file.name,
                                                                                        file: reader.result
                                                                                    }
                                                                                })
                                                                            }
                                                                        }
                                                                        else {
                                                                            this.setState({ foto_selfie: [] })
                                                                        }
                                                                    }}
                                                                >
                                                                    {this.state.fileSelfie.length >= 1 ? null :
                                                                        <div className="p-2">
                                                                            <span>
                                                                                <CloudUpload color="primary" fontSize="large" />
                                                                            </span><br />
                                                                            <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                                        </div>
                                                                    }
                                                                </Upload>
                                                            </ImgCrop>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">Foto Ktp Karyawan</label>
                                                        <div className="col-xl-3 col-md-4">
                                                            <ImgCrop
                                                                width={960}
                                                                height={480}>
                                                                <Upload action={API_HOST}
                                                                    listType="picture-card"
                                                                    fileList={this.state.fileKtp}
                                                                    onRemove={() => {
                                                                        this.setState({ foto_ktp: null })
                                                                    }}
                                                                    onChange={(file) => {
                                                                        this.setState({ fileKtp: file.fileList })

                                                                        if (file.fileList.length > 0) {
                                                                            let files = file.file.originFileObj
                                                                            let reader = new FileReader()

                                                                            reader.readAsDataURL(files)
                                                                            reader.onloadend = () => {
                                                                                this.setState({
                                                                                    foto_ktp: {
                                                                                        name: file.file.name,
                                                                                        file: reader.result
                                                                                    }
                                                                                })
                                                                            }
                                                                        }
                                                                        else {
                                                                            this.setState({ foto_ktp: [] })
                                                                        }
                                                                    }}
                                                                >
                                                                    {this.state.fileKtp.length >= 1 ? null :
                                                                        <div className="p-2">
                                                                            <span>
                                                                                <CloudUpload color="primary" fontSize="large" />
                                                                            </span><br />
                                                                            <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                                        </div>
                                                                    }
                                                                </Upload>
                                                            </ImgCrop>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">Foto Ijazah Karyawan</label>
                                                        <div className="col-xl-3 col-md-4">
                                                            <ImgCrop
                                                                aspect={550 / 860}
                                                                width={550}
                                                                height={860}>
                                                                <Upload action={API_HOST}
                                                                    listType="picture-card"
                                                                    style={{ width: '200px !important' }}
                                                                    fileList={this.state.fileIjazah}
                                                                    onRemove={() => {
                                                                        this.setState({ foto_ijazah: null })
                                                                    }}
                                                                    onChange={(file) => {
                                                                        this.setState({ fileIjazah: file.fileList })

                                                                        if (file.fileList.length > 0) {
                                                                            let files = file.file.originFileObj
                                                                            let reader = new FileReader()

                                                                            reader.readAsDataURL(files)
                                                                            reader.onloadend = () => {
                                                                                this.setState({
                                                                                    foto_ijazah: {
                                                                                        name: file.file.name,
                                                                                        file: reader.result
                                                                                    }
                                                                                })
                                                                            }
                                                                        }
                                                                        else {
                                                                            this.setState({ foto_ijazah: [] })
                                                                        }
                                                                    }}
                                                                >
                                                                    {this.state.fileIjazah.length >= 1 ? null :
                                                                        <div className="p-2">
                                                                            <span>
                                                                                <CloudUpload color="primary" fontSize="large" />
                                                                            </span><br />
                                                                            <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                                        </div>
                                                                    }
                                                                </Upload>
                                                            </ImgCrop>
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> Nama Karyawan</label>
                                                        <input name="nama_karyawan" className="form-control col-xl-8 col-md-7" placeholder="Nama Karyawan..."
                                                            type="text" required="" defaultValue={this.state.data_karyawan.karyawan_nama} onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">Nomor Induk Karyawan (Opsional)</label>
                                                        <input name="no_induk_karyawan" className="form-control col-xl-8 col-md-7" placeholder="No Induk Karyawan..."
                                                               type="text" required="" defaultValue={this.state.data_karyawan.karyawan_no_induk} onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> Gender Karyawan</label>
                                                        <select name="gender_karyawan" className="form-control col-xl-8 col-md-7" onChange={this.handleChange}>
                                                            <option value={"Laki-laki"} selected={this.state.gender_karyawan === 'Laki-laki'}>Laki-laki</option>
                                                            <option value={"Perempuan"} selected={this.state.gender_karyawan === 'Perempuan'}>Perempuan</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> Divisi Karyawan</label>
                                                        <select name="divisi_karyawan" className="form-control col-xl-8 col-md-7" onChange={this.handleChange}>
                                                            <option>Pilih Divisi Karyawan</option>
                                                            {this.state.divisi.map((item, index) => {
                                                                return (
                                                                    <option value={item.divisi_id} selected={this.state.data_karyawan.divisi_id == item.divisi_id}>{item.divisi_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> Jabatan Karyawan</label>
                                                        <select name="jabatan_karyawan" className="form-control col-xl-8 col-md-7" onChange={this.handleChange}>
                                                            <option>Pilih Jabatan Karyawan</option>
                                                            {this.state.jabatan.map((item, index) => {
                                                                return (
                                                                    <option value={item.jabatan_id} selected={this.state.data_karyawan.jabatan_id == item.jabatan_id}>{item.jabatan_nama}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> Lokasi Absen Karyawan</label>
                                                        <div className="col-xl-8 col-md-7">
                                                            <label>
                                                                <input type="checkbox" checked={this.state.lokasi_karyawan === null} onChange={() => this.lokasiHandler()} />
                                                                &nbsp;Semua Lokasi Perusahaan
                                                            </label>

                                                            {this.state.lokasi_karyawan !== null &&
                                                                <Select name="lokasi_karyawan"
                                                                    style={{ width: '100%' }}
                                                                    value={this.state.lokasi_karyawan}
                                                                    mode={"multiple"}
                                                                    placeholder="Pilih Lokasi"
                                                                    filterOption={(input, option) =>
                                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    onChange={(event) => {
                                                                        this.setState({
                                                                            lokasi_karyawan: event
                                                                        })
                                                                    }}>
                                                                    {this.state.lokasi.map((item, index) => {
                                                                        return (
                                                                            <Option key={item.lokasi_id}>{item.lokasi_nama}</Option>)
                                                                    })}
                                                                </Select>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> Email</label>
                                                        <input name="email_karyawan" className="form-control col-xl-8 col-md-7" placeholder="Email Karyawan..."
                                                            type="email" required="" defaultValue={this.state.data_karyawan.karyawan_email} onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> Status</label>
                                                        <div className="col-xl-2 col-md-7" >
                                                            <input
                                                                name="status_karyawan"
                                                                id="active"
                                                                type="radio"
                                                                value="active"
                                                                checked={this.state.data_karyawan.status_karyawan === "active"}
                                                                onClick={(event) => this.handlePressChange(event)}
                                                            />
                                                            Active
                                                        </div>
                                                        <div className="col-xl-3 col-md-7">
                                                            <input
                                                                name="status_karyawan"
                                                                type="radio"
                                                                value="non-active"
                                                                checked={this.state.data_karyawan.status_karyawan === "non-active"}
                                                                onClick={(event) => this.handlePressChange(event)}
                                                            />
                                                            Non-Active
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> Tanggal Lahir</label>
                                                        <input name="ttl_karyawan" defaultValue={this.state.data_karyawan.karyawan_ttl} className="form-control col-xl-8 col-md-7" onChange={this.handleChange}
                                                            type="date" required="" />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">Tanggal Mulai Kerja/Bergabung Karyawan</label>
                                                        <input name="join_date_karyawan" defaultValue={this.state.data_karyawan.karyawan_join_date} className="form-control col-xl-8 col-md-7" onChange={this.handleChange}
                                                               type="date" required="" />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> No. Handphone</label>
                                                        <input name="no_hp_karyawan" className="form-control col-xl-8 col-md-7"
                                                            type="number" required="" placeholder="No. HP Karyawan..." defaultValue={this.state.data_karyawan.no_hp} onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4"><span>*</span> Alamat</label>
                                                        <input name="alamat_karyawan" type="text" className="form-control col-xl-8 col-md-7" defaultValue={this.state.data_karyawan.karyawan_alamat} onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">Nomor Pokok Wajib Pajak (Opsional)</label>
                                                        <input maxLength={16} name="no_npwp" type="text" className="form-control col-xl-8 col-md-7" defaultValue={this.state.data_karyawan.karyawan_no_npwp} onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">No. BPJS (Opsional)</label>
                                                        <input maxLength={16} name="no_bpjs" type="text" className="form-control col-xl-8 col-md-7" defaultValue={this.state.data_karyawan.karyawan_no_bpjs} onChange={this.handleChange} />
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-md-4">Pengaturan BPJS</label>
                                                        <select name="karyawan_bpjs" className="form-control col-xl-8 col-md-7" onChange={this.handleChange}>
                                                            <option>Pilih Pengaturan BPJS</option>
                                                            {this.state.bpjs_data.map((item, index) => {
                                                                return (
                                                                    <option value={item.bpjs_setting_id} selected={this.state.data_karyawan.karyawan_bpjs == item.bpjs_setting_id}>{item.bpjs_setting_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="pull-right">
                                                {this.state.prosesSimpan === false ?
                                                    <button type="button" className="btn ipresens-btn-color"
                                                        onClick={this.simpanKaryawan}>Simpan</button>
                                                    :
                                                    <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                                }
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <form className="needs-validation user-add" noValidate="">
                                                <div className="input-group row">
                                                    <label className="col-xl-3 col-md-4"><span>*</span> Password
                                                        Baru</label>
                                                    <input name="password_baru"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder="Password Baru Karyawan..."
                                                           type={this.state.hidePassword ? "text" : "password"}
                                                           required="" onChange={this.handleChange} onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.simpanPasswordKaryawan(e)
                                                        }
                                                    }}/>
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <img className="show-hide-button" style={{
                                                                width: "16px",
                                                                height: "16px",
                                                                backgroundColor: "#E9ECEF"
                                                            }} src={this.state.hidePassword ? eyeIcon : eyeIconSlash}
                                                                 onClick={() => this.setState({
                                                                     hidePassword: !this.state.hidePassword
                                                                 })}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input-group row">
                                                    <label className="col-xl-3 col-md-4"><span>*</span>Konfirmasi Password
                                                        Baru</label>
                                                    <input name="confirm_password"
                                                           className="form-control col-xl-8 col-md-7"
                                                           placeholder="Konfirmasi Password Baru Karyawan..."
                                                           type={this.state.hidePassword ? "text" : "password"}
                                                           required="" onChange={this.handleChange} onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.simpanPasswordKaryawan(e)
                                                        }
                                                    }}/>
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <img className="show-hide-button" style={{
                                                                width: "16px",
                                                                height: "16px",
                                                                backgroundColor: "#E9ECEF"
                                                            }} src={this.state.hidePassword ? eyeIcon : eyeIconSlash}
                                                                 onClick={() => this.setState({
                                                                     hidePassword: !this.state.hidePassword
                                                                 })}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="pull-right mt-2">
                                                {this.state.prosesSimpan === false ?
                                                    <button type="button" className="btn ipresens-btn-color"
                                                            onClick={this.simpanPasswordKaryawan}>Simpan</button>
                                                    :
                                                    <button type="button" className="btn btn-primary"><i
                                                        className="fa fa-refresh fa-spin"></i> Loading</button>
                                                }
                                            </div>
                                        </TabPanel>
                                        <Modal
                                            visible={this.state.modalUpload}
                                            onCancel={this.handleCancel}
                                            onOk={this.handleOk}
                                            title='Upload Foto Karyawan'
                                        >
                                            <div>
                                                <div>
                                                    <a style={{color: "#d10000", cursor: "default", fontSize: '14px'}}>
                                                        Foto karyawan akan digunakan untuk verifikasi saat absen, pastikan foto wajah karyawan terlihat jelas !
                                                    </a>
                                                    <br/>
                                                    <br/>
                                                </div>
                                            </div>
                                            <div>
                                                <ImgCrop
                                                    aspect={75 / 100}
                                                    width={75}
                                                    height={100}>
                                                    <Upload action={API_HOST}
                                                        listType="picture-card"
                                                        style={{width: '200px !important'}}
                                                        fileList={this.state.fileFoto}
                                                        onRemove={() => {
                                                            this.setState({foto_karyawan: null})
                                                        }}
                                                        onChange={(file) => {
                                                            this.setState({fileFoto: file.fileList})
                                                        if (file.fileList.length > 0) {
                                                               let files = file.file.originFileObj
                                                               let reader = new FileReader()
                                                            reader.readAsDataURL(files)
                                                               reader.onloadend = () => {
                                                                   this.setState({
                                                                       foto_karyawan: {
                                                                           name: file.file.name,
                                                                           file: reader.result
                                                                       }
                                                                   })
                                                               }
                                                           } else {
                                                               this.setState({foto_karyawan: []})
                                                           }
                                                        }}
                                                    >
                                                        {this.state.fileFoto.length >= 1 ? null :
                                                            <div className="p-2">
                                                                            <span>
                                                                                <CloudUpload color="primary"
                                                                                             fontSize="large"/>
                                                                            </span><br/>
                                                                <a className="btn btn-outline-custom tambah-karyawan">Cari
                                                                    Gambar</a>
                                                            </div>
                                                        }
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </Modal>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Edit_karyawan
