import React, {Component} from 'react'
import {Button, Drawer, Form} from 'antd';
import Swal from "sweetalert2";
import i18n from "i18next";
import TimePicker from "rc-time-picker";
import moment from "moment";

class EditJamKerja extends Component {
    constructor() {
        super();
        this.state = {
            hari_kerja: '',
            sift_id: '',
            jam_masuk: '',
            jam_keluar: '',
            shift: [],
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.props.changeState({
            [name]: value
        })
    }

    handleEdit = () => {
        let form = {
            id: this.props.data.id_jam_kerja,
            hari_kerja: this.props.data.hari_kerja,
            jam_masuk: this.props.data.jam_masuk,
            jam_keluar: this.props.data.jam_keluar,
        }
        this.props.request("jamKerja/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        hari_kerja : ''
                    },() =>this.props.changeState({
                        edit_visible: false,
                        hari_kerja : '',
                        data: [],
                        isLoading: true,
                    },() => {
                        this.props.loadData()
                    }))
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    loadShift(){
        let form = {}
        this.props.request("shift/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        shift: response.data.data,
                        sift_id: null,
                    })
                }
            });
    }

    componentDidMount() {
        this.setState({
            jam_masuk: this.props.data.jam_masuk,
            jam_keluar: this.props.data.jam_keluar,
        })
        this.loadShift()
    }

    render() {
        return (
            <Drawer
                title="Edit Jam Kerja"
                width={350}
                onClose={() => {
                    this.props.changeState({
                        edit_visible: false,
                        jam_keluar: '',
                        jam_masuk: '',
                    });
                }}
                visible={this.props.data.edit_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onChange={() => {
                            this.props.changeState({
                                edit_visible: false,
                                jam_keluar: '',
                                jam_masuk: '',
                            });
                        }}
                                style={{ marginRight: 8 }}>
                            Batal
                        </Button>
                        <Button onClick={this.handleEdit} type="primary">
                            Simpan
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Shift {this.props.data.dataEdit.sift_name}</label>
                    </div>
                    <div className="form-group">
                        <label>Hari Senin</label><br/>
                        {this.props.data.dataEdit.jam_kerja_senin &&
                            <div className={"row"} style={{marginLeft: 0}}>
                                <label>Jam Masuk:</label>
                                <TimePicker name="jam_masuk_senin"
                                            style={{marginBottom: 5, marginLeft: 5}}
                                            value={moment().set("hour", this.props.data.dataEdit.jam_kerja_senin.jam_masuk.substr(0, 2)).set("minute", this.props.data.dataEdit.jam_kerja_senin.jam_masuk.substr(3, 2))}
                                            allowEmpty={false} showSecond={false}
                                            onChange={(e) => {
                                                this.props.changeState({
                                                    jam_masuk_senin: e.hour() + ':' + e.minute() + ':00'
                                                })
                                            }}/>
                            </div>
                        }
                        {this.props.data.dataEdit.jam_kerja_senin &&
                            <div className={"row"} style={{marginLeft: 0}}>
                                <label>Jam Pulang:</label>
                                <TimePicker name="jam_keluar_senin"
                                            style={{marginLeft: 5}}
                                            value={moment().set("hour", this.props.data.dataEdit.jam_kerja_senin.jam_keluar.substr(0, 2)).set("minute", this.props.data.dataEdit.jam_kerja_senin.jam_keluar.substr(3, 2))}
                                            allowEmpty={false} showSecond={false}
                                            onChange={(e) => {
                                                this.props.changeState({
                                                    jam_keluar_senin: e.hour() + ':' + e.minute() + ':00'
                                                })
                                            }}/>
                            </div>
                        }
                    </div>
                </Form>
            </Drawer>

        )
    }
}

export default EditJamKerja
