import React, { Component, Fragment, useState } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {Empty, Spin, Tooltip} from 'antd';
import {Modal, Modal as ModalRs, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, InputGroup, InputGroupAddon} from 'reactstrap';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import i18n from "i18next";
import { Helmet } from "react-helmet";
import moment from "moment";
import { pageNumber } from "../../../functions/functionGeneral";
import format from "format-number";
import "./elipsis.css";
import ImportIcon from "../../../assets/icons/import-icon.svg";
import { clear_auth, set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";
import SearchIcon from "../../../assets/icons/search.svg";
import FilterIcon from "../../../assets/icons/filter.svg";
import PlusWhiteIcon from "../../../assets/icons/plus-white.svg";
import RefreshIcon from "../../../assets/icons/refresh.svg";
import ArrowUpIcon from "../../../assets/icons/arrow-up.svg";
import ArrowDownIcon from "../../../assets/icons/arrow-down.svg";
import ElipsisIcon from "../../../assets/icons/ellipsis-vertical.svg";
import ReactPaginate from "react-paginate";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg";
import ArrowRightIcon from "../../../assets/icons/arrow-right.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WarningIcon from '../../../assets/icons/warning-icon.svg';
import { faCalendarAlt, } from '@fortawesome/free-solid-svg-icons';
import { DateRange } from 'react-date-range';
import { BACKEND_API_HOST, IMG_HOST } from "../../../helper/host";
import DownloadIcon from '../../../assets/icons/download-white.svg';
import EmptyDataImage from "../../../assets/images/data-kosong.png";
import ArrowRight from "../../../assets/icons/arrow-right.svg";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";

export class list_kasbon extends Component {
    constructor(props) {
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        super(props);
        this.state = {
            dataKasbon: [],
            loading: true,
            modalOpen: false,
            currentPage: 0,
            totalData: 0,
            kasbon_id: "",
            note: "",
            status: "",
            idDel: '',
            confirmDelete: false,
            edit_kasbon_id: 0,
            edit_karyawan_id: 0,
            edit_karyawan_nama: "",
            edit_tanggal_kasbon: (new Date()).toISOString().substr(0, 10),
            edit_nominal_kasbon: 0,
            edit_kasbon_tenor: 0,
            edit_kasbon_cicilan: 0,
            edit_deskripsi_kasbon: "",
            month: namabulan[bulan],
            year: tahun,
            monthNow: namabulan[bulan],
            yearNow: tahun,
            filter: false,
            slice_karyawan_nama: true,
            exportStartDate: "",
            exportEndDate: "",
            reactDateRange: false,
            valueDate: "",
            modalexport: false,
            modalManagement: false,
            dataHistory: [],
            modalHistory: false,
            loadingHistory: false,
            currentPageHistory: 0,
            start_date: new Date(),
            end_date: new Date(),
            cutiStartDate: '',
            cutiEndDate: '',
            approvedData: "",
            rejectData : "",
            filterExport : "",
            buttonFilter : "",
            modalDetail: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleReject = this.handleReject.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.exportKbeban = this.exportKbeban.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            loading: true
        }, () => this.loadData())
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            status: "",
            nominal: "",
            tanggal: "",
            loading: true,
            month: this.state.monthNow,
            year: this.state.yearNow,
        }, () => this.loadData())
    }

    sortabledate = (val) => {

        const copyArray = this.state.dataKasbon

        copyArray.sort((a, b) => {
            return val === 0 ? new Date(a.kasbon_tanggal) - new Date(b.kasbon_tanggal) : new Date(b.kasbon_tanggal) - new Date(a.kasbon_tanggal);
        });

        this.setState({
            dataKasbon: copyArray
        })
    }

    approve = () => {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menyetujui Kasbon?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        dataKasbon: [],
                        loading: true
                    })
                    let form = {
                        id: this.state.kasbon_id
                    }
                    this.props.request("kasbon/success", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                            }
                            this.loadData()
                        });
                }
            })
    }

    reject = () => {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin Menolak Pengajuan Kasbon?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.handleReject()
                }
            })
    }

    handleReject() {
        let form = {
            id: this.state.kasbon_id,
            note: this.state.note
        }
        this.props.request("kasbon/cancel", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        modalOpen: false,
                        kasbon_id: "",
                        note: ""
                    }, () => {
                        Swal.fire({
                            title: 'Berhasil!',
                            text: response.message,
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1200,
                        })
                    })


                }
                this.loadData()
            });
    }
    handleEdit = () => {
        this.setState({
            editLoading: true
        })
        let nominal = this.state.edit_nominal_kasbon;
        let tenor = this.state.edit_kasbon_tenor;
        let cicilan = nominal / tenor;
        let form = {
            id: this.state.edit_kasbon_id,
            tanggal: this.state.edit_tanggal_kasbon,
            nominal: this.state.edit_nominal_kasbon,
            cicilan: cicilan,
            tenor: this.state.edit_kasbon_tenor,
            desc: this.state.edit_deskripsi_kasbon,
        }
        this.props.request("kasbon/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        modalEdit: false,
                        edit_karyawan_id: 0,
                        edit_tanggal_kasbon: (new Date()).toISOString().substr(0, 10),
                        edit_nominal_kasbon: 0,
                        edit_kasbon_tenor: 0,
                        edit_kasbon_cicilan: 0,
                        karyawan_selected: undefined
                    })
                    this.loadData()
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    delete = () => {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin Menghapus Pengajuan Kasbon?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.wantDelete()
                }
            })
    }

    wantDelete() {
        this.setState({
            data: [],
            loading: true
        })
        let form = {
            id: this.state.idDel
        }
        this.props.request("kasbon/delete", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        confirmDelete: false
                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.loadData()
            });
    }

    loadData() {
        let form = {
            paginate: 10,
            page: this.state.currentPage + 1,
            status: this.state.status,
            keyword: this.state.filter_nama,
            month: this.state.month,
            year: this.state.year,
        }
        this.props.request("kasbon/list-kasbon", form, 'POST')
            .then(response => {
                if (response.success) {
                    var result = response.data
                    this.setState({
                        dataKasbon: result.data,
                        totalData: response.data.total,
                        pageCount: result.last_page,
                        perPage: result.per_page,
                        loading: false
                    })
                }
            });
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            modalexport: true,
            exportStartDate: start,
            exportEndDate: end,
            filterExport: true,
        }, () => {
            this.loadData()
        });
    }
    exportKbeban() {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        console.log(BACKEND_API_HOST + 'kasbon/export?token=' + AuthKey + '&kasbonStartDate=' + this.state.exportStartDate + '&kasbonEndDate=' + this.state.exportEndDate + "&filterKasbon=" + this.state.filterExport)
        window.open(BACKEND_API_HOST + 'kasbon/export?token=' + AuthKey + '&kasbonStartDate=' + this.state.exportStartDate + '&kasbonEndDate=' + this.state.exportEndDate + "&filterKasbon=" + this.state.filterExport, "_blank")
    }

    handleCancel = e => {
        this.setState({
            modalexport: false,
            modalManagement: false,
            modalimport: false,
            valueDate: "",
            exportStartDate: "",
            exportEndDate: "",
            start_date: new Date(),
            end_date: new Date()
        });
    };

    componentDidMount() {
        this.loadData()
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.kasbon_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("kasbon/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            kasbon_id: ''
                        });
                    }
                });
        })
    }

    render() {
        const selectionRange = {
            startDate: this.state.start_date,
            endDate: this.state.end_date,
            key: 'selection',
        }
        const style_add = {
            font_header: {
                "fontWeight": 700,
                "fontSize": "18px",
                "lineHeight": "27px",
                "color": "#143B5E"
            },
            font_label: {
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            font_time: {
                "padding": "16px",
                "textAlign": "center",
                "fontWeight": 500,
                "fontSize": "16px",
                "lineHeight": "24px",
                "color": "#143B5E"
            },
            input: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E"
            },
            input_select: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E",
                "marginTop": "30px"
            },
            select: {
                "padding": "16px",
                "borderRadius": "8px",
                "fontWeight": 500,
                "fontSize": "14px",
                "lineHeight": "18px",
                "color": "#143B5E",
                "width": "100%"
            }
        }
        let no = 0
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengajuan Kasbon") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Pengajuan Kasbon - Presensi</title>
                        <meta name="description" content="Detail Absensi" />
                    </Helmet>
                    <Modal isOpen={this.state.modalEdit} toggle={() => {
                        this.setState({
                            modalEdit: false,
                            edit_tanggal_kasbon: (new Date()).toISOString().substr(0, 10),
                            edit_nominal_kasbon: 0,
                            edit_kasbon_tenor: 0,
                            edit_kasbon_cicilan: 0,
                            edit_deskripsi_kasbon: "",
                            karyawan_selected: undefined
                        })
                    }} style={{
                        marginRight: "10px",
                        maxWidth: "419px",
                    }}>
                        <ModalHeader className='pl-4 border-bottom-0' toggle={() => {
                            this.setState({
                                modalEdit: false,
                                edit_karyawan_id: 0,
                                edit_tanggal_kasbon: (new Date()).toISOString().substr(0, 10),
                                edit_nominal_kasbon: 0,
                                edit_kasbon_tenor: 0,
                                edit_kasbon_cicilan: 0,
                                edit_deskripsi_kasbon: "",
                                karyawan_selected: undefined
                            })
                        }}>
                            <div style={style_add.font_header}>Edit Kasbon</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Nama Karyawan</label>
                                    <a style={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        lineHeight: "24px"
                                    }} href={"/karyawan/profil/detail/" + this.state.edit_karyawan_id} className='text-capitalize'>{this.state.edit_karyawan_nama}</a>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label for="tanggal_kasbon" className="form-label" style={style_add.font_label}>Tanggal Kasbon</label>
                                    <input type="date" onChange={this.handleChange} className="form-control" id="tanggal_kasbon" name='edit_tanggal_kasbon' style={style_add.input} value={this.state.edit_tanggal_kasbon} placeholder='Pilih Tanggal...' />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-12'>
                                <label for="nominal" type='number' className="form-label" style={style_add.font}>Nominal Kasbon</label>
                                        <input type="number" className="form-control" id="nominal" style={style_add.input} placeholder='000' onChange={(e) => {
                                            this.setState({
                                                edit_nominal_kasbon: e.target.value !== "" ? parseInt(e.target.value) : e.target.value
                                            })
                                        }} name='edit_nominal_kasbon' value={this.state.edit_nominal_kasbon} onFocus={() => {
                                            if (this.state.edit_nominal_kasbon === 0 || this.state.edit_nominal_kasbon === "0") {
                                                this.setState({ edit_nominal_kasbon: "" })
                                            }
                                        }} onBlur={() => {
                                            if (this.state.edit_nominal_kasbon === "") {
                                                this.setState({
                                                    edit_nominal_kasbon: 0
                                                })
                                            }
                                        }} />
                                </div>
                            </div>
                        <div className='row mb-4'>
                                <div className='col-md-12'>
                                    <label for="tenor" type='number' className="form-label" style={style_add.font}>tenor</label>
                                    <select id="tenor" style={style_add.select} onChange={(e) => {
                                            this.setState({
                                                edit_kasbon_tenor: e.target.value !== "" ? parseInt(e.target.value) : e.target.value
                                            })
                                        }} name='edit_kasbon_tenor' value={this.state.edit_kasbon_tenor} onFocus={() => {
                                            if (this.state.edit_kasbon_tenor === 0 || this.state.edit_kasbon_tenor === "0") {
                                                this.setState({ edit_kasbon_tenor: "" })
                                            }
                                        }} onBlur={() => {
                                            if (this.state.edit_kasbon_tenor === "") {
                                                this.setState({
                                                    edit_kasbon_tenor: 0
                                                })
                                            }
                                        }}>
                                        <option value="">Pilih opsi</option>
                                        <option value="1">1 Bulan</option>
                                        <option value="2">2 Bulan</option>
                                        <option value="3">3 Bulan</option>
                                        <option value="4">4 Bulan</option>
                                        <option value="5">5 Bulan</option>
                                        <option value="6">6 Bulan</option>
                                        <option value="7">7 Bulan</option>
                                        <option value="8">8 Bulan</option>
                                        <option value="9">9 Bulan</option>
                                        <option value="10">10 Bulan</option>
                                        <option value="11">11 Bulan</option>
                                        <option value="12">12 Bulan</option>
                                        <option value="13">13 Bulan</option>
                                        <option value="14">14 Bulan</option>
                                        <option value="15">15 Bulan</option>
                                        <option value="16">16 Bulan</option>
                                        <option value="17">17 Bulan</option>
                                        <option value="18">18 Bulan</option>
                                        <option value="19">19 Bulan</option>
                                        <option value="20">20 Bulan</option>
                                        <option value="21">21 Bulan</option>
                                        <option value="22">22 Bulan</option>
                                        <option value="23">23 Bulan</option>
                                        <option value="24">24 Bulan</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row mb-4' style={{ marginBottom: "70px" }}>
                                <div className='col-md-12'>
                                    <label for="nama_karyawan" className="form-label" style={style_add.font_label}>Deskripsi Kasbon</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder='Deskripsi Kasbon...' onChange={this.handleChange} name='edit_deskripsi_kasbon' value={this.state.edit_deskripsi_kasbon}></textarea>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                <label for="cicilan" type='number' className="form-label" style={style_add.font}>Cicilan</label>
                                        <p id="cicilan" style={{ color:"black" }}>Rp. {this.value=[this.state.edit_nominal_kasbon / this.state.edit_kasbon_tenor]} /Bulan</p>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            borderTop: 0,
                            backgroundColor: "#F5F5F5",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                        }}>
                            <button className='izin-tolak-button' onClick={() => {
                                this.setState({
                                    modalEdit: false,
                                    edit_karyawan_id: 0,
                                    edit_tanggal_kasbon: (new Date()).toISOString().substr(0, 10),
                                    edit_nominal_kasbon: 0,
                                    edit_kasbon_tenor: 0,
                                    edit_kasbon_cicilan: 0,
                                    edit_deskripsi_kasbon: "",
                                    karyawan_selected: undefined
                                })
                            }}>
                                Batal
                            </button>{' '}
                            <button
                                className='btn btn-primary text-capitalize'
                                style={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    lineHeight: "24px"
                                }}
                                disabled={this.state.editLoading}
                                onClick={() => {
                                    this.handleEdit()
                                }}
                            >
                                Simpan
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={this.state.modalimport}>
                        <ModalHeader onClick={this.handleCancel} toggle={() => this.setState({importFile: null})}>
                            <div>Import Kasbon</div>
                        </ModalHeader>
                        <ModalBody style={{ padding: "20px" }}>
                            <div className='row mb-2'>
                                <div className="col">
                                    <a style={{color: "#5D6F80", cursor: "default"}}>Anda bisa mengunduh template yang
                                        sudah
                                        kami sediakan untuk mempermudah pengimportan.</a><br/>
                                    <a onClick={this.exportTemplateKasbon} style={{color: "#057CE4"}}>Unduh template
                                        disini</a>
                                    <div style={{
                                        position: 'relative',
                                        width: '100%',
                                        backgroundColor: '#F9F9F9',
                                        padding: 16,
                                        borderRadius: 6,
                                        marginTop: 8,
                                        border: "1px dashed #DDDDDD"
                                    }}>
                                        <input type='file'
                                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                               onChange={(event) => {
                                                   this.setState({
                                                       importFile: event.target.files[0],
                                                   })
                                               }}/><br/>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            {[
                                this.state.importLoading ?
                                    <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}}
                                            disabled={true}><i className="fa fa-refresh fa-spin"></i> Sedang mengupload data</button>
                                    :
                                    <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                            ]}
                        </ModalFooter>
                    </Modal>
                    <Breadcrumb title={i18n.t("daftarpengajuankasbon")} parent={i18n.t('pengajuan')} setGuide={() => { this.setState({ guide: true }) }} />
                    <ModalRs size='lg' isOpen={this.state.modalexport} toggle={this.handleCancel}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={this.handleCancel}>Export Excel</ModalHeader>
                        <ModalBody>
                            <div className='row'>
                                <div className='col'>
                                    <div className='d-flex flex-row warning-info px-2 mb-2'>
                                        <div className='px-0 mr-1'><img src={WarningIcon} alt="" /></div>
                                        <div className='px-0' style={{ fontSize: "14px" }}>
                                            Filter berdasarkan tanggal terlebih dahulu
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2' style={{ width:"780px" }}>
                                <div className='col-md-6'>
                                    <InputGroup className="form-tgl-kasbon">
                                        <InputGroupAddon addonType="prepend">
                                            <div className="input-group-text">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </div>
                                        </InputGroupAddon>
                                        <Input bsSize="sm" className='font-edit-value' style={{ paddingTop: "8px", paddingBottom: "8px" }} onFocus={() => this.setState({ reactDateRange: true })} placeholder="Filter Tanggal" value={this.state.valueDate} />
                                    </InputGroup>
                                </div>
                            </div>
                            {this.state.reactDateRange === true &&
                                <div className='shadow d-flex flex-column' style={{ maxWidth: "388px" }}>
                                    <div>
                                        <DateRange
                                            onBlur={() => this.setState({ reactDateRange: false })}
                                            ranges={[selectionRange]}
                                            onChange={(e) => {
                                                this.setState({
                                                    start_date: e.selection.startDate,
                                                    end_date: e.selection.endDate,
                                                    exportStartDate: moment(e.selection.startDate).format("YYYY-MM-DD"),
                                                    exportEndDate: moment(e.selection.endDate).format("YYYY-MM-DD")
                                                })
                                            }
                                            }
                                        />
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-end pr-2'>
                                            <button className='btn ipresens-btn-cancel text-capitalize mb-2' style={{ fontSize: "14px", fontWeight: 500 }} onClick={() => this.setState({ reactDateRange: false })}>Batal</button>
                                            <button className='btn ipresens-btn-color mb-2 ml-2 text-capitalize' style={{ fontSize: "14px", fontWeight: 500, lineHeight: "24px" }} onClick={
                                                () => this.setState({
                                                    valueDate: this.state.exportStartDate + " - " + this.state.exportEndDate,
                                                    reactDateRange: false
                                                })
                                            }>Terapkan</button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.valueDate !== "" &&
                                <>
                                <div>
                                    <div className='row'>
                                        <div className='col-auto d-flex justify-content-start mb-2'>
                                            <div className='btn-group width-filter'>
                                                <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filterExport === "pending" ? ' ipresens-active-custom-btn-group' : '' ? " ipresens-custom-btn-group" : "")} onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "pending" ? '' : 'pending',}))}>Menunggu</button>
                                                <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filterExport === "success" ? ' ipresens-active-custom-btn-group' : '' ? " ipresens-custom-btn-group" : "")} onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "success" ? '' : 'success',}))}>Sukses</button>
                                                <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.filterExport === "cancel" ? ' ipresens-active-custom-btn-group' : '' ? " ipresens-custom-btn-group" : "")} onClick={() => this.setState((prevState) => ({filterExport: prevState.filterExport === "cancel" ? '' : 'cancel',}))}>Batal</button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{ marginBottom: "40px" }} />
                                    <div className='row px-2'>
                                        <div className='col-md-4 px-1'>
                                            <button className={'btn btn-block ipresens-btn-color px-3 py-2 text-capitalize mb-2'} style={{ fontSize: "14px", borderRadius: "11px" }} onClick={this.exportKbeban}>Export Data Kasbon</button>
                                        </div>
                                    </div>
                                </div>
                                </>
                            }
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                            <button className="btn ipresens-btn-cancel text-capitalize" onClick={this.handleCancel}>
                                Batalkan
                            </button>
                        </ModalFooter>
                    </ModalRs>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>Aktivitas</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px', paddingBottom: '16', paddingRight: '12px', paddingLeft: '12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Dibuat
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Diubah
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.kasbon_desc != item.kasbon_desc && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Deskripsi Kasbon: Dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.kasbon_desc
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: {item.kasbon_desc ? item.kasbon_desc : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.kasbon_nominal != item.kasbon_nominal && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Nominal Kasbon: Dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.kasbon_nominal
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: {item.kasbon_nominal ? item.kasbon_nominal : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.kasbon_status != item.kasbon_status && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 100,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Status Kasbon : dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.kasbon_status
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRight}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '1px',
                                                                                                height: '18px'
                                                                                            }}
                                                                                        /> jadi {item.kasbon_status ? item.kasbon_status : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.kasbon_tanggal != item.kasbon_tanggal && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Tanggal Kasbon: Dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.kasbon_tanggal
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: {item.kasbon_tanggal ? item.kasbon_tanggal : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.kasbon_tenor != item.kasbon_tenor && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Tenor Kasbon: Dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.kasbon_tenor
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: {item.kasbon_tenor ? item.kasbon_tenor : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        )}
                                    </div>
                                </div>
                                </ModalBody>
                                <ModalFooter style={{ backgroundColor: "#F5F5F5", borderTop: 0 }}>
                                    <button className="btn ipresens-btn-cancel text-capitalize" onClick={this.handleCancel}>
                                        Batalkan
                                    </button>
                                </ModalFooter>
                            </ModalRs>
                    <Modal size='lg' isOpen={this.state.modalDetail} toggle={() => this.setState({
                        modalDetail: false
                    })} style={{
                        marginRight: "10px",
                        maxWidth: "419px",
                    }}>
                        <ModalHeader toggle={() => this.setState({
                            modalDetail: false
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>Detail Kasbon</div>
                        </ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div>
                                <div className="dropdown-menu-header">
                                    <div className="dropdown-menu-header-inner">
                                        <div className="menu-header-content btn-pane-right">
                                            <div>
                                                {this.state.karyawan_foto !== null ? (
                                                    <img
                                                        id="avatar"
                                                        src={IMG_HOST + 'frontapp/karyawan/' + this.state.karyawan_foto}
                                                        className="img-fluid blur-up lazyloaded"/>) : (
                                                    <img
                                                        id="avatar"
                                                        src={IMG_HOST + 'user-profile.png'}
                                                        className="img-fluid blur-up lazyloaded"/>
                                                )}
                                            </div>
                                            <div>
                                                <a style={{
                                                    fontWeight: 700,
                                                    fontSize: "16px",
                                                    lineHeight: "24px"
                                                }} href={"/karyawan/profil/detail/" + this.state.karyawan_id}
                                                   className='text-capitalize'>{this.state.karyawan_nama}</a>
                                                {/*<h6 style={{ fontSize: 12 }}>{this.state.divisi.divisi_name}</h6>*/}
                                                {/*<h6 style={{ fontSize: 12 }}>{format({prefix: '', integerSeparator: "."})(this.state.karyawan.karyawan_payroll_amount)}</h6>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label htmlFor="kasbon_desc" className="form-label"
                                                   style={style_add.font_label}>Deskripsi Kasbon</label>
                                            <p style={{color: "black", fontSize: '14px'}}>{this.state.kasbon_desc}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label htmlFor="kasbon_nominal" className="form-label"
                                                   style={style_add.font_label}>Nominal Kasbon</label>
                                            <p style={{color: "black"}}>
                                                {format({
                                                    prefix: 'Rp ',
                                                    integerSeparator: "."
                                                })(this.state.kasbon_nominal)}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label htmlFor="kasbon_tenor" className="form-label"
                                                   style={style_add.font_label}>Tenor Pembayaran</label>
                                            <p style={{color: "black"}}>
                                                {format({
                                                    suffix: 'x',
                                                    integerSeparator: "."
                                                })(this.state.kasbon_tenor)}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label htmlFor="kasbon_cicilan" className="form-label"
                                                   style={style_add.font_label}>Cicilan</label>
                                            <p style={{color: "black"}}>
                                                {format({
                                                    prefix: 'Rp ',
                                                    integerSeparator: "."
                                                })(this.state.kasbon_cicilan)}</p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <label htmlFor="kasbon_note" className="form-label"
                                                   style={style_add.font_label}>Kasbon Note</label>
                                            <p style={{color: "black"}}>{this.state.kasbon_note}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <div className="container-fluid">
                        <div className="card" style={{ border: "1px solid #DDDDDD" }}>
                            <div className="card-body">
                                <div className='mb-4'>
                                    <div className='row'>
                                        <div className='col-md-5'>
                                            <div className='d-flex flex-row mb-2'>
                                                <div className='flex-fill pr-2'>
                                                    <div className='input-group btn-srch-kasbon'>
                                                    <span className="input-group-text izin-search-karyawan-img" id="basic-addon1">
                                                        <img alt='' src={SearchIcon} />
                                                    </span>
                                                        <input type="text" name='filter_nama' onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                this.setState({
                                                                    data: [],
                                                                    page: 0,
                                                                    paging: this.state.paging,
                                                                    loading: true
                                                                }, () => {
                                                                    this.loadData()
                                                                });
                                                            }
                                                        }} value={this.state.filter_nama} onChange={this.handleChange} className="form-control izin-search-karyawan-form" placeholder={i18n.t('carinamakaryawan') + "..."} aria-label="Username" aria-describedby="basic-addon1" />
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className='btn ipresens-btn-filter' onClick={() => this.setState({ filter: !this.state.filter })}><img src={FilterIcon} />   Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className='mx-1'>
                                                <button className='btn ipresens-btn-color px-3 text-capitalize pull-right width-filter tombol-export' style={{ fontSize: "14px", paddingTop: "6px", paddingBottom: "6px" }} onClick={() => { this.setState({ modalexport: true }) }}><img src={DownloadIcon} /> {i18n.t('eksporexcel')}</button>
                                            </div>
                                            <div>
                                                <button
                                                    className="mr-2 ipresens-import-button btn-import-krywn pull-right"
                                                    style={{
                                                        lineHeight: "20px",
                                                        padding: "8px 18px",
                                                        borderRadius: "6px"
                                                    }}
                                                    onClick={() => {
                                                        this.setState({modalimport: true})
                                                    }}><img src={ImportIcon} alt=''/> Import
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.filter &&
                                        <div className='row mx-0'>
                                            <div className='col-md-2 px-1 mb-2'>
                                                <label className='form-label' style={{fontSize: "12px"}}>Bulan</label>
                                                <select name="month" className='form-control' value={this.state.month} style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                                <option value="">Pilih Bulan</option>
                                                <option value="1">Januari</option>
                                                <option value="2">Februari</option>
                                                <option value="3">Maret</option>
                                                <option value="4">April</option>
                                                <option value="5">Mei</option>
                                                <option value="6">Juni</option>
                                                <option value="7">Juli</option>
                                                <option value="8">Agustus</option>
                                                <option value="9">September</option>
                                                <option value="10">Oktober</option>
                                                <option value="11">November</option>
                                                <option value="12">Desember</option>
                                            </select>
                                        </div>
                                        <div className='col-md-2 px-1 mb-2'>
                                            <label className='form-label' style={{ fontSize: "12px" }}>Tahun</label>
                                            <select name="year" className='form-control' value={this.state.year} style={{ fontSize: "12px" }} onChange={this.handleFilter}>
                                                <option value="">Pilih Tahun</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                            </select>
                                        </div>
                                        <div className='col-auto px-1 d-flex align-items-end justify-content-end mb-2'>
                                            <div className='btn-group width-filter'>
                                                <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.status === "pending" ? ' ipresens-active-custom-btn-group' : '')} onClick={() => this.setState({ status: "pending", loading: true }, () => this.loadData())}>Menunggu</button>
                                                <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.status === "success" ? ' ipresens-active-custom-btn-group' : '')} onClick={() => this.setState({ status: "success", loading: true }, () => this.loadData())}>Disetujui</button>
                                                <button className={'btn text-capitalize ipresens-custom-btn-group filter-masuk' + (this.state.status === "cancel" ? ' ipresens-active-custom-btn-group' : '')} onClick={() => this.setState({ status: "cancel", loading: true }, () => this.loadData())}>Ditolak</button>
                                            </div>
                                        </div>
                                        <div className='col-auto px-1 d-flex align-items-end justify-content-end pb-2'>
                                            <button className='btn text-capitalize px-2 width-filter ipresens-btn-reset-filter' onClick={this.resetFilter}><img src={RefreshIcon} alt="icon refresh" /> Reset Filter</button>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className='table table-borderless'>
                                                <thead>
                                                <tr>
                                                    <th className='lembur-th-style'>#</th>
                                                    <th className='lembur-th-style mr-2' style={{ maxWidth: "115px" }}>{i18n.t('karyawan')}</th>
                                                    <th className='lembur-th-style' style={{ minWidth: "150px" }}>
                                                        <div className='d-flex'>
                                                            <div className='izin-th-style'>{i18n.t('tanggalkasbon')} </div>
                                                            <div className='ml-1 d-flex flex-column pt-1'>
                                                                <img alt='' onClick={() => {
                                                                    this.sortabledate(1)
                                                                }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} />
                                                                <img alt='' onClick={() => {
                                                                    this.sortabledate(0)
                                                                }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} />
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className='lembur-th-style' style={{ minWidth: "160px" }}>{i18n.t('kasbonnominal')}</th>
                                                    <th className='lembur-th-style' style={{ minWidth: "135px" }}>{i18n.t('kasbontenor')}</th>
                                                    <th className='lembur-th-style'>
                                                        <div className='d-flex'>
                                                            <div className='izin-th-style'>{i18n.t('status')} </div>
                                                            <div className='ml-1 d-flex flex-column pt-1'>
                                                                <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowUpIcon} onClick={() => {
                                                                    this.sortablestatus(1)
                                                                }} />
                                                                <img alt='' style={{ width: "8px", height: "8px" }} className='izin-btn' src={ArrowDownIcon} onClick={() => {
                                                                    this.sortablestatus(0)
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className='lembur-th-style'></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.loading ?
                                                    <tr>
                                                        <td colSpan={10}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }} />
                                                        </td>
                                                    </tr>
                                                    :
                                                    this.state.dataKasbon.length === 0 ?
                                                        <tr>
                                                            <td colSpan={9}>
                                                                <Empty description={i18n.t("datakosong")}
                                                                       image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                                            </td>
                                                        </tr>
                                                        :
                                                        this.state.dataKasbon.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <tr style={{
                                                                    borderBottom: no === this.state.dataKasbon.length ? "" : "1px solid #EFEFEF"
                                                                }}>
                                                                    <td className='lembur-td-style'>{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                    <td className='lembur-td-style' style={{ maxWidth: "115px" }}>
                                                                        <a href={"/karyawan/profil/detail/" + item.karyawan.karyawan_id}>
                                                                            {this.state.slice_karyawan_nama ? item.karyawan.karyawan_nama.slice(0, 14) : item.karyawan.karyawan_nama}
                                                                        </a>
                                                                        {item.karyawan.karyawan_nama.length >= 15 && this.state.slice_karyawan_nama === true ?
                                                                            <button className='izin-btn' style={{ color: "#6BA9FF", fontSize: "24px", fontWeight: 400, padding: "0px" }} onClick={() => {
                                                                                this.setState({
                                                                                    slice_karyawan_nama: false
                                                                                })
                                                                            }}>...</button> : ""}
                                                                        {item.karyawan.karyawan_nama.length >= 15 && this.state.slice_karyawan_nama === false ?
                                                                            <button className='izin-btn' style={{ color: "#6BA9FF", fontSize: "14px", fontWeight: 400, padding: "0px" }} onClick={() => {
                                                                                this.setState({
                                                                                    slice_karyawan_nama: true
                                                                                })
                                                                            }}>lebih sedikit</button> : ""}
                                                                    </td>
                                                                    <td className='lembur-td-style'>
                                                                        {moment(item.kasbon_tanggal).format('ll')}
                                                                    </td>
                                                                    <td className='lembur-td-style'>
                                                                        {format({ prefix: 'Rp ', integerSeparator: "." })(item.kasbon_nominal)}
                                                                    </td>
                                                                    <td className='lembur-td-style'>
                                                                        {format({ suffix: 'x', integerSeparator: "." })(item.kasbon_tenor)}
                                                                    </td>
                                                                    <td className='lembur-td-style' style={{ color: item.kasbon_status === "pending" ? "#FF9C00" : item.kasbon_status === "cancel" ? "#E03616" : item.kasbon_status === "success" ? "#1E7E0E" : "" }}>
                                                                        {item.kasbon_status === "pending" ? "Menunggu" : item.kasbon_status === "cancel" ? "Ditolak" : item.kasbon_status === "success" ? "Disetujui" : ""}
                                                                    </td>
                                                                    <td className='lembur-td-style'>
                                                                        <Tooltip placement="bottom" trigger={"click"} zIndex={1000} color={'#fff'} title={
                                                                            <div className={'d-flex flex-column'}>
                                                                                {/*<Link to={"/submission/casbon-detail/" + item.kasbon_id} target="_blank">*/}
                                                                                {/*    <button style={{ width: "84px"}} className='izin-btn izin-tooltip-menu px-2 py-1'>Detail</button>*/}
                                                                                {/*</Link>*/}
                                                                                <button style={{width: "84px"}}
                                                                                    className='izin-btn izin-tooltip-menu px-2 py-1 mb-1'
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            modalDetail     : true,
                                                                                            karyawan_id     : item.karyawan.karyawan_id,
                                                                                            karyawan_nama   : item.karyawan.karyawan_nama,
                                                                                            karyawan_foto   : item.karyawan.karyawan_foto,
                                                                                            kasbon_nominal  : item.kasbon_nominal,
                                                                                            kasbon_tenor    : item.kasbon_tenor,
                                                                                            kasbon_cicilan  : item.kasbon_cicilan,
                                                                                            kasbon_desc     : item.kasbon_desc,
                                                                                            kasbon_note     : item.kasbon_note,
                                                                                        })
                                                                                    }}>Detail
                                                                                </button>
                                                                                {item.kasbon_status !== "success" &&
                                                                                    <button
                                                                                        style={{width: "84px"}}
                                                                                        className='izin-btn izin-tooltip-menu px-2 py-1 mb-1'
                                                                                        disabled={item.kasbon_status === "success"}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                kasbon_id: item.kasbon_id
                                                                                            }, () => {
                                                                                                this.approve()
                                                                                            })
                                                                                        }}>Setujui</button>
                                                                                }
                                                                                {item.kasbon_status !== "cancel" &&
                                                                                    <button
                                                                                        style={{width: "84px"}}
                                                                                        className='izin-btn izin-tooltip-menu px-2 py-1 mb-1'
                                                                                        disabled={item.kasbon_status === "cancel"}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                kasbon_id: item.kasbon_id
                                                                                            }, () => {
                                                                                                this.reject()
                                                                                            })
                                                                                        }}>Tolak</button>
                                                                                }
                                                                                <button style={{width: "84px"}}
                                                                                    className='izin-btn izin-tooltip-menu px-2 py-1 mb-1'
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            modalEdit: true,
                                                                                            edit_kasbon_id: item.kasbon_id,
                                                                                            edit_karyawan_id: item.karyawan.karyawan_id,
                                                                                            edit_karyawan_nama: item.karyawan.karyawan_nama,
                                                                                            edit_tanggal_kasbon: item.kasbon_tanggal,
                                                                                            edit_nominal_kasbon: item.kasbon_nominal,
                                                                                            edit_kasbon_tenor: item.kasbon_tenor,
                                                                                            edit_kasbon_cicilan: item.kasbon_cicilan,
                                                                                            edit_deskripsi_kasbon: item.kasbon_desc,
                                                                                        })
                                                                                    }}>Edit
                                                                                </button>
                                                                                <button style={{
                                                                                    width: "84px",
                                                                                    color: "red"
                                                                                }}
                                                                                        className='izin-btn izin-tooltip-menu px-2 py-1'
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                idDel: item.kasbon_id,
                                                                                            }, () => {
                                                                                                this.delete()
                                                                                            })
                                                                                        }}>Hapus
                                                                                </button>
                                                                            </div>
                                                                        }>
                                                                            <button className='izin-btn'>
                                                                                <img alt='' src={ElipsisIcon}/>
                                                                            </button>
                                                                        </Tooltip>

                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer p-0 border-0'>
                                <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                    {this.state.dataKasbon.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataKasbon.length === 0 || this.state.dataKasbon.length === null ? "0" : this.state.dataKasbon.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeftIcon} alt='' />}
                                                nextLabel={<img src={ArrowRightIcon} alt='' />}
                                                pageCount={this.state.pageCount}
                                                forcePage={this.state.currentPage}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageClick}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link lembur-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link lembur-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link lembur-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link lembur-prev-next-link'}
                                                activeClassName={'active lembur-active'}
                                            />
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(list_kasbon);
