// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import IDLanguage from './assets/json/id.json';
import ENLanguage from './assets/json/en.json';

const resources = {
    id: {
        translation: IDLanguage
    },
    en: {
        translation: ENLanguage
    },
};

const savedLanguage = localStorage.getItem('lang');


function getAvailableLanguage(language) {
    console.log(language)
    if (resources[language]) {
        return language;
    } else {
        return 'en';
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage || undefined,
        fallbackLng: 'en',
        load: 'languageOnly',
        detection: {
            lookupLocalStorage: 'lang',
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
