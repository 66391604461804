import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Empty, Modal, Spin } from 'antd';
import { Button, ButtonGroup, Col, Modal as ModalRS, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Paging from "../../../components/paging";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../../functions/functionGeneral";
import moment from "moment";
import format from "format-number";
import i18n from "i18next";
import "./PayrollList.css"
import html2canvas from "html2canvas";
import pdfMake from 'pdfmake';
// edit Modal
import Edit from "./edit-modal";
import presensiHeaderModalBG from "../../../assets/images/modal_header_bg.png";
import presensiWhiteLogo from "../../../assets/images/presensi_white_logo.png";
import {DEFAULT_IMG, IMG_HOST} from "../../../helper/host";

import Potongan from  "./edit-modal/modalPotongan"
import EmptyDataImage from "../../../assets/images/data-kosong.png";

export class List_payroll extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var tahun = lastDay.getFullYear();
        this.state = {
            loading: true,
            datas: [],
            payroll: [],
            idDel: '',
            filter_nama: "",
            status: "",
            year: tahun,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 10,
            guide: false,
            modalSlip: false,
            modalEdit: false,
            visiblePot: false,

            dataSlip: [],
            kompensasi: [],
            karyawan: [],
            gaji: [],
            penghasilan: [],
            perusahaan: [],
            payroll_id: '',
            karyawan_nama: '',
            karyawan_id: '',
            saveAll: false,

            potongan: [],
            rows: [{}],
            data_term_potongan: [],

            kompensasiDisableUpdata: false,
            potonganDisableUpdata: false,
        }
        this.handleChange           = this.handleChange.bind(this)
        this.handlePageClick        = this.handlePageClick.bind(this)
        this.resetFilter            = this.resetFilter.bind(this)
        this.handleFilter           = this.handleFilter.bind(this)
        this.loadSlip               = this.loadSlip.bind(this)
        this.checkPotongan          = this.checkPotongan.bind(this)
        this.checkKompensasi        = this.checkKompensasi.bind(this)
        this.handleChangeStock      = this.handleChangeStock.bind(this)

    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChangeStock = id => e => {

        const { name, value } = e.target;
        const list = [...this.state.rows];

        list[id] = { ...list[id], [name]: value };
        this.setState({
            rows: list,
        })
    };

    potonganModal = () => {
        this.setState({
            visiblePot: !this.state.visiblePot
        })
    };

    checkPotongan() {
        this.state.datas.map((data) => {
            if (data != null && data.potongan) {
                if (data.potongan.length == 0) {
                    this.setState({
                        potonganDisableUpdata: true
                    })
                }
            }
        }
        );
    }

    checkKompensasi() {
        this.state.datas.map((data) => {
            if (data != null) {
                if (data.kompensasi.length == 0) {
                    this.setState({
                        kompensasiDisableUpdata: true
                    })
                }
            }
        }
        );
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, datas: [] }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }
    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            datas: [],
            loading: true
        }, () => this.loadData())
    }


    resetFilter(event) {
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var tahun = lastDay.getFullYear();
        this.setState({
            filter_nama: "",
            year: tahun,
            status: "",
            datas: [],
            loading: true
        }, () => this.loadData())
    }

    printDocumentFour = () => {
        var name = this.state.karyawan.karyawan_nama;
        var periode = this.state.dataSlip.payroll_date;
        var id = this.state.karyawan.karyawan_id;
        html2canvas(document.getElementById("content-list")).then(canvas => {
            var data = canvas.toDataURL();
            var pdfExportSetting = {
                content: [
                    {
                        image: data,
                        width: 550
                    }
                ]
            };
            pdfMake.createPdf(pdfExportSetting).download(name + '-' + periode + '-' + id + '.pdf');
        });

        this.setState({
            modalSlip: false
        })
    };

    loadSlip() {
        let form = {
            id: this.state.id
        }
        this.props.request("karyawan/slip_gaji", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataSlip: response.data,
                        kompensasi: response.data.kompensasi,
                        potongan: response.data.potongan,
                        karyawan: response.data.karyawan,
                        gaji: response.gaji,
                        penghasilan: response.penghasilan,
                        perusahaan: response.data.perusahaan,
                        modalSlip: true
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    loadData() {
        let form = {
            id_karyawan: this.props.match.params.id,
            paging: this.state.paging,
            page: this.state.currentPage + 1,
            filter_nama: this.state.filter_nama,
            year: this.state.year,
            status: this.state.status
        }
        this.props.request("karyawan/list_payroll", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        datas: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    }, () => this.checkPotongan(), () => this.checkKompensasi())
                }
            });

    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Daftar Payroll") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Detail Payroll karyawan - Presensi</title>
                        <meta name="description" content="Detail Daftar Payroll" />
                    </Helmet>
                    <Tour isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <div className="container-fluid list-karyawan">
                        <div className="card">
                            <div className="card-header">
                                <h5>Payroll List Karyawan</h5>
                            </div>
                            <div className="row filter-karyawan" style={{ marginLeft: '5px' }}>
                                <div className="col-lg-2">
                                    <label style={{ fontSize: '10px' }}>Filter Tahun:</label>
                                    <select name="year" className="form-control" onChange={this.handleFilter} value={this.state.year}>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label style={{ fontSize: '10px' }}>Filter Status :</label>
                                    <select className="form-control" name="status" onChange={this.handleFilter}>
                                        <option value="" selected={this.state.status === ''}>Pilih Status</option>
                                        <option value="new">New</option>
                                        <option value="finish">Finish</option>
                                    </select>
                                </div>
                                <div className="col-md-8 mt-1">
                                        <label style={{ fontSize: '10px', textAlign: 'right'}}>Atur Ulang Filter:</label>
                                        <button className="btn btn-danger" onClick={this.resetFilter} style={{ float: "right" }}><span className="fa fa-refresh"></span> Atur Ulang</button>
                                </div>
                            </div>
                            <div className="card-body daftar-karyawan">
                                {!this.state.loading && this.state.datas.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table striped className="mb-5">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th className="text-center">Periode</Th>
                                                <Th className="text-center">Tanggal</Th>
                                                <Th className="text-center">Waktu Kerja</Th>
                                                <Th className="text-center">Gaji</Th>
                                                <Th className="text-center">Kompensasi</Th>
                                                <Th className="text-center">Potongan</Th>
                                                <Th className="text-center">Total</Th>
                                                <Th className="text-center">Status</Th>
                                                <Th className="text-center">Slip Gaji</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={11}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.datas.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr className="col-lg-12 ps-5">
                                                        <small>
                                                            <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                        </small>
                                                        <Td align="center">
                                                            {item.payroll_month !== null ? moment(item.payroll_month).format('MMM') : moment(this.state.month).format('MMM')} {item.payroll_year !== null ? (item.payroll_year) : (this.state.year)}
                                                        </Td>
                                                        <Td align="center">
                                                            {item.payroll_date !== null ? moment(item.payroll_date).format('ll') : ('-')}
                                                        </Td>
                                                        <Td align="center">
                                                            {item.payroll_waktu_kerja}/ {item.payroll_type}
                                                        </Td>
                                                        <Td align="center">
                                                            {item.payroll_gaji !== null ? format({ prefix: '', integerSeparator: "." })(item.payroll_gaji) : ('-')}
                                                        </Td>
                                                        <Td align="center">
                                                            {format({ prefix: '', integerSeparator: "." })(item.payroll_kompensasi ? item.payroll_kompensasi : '-')}
                                                        </Td>
                                                        <Td align="center">
                                                            {format({ prefix: '', integerSeparator: "." })(item.payroll_potongan ? item.payroll_potongan : '-')}
                                                        </Td>
                                                        <Td align="center">
                                                            {format({ prefix: '', integerSeparator: "." })(item.payroll_total)}
                                                        </Td>
                                                        <Td align="center">{item.payroll_status === 'new' ?
                                                            <div className={"badge badge-warning"}>New</div>
                                                            :
                                                            <div className={"badge badge-success"}>Finish</div>
                                                        }</Td>
                                                        <td align="center">
                                                            <small>
                                                                <Button className={'btn btn-info'} enabled={item.payroll_status != 'finish'} onClick={() => {

                                                                    this.setState({
                                                                        id: item.payroll_id
                                                                    }, () => {
                                                                        this.loadSlip()
                                                                    })

                                                                }}
                                                                    style={{
                                                                        padding: '2px 2px',
                                                                        textAlign: 'center',
                                                                        textDecoration: 'none',
                                                                        display: 'inline-block',
                                                                        fontSize: '16px',
                                                                        margin: '4px 2px'
                                                                    }}>
                                                                    <i className="fa fa-download" style={{ width: 30, color: '#ffffff' }}></i>
                                                                </Button>
                                                            </small>
                                                        </td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                                <Modal
                                    title="Import Karyawan"
                                    visible={this.state.modalimport}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={[
                                        this.state.importLoading ?
                                            <button className={'btn btn-success mt-2'} disabled={true}><i className="fa fa-refresh fa-spin"></i>  Sedang mengupload data</button>
                                            :
                                            <>
                                                <button className={'btn btn-info mt-2'} onClick={this.exportKaryawan}>Download Template</button>
                                                <button className={'btn btn-success mt-2'} onClick={this.importExcel}>Import
                                                    Data</button>
                                            </>
                                    ]}
                                >
                                    <div style={{ position: 'relative', width: '100%', height: '250px' }}>
                                        <input type='file' className={'mt-2'}
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={(event) => {
                                                this.setState({
                                                    importFile: event.target.files[0],
                                                })
                                            }} /><br />
                                    </div>
                                </Modal>
                            </div>
                            {
                                this.state.modalEdit ?
                                    <ModalRS size="lg" isOpen={this.state.modalEdit} style={{ maxWidth: '900px', width: '100%' }}>
                                        <ModalHeader toggle={() => this.setState({ modalEdit: false })}>Edit Payroll {this.state.karyawan_nama}</ModalHeader>
                                        <ModalBody>
                                            <Edit
                                                payroll_id={this.state.payroll_id}
                                                karyawan_id={this.state.karyawan_id}
                                                request={this.props.request}
                                                saveAll={this.state.saveAll}
                                                history={this.props.history}
                                                match={this.props.match}
                                                // loadPotonganKaryawan={this.loadPotonganKaryawan}
                                                // loadDataTermPotongan={this.loadDataTermPotongan}
                                                updateState={(state) => {
                                                    this.setState(state)
                                                }}
                                                loadSlip={this.loadSlip}
                                            />
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button onClick={() => this.setState({ modalEdit: false })} className={'btn btn-danger'}>Tutup</Button>
                                        </ModalFooter>
                                    </ModalRS>

                                    : ""
                            }
                            {/*modal Slip Gaji Karyawan*/}
                            {
                                this.state.modalSlip ?
                                    <ModalRS size="lg" isOpen={this.state.modalSlip} toggle={() => this.setState({ modalSlip: false })}>
                                        <ModalHeader>Slip Gaji Karyawan</ModalHeader>
                                        <ModalBody id="content-list">
                                            <div className="">
                                                <div>
                                                    <div className="row bg-blue-dark p-3 text-white border-bottom-blue" style={{backgroundImage: `url(${presensiHeaderModalBG})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'left center'}}>
                                                        <div className="col-4 d-flex align-items-center">
                                                            <img src={presensiWhiteLogo} alt="Logo" style={{ height: '26px'}}/>
                                                        </div>
                                                        <div className="col-8 text-right">
                                                            <b>{moment(this.state.dataSlip.payroll_month).format('MMMM')} {this.state.dataSlip.payroll_year}</b><br />
                                                            <span>{this.state.perusahaan.perusahaan_nama}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row px-2 py-3 border-btm-gray">
                                                    <div className="col">
                                                        <div className="text-muted font-weight-bold mb-2">DARI</div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <img className="align-self-center pull-right rounded-circle blur-up lazyloaded" src={this.state.perusahaan.perusahaan_foto !== null ? IMG_HOST + this.state.perusahaan.perusahaan_foto : DEFAULT_IMG} alt="header-user" style={{width: "40px", height: "40px", objectFit: "cover"}} />
                                                            </div>
                                                            <div>
                                                                <div className="text-blue-dark font-weight-bold fs-14">{this.state.perusahaan.perusahaan_nama}</div>
                                                                <div className="text-muted">{this.state.perusahaan.perusahaan_alamat}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="text-muted font-weight-bold mb-2">UNTUK</div>
                                                        <div>
                                                            <div className="text-blue-dark font-weight-bold fs-14">{this.state.karyawan.karyawan_nama}</div>
                                                            <div className="text-muted">{this.state.karyawan.divisi.divisi_name} - {this.state.karyawan.jabatan.jabatan_nama}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-6">
                                                        <div className="text-muted font-weight-bold mb-2">PENDAPATAN</div>
                                                        <div className="pl-3 pr-1">
                                                            <div className="row align-items-center justify-content-between pb-1">
                                                                <div>
                                                                    Gaji Pokok
                                                                </div>
                                                                <div className="text-blue-dark">
                                                                    Rp {format({
                                                                    prefix: '',
                                                                    integerSeparator: "."
                                                                })(this.state.gaji)}
                                                                </div>
                                                            </div>
                                                            {this.state.kompensasi.map((item, index) => {
                                                                return (
                                                                    <div className="row align-items-center justify-content-between pb-1">
                                                                        <div>
                                                                            {item.payroll_term.p_term_name}
                                                                        </div>
                                                                        <div className="text-blue-dark">
                                                                            Rp {format({ prefix: '', integerSeparator: "." })(item.p_kompensasi_total)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="row align-items-center justify-content-between pt-2" style={{borderTop: "1px dotted #DDDDDD"}}>
                                                                <div>
                                                                    <strong>Total</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>Rp {format({
                                                                        prefix: '',
                                                                        integerSeparator: "."
                                                                    })(this.state.penghasilan)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="text-muted font-weight-bold mb-2">POTONGAN</div>
                                                        <div className="pr-3 pl-3">
                                                            {this.state.potongan.map((item, index) => {
                                                                return (
                                                                    <div className="row align-items-center justify-content-between pb-1">
                                                                        <div>
                                                                            {item.payroll_term.p_term_name}
                                                                        </div>
                                                                        <div className="text-blue-dark">
                                                                            Rp {format({ prefix: '', integerSeparator: "." })(item.p_potongan_total)}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="row align-items-center justify-content-between pt-2" style={{borderTop: "1px dotted #DDDDDD"}}>
                                                                <div>
                                                                    <strong>Total</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>Rp {format({
                                                                        prefix: '',
                                                                        integerSeparator: "."
                                                                    })(this.state.dataSlip.payroll_potongan)}</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-light mt-2 px-4 py-2">
                                                    <div className="row align-items-center justify-content-between fs-14 font-weight-bold text-blue-dark">
                                                        <div>
                                                            <strong>TAKE HOME PAY</strong>
                                                        </div>
                                                        <div>
                                                            <strong>Rp {format({ prefix: '', integerSeparator: "." })(this.state.dataSlip.payroll_total)}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="text-center text-secondary mt-2">
                                                        <span>Presensi.co.id @2020</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button className={'btn btn-success'} onClick={this.printDocumentFour}>Download</Button>
                                            <Button className={'btn btn-danger'} onClick={() => this.setState({ modalSlip: false })}>Batal</Button>
                                        </ModalFooter>
                                    </ModalRS>
                                    :
                                    ""
                            }

                        </div>
                    </div>
                    <Potongan
                        potongan={this.state.potongan}
                        visiblePot={this.state.visiblePot}
                        id={this.state.karyawan_id}
                        rows={this.state.rows}
                        dataTermPotongan={this.state.data_term_potongan}
                        handleChangeStock={this.handleChangeStock}
                        potonganModal={this.potonganModal}
                        handlePotonganType={this.handlePotonganType}
                        handlePotonganAmount={this.handlePotonganAmount}
                        handleSubmitPotongan={this.handleSubmitPotongan}
                        removeClick={this.potonganDelete}
                        request={this.props.request}
                    />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

export default connect(mapStateToProps)(List_payroll);
