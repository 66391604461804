import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import MapPicker from 'react-google-map-picker'
import {Helmet} from "react-helmet";
import {Button, Modal, Select} from "antd";
import {APIKEY} from "../../helper/host";
import i18n from "i18next";
import {Label} from "reactstrap";

export class Edit_outlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            isLoading: true,
            pickKaryawan: false,
            data: [],
            nama_outlet: '',
            email_outlet: '',
            no_hp_outlet: '',
            radius: '',
            location: {
                lat: -7.865242,
                lng: 110.156070
            },
            karyawan: [],
            lokasi_karyawan: []

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    lokasiKaryawanHandler = () => {
        if (this.state.lokasi_karyawan === 'all') {
            this.setState({
                lokasi_karyawan: []
            })
        } else {
            this.setState({
                lokasi_karyawan: 'all'
            })
        }
    }

    loadKaryawan(){
        let form = {}
        this.props.request("karyawan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        loading         : false
                    })
                }
            });
    }

    loadData(){
        let form = {
            id: this.props.match.params.id
        }
        this.props.request("outlet/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        nama_outlet: response.data.outlet_nama,
                        email_outlet: response.data.outlet_email,
                        no_hp_outlet: response.data.outlet_no_hp,
                        radius: response.data.outlet_radius,
                        location: {
                            lat : response.data.outlet_latitude,
                            lng : response.data.outlet_longitude,
                        },
                        lokasi_karyawan: response.data.karyawan ? response.data.karyawan.map(function (item) {
                            return parseInt(item.karyawan_id);
                        }) : null,
                        loading         : false
                    })
                }
            });
    }

    simpanOutlet = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.props.match.params.id,
            nama: this.state.nama_outlet,
            email: this.state.email_outlet,
            no: this.state.no_hp_outlet,
            latitude: this.state.location.lat,
            longtitude: this.state.location.lng,
            radius: this.state.radius,
            karyawan: this.state.lokasi_karyawan,
        }

        this.props.request("outlet/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.props.history.push('/visitation-location')
                }else{
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    componentDidMount() {
        this.loadData()
        this.loadKaryawan()
    }

    render() {
        if(this.state.lokasi_karyawan.length > 0 && this.state.lokasi_karyawan.length == this.state.karyawan.length){
            this.setState({
                lokasi_karyawan: 'all'
            })
        }
        const { Option } = Select;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Edit Lokasi Kunjungan - Presensi</title>
                    <meta name="description" content="Detail Lokasi Kunjungan"/>
                </Helmet>
                <Breadcrumb title="Edit Lokasi Kunjungan" parent="Lokasi Kunjungan" parentLink="/visitation-location"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                        <h4>Detail Lokasi Kunjungan</h4>
                                        <div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Nama Lokasi Kunjungan</label>
                                                <input type="text" name="nama_outlet" className="form-control col-xl-8 col-md-7" placeholder="Nama Lokasi Kunjungan..." defaultValue={this.state.nama_outlet} onChange={this.handleChange}/>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Email Lokasi Kunjungan</label>
                                                <input type="text" name="email_outlet" className="form-control col-xl-8 col-md-7" placeholder="Email Lokasi Kunjungan..." defaultValue={this.state.email_outlet} onChange={this.handleChange}/>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> No HP. Lokasi Kunjungan</label>
                                                <input type="text" name="no_hp_outlet" className="form-control col-xl-8 col-md-7" placeholder="No Telepon Lokasi Kunjungan..." defaultValue={this.state.no_hp_outlet} onChange={this.handleChange}/>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Radius Kunjungan</label>
                                                <input type="text" name="radius" className="form-control col-xl-2 col-md-4" placeholder="radius Kunjungan..." defaultValue={this.state.radius} onChange={this.handleChange}/>
                                                <div className="input-group-text"
                                                     style={{height: '38px', backgroundColor: "#eef1f3"}}>
                                                    <Label style={{marginTop: '5px'}}>Meter</Label>
                                                </div>
                                            </div>
                                            {this.state.location &&
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Lokasi Presensi</label>
                                                <div className={"row"}>
                                                    <div className={"col-md-4"}>
                                                        <label style={{fontSize: '10px'}}><span>*</span> Pin Lokasi:</label>
                                                        <button type="button" className="btn ipresens-btn-color" onClick={() => {this.setState({modalLokasi: true})}}>Pin Lokasi Presensi</button>
                                                    </div>
                                                    <div className={"col-md-4"}>
                                                        <label style={{fontSize: '10px'}}>Latitude:</label>
                                                        <input name="latitude" className="form-control col-xl-12" placeholder="Latitude..." type="text" required="" value={this.state.location.lat} onChange={(event) =>
                                                            this.setState({
                                                                location: {lat: event.target.value, lng: this.state.location.lng}
                                                            })}/>
                                                    </div>
                                                    <div className={"col-md-4"}>
                                                        <label style={{fontSize: '10px'}}>Longitude:</label>
                                                        <input name="longitude" className="form-control col-xl-12" placeholder="Longitude..." type="text" required="" value={this.state.location.lng} onChange={(event) =>
                                                            this.setState({
                                                                location: {lat: this.state.location.lat, lng: event.target.value}
                                                            })}/>
                                                    </div>
                                                </div>
                                                <Modal
                                                    title="Pin Lokasi Absen"
                                                    visible={this.state.modalLokasi}
                                                    onOk={() => {this.setState({modalLokasi: false})}}
                                                    onCancel={() => {this.setState({modalLokasi: false})}}
                                                >
                                                    <MapPicker
                                                        style={{position: 'relative', width: '100%', height: '250px'}}
                                                        name='location'
                                                        defaultLocation={this.state.location}
                                                        zoom={10}
                                                        onChangeLocation={ (lat, lng) => {
                                                            this.setState({
                                                                location: {
                                                                    lat: lat,
                                                                    lng: lng
                                                                }
                                                            })
                                                        }}
                                                        apiKey={APIKEY} />
                                                </Modal>
                                            </div>
                                            }

                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Karyawan</label>
                                                <div className="col-xl-8 col-md-8">
                                                    <label style={{ fontSize: "12px" }}>
                                                        <input style={{ fontSize: "12px" }} type="checkbox"
                                                               checked={this.state.lokasi_karyawan === 'all'}
                                                               onChange={() => this.lokasiKaryawanHandler()} />
                                                        &nbsp;Semua Karyawan
                                                    </label>
                                                    {this.state.lokasi_karyawan !== 'all' ?
                                                        <Select name="select_karyawan"
                                                                style={{ width: '100%' }}
                                                                mode={"multiple"}
                                                                placeholder="Pilih Karyawan"
                                                                filterOption={(input, option) =>
                                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                value={this.state.lokasi_karyawan}
                                                                onChange={(event) => {
                                                                    this.setState({
                                                                        lokasi_karyawan: event
                                                                    })
                                                                }}>
                                                            {this.state.karyawan.map((item, index) => {
                                                                return (<Option value={item.karyawan_id} key={item.karyawan_id}>{item.karyawan_nama}</Option>)
                                                            })}
                                                        </Select> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="pull-right">
                                        {this.state.prosesSimpan === false ?
                                            <button type="button" className="btn ipresens-btn-color"
                                                    onClick={this.simpanOutlet}>Simpan</button>
                                            :
                                            <button type="button" className="btn ipresens-btn-color"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Edit_outlet
