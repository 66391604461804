import React, { Component, Fragment } from 'react';
import { Empty, Spin, Tooltip } from 'antd';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { IMG_HOST } from "../../helper/host";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux"
import { Upload } from "antd";
import i18n from "i18next";
import ImgCrop from "antd-img-crop";
import CKEditor from "react-ckeditor-component";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import SaveIcon from "../../assets/icons/save.svg";
import ImportIcon from "../../assets/icons/import-icon-blue-light.svg";

export class ListAgenda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            prosesSimpan: false,
            agenda: [],
            agendaDetail: [],
            agendaData: [],
            agendaAdmin: [],
            agendaModal: false,
            perPage: 0,
            currentPage: 0,
            modalDetail: false,
            idDetail: "",
            idDel: "",
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    resetFilter(event) {
        this.setState({
            filter_judul: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus pengumuman?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("agenda/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_judul: this.state.filter_judul,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("agenda/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        agendaAdmin: response.admin,
                        agendaData: response.data,
                        loading: false
                    })
                }
            });
    }

    loadDetail() {
        let form = {
            id : this.state.idDetail
        }
        this.props.request("agenda/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        agendaDetail: response.data,
                        agenda_title: response.data.agenda_title,
                        agenda_type: response.data.agenda_type,
                        agenda_date: response.data.agenda_date,
                        agenda_note: response.data.agenda_note,
                        loading: false,
                        modalDetail: true,
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0
        const stylefoto = {
            maxHeight: 60,
            borderRadius: "4px"
        }
        const steps = [
            {
                selector: '.tambah-pengumuman',
                content: 'Anda dapat membuat Pengumuman disini',
            },
            {
                selector: '.daftar-pengumuman',
                content: 'Daftar Pengumuman anda dapat dilihat disini',
            },
        ];
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Agenda") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Agenda - Presensi</title>
                        <meta name="description" content="Daftar Pengumuman" />
                    </Helmet>

                    <Modal size='lg' zIndex={999} isOpen={this.state.modalDetail} toggle={() => this.setState({
                        modalDetail: false
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalDetail: false
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>Agenda Detail</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row mb-3'>
                                <div className='col-md-12' style={{
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    lineHeight: "32px",
                                    color: "#143B5E"
                                }}>{this.state.judulDetail}</div>
                            </div>
                            <div className='row'>
                                    <div className='card-body px-3'>
                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                            <div className='col-md-5 font-detail-label pr-0'>Judul Agenda</div>
                                            <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                            <div
                                                className='col-md-6 px-0 font-detail-value'>{this.state.agendaDetail ? this.state.agendaDetail.agenda_title : "-"}</div>
                                        </div>
                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                            <div className='col-md-5 font-detail-label pr-0'>Tipe agenda</div>
                                            <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                            <div className='col-md-6 px-0 font-detail-value'>
                                                {this.state.agendaDetail.agenda_type === "liburnasional" ? "libur nasional" : this.state.agendaDetail.agenda_type === "liburperusahan" ? "libur perusahaan" : this.state.agendaDetail.agenda_type === "acara" ? "acara" : ""}
                                            </div>
                                        </div>
                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                            <div className='col-md-5 font-detail-label pr-0'>tanggal agenda</div>
                                            <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                            <div className='col-md-6 px-0 font-detail-value'>
                                                {this.state.agendaDetail.agenda_date}
                                            </div>
                                        </div>
                                        <div className='row d-flex justify-content-start flex-nowrap'>
                                            <div className='col-md-5 font-detail-label pr-0'>Agenda Note</div>
                                            <div className='col-md-1 px-0' style={{maxWidth: "20px"}}>:</div>
                                            <div
                                                className='col-md-6 px-0 font-detail-value'>{this.state.agendaDetail ? this.state.agendaDetail.agenda_note : "-"}</div>
                                        </div>
                                    </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <div className="container-fluid pt-4">
                        <div className='row'>
                            <div className='col-md-12' style={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5D6F80"
                            }}>Presensi Admin Panel
                            </div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12' style={{
                                fontWeight: 700,
                                fontSize: "24px",
                                lineHeight: "36px",
                                color: "#143B5E"
                            }}>Daftar Agenda
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-0 p-4">
                                <div className='row'>
                                    <div className='col-md-6 pengumuman-col-filter'>
                                        <div className='d-flex flex-row flex-nowrap'>
                                            <div className='input-group form-search mr-1'>
                                                <span className='input-group-text pengumuman-filter-nama-input-text'>
                                                    <img alt='' src={SearchIcon}/>
                                                </span>
                                                <input className='form-control pengumuman-filter-nama-input' type='text'
                                                       placeholder='Cari judul pengumuman ...' onKeyUp={(event) => {
                                                    if (event.which === 13) {
                                                        event.preventDefault()
                                                        this.setState({
                                                            page: 0,
                                                            paging: this.state.paging,
                                                            loading: true,
                                                            data: []
                                                        }, () => {
                                                            this.loadData()
                                                        });
                                                    }
                                                }} onChange={this.handleChange} name="filter_judul"
                                                       value={this.state.filter_judul}/>
                                            </div>
                                            <button className='pengumuman-button-refresh' onClick={this.resetFilter}>
                                                <img alt='' src={RefreshIcon}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                            <a href="/agenda/add">
                                                <button className='pengumuman-add-button'><img alt=''
                                                                                               src={PlusIcon}/> Tambah Agenda
                                                </button>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-pengumuman pt-3">
                                {!this.state.loading && this.state.agendaData.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"/>
                                    :
                                    (this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("R") >= 0) && (
                                        <Table className="table table-borderless mb-5">
                                            <Thead>
                                                <Tr>
                                                    <Th className="pengumuman-th-table">#</Th>
                                                    <Th className="pengumuman-th-table">Title</Th>
                                                    <Th className="pengumuman-th-table">Perusahaan</Th>
                                                    <Th className="pengumuman-th-table">Hari</Th>
                                                    <Th className="pengumuman-th-table">Bulan</Th>
                                                    <Th className="pengumuman-th-table">Tahun</Th>
                                                    <Th className="pengumuman-th-table">Note</Th>
                                                    <Th className="pengumuman-th-table"></Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {this.state.loading &&
                                                    <Tr>
                                                        <Td colSpan={5}>
                                                            <Spin size="large" tip="Loading..." style={{
                                                                width: '100%',
                                                                margin: 'auto',
                                                                marginBottom: '10px',
                                                                marginTop: '10px'
                                                            }}/>
                                                        </Td>
                                                    </Tr>
                                                }
                                                {this.state.agendaData.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr>
                                                            <Td className="pengumuman-td-table">
                                                                {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                            </Td>
                                                            <Td className="pengumuman-td-table">{item.title}</Td>
                                                            <Td className="pengumuman-td-table">{item.perusahaan.perusahaan_nama}</Td>
                                                            <Td className="pengumuman-td-table">{item.hari}</Td>
                                                            <Td className="pengumuman-td-table">{item.bulan}</Td>
                                                            <Td className="pengumuman-td-table">{item.tahun}</Td>
                                                            <Td className="pengumuman-td-table">{item.note}</Td>
                                                            <Td className="pengumuman-td-table">
                                                                <Tooltip trigger={'click'} placement="bottom" color={'#fff'}
                                                                         zIndex={900}
                                                                         title={
                                                                             <div className={'p-2'}>
                                                                                 <a onClick={() => this.setState({
                                                                                     idDetail: item.agenda_id
                                                                                 }, () => this.loadDetail())}
                                                                                    className={'text-dark'}>Detail</a><br/>
                                                                                 {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                     <>
                                                                                         <a href={'/agenda/edit/' + item.agenda_id}
                                                                                            className={'text-dark'}>Edit</a><br/>
                                                                                     </>
                                                                                 )}
                                                                                 {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("D") >= 0) && (
                                                                                     <>
                                                                                         <a onClick={() => {
                                                                                             this.setState({
                                                                                                 idDel: item.agenda_id
                                                                                             })
                                                                                             this.wantDelete()
                                                                                         }}
                                                                                            className={'text-danger'}>Hapus</a>
                                                                                     </>
                                                                                 )}
                                                                             </div>
                                                                         }>
                                                                    <a><i
                                                                        className="fa fa-ellipsis-v" style={{
                                                                        width: 35,
                                                                        fontSize: 18,
                                                                        padding: 11,
                                                                        color: 'rgb(51 51 51)'
                                                                    }}
                                                                    ></i></a>
                                                                </Tooltip>

                                                            </Td>
                                                        </Tr>
                                                    )
                                                })}
                                                {this.state.agendaAdmin.map((item, index) => {
                                                    no += 1
                                                    return (
                                                        <Tr>
                                                            <Td className="pengumuman-td-table">
                                                                {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                            </Td>
                                                            <Td className="pengumuman-td-table">{item.title}</Td>
                                                            <Td className="pengumuman-td-table">{item.perusahaan_id === "0" ? "Agenda yang dibuat oleh Admin" : ""}</Td>
                                                            <Td className="pengumuman-td-table">{item.hari}</Td>
                                                            <Td className="pengumuman-td-table">{item.bulan}</Td>
                                                            <Td className="pengumuman-td-table">{item.tahun}</Td>
                                                            <Td className="pengumuman-td-table">{item.note}</Td>
                                                            <Td className="pengumuman-td-table">
                                                                <Tooltip trigger={'click'} placement="bottom" color={'#fff'} zIndex={900}
                                                                         title={
                                                                             <div className={'p-2'}>
                                                                                 <a onClick={() => this.setState({
                                                                                     idDetail: item.agenda_id
                                                                                 }, () => this.loadDetail())}
                                                                                    className={'text-dark'}>Detail</a><br/>
                                                                                 {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("U") >= 0) && (
                                                                                     <>
                                                                                         <a href={'/agenda/edit/' + item.agenda_id}
                                                                                            className={'text-dark'}>Edit</a><br/>
                                                                                     </>
                                                                                 )}
                                                                             </div>
                                                                         }>
                                                                    <a><i
                                                                        className="fa fa-ellipsis-v" style={{
                                                                        width: 35,
                                                                        fontSize: 18,
                                                                        padding: 11,
                                                                        color: 'rgb(51 51 51)'
                                                                    }}
                                                                    ></i></a>
                                                                </Tooltip>

                                                            </Td>
                                                        </Tr>
                                                    )
                                                })}
                                            </Tbody>
                                        </Table>

                                    )
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListAgenda);