import React, { Component, Fragment } from 'react'
import { APIKEY } from "../../../../helper/host";
import { GoogleApiWrapper } from 'google-maps-react';
import { Button, ButtonGroup, Col, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap'
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import i18n from "i18next";
import Select from 'react-select'
import CurrencyInput from 'react-currency-input';
import Iconlogo from '../../../../assets/icons/info.svg';
import {Spin} from "antd";

export class Potongan_modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            term: [],
            potongan: [],
            rows: [{}],
            payroll: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pot_finish: false,

        }

        this.handleTerm = this.handleTerm.bind(this);
        this.handleChange = this.handleChange.bind(this);

    }

    handleTerm = (pilihTerm) => {
        this.setState({
            pilihTerm,
            term_id: pilihTerm.value
        });
    }

    handleChange = id => e => {

        const { name, value } = e.target;
        const list = [...this.state.rows];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            rows: list,
        })
    };

    handlePotonganQty = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.props.potongan];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };


        this.props.setState({
            potongan: list,
        }, () => this.countTotalPotongan(id))
    };

    countTotalPotongan = (id) => {
        var list = [...this.props.potongan];

        var data = this.props.potongan[id]

        var nominal = data.p_potongan_amount.toString().split(',').join('.')
        var qty = data.p_potongan_qty.toString().split(',').join('.')

        var total = eval(nominal) * eval(qty)

        list[id] = { ...list[id], p_potongan_total: total };

        this.props.setState({
            potongan: list,
        })

    }

    handlePotonganAmount = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.props.potongan];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };

        this.props.setState({
            potongan: list,
        }, () => this.countTotalPotongan(id))
    };

    handleAmountRows = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.rows];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };

        this.setState({
            rows: list,
        }, () => this.countTotalRow(id))
    };
    handleQtyRows = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.rows];
        var str = maskedvalue.split('Rp ').join('');
        var final = str.split('.').join('');

        list[id] = { ...list[id], [name]: final };


        this.setState({
            rows: list,
        }, () => this.countTotalRow(id))
    };


    countTotalRow = (id) => {
        var list = [...this.state.rows];

        var data = this.state.rows[id]
        var test = data.p_potongan_qty

        var nominal = data.p_potongan_amount === undefined ? 0 : data.p_potongan_amount.toString().split(',').join('.')
        var qty = data.p_potongan_qty === undefined ? 0 : data.p_potongan_qty.toString().split(',').join('.')

        var total = eval(nominal) * eval(qty)

        list[id] = { ...list[id], p_potongan_total: total };

        this.setState({
            rows: list,
        })
    }

    handlePotonganType = id => (e, maskedvalue) => {

        var { name, value } = e.target;
        var list = [...this.state.rows];

        list[id] = { ...list[id], [name]: value };

        this.setState({
            rows: list,
        })
    };

    addClick() {
        this.setState({
            rows: [...this.state.rows, { p_term_id: "", p_potongan_amount: "", p_potongan_qty: "", p_potongan_type: "" }]
        })
    }

    removeClick = (id) => {
        this.state.rows.splice(id, 1);
        this.setState({ rows: this.state.rows });
    };

    handleSubmit(event) {
        this.setState({
            isLoading: true,
            pot_finish: true,
        })

        let formData = {
            rows: this.state.rows,
            data: this.props.potongan,
            payroll_id: this.props.payroll_id,
        }
        this.props.request('payroll/add-payroll-potongan', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        isLoading: false,
                        rows: [{}],
                        data: [{}],
                    })
                    this.props.handleFinish()
                    this.loadDataPotongan()
                }
                else {
                    if (Array.isArray(response.message)) {

                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                    this.setState({ isLoading: false })
                }

                this.setState({ isLoading: false })
            })
    }

    dataChanged(data, item) {
        let dataval = data.target != null ? data.target.value : null
        let dataval2
        let dataname = data.target != null ? data.target.name : null

        if (dataname == 'pvName') {
            dataval2 = dataval.split(/[\~]/)[0]
            dataval = dataval.split(/[\~]/)[1].substr(1)
        }


        let formData = {
            p_potongan_id: item.p_potongan_id,
            p_potongan_amount: item.p_potongan_amount,
            p_potongan_qty: item.p_potongan_qty,
            p_potongan_total: item.p_potongan_total,
            p_term_id: dataname == "term" ? dataval : item.payroll_term.p_term_id,
        };
        this.props.request('payroll/edit_select_payroll', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    toast['success'](response.message);
                }
                else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            toast['error'](item)
                            return true
                        })
                    } else {
                        toast['error'](response.message)
                    }
                }
                this.loadDataPotongan()
                this.setState({ loading: false })
            })
    }

    loadDataPotongan() {
        this.setState({
            loading: true,
            isLoading: true
        })
        let form = {
            payroll_id: this.props.payroll_id,
        }
        this.props.request("payroll/list-payroll-potongan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.setState({
                        potongan: response.potongan
                    })
                    this.setState({
                        term: response.term,
                        potongan: response.potongan,
                        rows: (response.potonganKaryawan == null || response.potonganKaryawan.length == 0 ? [{}] : response.potonganKaryawan ),
                        loading: false,
                        isLoading: false
                    })
                }
            });
    }

    loadUpdatePotongan() {
        let form = {
            payroll_id: this.props.payroll_id,
        }
        this.props.request("payroll/update_potongan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        term: response.term,
                        potongan: response.potongan,
                        loading: false
                    })
                }
            });
    }

    wantDelete(potonganId) {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus potongan payroll?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        data: []
                    })
                    let form = {
                        id: potonganId
                    }
                    this.props.request("payroll/delete-potongan", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadDataPotongan()
                            }
                        });
                }
            })
    }

    loadPotonganKaryawan(id) {

        this.props.updateState({ loading: true })
        let form = {
            karyawan_id: id,
        }
        this.props.request("karyawan_potongan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.updateState({
                        potongan: response.data,
                        modalEdit: false, visiblePot: true
                    })
                }
                this.props.updateState({ loading: false })
            });
    }

    loadDataTermPotongan() {
        this.props.updateState({ loading: true })

        this.props.request("karyawan_potongan/list-payroll-term", 'POST')
            .then(response => {
                if (response.success) {

                    this.props.updateState({
                        data_term_potongan: response.data,
                    })
                }
                this.props.updateState({ loading: false })
            });
    }

    componentDidMount() {
        this.loadDataPotongan()
    }

    render() {
        if(this.props.potongan > 0){
            this.setState({
                loading: true
            })
        }
        return (
            <Fragment>
                <div>
                    <div className="card">
                        <div className="card-header text-center">
                            <h4>Form Input Potongan</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {this.state.loading ?
                                    <Spin size="large" tip="Sedang memuat Potongan..." style={{
                                        width: '100%',
                                        margin: 'auto',
                                        marginBottom: '10px',
                                        marginTop: '10px'
                                    }}/>
                                    :
                                    <div className="col-xl-12">
                                        <Row className="mb-2">
                                            <Col>
                                                <Row>
                                                    <Col className="text-center">
                                                        <Label>Nama Potongan</Label>
                                                    </Col>
                                                    <Col className="text-center">
                                                        <Label>Potongan Nominal</Label>
                                                    </Col>
                                                    <Col className="text-center">
                                                        <Label>Potongan Qty</Label>
                                                    </Col>
                                                    <Col className="text-center">
                                                        <Label>Potongan Type</Label>
                                                    </Col>
                                                    <Col className="text-center">
                                                        <Label>Total Potongan</Label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm="1" className="text-center">
                                                <Label>Option</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.props.potongan.map((item, id) => {
                                                    return (
                                                        <div>
                                                            {item.p_potongan_type == null || item.p_potongan_type == "" ?
                                                                <div className="row p-2" style={{
                                                                    backgroundColor: "#FFF6E8",
                                                                    color: "#252B2B",
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold"
                                                                }}>
                                                                    <div className="col-8">
                                                                        <img src={Iconlogo} alt="React Logo"
                                                                             style={{paddingRight: "5px"}}/>
                                                                        Silahkan lengkapi detail potongan
                                                                    </div>
                                                                    <div className="col-3 align-content-end">
                                                                        <Button
                                                                            color="warning"
                                                                            style={{textTransform: "capitalize"}}
                                                                            onClick={() => {
                                                                                this.loadPotonganKaryawan(this.props.karyawan_id)
                                                                                this.loadDataTermPotongan()
                                                                            }}

                                                                        >Lengkapi sekarang</Button>
                                                                    </div>

                                                                </div>
                                                                :
                                                                <Row>

                                                                    <Col className="text-center">
                                                                        {item.p_term_name === undefined ?
                                                                            <div>
                                                                                {item.payroll_term.p_term_prefix === 'general' ?
                                                                                    <Select
                                                                                        value={{
                                                                                            value: item.p_term_id,
                                                                                            label: item.payroll_term.p_term_name
                                                                                        }}
                                                                                        onChange={(val) => {
                                                                                            this.dataChanged({
                                                                                                target: {
                                                                                                    name: "term",
                                                                                                    value: val.value
                                                                                                }
                                                                                            }, item)
                                                                                        }}
                                                                                        options={
                                                                                            this.state.term.length != null ? (
                                                                                                this.state.term.map((data, index) =>
                                                                                                    (
                                                                                                        {
                                                                                                            key: index,
                                                                                                            value: data.p_term_id,
                                                                                                            label: data.p_term_name
                                                                                                        }
                                                                                                    )
                                                                                                )
                                                                                            ) : ("")
                                                                                        }
                                                                                        defaultValue={{
                                                                                            value: item.term_id,
                                                                                            label: item.payroll_term.p_term_id
                                                                                        }}
                                                                                    /> :
                                                                                    <Label bsSize="sm" type="select"
                                                                                           name="p_term_id"
                                                                                           value={item.p_term_id}>
                                                                                        <h6>{item.payroll_term.p_term_name}</h6>
                                                                                    </Label>
                                                                                }

                                                                            </div>
                                                                            :
                                                                            <Label bsSize="sm" type="select"
                                                                                   name="p_term_id"
                                                                                   value={item.p_term_id}>
                                                                                {item.p_term_name === undefined ?
                                                                                    <h6>{item.payroll_term.p_term_name}</h6> :
                                                                                    <h6>{item.p_term_name}</h6>
                                                                                }
                                                                            </Label>
                                                                        }
                                                                    </Col>
                                                                    <Col className="text-center">
                                                                        <FormGroup>
                                                                            <CurrencyInput
                                                                                name="p_potongan_amount"
                                                                                className="form-control"
                                                                                thousandSeparator="."
                                                                                value={item.p_potongan_amount}
                                                                                prefix="Rp "
                                                                                id="p_potongan_amount"
                                                                                precision={0}
                                                                                onChangeEvent={this.handlePotonganAmount(id)}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <FormGroup>
                                                                            <InputGroup className="mb-3">
                                                                                <CurrencyInput
                                                                                    name="p_potongan_qty"
                                                                                    className="form-control"
                                                                                    thousandSeparator="."
                                                                                    value={item.p_potongan_qty}
                                                                                    id="p_potongan_qty"
                                                                                    decimalSeparator=","
                                                                                    precision={2}
                                                                                    onChangeEvent={this.handlePotonganQty(id)}
                                                                                />
                                                                            </InputGroup>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col className="text-center">
                                                                        {item.p_potongan_type === undefined ?
                                                                            <div>
                                                                                {item.payroll_term.p_term_prefix === 'general' ?
                                                                                    <FormGroup>
                                                                                        <select
                                                                                            name="p_potongan_type"
                                                                                            id="p_potongan_type"
                                                                                            className="form-control"
                                                                                            onChange={this.handlePotonganType(id)}
                                                                                            placeholder="Type Potongan"
                                                                                            value={item.p_potongan_type}
                                                                                        >
                                                                                            <option value={""}>Type
                                                                                                Potongan
                                                                                            </option>
                                                                                            <option value="jam">Jam
                                                                                            </option>
                                                                                            <option value="hari">Hari
                                                                                            </option>
                                                                                            <option
                                                                                                value="bulan">Bulan
                                                                                            </option>
                                                                                        </select>
                                                                                    </FormGroup>
                                                                                    :
                                                                                    <h6>{item.p_potongan_type}</h6>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <h6>{item.p_potongan_type}</h6>
                                                                        }
                                                                    </Col>

                                                                    <Col>
                                                                        <FormGroup>
                                                                            <CurrencyInput
                                                                                style={{backgroundColor: '#f0f0f0'}}
                                                                                readOnly
                                                                                className="form-control"
                                                                                thousandSeparator="."
                                                                                value={item.p_potongan_total}
                                                                                prefix="Rp "
                                                                                precision={0}
                                                                            />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm="1" className="text-center">
                                                                        {id == 0 ?
                                                                            <Button
                                                                                onClick={this.handleSubmit.bind(this)}
                                                                                disabled={this.state.isLoading}
                                                                                color="success"
                                                                            >
                                                                                {!this.state.isLoading ?
                                                                                    <i className="fa fa-save"/>
                                                                                    :
                                                                                    <i className="fa fa-refresh fa-spin"/>
                                                                                }
                                                                            </Button>
                                                                            :
                                                                            item.payroll_term.p_term_prefix === 'general' &&
                                                                            <Button
                                                                                onClick={() => {
                                                                                    this.wantDelete(item.p_potongan_id)
                                                                                }}
                                                                                color='danger'
                                                                                style={{marginBottom: '10px'}}
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Button>
                                                                        }
                                                                    </Col>
                                                                </Row>}
                                                        </div>

                                                    );
                                                })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {this.state.rows.map((item, id) => {
                                                    return (
                                                        <Row>
                                                            <Col>
                                                                <Input bsSize="sm" type="select" name="p_term_id"
                                                                       value={item.p_term_id}
                                                                       onChange={this.handleChange(id)}>
                                                                    <option value="">- Nama Potongan -</option>
                                                                    {
                                                                        this.state.term.map((item, index) => {
                                                                            return (
                                                                                <option
                                                                                    key={index}
                                                                                    value={item.p_term_id}
                                                                                    selected={item.p_term_id == this.state.term.p_term_id}>
                                                                                    {item.p_term_name}
                                                                                </option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Input>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <CurrencyInput
                                                                        name="p_potongan_amount"
                                                                        className="form-control"
                                                                        thousandSeparator="."
                                                                        value={item.p_potongan_amount}
                                                                        prefix="Rp "
                                                                        id="p_potongan_amount"
                                                                        precision={0}
                                                                        onChangeEvent={this.handleAmountRows(id)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <CurrencyInput
                                                                        name="p_potongan_qty"
                                                                        className="form-control"
                                                                        thousandSeparator="."
                                                                        value={item.p_potongan_qty}
                                                                        id="p_potongan_qty"
                                                                        decimalSeparator=","
                                                                        precision={2}
                                                                        onChangeEvent={this.handleQtyRows(id)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <select
                                                                        name="p_potongan_type"
                                                                        id="p_potongan_type"
                                                                        className="form-control"
                                                                        onChange={this.handlePotonganType(id)}
                                                                        placeholder="Type Potongan"
                                                                        value={item.p_potongan_type}
                                                                    >
                                                                        <option value={""}>Type Potongan</option>
                                                                        <option value="jam">Jam</option>
                                                                        <option value="hari">Hari</option>
                                                                        <option value="bulan">Bulan</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <CurrencyInput
                                                                        style={{backgroundColor: '#f0f0f0'}}
                                                                        readOnly
                                                                        className="form-control"
                                                                        thousandSeparator="."
                                                                        value={item.p_potongan_total}
                                                                        prefix="Rp "
                                                                        precision={0}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="1">
                                                                <ButtonGroup size="sm">
                                                                    <div>
                                                                        {this.state.rows.length !== 1 &&
                                                                        <Button
                                                                            onClick={this.removeClick.bind(this, id)}
                                                                            color='danger'
                                                                            style={{marginBottom: '10px'}}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Button>}
                                                                        {this.state.rows.length - 1 === id &&
                                                                        <button
                                                                            onClick={this.addClick.bind(this)}
                                                                            className="btn ipresens-btn-color"
                                                                        >
                                                                            <i className="fa fa-plus"></i>
                                                                        </button>
                                                                        }
                                                                    </div>
                                                                </ButtonGroup>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: APIKEY
})(Potongan_modal)
