import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import {IMG_HOST} from "../../helper/host";
import {Helmet} from "react-helmet";
import i18n from "i18next";

export class Detail_pengumuman extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    loadData() {
        let form = {
            id: this.props.match.params.id
        }
        this.props.request("pengumuman/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const stylefoto = {
            width: '100%',
            maxHeight: 'auto',
        }
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Detail Pengumuman - iPresens</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title="Detail Pengumuman" parent="Pengumuman" parentLink='/announcement'/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>{this.state.data.pengumuman_judul}</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <img style={stylefoto} src={this.state.data.pengumuman_gambar !== null ? IMG_HOST + 'mitra/perusahaan/pengumuman/' + this.state.data.pengumuman_gambar : IMG_HOST + "user-profile.png"} alt=""/>
                                        </div>
                                        <div className="col-md-8">
                                            <h6>{this.state.data.pengumuman_shortdesc}</h6>
                                            <div dangerouslySetInnerHTML={{__html: this.state.data.pengumuman_deskripsi}}>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Detail_pengumuman
