import React, {Component} from 'react'

export class FooterRegister extends Component {
    render() {
        return (
                <footer className="footer py-2" style={{backgroundColor: "#1B002F"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div style={{textAlign: "center"}} className="col footer-copyright">
                                <p className="mb-0" style={{color: "white", fontSize: "14px"}}>Copyright Presensi.co.id 2024</p>
                            </div>
                        </div>
                    </div>
                </footer>
        )
    }
}

export default FooterRegister
