import React from "react";
import { Helmet } from "react-helmet";
import "./style.css";
import { set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import queryString from 'query-string';
import validation from '../../helper/validation';
import i18n from "i18next";
import LoginV2Img from "../../assets/images/login-v2.png"
import logo from "../../assets/images/full-logo.png"
import InfoIcon from "../../assets/icons/info-icon-green.svg"
import EyeGreyIcon from "../../assets/icons/eye-grey.svg"
import EyeSlashIcon from "../../assets/icons/eye-slash-grey.svg"
import CrossMarkIcon from "../../assets/icons/cross-mark.svg"
import "./style.css"

class ResetPasswordV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            confirmPassword: "",
            messagePassword: "",
            messageConfirmPassword: "",
            messageSuccess: {
                header: "Berhasil",
                value: ""
            },
            messageFailed: {
                header: "Gagal",
                value: ""
            },
            invalidPassword: false,
            invalidConfirmPassword: false,
            hidePassword: true,
            hideConfirmPassword: true
        }
        this.handleChange = this.handleChange.bind(this);
        this.verify = this.verify.bind(this);
        this.reqLink = this.reqLink.bind(this);
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value

        this.setState({
            [name]: value
        })
    }

    verify = ({ email, token }) => {
        const form = {
            email,
            token
        }

        this.props.request("verify", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        success: true,
                        message: response.message
                    })
                    setInterval(() => {
                        this.setState(state => {
                            return {
                                tick: state.tick - 1
                            }
                        })
                    }, 1000)
                    setTimeout(() => {
                        this.props.history.push('/login')
                    }, this.state.tick * 1000)
                } else {
                    this.setState({
                        loading: false,
                        success: false,
                        message: response.message
                    })
                }
            });
    }
    reqLink() {
        const form = {
            email: this.state.email
        }

        this.setState({ loading: true })
        this.props.request("req-resubmit-verify", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        loading: false,
                        success: true,
                        message: response.message
                    })
                } else {
                    this.setState({
                        loading: false,
                        success: false,
                        message: response.message
                    })
                }
            });
    }
    submitReset = () => {
        const queries = queryString.parse(this.props.location.search);

        this.setState({
            prosesSimpan: true,
        })
        let form = {
            email: queries.email,
            password: this.state.password,
            konfirmasi_password: this.state.confirmPassword
        }
        this.props.request("konfirmasi_password_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.props.history.push({
                        pathname: "/login",
                        state: {
                            success: true,
                            message: response.message
                        }
                    })
                } else {
                    this.setState({
                        messageFailed: {
                            header: "Gagal",
                            value: response.message
                        }
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    componentDidMount() {
        const queries = queryString.parse(this.props.location.search);
        const emailCheck = validation(queries.email);
        if (queries.email === undefined || queries.token === undefined) {
            this.setState({
                loading: false,
                success: false,
                message: 'Link verifikasi tidak valid!'
            });
        } else {
            let linkValid = true;
            if (emailCheck === true) {
                this.setState({ email: queries.email });
            } else {
                linkValid = false;
                this.setState({
                    invalidEmail: true,
                    message: emailCheck
                });
            }

            if (queries.token.trim().length !== 40) {
                linkValid = false;
                this.setState({
                    invalidToken: true,
                    message: 'Token tidak valid!'
                });
            }
            if (linkValid) {
                this.verify(queries);
            } else {
                this.setState({
                    loading: false,
                    success: false,
                });
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Login - iPresens</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <div className="container-fluid">
                    <div className="row flex-row-reverse">
                        <div className="col-md-8 p-0">
                            <div className="loginv2-div-img">
                                <img alt="" src={LoginV2Img} className="loginv2-img" />
                            </div>
                        </div>
                        <div className="col-md-4 loginv2-col-form">
                            <div className="row loginv2-row-logo">
                                <div className="col-md-12" style={{ textAlign: "center" }}>
                                    <img alt="" src={logo} />
                                </div>
                            </div>
                            {this.state.messageFailed.value !== "" &&
                                <div className="row">
                                    <div className="col-md-12 loginv2-row-form">
                                        <div className="d-flex flex-row loginv2-failed">
                                            <div>
                                                <img alt="" src={CrossMarkIcon} style={{ width: "24px", height: "24px" }} />
                                            </div>
                                            <div className="pl-2">
                                                <div style={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "18px"
                                                }}>{this.state.messageFailed.header}</div>
                                                <div>{this.state.messageFailed.value}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.messageSuccess.value !== "" &&
                                <div className="row">
                                    <div className="col-md-12 loginv2-row-form">
                                        <div className="d-flex flex-row loginv2-success">
                                            <div>
                                                <img alt="" src={InfoIcon} style={{ width: "24px", height: "24px" }} />
                                            </div>
                                            <div className="pl-2">
                                                <div style={{
                                                    fontSize: "14px",
                                                    fontWeight: 700,
                                                    lineHeight: "18px"
                                                }}>{this.state.messageSuccess.header}</div>
                                                <div>{this.state.messageSuccess.value}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-md-12 text-center loginv2-masuk-akun">
                                    Masukan Password Baru anda!
                                </div>
                            </div>
                            <div className="row loginv2-row-form">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="loginv2-label">Password Baru</label>
                                        <div className="input-group has-validation">
                                            <input className={this.state.invalidPassword ? "form-control is-invalid loginv2-input" : "form-control loginv2-input"} placeholder="Masukkan password baru" name="password" onChange={this.handleChange} type={this.state.hidePassword ? "password" : "text"} value={this.state.password} />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <img className="loginv2-input-icon" style={{ width: "16px", height: "16px", backgroundColor: "#E9ECEF" }} alt="" src={this.state.hidePassword ? EyeGreyIcon : EyeSlashIcon} onClick={() => this.setState({
                                                        hidePassword: !this.state.hidePassword
                                                    })} />
                                                </div>
                                            </div>
                                            {this.state.invalidPassword &&
                                                <div className="invalid-feedback loginv2-invalid">
                                                    {this.state.messagePassword}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row loginv2-row-form mb-4">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="loginv2-label">Konfirmasi Password Baru</label>
                                        <div className="input-group has-validation">
                                            <input className={this.state.invalidConfirmPassword ? "form-control is-invalid loginv2-input" : "form-control loginv2-input"} placeholder="Masukkan password baru" name="confirmPassword" onChange={this.handleChange} type={this.state.hideConfirmPassword ? "password" : "text"} value={this.state.confirmPassword} onKeyDown={(e) => {
                                                if (this.state.confirmPassword !== "") {
                                                    if (e.key === "Enter") {
                                                        e.preventDefault()
                                                        if (this.state.password.length < 6) {
                                                            this.setState({
                                                                invalidPassword: true,
                                                                messagePassword: "Password minimal 6 karakter",
                                                                invalidConfirmPassword: false,
                                                                messageConfirmPassword: ""
                                                            })
                                                        } else if (this.state.password.length > 16) {
                                                            this.setState({
                                                                invalidPassword: true,
                                                                messagePassword: "Password maksimal 16 karakter",
                                                                invalidConfirmPassword: false,
                                                                messageConfirmPassword: ""
                                                            })
                                                        } else if (this.state.password !== this.state.confirmPassword) {
                                                            this.setState({
                                                                invalidConfirmPassword: true,
                                                                messageConfirmPassword: "Konfirmasi password anda salah",
                                                                invalidPassword: false,
                                                                messagePassword: ""
                                                            })
                                                        } else {
                                                            this.submitReset()
                                                        }
                                                    }
                                                }
                                            }} />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <img className="loginv2-input-icon" style={{ width: "16px", height: "16px", backgroundColor: "#E9ECEF" }} alt="" src={this.state.hideConfirmPassword ? EyeGreyIcon : EyeSlashIcon} onClick={() => this.setState({
                                                        hideConfirmPassword: !this.state.hideConfirmPassword
                                                    })} />
                                                </div>
                                            </div>
                                            {this.state.invalidConfirmPassword &&
                                                <div className="invalid-feedback loginv2-invalid">
                                                    {this.state.messageConfirmPassword}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row loginv2-row-form mb-4">
                                <div className="col-md-12">
                                    <button className="btn btn-primary loginv2-button-login" disabled={this.state.password === "" || this.state.confirmPassword === "" || this.state.prosesSimpan} onClick={() => {
                                        if (this.state.password.length < 6) {
                                            this.setState({
                                                invalidPassword: true,
                                                messagePassword: "Password minimal 6 karakter",
                                                invalidConfirmPassword: false,
                                                messageConfirmPassword: ""
                                            })
                                        } else if (this.state.password.length > 16) {
                                            this.setState({
                                                invalidPassword: true,
                                                messagePassword: "Password maksimal 16 karakter",
                                                invalidConfirmPassword: false,
                                                messageConfirmPassword: ""
                                            })
                                        } else if (this.state.password !== this.state.confirmPassword) {
                                            this.setState({
                                                invalidConfirmPassword: true,
                                                messageConfirmPassword: "Konfirmasi password anda salah",
                                                invalidPassword: false,
                                                messagePassword: ""
                                            })
                                        } else {
                                            this.submitReset()
                                        }
                                    }}>Reset Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordV2);
