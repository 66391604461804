import React, {Component, Fragment} from 'react';
import Breadcrumb from '../../../common/breadcrumb';
import {Spin} from 'antd';
import {Card, CardBody, Col} from 'reactstrap';
import {Helmet} from "react-helmet";
import "../elipsis.css";
import {IMG_HOST} from "../../../../helper/host";
import format from "format-number";
import i18n from "i18next";

export class DetailKasbon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataKasbon  : [],
            karyawan    : [],
            loading     : true,
            kasbon_id   : "",
            note        : "",
            status      : ""
        }
    }
    loadData() {
        let form = {
            id      : this.props.match.params.id
        }
        this.props.request("kasbon/detail-kasbon", form, 'POST')
            .then(response => {
                if (response.success) {
                    var result = response.data
                    this.setState({
                        dataKasbon  : result,
                        karyawan    : result.karyawan,
                        loading     : false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Detail Kasbon - Presensi</title>
                    <meta name="description" content="Detail Kasbon" />
                </Helmet>

                <Breadcrumb title="Detail Kasbon" parent="Pengajuan" parentLink='/submission/casbon'/>
                <Col lg="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            {this.state.loading ?
                                <Spin size="large" tip="Mohon Tunggu..." style={{
                                    width: '100%',
                                    margin: 'auto',
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                }}/>
                                :
                                <div>
                                    <Card className="card-shadow-primary profile-responsive card-border">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner bg-primary">
                                                <div className="menu-header-content btn-pane-right">
                                                    <div >
                                                        {this.state.karyawan.karyawan_foto !== null ? (
                                                        <img
                                                        id="avatar"
                                                        src={IMG_HOST + 'frontapp/karyawan/' + this.state.karyawan.karyawan_foto}
                                                        className="img-fluid blur-up lazyloaded"/>) : (
                                                        <img
                                                        id="avatar"
                                                        src={IMG_HOST + 'user-profile.png'}
                                                        className="img-fluid blur-up lazyloaded"/>
                                                        )}
                                                    </div>
                                                    <div>
                                                    <h5 className="menu-header-title">{this.state.karyawan.karyawan_nama}</h5>
                                                    {/*<h6 style={{ fontSize: 12 }}>{this.state.divisi.divisi_name}</h6>*/}
                                                    {/*<h6 style={{ fontSize: 12 }}>{format({prefix: '', integerSeparator: "."})(this.state.karyawan.karyawan_payroll_amount)}</h6>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <div>
                                        <Fragment>
                                            <Col lg="12">
                                                <Card>
                                                    <CardBody>
                                                        <div className="row">
                                                            <div className="col col-lg-2">
                                                                Deskripsi Kasbon
                                                            </div>
                                                            <div className="col-md-auto">
                                                                :
                                                            </div>
                                                            <div className="col-md-auto">
                                                                {this.state.dataKasbon.kasbon_desc}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col col-lg-2">
                                                                Nominal Kasbon
                                                            </div>
                                                            <div className="col-md-auto">
                                                                :
                                                            </div>
                                                            <div className="col-md-auto">
                                                                {format({ prefix: 'Rp ', integerSeparator: "." })(this.state.dataKasbon.kasbon_nominal)}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col col-lg-2">
                                                                Cicilan
                                                            </div>
                                                            <div className="col-md-auto">
                                                                :
                                                            </div>
                                                            <div className="col-md-auto">
                                                                {this.state.dataKasbon.kasbon_cicilan}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col col-lg-2">
                                                                Tenor Pembayaran
                                                            </div>
                                                            <div className="col-md-auto">
                                                                :
                                                            </div>
                                                            <div className="col-md-auto">
                                                                {format({ suffix: 'x', integerSeparator: "." })(this.state.dataKasbon.kasbon_tenor)}
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.dataKasbon.kasbon_status == "cancel" ?
                                                                <div className="row">
                                                                    <div className="col col-lg-2">
                                                                        Catatan
                                                                    </div>
                                                                    <div className="col-md-auto">
                                                                        :
                                                                    </div>
                                                                    <div className="col-md-auto">
                                                                        <i>"{this.state.dataKasbon.kasbon_note}"</i>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Fragment>
                                    </div>
                                </div>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Fragment>
        )
    }
}

export default DetailKasbon
