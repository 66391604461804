import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import MapPicker from 'react-google-map-picker'
import {Button, Modal} from "antd";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import {APIKEY} from "../../helper/host";

export class Edit_lokasi_absensi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            prosesSimpan: false,
            isLoading: true,
            nama_lokasi: '',
            tipe_lokasi: '',
            alamat_lokasi: '',
            max_jarak: '',
            location: {
                lat: -7.865242,
                lng: 110.156070
            },

        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    showModal = () => {
        this.setState({
            modal: true,
        });
    };

    handleOk = e => {
        this.setState({
            modal: false,
        });
    };

    handleCancel = e => {
        this.setState({
            modal: false,
        });
    };

    simpanLokasi = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.props.match.params.id,
            nama: this.state.nama_lokasi,
            perusahaan_title: this.state.tipe_lokasi === '' ? 'pusat' : this.state.tipe_lokasi,
            perusahaan_alamat: this.state.alamat_lokasi,
            max_jarak: this.state.max_jarak,
            latitude: this.state.location.lat,
            longitude: this.state.location.lng,
        }

        this.props.request("lokasi/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/presensi-location')
                }else{
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadDataLokasi(){
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.props.match.params.id
        }
        this.props.request("lokasi/profil", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        nama_lokasi: response.data.lokasi_nama,
                        tipe_lokasi: response.data.perusahaan_title,
                        alamat_lokasi: response.data.perusahaan_alamat,
                        max_jarak: response.data.max_jarak,
                        location: {
                            lat: response.data.latitude,
                            lng: response.data.longitude,
                        }
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    componentDidMount() {
        this.loadDataLokasi()
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Edit Lokasi Presensi - iPresens</title>
                    <meta name="description" content="Detail Presensi" />
                </Helmet>
                <Breadcrumb title="Edit Lokasi Presensi" parent="Lokasi Presensi" parentLink="/presensi-location"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                        <h4>Detail Lokasi Presensi</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Nama Lokasi</label>
                                            <input name="nama_lokasi" className="form-control col-xl-8 col-md-7" placeholder="Nama lokasi..."
                                                   type="text" required="" defaultValue={this.state.nama_lokasi} onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Tipe Lokasi</label>
                                            <select name="tipe_lokasi" className="form-control col-xl-8 col-md-7" onChange={this.handleChange}>
                                                <option selected={this.state.tipe_lokasi === 'pusat'} value="pusat">Pusat</option>
                                                <option selected={this.state.tipe_lokasi === 'cabang'} value="cabang">Cabang</option>
                                            </select>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Alamat Lokasi</label>
                                            <textarea name="alamat_lokasi" className="form-control col-xl-8 col-md-7" placeholder="Alamat Lokasi.." value={this.state.alamat_lokasi} onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Pin Lokasi</label>
                                            <Button type="primary" onClick={this.showModal}>Pin Lokasi Presensi</Button> &nbsp; Latitude: {this.state.location.lat}, Longitude: {this.state.location.lng   }
                                            <Modal
                                                title="Pin Lokasi Presensi"
                                                visible={this.state.modal}
                                                onOk={this.handleOk}
                                                onCancel={this.handleCancel}
                                            >
                                                <MapPicker
                                                    style={{position: 'relative', width: '100%', height: '250px'}}
                                                    name='location'
                                                    defaultLocation={ this.state.location }
                                                    zoom={10}
                                                    onChangeLocation={ (lat, lng) => {
                                                        this.setState({
                                                            location: {
                                                                lat: lat,
                                                                lng: lng
                                                            }
                                                        })
                                                    }}
                                                    apiKey={APIKEY} />
                                            </Modal>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Max Jarak Presensi dari Pin Lokasi <b>(*dalam satuan Meter)</b></label>
                                            <input name="max_jarak" className="form-control col-xl-8 col-md-7" placeholder="Max Jarak Presensi dalam satuan Meter..."
                                                   type="number" required="" defaultValue={this.state.max_jarak} onChange={this.handleChange}/>
                                        </div>
                                    </form>
                                    <div className="pull-right">
                                        {this.state.prosesSimpan === false ?
                                            <button type="button" className="btn ipresens-btn-color"
                                                    onClick={this.simpanLokasi}>Simpan</button>
                                            :
                                            <button type="button" className="btn ipresens-btn-color"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Edit_lokasi_absensi
