import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import {APIKEY, BACKEND_API_HOST} from "../../helper/host";
import { GoogleApiWrapper } from 'google-maps-react';
import { Modal, Empty, Spin } from 'antd';
import { Helmet } from "react-helmet";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Add from "./add";
import Edit from "./edit";
import { pageNumber } from "../../functions/functionGeneral";
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import refreshIcon from "../../assets/icons/refresh.svg";
import plusIconWhite from "../../assets/icons/IconPlusWhite.svg";
import "./style.css";
import deleteImages from "../../assets/images/delete-images.png"
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import ImportIcon from "../../assets/icons/import-icon.svg";
import moment from "moment";
import i18n from "i18next";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";

export class List_istillah extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            dataHistory: [],
            loading: true,
            add_visible: false,
            visable: false,
            filter_nama: '',
            filter_type: '',
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination: 2,
            currentPageHistory: 0,
            modalEdit: false,
            modalDelete: false,
            id_del: null,
            importFile: null,
            modalimport: false,
            modalHistory: false,
            loadingHistory: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.importExcel = this.importExcel.bind(this)

    }
    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, data: [] }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }
    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_type: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        let form = {
            id: this.state.id_del
        }
        this.props.request("payroll/delete_term", form, 'POST')
            .then(response => {
                if (response.success == true) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 4000,
                    })
                    this.setState({
                        loading: true,
                        modalDelete: false,
                        data: []
                    })
                    this.loadData()
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 5000,
                    })
                    this.setState({
                        loading: true,
                        data: []
                    })
                    this.loadData()
                }
            });
    }

    loadData() {
        this.setState({
            loading: true,
        })
        let form = {
            filter_nama: this.state.filter_nama,
            filter_type: this.state.filter_type,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("payroll/list_payroll_term", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
                this.setState({
                    loading: false,
                })
            });
    }

    exportIstilahGaji = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'payroll/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'payroll/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.p_term_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("payroll/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            p_term_id: ''
                        });
                    }
                });
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;

        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Daftar Istillah") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("daftaristilahgaji")} - iPresens</title>
                        <meta name="description" content="Daftar Istilah" />
                    </Helmet>
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Edit
                        data={this.state}
                        modalEdit={this.state.modalEdit}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        setState={(val, callback) => this.setState(val, callback)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    {this.state.modalDelete &&
                    <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: false})} style={{
                        top: "20%"
                    }}>
                        <ModalBody align={"center"}>
                            <img src={deleteImages} alt="" />
                            <h3 style={{
                                "fontWeight": 700,
                                "fontSize": "18px",
                                "lineHeight": "27px",
                                "color": "#143B5E"
                            }}
                            >Peringatan</h3>
                            <p>Anda yakin ingin menghapus istilah Lembur?</p>
                            <ModalFooter style={{
                                borderTop: 0,
                                backgroundColor: "#FFFFFF",
                                borderBottomLeftRadius: "8px",
                                borderBottomRightRadius: "8px",
                                justifyContent: "space-between"
                            }}>
                                            <button className="btn ipresens-btn-cancel" onClick={() => this.setState({ modalDelete: false})}>
                                                Batal
                                            </button>
                                            <>
                                            {/*{this.state.data.map((item, index) => {*/}
                                            {/*    return (*/}
                                                    <button className="btn ipresens-btn-color" onClick={() => this.setState({
                                                        idDel: this.state.data.p_term_id
                                                    },this.wantDelete)}>
                                                        Yakin
                                                    </button>
                                            {/*)})}*/}
                                            </>
                            </ModalFooter>
                        </ModalBody>
                    </Modal>
                    }
                    <Modal
                        title="Import Istilah Gaji"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={true}><i className="fa fa-refresh fa-spin"></i> Sedang mengupload data</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                            <a onClick={this.exportIstilahGaji} style={{ color: "#057CE4" }}>Unduh template disini</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>Aktivitas</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px', paddingBottom: '16', paddingRight: '12px', paddingLeft: '12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Dibuat
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Diubah
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.p_term_name != item.p_term_name && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Nama Istilah: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.p_term_name
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.p_term_name ? item.p_term_name : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.p_term_type != item.p_term_type && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Type Istilah: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.p_term_type
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.p_term_type ? item.p_term_type : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>
                    <div className='row pt-4'>
                        <div className='col-md-12 header-istilah'>
                            <div className='row'>
                                <div className='col-md-12' style={{
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "16px",
                                    color: "#5D6F80"
                                }}>iPresens Admin Panel</div>
                            </div>
                            <div className='row' style={{ marginBottom: "28px" }}>
                                <div className='col-md-12' style={{
                                    fontSize: "24px",
                                    lineHeight: "36px",
                                    fontWeight: 700,
                                    color: "#143B5E"
                                }}>{i18n.t("daftaristilahgaji")}</div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='form-group mb-0'>
                                        <div className="row filter-karyawan">
                                            <div className="col-md-3 pr-0 input-column-search">
                                                <div className="input-group button-search-istilah">
                                                    <input name="filter_nama"
                                                           className={this.state.filter_nama === "" ? "form-control bg-image-input filter-name input-select-style" : "form-control filter-name input-select-style"}
                                                           placeholder="       Cari nama istilah..."
                                                           type="text"
                                                           style={{
                                                               backgroundColor: "#F9F9F9",
                                                               fontSize: "12px",
                                                               fontWeight: 400,
                                                               paddingTop: "9px",
                                                               paddingBottom: "9px",
                                                               borderRadius: "8px"
                                                           }}
                                                           required=""
                                                           value={this.state.filter_nama}
                                                           onChange={this.handleChange}
                                                           onKeyUp={(event) => {
                                                               event.preventDefault()
                                                               if (event.which == 13) {

                                                                   this.setState({
                                                                       page: 0,
                                                                       paging: this.state.paging,
                                                                       loading: true
                                                                   }, () => {
                                                                       this.loadData()
                                                                   });
                                                               }
                                                           }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 column-select column-select-istilah">
                                                <div className="input-group button-select-istilah">
                                                    <select name="filter_type"
                                                            className="form-control input-select-style select-column mr-2"
                                                            style={{ borderRadius: "8px" }}
                                                            onChange={this.handleFilter}
                                                    >
                                                        <option value="" selected={this.state.filter_type === ''}>Pilih Tipe</option>
                                                        <option value="kompensasi">Kompensasi</option>
                                                        <option value="potongan">Potongan</option>
                                                    </select>
                                                    <button className="input-select-style button-reset" onClick={this.resetFilter}><img src={refreshIcon} alt=""/></button>
                                                </div>
                                            </div>
                                            <div className="col"></div>
                                            <div className='col-auto px-0 mb-4' style={{textAlign: "right"}}>
                                                <button className="ipresens-import-button btn-import-krywn"
                                                        onClick={() => {
                                                            this.setState({modalimport: true})
                                                        }}><img src={ImportIcon} alt=''/> Import
                                                </button>
                                            </div>
                                            <div className="col-auto col-button column-add-term">
                                                {(this.props.user.user_level.level_action_limited == null || this.props.user.user_level.level_action_limited.search("C") >= 0) && (
                                                    <a className="btn ipresens-btn-color tambah-term button-add-istilah mt-0"
                                                       style={{lineHeight: "24px"}}
                                                       onClick={() => {
                                                           this.setState({
                                                               add_visible: true
                                                           })
                                                       }}><img src={plusIconWhite} alt=""/> Tambah</a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading ?
                                    <div className="card-body">
                                        <Spin size="large" tip="Sedang memuat data..." style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }}/>
                                    </div>
                                    :
                                    <div className="card-body">
                                        {!this.state.loading && this.state.data.length === 0 ?
                                            <Empty description={i18n.t("datakosong")}
                                                   image={EmptyDataImage} className="ipresens-image-empty "/>
                                            :
                                            <Table striped className="mb-5">
                                                <Thead>
                                                    <Tr>
                                                        <Th width="30">#</Th>
                                                        <Th width="100" className="text-left">Nama</Th>
                                                        <Th width="100" className="text-left">Type</Th>
                                                        <Th className="text-center" width="100">Aksi</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {this.state.loading &&
                                                        <Tr>
                                                            <Td colSpan={9}>
                                                                <Spin size="large" tip="Loading..." style={{
                                                                    width: '100%',
                                                                    margin: 'auto',
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px'
                                                                }}/>
                                                            </Td>
                                                        </Tr>
                                                    }
                                                    {this.state.data.map((item, index) => {
                                                        no += 1
                                                        return (
                                                            <Tr className="table-istilah">
                                                                <small>
                                                                    <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                </small>
                                                                <Td align="left">
                                                                    {item.p_term_name}
                                                                </Td>
                                                                <Td align="left">
                                                                    {item.p_term_type === 'kompensasi' ?
                                                                        <div style={{color: "#6BAA61"}}>Kompensasi</div>
                                                                        :
                                                                        <div style={{color: "#E03616"}}>Potongan</div>
                                                                    }
                                                                </Td>
                                                                <Td align={"center"}>
                                                                    <a onClick={() => {
                                                                        this.setState({
                                                                            modalHistory: true,
                                                                            p_term_id: item.p_term_id
                                                                        }, () => {
                                                                            this.loadHistory(item.p_term_id);
                                                                        })
                                                                    }}>
                                                                        <button className={"btn-riwayat"}>Riwayat</button>
                                                                    </a>
                                                                    <a onClick={() => {
                                                                        this.setState({
                                                                            p_term_id: item.p_term_id,
                                                                            p_term_type: item.p_term_type,
                                                                            p_term_name: item.p_term_name,
                                                                            modalEdit: true,
                                                                        })
                                                                    }}>
                                                                        <button className={"btn-edit"}>Edit</button>
                                                                    </a>
                                                                    <a onClick={() => {
                                                                        this.setState({
                                                                            modalDelete: true,
                                                                            id_del: item.p_term_id
                                                                        })
                                                                    }}><button className="btn-delete">Hapus</button></a>
                                                                </Td>
                                                            </Tr>
                                                        )
                                                    })}
                                                </Tbody>
                                            </Table>
                                        }
                                        <Paging
                                            className="float-right"
                                            handlePageClick={this.handlePageClick}
                                            pageCount={this.state.pageCount}
                                            currentPage={this.state.currentPage}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}


const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_istillah));
