import React, { Component } from 'react'
import { Button, Drawer, Form } from 'antd';
import Swal from "sweetalert2";
import Select from "react-select";
import i18n from "i18next";
import { set_auth } from "../../../reducers/Auth";
import { connect } from "react-redux";

class LemburAdd extends Component {
    constructor() {
        super();
        this.state = {
            divisi_name: '',
            karyawan_id: '',
            karyawan_name: '',
            durasi_lembur: '',
            deskripsi_lembur: '',
            tanggal: (new Date()).toISOString().substr(0, 10),
            karyawan: []
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    simpanLembur = () => {
        if (isNaN(this.state.durasi_lembur)) {
            Swal.fire({
                title: 'Gagal!',
                text: "Pastikan durasi berbentuk angka!",
                icon: 'warning',
                confirmButtonText: 'OK'
            })
        }

        this.setState({
            loading: true
        })

        let form = {
            karyawan_id: this.state.karyawan_id,
            tanggal: this.state.tanggal,
            desc: this.state.deskripsi_lembur,
            durasi: this.state.durasi_lembur
        }

        this.props.request("lembur/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({ karyawan_id: '', durasi: '' })
                    this.props.loadData()
                    this.props.changeState({
                        add_visible: false
                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    searchKaryawan() {
        let form = {
            karyawan_name: this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }

    componentDidMount() {
    }

    render() {
        return (
            <Drawer
                title="Tambah Pengajuan Lembur"
                width={350}
                onClose={() => {
                    this.props.changeState({ add_visible: false, divisi_name: '' });
                }}
                visible={this.props.add_visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => {
                            this.props.changeState({ add_visible: false, divisi_name: '' });
                        }}
                            style={{ marginRight: 8 }}>
                            Batal
                        </Button>
                        <Button onClick={() => this.simpanLembur()} disabled={this.state.loading} type="primary">
                            Simpan
                        </Button>
                    </div>
                }
            >
                <Form layout="vertical" hideRequiredMark>
                    <div className="form-group">
                        <label>Nama Divisi</label><br />
                        <Select
                            placeholder="Nama Karyawan"
                            backspaceRemoves={true}
                            deleteRemoves={true}
                            styles={{ paddingLeft: "0px" }}
                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                            value={this.state.karyawan_selected}
                            isLoading={this.state.karyawan_loading}
                            onFocus={() => this.setState({ karyawan_loading: true }, () => this.searchKaryawan())}
                            onBlur={() => this.setState({ karyawan_loading: false })}
                            onInputChange={(val) => this.setState({ karyawan_name: val }, () => this.setState({ karyawan_loading: true }, () => this.searchKaryawan()))}
                            options={
                                this.state.karyawan != null ? (
                                    this.state.karyawan.map((item, index) =>
                                    (
                                        {
                                            key: index,
                                            value: item.karyawan_id,
                                            label: item.karyawan_nama,
                                            shift: item.divisi_sift
                                        }
                                    )
                                    ))
                                    : ("")
                            }
                            onChange={
                                (karyawan_selected) => {
                                    if (!karyawan_selected) {
                                        this.setState({
                                            karyawan_selected: '',
                                            karyawan_id: 0,
                                        })
                                    } else {
                                        this.setState({
                                            karyawan_selected,
                                            karyawan_id: karyawan_selected.value,
                                        })
                                    }
                                }
                            }
                            isClearable={() => {
                                this.setState({
                                    isClearable: true,
                                })
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label>Tanggal Lembur</label>
                        <input type="date" name="tanggal" className="form-control col-xl-8 col-md-7" defaultValue={(new Date()).toISOString().substr(0, 10)} onChange={this.handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Durasi Lembur <small>(dalam menit)</small></label>
                        <input name="durasi_lembur" className="form-control col-xl-12 col-md-12" placeholder="Durasi Lembur..."
                            type="text" required="" value={this.state.durasi_lembur}
                            onChange={(event) => {
                                const target = event.target;
                                const value = target.value;
                                const name = target.name;
                                this.setState({
                                    [name]: value
                                });
                            }} />
                    </div>
                    <div className="form-group">
                        <label>Deskripsi Lembur </label>
                        <input name="deskripsi_lembur" className="form-control col-xl-12 col-md-12" placeholder="Deskripsi Lembur..."
                            type="text" required="" value={this.state.deskripsi_lembur}
                            onChange={(event) => {
                                const target = event.target;
                                const value = target.value;
                                const name = target.name;
                                this.setState({
                                    [name]: value
                                });
                            }} />
                    </div>
                </Form>
            </Drawer>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(LemburAdd)
