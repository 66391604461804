import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import {Helmet} from "react-helmet";
import {Button, Modal, Select} from "antd";
import {APIKEY} from "../../helper/host";
import MapPicker from "react-google-map-picker";
import {Label} from "reactstrap";
import i18n from "i18next";
import "./kunjungan.css"

export class Add_outlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan    : false,
            isLoading       : true,
            nama_outlet     : '',
            email_outlet    : '',
            no_hp_outlet    : '',
            alamat          : '',
            nama_pemilik    : '',
            radius    : '',
            location        : {
                lat: -7.865242,
                lng: 110.156070
            },
            karyawan: [],
            lokasi_karyawan: []
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    lokasiKaryawanHandler = () => {
        if (this.state.lokasi_karyawan === 'all') {
            this.setState({
                lokasi_karyawan: []
            })
        } else {
            this.setState({
                lokasi_karyawan: 'all'
            })
        }
    }

    simpanOutlet = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            nama            : this.state.nama_outlet,
            email           : this.state.email_outlet,
            no              : this.state.no_hp_outlet,
            latitude        : this.state.location.lat,
            longtitude      : this.state.location.lng,
            alamat          : this.state.alamat,
            nama_pemilik    : this.state.nama_pemilik,
            radius          : this.state.radius,
            karyawan        : this.state.lokasi_karyawan,
        }

        this.props.request("outlet/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title               : 'Berhasil!',
                        text                : response.message,
                        icon                : 'success',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                    this.props.history.push('/visitation-location')
                }else{
                    Swal.fire({
                        title               : 'Gagal!',
                        text                : response.message,
                        icon                : 'warning',
                        showConfirmButton   : false,
                        timer               : 800,
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadKaryawan(){
        let form = {}
        this.props.request("karyawan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        loading         : false
                    })
                }
            });
    }

    componentDidMount() {
        this.loadKaryawan()
    }

    render() {
        const { Option } = Select;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Tambah Lokasi Kunjungan - Presensi</title>
                    <meta name="description" content="Detail Lokasi Kunjungan" />
                </Helmet>
                <Breadcrumb title="Tambah Lokasi Kunjungan" parent="Lokasi Kunjungan" parentLink="/visitation-location"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                        <h4>Detail Lokasi Kunjungan</h4>
                                        <div>
                                            <div className="form-group">
                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Nama Lokasi Kunjungan</label>
                                                <div>
                                                    <input type="text" name="nama_outlet" className="form-control col-xl-8 col-md-7" placeholder="Nama Lokasi Kunjungan..." onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Nama Pemilik</label>
                                                <div>
                                                    <input type="text" name="nama_pemilik" className="form-control col-xl-8 col-md-7" placeholder="Nama pemilik..." onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Email lokasi kunjungan</label>
                                                <div>
                                                    <input type="text" name="email_outlet" className="form-control col-xl-8 col-md-7" placeholder="Email Lokasi Kunjungan..." onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">NO. Telepon Lokasi Kunjungan</label>
                                                <div>
                                                    <input type="text" name="no_hp_outlet" className="form-control col-xl-8 col-md-7" placeholder="No Telepon Lokasi Kunjungan..." onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Alamat</label>
                                                <div>
                                                    <textarea name="alamat" className="form-control col-xl-8 col-md-7" placeholder="Alamat..." onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="input-group form-radius-add-otl">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Radius Kunjungan</label>
                                                        <input type="text" name="radius" className="form-control" style={{ borderRadius:"5px" }} placeholder="100" onChange={this.handleChange}/>
                                                        <div className="input-group-append label-radius">
                                                            <div className="input-group-text">
                                                                <Label>Meter</Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 group-button">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Latitude</label>
                                                        <input name="latitude" className="form-control" placeholder="Latitude..." type="text" required="" value={this.state.location.lat} onChange={(event) =>
                                                            this.setState({
                                                                location: {lat: event.target.value, lng: this.state.location.lng}
                                                            })}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 group-button">
                                                    <div className="form-group">
                                                        <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Longitude</label>
                                                        <input name="longitude" className="form-control" placeholder="Longitude..." type="text" required="" value={this.state.location.lng} onChange={(event) =>
                                                            this.setState({
                                                                location: {lat: this.state.location.lat, lng: event.target.value}
                                                            })}/>
                                                    </div>
                                                </div>
                                                    <div className="col-md-3 group-button">
                                                        <div className="col-md-3">
                                                            <div style={{ marginTop: '26px' }}>
                                                                <button className="btn ipresens-btn-color btn-pin-otl" type="button" style={{ color:"#fff" }} onClick={() => {this.setState({modalLokasi: true})}}>Pin Lokasi Kunjungan</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Modal
                                                    title="Pin Lokasi Absen"
                                                    visible={this.state.modalLokasi}
                                                    onOk={() => {this.setState({modalLokasi: false})}}
                                                    onCancel={() => {this.setState({modalLokasi: false})}}
                                                >
                                                    <MapPicker
                                                        style={{position: 'relative', width: '100%', height: '250px'}}
                                                        name='location'
                                                        defaultLocation={this.state.location}
                                                        zoom={10}
                                                        onChangeLocation={ (lat, lng) => {
                                                            this.setState({
                                                                location: {
                                                                    lat: lat,
                                                                    lng: lng
                                                                }
                                                            })
                                                        }}
                                                        apiKey={APIKEY} />
                                                </Modal>
                                            <div className="form-group">
                                                <label style={{ fontSize: "12px", color: "#143B5E" }} className="mb-2 font-weight-bold">Karyawan</label>
                                                    {this.state.lokasi_karyawan !== 'all' ?
                                                    <Select name="select_karyawan"
                                                            className={"col-xl-8 col-md-7"}
                                                            mode={"multiple"}
                                                            placeholder="Pilih Karyawan"
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            onChange={(event) => {
                                                                this.setState({
                                                                    lokasi_karyawan: event
                                                                })
                                                            }}>
                                                        {this.state.karyawan.map((item, index) => {
                                                            return (<Option
                                                                key={item.karyawan_id}>{item.karyawan_nama}</Option>)
                                                        })}
                                                    </Select> : null
                                                    }
                                                <label style={{ fontSize: "12px" }}>
                                                    <input style={{ fontSize: "16px", borderRadius:"8px", marginTop:"10px" }} type="checkbox"
                                                           checked={this.state.lokasi_karyawan === 'all'}
                                                           onChange={() => this.lokasiKaryawanHandler()} />
                                                    &nbsp;Semua Karyawan
                                                </label>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="pull-right">
                                        {this.state.prosesSimpan === false ?
                                            <button type="button" className="btn ipresens-btn-color"
                                                    onClick={this.simpanOutlet}>Simpan</button>
                                            :
                                            <button type="button" className="btn ipresens-btn-color"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Add_outlet
