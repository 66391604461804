import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from './83441-success.json';
import i18n from "i18next";

export default class SuccessPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStoped: false,
            isPaused: false,
            seconds: 5
        }
    }

    onStart = () => {
        this.setState({ seconds: this.state.seconds - 1 })
        if (this.state.seconds === 0) {
            clearInterval(this.time)
            this.props.history.push({
                pathname: '/',
                state: { success: true, message: this.props.message }
            });
        }
    }
    timer() {
        this.time = setInterval(this.onStart, 1000)
    }
    componentDidMount() {
        this.timer()
    }
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
        return (
            <main style={{ backgroundColor: "#F9F9F9", minHeight: "85vh", marginTop: "150px" }}>
                <div className='container' style={{ margin: "auto", width: "70%" }}>
                    <div className='row mb-3'>
                        <div className='col d-flex justify-content-center'>
                            <Lottie options={defaultOptions}
                                height={208}
                                width={208}
                                isStopped={this.state.isStopped}
                                isPaused={this.state.isPaused}
                                style={{ mixBlendMode: "darken" }}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col d-flex justify-content-center flex-column'>
                            <h4 className='text-center mb-3'>{i18n.t('pendaftaransukses')}</h4>
                            <p disabled className='text-center' style={{ color: "#1B002F", fontSize: "16px" }}>Anda akan diarahkan ke halaman login dalam {this.state.seconds}d ...</p>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}
