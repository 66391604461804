import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import CKEditor from "react-ckeditor-component";
import ImgCrop from "antd-img-crop";
import {Upload} from "antd";
import {API_HOST, IMG_HOST} from "../../helper/host";
import {Helmet} from "react-helmet";
import i18n from "i18next";
import {CloudUpload} from "@material-ui/icons";

export class Edit_pengumuman extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            isLoading: true,
            judul: '',
            shortdesc: '',
            gambar: null,
            fileList: [],
            deskripsi: '',

        }
        this.handleChange = this.handleChange.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    onEditorStateChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            deskripsi: newContent
        })
    }

    simpanPengumuman = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.props.match.params.id,
            judul: this.state.judul,
            shortdesc: this.state.shortdesc,
            gambar: this.state.gambar === null ? this.state.data.pengumuman_gambar : this.state.gambar,
            deskripsi: this.state.deskripsi,
        }

        this.props.request("pengumuman/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/pengumuman')
                }else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadData(){
        let form = {
            id: this.props.match.params.id
        }
        this.props.request("pengumuman/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        judul: response.data.pengumuman_judul,
                        shortdesc: response.data.pengumuman_shortdesc,
                        deskripsi: response.data.pengumuman_deskripsi,
                    })
                    if (response.data.pengumuman_gambar !== null){
                        this.setState({
                            fileList       : [
                                {
                                    uid: "-1",
                                    name: response.data.pengumuman_gambar,
                                    status: "done",
                                    url: IMG_HOST + 'mitra/perusahaan/pengumuman/' + response.data.pengumuman_gambar
                                }
                            ],
                        })}
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Edit Pengumuman - iPresens</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <Breadcrumb title="Edit Pengumuman" parent="Pengumuman" parentLink="/announcement"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                        <div>
                                            <div className="pull-right">
                                                {this.state.prosesSimpan === false ?
                                                    <button type="button" className="btn btn-primary"
                                                            onClick={this.simpanPengumuman}><i className="fa fa-floppy-o"></i> Simpan</button>
                                                    :
                                                    <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                                }
                                            </div>
                                            <h3>Detail Pengumuman</h3>
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold"><span>*</span> Gambar Pengumuman</label>
                                            <div className="col-lg-3 col-md-4 col-sm-12">
                                                <ImgCrop
                                                    width={500}
                                                    height={250}
                                                    quality={1}>
                                                    <Upload action={API_HOST}
                                                            listType="picture-card"
                                                            style={{width: '200px !important'}}
                                                            fileList={this.state.fileList}
                                                            onRemove={() => {
                                                                this.setState({gambar: ''})
                                                            }}
                                                            onChange={(file) => {
                                                                this.setState({ fileList: file.fileList })

                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()

                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            gambar: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({ gambar: [] })
                                                                }
                                                            }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null :
                                                            <div className="p-2">
                                                                            <span>
                                                                                <CloudUpload color="primary" fontSize="large"/>
                                                                            </span><br/>
                                                                <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                            </div>
                                                        }
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold"><span>*</span> Judul Pengumuman</label>
                                            <input name="judul" className="form-control" placeholder="Judul Pengumuman..."
                                                   type="text" required="" defaultValue={this.state.judul} onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold"><span>*</span> Deskripsi Singkat Pengumuman</label>
                                            <input name="shortdesc" className="form-control" placeholder="Deskripsi Singkat Pengumuman..."
                                                   type="text" required="" defaultValue={this.state.shortdesc} onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label className="mb-2 font-weight-bold"><span>*</span> Deskripsi Pengumuman</label>
                                            <div className="">
                                                <CKEditor
                                                    content={this.state.deskripsi}
                                                    events={{
                                                        change:this.onEditorStateChange
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Edit_pengumuman
