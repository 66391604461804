import React, { Fragment } from 'react';

import { Helmet } from "react-helmet";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TimeInput from '../../helper/time_input'
import Swal from 'sweetalert2';
import { Switch } from 'antd';
import i18n from "i18next";
import Breadcrumb from '../common/breadcrumb';
import ModalDefault from '../menu-guide/modal-default';
import RefreshIcon from '../../assets/icons/refresh.svg';
import PlusWhiteIcon from '../../assets/icons/plus-white.svg';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import ArrowLeft from '../../assets/icons/arrow-left.svg';
import EmptyDataImage from '../../assets/images/data-kosong.png';
import PencilIcon from '../../assets/icons/pencil.svg';
import TrashIcon from '../../assets/icons/trash.svg';
// import './style.css';

class ShiftJamKerja extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data_byid: {},
            data_divisi: [],
            hari_jam_kerja: [],
            paginate: 20,
            currentPage: 0,
            perPage: 0,
            totalData: 0,
            totalPerpage: 0,
            pageCount: 0,
            loading: false,
            modalAdd: false,
            nama_shift: "",
            toleransi_keterlambatan: 0,
            hari: false,
            senin: false,
            selasa: false,
            rabu: false,
            kamis: false,
            jumat: false,
            sabtu: false,
            minggu: false,
            jam_masuk: "",
            jam_keluar: "",
            progresModal: false,
            status: false,
            reload_check: false,
            shift: [],
            lokasi: [],
            divisi: [],
            karyawan: [],
            filter_nama: "",
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            confirm_modal_default: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckboxAdd = this.handleCheckboxAdd.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, data: [], loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(e) {
        const name = e.target.name
        const value = e.target.value

        this.setState({
            [name]: value
        })
    }

    handleCheckboxAdd(e) {
        const name = e.target.name
        const value = e.target.checked

        if (name === "hari") {
            if (value === true) {
                this.setState({
                    senin: true,
                    selasa: true,
                    rabu: true,
                    kamis: true,
                    jumat: true,
                    sabtu: true,
                    minggu: true,
                    hari_jam_kerja: ["senin", "selasa", "rabu", "kamis", "jumat", "sabtu", "minggu"]
                });
            } else {
                this.setState({
                    senin: false,
                    selasa: false,
                    rabu: false,
                    kamis: false,
                    jumat: false,
                    sabtu: false,
                    minggu: false,
                    hari_jam_kerja: []
                });
            }
        }

        this.setState({
            [name]: value
        }, () => {
            if (this.state.senin && this.state.selasa && this.state.rabu && this.state.kamis && this.state.jumat && this.state.sabtu && this.state.minggu) {
                this.setState({
                    hari: true,
                })
            } else {
                this.setState({
                    hari: false
                })
            }

            if (name !== "hari") {
                if (value) {
                    this.setState({
                        hari_jam_kerja: [...this.state.hari_jam_kerja, name]
                    })
                } else {
                    let deleted_value = this.state.hari_jam_kerja.filter(function (item) {
                        return item !== name
                    })
                    this.setState({
                        hari_jam_kerja: deleted_value
                    })
                }
            }
        })
    }

    updateStatus() {
        let form = {
            status: this.state.status,
            id: this.state.shift_id
        }
        this.props.request("jamKerja/update-status-jamkerja-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        modalEdit: false
                    })
                    this.loadData()
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: "gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    deleteJamKerja() {
        let form = {
            id: this.state.shift_id
        }
        this.props.request("jamKerja/delete-jamkerja-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.loadData()
                    this.loadCheck()
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: "gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    byIdJamKerja() {
        let form = {
            id: this.state.shift_id
        }
        this.props.request("jamKerja/by-id-jamkerja-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data_byid: response.data,
                    }, () => {
                        this.state.data_byid.jam_kerja.map((item, index) => {
                            this.setState(prevState => {
                                return {
                                    ...prevState,
                                    hari_jam_kerja: [...prevState.hari_jam_kerja, item.kerja_hari]
                                }
                            })

                            if (item.kerja_hari === "senin") {
                                this.setState({
                                    senin: true
                                })
                            }
                            if (item.kerja_hari === "selasa") {
                                this.setState({
                                    selasa: true
                                })
                            }
                            if (item.kerja_hari === "rabu") {
                                this.setState({
                                    rabu: true
                                })
                            }
                            if (item.kerja_hari === "kamis") {
                                this.setState({
                                    kamis: true
                                })
                            }
                            if (item.kerja_hari === "jumat") {
                                this.setState({
                                    jumat: true
                                })
                            }
                            if (item.kerja_hari === "sabtu") {
                                this.setState({
                                    sabtu: true
                                })
                            }
                            if (item.kerja_hari === "minggu") {
                                this.setState({
                                    minggu: true
                                })
                            }
                        })

                        if (this.state.data_byid.jam_kerja.length === 7) {
                            this.setState({
                                hari: true,
                            })
                        } else {
                            this.setState({
                                hari: false
                            })
                        }

                        this.setState({
                            nama_shift: response.data.sift_name,
                            toleransi_keterlambatan: response.data.sift_late_tolerance,
                            jam_masuk: response.data.jamkerja.jam_masuk.slice(0, 5),
                            jam_keluar: response.data.jamkerja.jam_keluar.slice(0, 5),
                            modalEdit: true
                        })
                    })
                }
            })
    }

    updateJamKerja() {
        let form = {
            id: this.state.shift_id,
            nama_shift: this.state.nama_shift,
            toleransi_terlambat: this.state.toleransi_keterlambatan,
            hari_jam_kerja: this.state.hari_jam_kerja,
            jam_masuk: this.state.jam_masuk,
            jam_keluar: this.state.jam_keluar
        }
        this.props.request("jamKerja/update-jamkerja-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        modalEdit: false,
                        nama_shift: "",
                        toleransi_keterlambatan: 0,
                        hari: false,
                        senin: false,
                        selasa: false,
                        rabu: false,
                        kamis: false,
                        jumat: false,
                        sabtu: false,
                        minggu: false,
                        jam_masuk: "",
                        jam_keluar: "",
                        hari_jam_kerja: []
                    })
                    this.loadData()
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: "gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    addJamKerja() {
        let form = {
            nama_shift: this.state.nama_shift,
            toleransi_terlambat: this.state.toleransi_keterlambatan,
            hari_jam_kerja: this.state.hari_jam_kerja,
            jam_masuk: this.state.jam_masuk,
            jam_keluar: this.state.jam_keluar
        }
        this.props.request("jamKerja/add-jamkerja-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        nama_shift: "",
                        toleransi_keterlambatan: 0,
                        hari: false,
                        senin: false,
                        selasa: false,
                        rabu: false,
                        kamis: false,
                        jumat: false,
                        sabtu: false,
                        minggu: false,
                        jam_masuk: "",
                        jam_keluar: "",
                        modalAdd: false,
                        modalEdit: false,
                        hari_jam_kerja: []
                    })
                    this.loadData()
                    Swal.fire({
                        title: "berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.loadCheck()
                            this.setState({
                                progresModal: true
                            })
                            this.props.set_auth(this.props.api_token, response.perusahaan);
                        } else {
                            this.loadCheck()
                            this.setState({
                                progresModal: true
                            })
                            this.props.set_auth(this.props.api_token, response.perusahaan);
                        }
                    })
                } else {
                    Swal.fire({
                        title: "gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    loadData() {
        let form = {
            page: this.state.currentPage + 1,
            perusahaan_id: this.props.user.perusahaan_id,
            shift_nama: this.state.filter_nama,
            paging: this.state.paginate
        }
        this.props.request("jamKerja/list-jamkerja-guide", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        totalPerpage: response.data.to,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            })
    }

    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }

    componentDidMount() {
        this.loadData()
        this.loadCheck()
    }

    render() {
        let no = 0
        const style = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#143B5E",
                padding: "12px 24px",
                fontSize: "24px",
                lineHeight: "36px",
                fontWeight: 700
            },
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "10px 16px",
                color: "#143B5E",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            card_body: {
                backgroundColor: "#F9F9F9",
                padding: "12px 16px"
            },
            column_card_body: {
                backgroundColor: "#F9F9F9",
                textAlign: "center"
            },
            hari: {
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px"
            },
            time_input: {
                padding: "12px",
                fontSize: "14px",
                color: "#252B2B",
                backgroundColor: "#FFFFFF",
                lineHeight: "16px",
                width: "88px"
            }
        }
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{i18n.t("daftarjamkerja")} - iPresens</title>
                    <meta name="description" content="Daftar Jam Kerja" />
                </Helmet>
                <Breadcrumb parent={i18n.t("manajemenjamkerja")} title={i18n.t("daftarjamkerja")} setGuide={() => {
                    this.setState({ guide: true })
                }} />
                <ModalDefault
                    shift={this.state.shift_check}
                    lokasi={this.state.lokasi_check}
                    divisi={this.state.divisi_check}
                    karyawan={this.state.karyawan_check}
                    confirm_modal_default={this.state.confirm_modal_default}
                    setState={(name, val) => this.setState(name, val)}
                    history={this.props.history}
                />
                <Modal isOpen={this.state.modalAdd} style={{ maxWidth: "1000px" }} size='lg' toggle={() => this.setState({
                    modalAdd: false, nama_shift: "",
                    toleransi_keterlambatan: 0,
                    hari: false,
                    senin: false,
                    selasa: false,
                    rabu: false,
                    kamis: false,
                    jumat: false,
                    sabtu: false,
                    minggu: false,
                    jam_masuk: "",
                    jam_keluar: "",
                    hari_jam_kerja: []
                })}>
                    <ModalHeader style={style_add.modal_header}><div style={style_add.title_header}>Tambah Jam Kerja</div></ModalHeader>
                    <ModalBody className='p-4'>
                        <div className='row mb-2'>
                            <div className='col-md-3'>
                                <label className='form-label' style={style_add.label_input}>Nama jam kerja</label>
                                <input className='form-control' type='text' name='nama_shift' onChange={this.handleChange} value={this.state.nama_shift} style={style_add.input} placeholder='Ketik nama jam kerja disini...' />
                            </div>
                            <div className='col-md-5'>
                                <label className='form-label' style={style_add.label_input}>Toleransi keterlambatan</label>
                                <div className='input-group'>
                                    <input className='form-control' type='number' onChange={this.handleChange} name='toleransi_keterlambatan' style={style_add.input} placeholder='Ketik toleransi keterlambatan...' />
                                    <div className='input-group-append'>
                                        <span className='input-group-text' style={style_add.input_group_text}>Menit</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'></div>
                        </div>
                        <div className='row pl-2 mt-4'>
                            <div className='col-md-5 px-2'>
                                <div className='card border'>
                                    <div className='card-header p-0 border' style={{ backgroundColor: "#FAFDFF" }}>
                                        <div className='row m-0'>
                                            <div className='col-md-12 d-flex flex-nowrap' style={style_add.card_header}>
                                                <input type='checkbox' checked={this.state.hari} name='hari' id='hari' onChange={this.handleCheckboxAdd} />
                                                <label for='hari' style={{
                                                    padding: "0px 8px",
                                                    margin: "0px",
                                                    fontWeight: 500,
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    color: "#143B5E"
                                                }}>Hari</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body' style={style_add.card_body}>
                                        <div className='row'>
                                            <div className='col-md-12' style={style_add.column_card_body}>
                                                <div className='row d-flex flex-nowrap'>
                                                    <div className='col-md-6 d-flex flex-column flex-nowrap'>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.senin === true} onChange={this.handleCheckboxAdd} name='senin' id='senin' />
                                                            <label className='px-1' for="senin" style={style_add.hari}>
                                                                Senin
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.selasa === true} name='selasa' id='selasa' value={this.state.selasa} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="selasa" style={style_add.hari}>
                                                                Selasa
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.rabu === true} name='rabu' id='rabu' value={this.state.rabu} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="rabu" style={style_add.hari}>
                                                                Rabu
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.kamis === true} name='kamis' id='kamis' value={this.state.kamis} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="kamis" style={style_add.hari}>
                                                                Kamis
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 d-flex flex-column flex-nowrap'>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.jumat === true} name='jumat' id='jumat' value={this.state.jumat} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="jumat" style={style_add.hari}>
                                                                Jumat
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.sabtu === true} name='sabtu' id='sabtu' value={this.state.sabtu} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="sabtu" style={style_add.hari}>
                                                                Sabtu
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.minggu === true} name='minggu' id='minggu' value={this.state.minggu} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="minggu" style={style_add.hari}>
                                                                Minggu
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5 px-2'>
                                <div className='card border' style={{ minHeight: "200px" }}>
                                    <div className='card-header p-0 border' style={{ backgroundColor: "#FAFDFF" }}>
                                        <div className='row d-flex flex-nowrap m-0 px-3'>
                                            <div className='col-md-6 px-0' style={style_add.card_header}>Jam Masuk</div>
                                            <div className='col-md-6 px-0' style={style_add.card_header}>Jam Keluar</div>
                                        </div>
                                    </div>
                                    <div className='card-body' style={style_add.card_body}>
                                        <div className='row'>
                                            <div className='col-md-12 pl-3' style={style_add.column_card_body}>
                                                <div className='row d-flex flex-nowrap'>
                                                    <div className='col-md-6' style={style_add.column_card_body}>
                                                        <TimeInput
                                                            style={style_add.time_input}
                                                            initTime={''}
                                                            name='jam_masuk'
                                                            className='s-input -time form-control'

                                                            onTimeChange={(event) => {
                                                                if (event.length === 5) {
                                                                    this.setState({
                                                                        jam_masuk: event + ":00"
                                                                    })
                                                                } else if (event.length === 0) {
                                                                    this.setState({
                                                                        jam_masuk: event
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-md-6 pl-0' style={style_add.column_card_body}>
                                                        <TimeInput
                                                            initTime={''}
                                                            name="jam_keluar"
                                                            style={style_add.time_input}
                                                            className='s-input -time form-control'

                                                            onTimeChange={(event) => {
                                                                if (event.length === 5) {
                                                                    this.setState({
                                                                        jam_keluar: event + ":00"
                                                                    })
                                                                } else if (event.length === 0) {
                                                                    this.setState({
                                                                        jam_keluar: event
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F9F9F9",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} className='px-4 py-3'>
                        <button className='btn text-capitalize batalkan-add-jamker' onClick={() => this.setState({
                            modalAdd: false, nama_shift: "",
                            toleransi_keterlambatan: 0,
                            hari: false,
                            senin: false,
                            selasa: false,
                            rabu: false,
                            kamis: false,
                            jumat: false,
                            sabtu: false,
                            minggu: false,
                            jam_masuk: "",
                            jam_keluar: "",
                            hari_jam_kerja: []
                        })}>Batalkan</button>
                        <button className='btn btn-primary text-capitalize' onClick={() => this.addJamKerja()} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>Tambahkan Jam Kerja</button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modalEdit} style={{ maxWidth: "1000px" }} size='lg' toggle={() => this.setState({
                    modalEdit: false,
                    nama_shift: "",
                    toleransi_keterlambatan: 0,
                    hari: false,
                    senin: false,
                    selasa: false,
                    rabu: false,
                    kamis: false,
                    jumat: false,
                    sabtu: false,
                    minggu: false,
                    jam_masuk: "",
                    jam_keluar: "",
                    hari_jam_kerja: []
                })}>
                    <ModalHeader style={style_add.modal_header}><div style={style_add.title_header}>Edit Jam Kerja</div></ModalHeader>
                    <ModalBody className='p-4'>
                        <div className='row mb-2'>
                            <div className='col-md-3'>
                                <label className='form-label' style={style_add.label_input}>Nama jam kerja</label>
                                <input className='form-control' type='text' name='nama_shift' onChange={this.handleChange} value={this.state.nama_shift} style={style_add.input} placeholder='Ketik nama jam kerja disini...' />
                            </div>
                            <div className='col-md-5'>
                                <label className='form-label' style={style_add.label_input}>Toleransi keterlambatan</label>
                                <div className='input-group'>
                                    <input className='form-control' type='number' value={this.state.toleransi_keterlambatan} onChange={this.handleChange} name='toleransi_keterlambatan' style={style_add.input} placeholder='Ketik toleransi keterlambatan...' />
                                    <div className='input-group-append'>
                                        <span className='input-group-text' style={style_add.input_group_text}>Menit</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'></div>
                        </div>
                        <div className='row pl-2 mt-4'>
                            <div className='col-md-5 px-2'>
                                <div className='card border'>
                                    <div className='card-header p-0 border' style={{ backgroundColor: "#FAFDFF" }}>
                                        <div className='row m-0'>
                                            <div className='col-md-12 d-flex flex-nowrap' style={style_add.card_header}>
                                                <input type='checkbox' checked={this.state.hari} name='hari' id='hari' onChange={this.handleCheckboxAdd} />
                                                <label for='hari' style={{
                                                    padding: "0px 8px",
                                                    margin: "0px",
                                                    fontWeight: 500,
                                                    fontSize: "16px",
                                                    lineHeight: "24px",
                                                    color: "#143B5E"
                                                }}>Hari</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body' style={style_add.card_body}>
                                        <div className='row'>
                                            <div className='col-md-12' style={style_add.column_card_body}>
                                                <div className='row d-flex flex-nowrap'>
                                                    <div className='col-md-6 d-flex flex-column flex-nowrap'>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.senin === true} onChange={this.handleCheckboxAdd} name='senin' id='senin' />
                                                            <label className='px-1' for="senin" style={style_add.hari}>
                                                                Senin
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.selasa === true} name='selasa' id='selasa' value={this.state.selasa} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="selasa" style={style_add.hari}>
                                                                Selasa
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.rabu === true} name='rabu' id='rabu' value={this.state.rabu} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="rabu" style={style_add.hari}>
                                                                Rabu
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.kamis === true} name='kamis' id='kamis' value={this.state.kamis} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="kamis" style={style_add.hari}>
                                                                Kamis
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 d-flex flex-column flex-nowrap'>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.jumat === true} name='jumat' id='jumat' value={this.state.jumat} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="jumat" style={style_add.hari}>
                                                                Jumat
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.sabtu === true} name='sabtu' id='sabtu' value={this.state.sabtu} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="sabtu" style={style_add.hari}>
                                                                Sabtu
                                                            </label>
                                                        </div>
                                                        <div className='d-flex justify-content-start py-1'>
                                                            <input type='checkbox' checked={this.state.minggu === true} name='minggu' id='minggu' value={this.state.minggu} onChange={this.handleCheckboxAdd} />
                                                            <label className='px-1' for="minggu" style={style_add.hari}>
                                                                Minggu
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5 px-2'>
                                <div className='card border' style={{ minHeight: "200px" }}>
                                    <div className='card-header p-0 border' style={{ backgroundColor: "#FAFDFF" }}>
                                        <div className='row d-flex flex-nowrap m-0 px-3'>
                                            <div className='col-md-6 px-0' style={style_add.card_header}>Jam Masuk</div>
                                            <div className='col-md-6 px-0' style={style_add.card_header}>Jam Keluar</div>
                                        </div>
                                    </div>
                                    <div className='card-body' style={style_add.card_body}>
                                        <div className='row'>
                                            <div className='col-md-12 pl-3' style={style_add.column_card_body}>
                                                <div className='row d-flex flex-nowrap'>
                                                    <div className='col-md-6' style={style_add.column_card_body}>
                                                        <TimeInput
                                                            style={style_add.time_input}
                                                            name='jam_masuk'
                                                            className='s-input -time form-control'
                                                            initTime={this.state.jam_masuk}

                                                            onTimeChange={(event) => {
                                                                if (event.length === 5) {
                                                                    this.setState({
                                                                        jam_masuk: event + ":00"
                                                                    })
                                                                } else if (event.length === 0) {
                                                                    this.setState({
                                                                        jam_masuk: event
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='col-md-6 pl-0' style={style_add.column_card_body}>
                                                        <TimeInput
                                                            name="jam_keluar"
                                                            style={style_add.time_input}
                                                            className='s-input -time form-control'
                                                            initTime={this.state.jam_keluar}

                                                            onTimeChange={(event) => {
                                                                if (event.length === 5) {
                                                                    this.setState({
                                                                        jam_keluar: event + ":00"
                                                                    })
                                                                } else if (event.length === 0) {
                                                                    this.setState({
                                                                        jam_keluar: event
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{
                        backgroundColor: "#F9F9F9",
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px"
                    }} className='px-4 py-3'>
                        <button className='btn text-capitalize batalkan-add-jamker' onClick={() => this.setState({
                            modalEdit: false,
                            nama_shift: "",
                            toleransi_keterlambatan: 0,
                            hari: false,
                            senin: false,
                            selasa: false,
                            rabu: false,
                            kamis: false,
                            jumat: false,
                            sabtu: false,
                            minggu: false,
                            jam_masuk: "",
                            jam_keluar: "",
                            hari_jam_kerja: []
                        })}>Batalkan</button>
                        <button className='btn btn-primary text-capitalize' onClick={() => this.updateJamKerja()} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>Simpan</button>
                    </ModalFooter>
                </Modal>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12 px-0'>
                            <div className='card border'>
                                <div className='card-header border-bottom' style={style.card_header}>
                                    Jam Kerja
                                </div>
                                <div className='card-body' style={{ padding: "24px" }}>
                                    <div className='row mb-3'>
                                        <div className='col-md-5 pr-1'>
                                            <input type='text' onChange={this.handleChange} name='filter_nama' value={this.state.filter_nama} className='form-control' placeholder='Cari nama jam kerja ...' style={style.filter_nama} onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.loadData()
                                                }
                                            }}></input>
                                        </div>
                                        <div className='col-md-1 tombol-refresh'>
                                            <button className='hover-pointer' style={style.button_refresh}><img alt="" src={RefreshIcon} onClick={(e) => {
                                                this.setState({
                                                    filter_nama: ""
                                                }, () => this.loadData())
                                            }} /></button>
                                        </div>
                                        <div className='col-md'>
                                            <button onClick={() => this.setState({ modalAdd: true })} className='btn btn-primary text-capitalize pull-right button-w-full'><img className='mr-2' alt='' src={PlusWhiteIcon} />Tambah Jam Kerja</button>
                                        </div>
                                    </div>
                                    {this.state.data.length !== 0 ?
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='table-responsive'>
                                                    <table className='table table-striped table-borderless'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B"
                                                                }}>#</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "126px"
                                                                }}>Nama</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "126px"
                                                                }}>Jadwal Kerja</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "126px"
                                                                }}>Jam Kerja</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "196px"
                                                                }} maxWidth={"100px"}>Toleransi Keterlambatan</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "96px"
                                                                }}>Status</th>
                                                                <th style={{
                                                                    borderBottom: "0px",
                                                                    padding: "16px 12px",
                                                                    fontWeight: 500,
                                                                    fontSize: "14px",
                                                                    lineHeight: "24px",
                                                                    color: "#252B2B",
                                                                    minWidth: "136px"
                                                                }}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.data.map((item, index) => {
                                                                no += 1
                                                                return (
                                                                    <tr>
                                                                        <td style={style.td_style}>{no}</td>
                                                                        <td style={style.td_style}>{item.sift_name}</td>
                                                                        <td style={style.td_style}>{item.jam_kerja.length} hari kerja</td>
                                                                        <td style={style.td_style}>{item.jamkerja.jam_masuk !== "" ? item.jamkerja.jam_masuk.slice(0, 5) : ""} - {item.jamkerja.jam_keluar !== "" ? item.jamkerja.jam_keluar.slice(0, 5) : ""}</td>
                                                                        <td style={style.td_style}>{item.sift_late_tolerance} menit</td>
                                                                        <td style={style.td_style} className='d-flex flex-nowrap'>
                                                                            <Switch onChange={(e) => this.setState({ status: e, shift_id: item.sift_id }, () => this.updateStatus())} defaultChecked={item.sift_status === "1"}
                                                                            /> {item.sift_status === "1" ? <div className='flex-fill pl-1' style={{ color: "#057CE4" }}>Aktif</div> : <div className='flex-fill pl-1'>Non-Aktif</div>}
                                                                        </td>
                                                                        <td style={style.td_style}>
                                                                            <>
                                                                                <a className="mr-3" onClick={() => this.setState({ shift_id: item.sift_id }, () => this.byIdJamKerja())}>
                                                                                    <img src={PencilIcon} alt='' />
                                                                                </a>
                                                                                <a onClick={() => this.setState({ shift_id: item.sift_id }, () => {
                                                                                    Swal.fire({
                                                                                        title: 'Apakah anda yakin?',
                                                                                        text: "Anda tidak dapat mengembalikan data ini setelah dihapus!",
                                                                                        icon: 'warning',
                                                                                        showCancelButton: true,
                                                                                        confirmButtonColor: '#3085d6',
                                                                                        cancelButtonColor: '#d33',
                                                                                        confirmButtonText: 'Hapus',
                                                                                        cancelButtonText: "Batal"
                                                                                    }).then((result) => {
                                                                                        if (result.isConfirmed) {
                                                                                            this.deleteJamKerja()
                                                                                        }
                                                                                    })
                                                                                })}>
                                                                                    <img src={TrashIcon} alt='' />
                                                                                </a>
                                                                            </>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className='row' style={{ width: "100%" }}>
                                            <div className='col' style={{ textAlign: "center" }}>
                                                <img src={EmptyDataImage} className="ipresens-image-empty" alt="icon data kosong" />
                                                <p className='mb-3'>{i18n.t("datakosong")}...</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='card-footer p-0'>
                                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                        {/* {this.state.data.length !== 0 && */}
                                        <>
                                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.totalPerpage === 0 || this.state.totalPerpage === null ? "0" : this.state.totalPerpage} {i18n.t("data")} {this.state.totalData} {i18n.t("data")}</div>
                                            <div className='col'>
                                                <ReactPaginate
                                                    previousLabel={<img src={ArrowLeft} alt='' />}
                                                    nextLabel={<img src={ArrowRight} alt='' />}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link custom-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link custom-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link custom-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link custom-prev-next-link'}
                                                    activeClassName={'active custom-active'}
                                                />
                                                {/* catatan.
                                                custom classname pagination dipanggil dari file css di folder absensi harian
                                                ga hanya pagination, semua classname dari css absensi harian bisa dipanggil file ini */}
                                            </div>
                                        </>
                                        {/* // } */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})


export default connect(mapStateToProps, mapDispatchToProps)(ShiftJamKerja);
