// Local
// export const API_HOST = "http://localhost:8000/"
// export const MAIN_HOST = 'http://localhost:8000/'
// export const BACKEND_API_HOST = "http://localhost:8000/backend/"
// export const IMG_HOST       = "http://localhost:8000/media/"
// export const DEFAULT_IMG    = MAIN_HOST + 'media/logo-default-user.png'

// export const API_TRACKING_HOST = "http://localhost:8080/"
// export const MAIN_TRACKING_HOST = 'http://localhost:8080/'
export const BACKEND_API_TRACKING_HOST = "http://localhost:8080/backend/"

//Development
// export const API_HOST = "https://servicesdev.presensi.co.id/"
// export const MAIN_HOST = "https://servicesdev.presensi.co.id/"
// export const BACKEND_API_HOST = "https://servicesdev.presensi.co.id/backend/"
// export const IMG_HOST = "https://servicesdev.presensi.co.id/media/"
// export const DEFAULT_IMG = MAIN_HOST + "media/logo-default-user.png"

// Production
// export const API_HOST           = "https://services.presensi.co.id/"
// export const MAIN_HOST          = "https://services.presensi.co.id/"
// export const BACKEND_API_HOST   = "https://services.presensi.co.id/backend/"
// export const IMG_HOST           = "https://services.presensi.co.id/media/"
// export const DEFAULT_IMG        = MAIN_HOST + "media/logo-default-user.png"

// Postgre Development
export const API_HOST           = "https://api.ipresens.com"
export const MAIN_HOST          = "https://api.ipresens.com"
export const BACKEND_API_HOST   = "https://api.ipresens.com/backend/"
export const IMG_HOST           = "https://api.ipresens.com/media/"
export const DEFAULT_IMG        = MAIN_HOST + "media/logo-default-user.png"

export const THUMB_HOST = MAIN_HOST + 'media/image/thumb/'

export const APIKEY = 'AIzaSyAn3PgsveGi-bW-JH6YofGEoxk3UJwZr3E'
export const GOOGLECLOUDKEY = 'AIzaSyDLiF_vebUPQLa-4ayyovMQ0qSUrp5mNF4'
export const GOOGLECLIENTID = "37963463790-lhddd8cb7a6pkek5ubetmrkj06lfvod3.apps.googleusercontent.com"
