import React, { useState } from 'react';
import './lang_switcher.css'; // Make sure to import or copy your styles

const LanguageSwitcher = ({ currentLanguage, onChange }) => {
    const [isChecked, setIsChecked] = useState(currentLanguage.slice(0, 2) === 'en');

    const handleToggle = () => {
        setIsChecked(!isChecked);
        const newLanguage = isChecked ? 'id' : 'en';
        onChange(newLanguage);
        window.location.reload();
    };

    return (
        <center>
            <div className="lang-switch">
                <input
                    id="language-toggle"
                    className="check-toggle check-toggle-round-flat"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleToggle}
                />
                <label htmlFor="language-toggle"></label>
                <span className="on">🇮🇩ID</span>
                <span className="off">🇺🇸EN </span>
            </div>
        </center>
    );
};

export default LanguageSwitcher;
