import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import {Empty, Spin, Tooltip, Modal} from 'antd';
import {BACKEND_API_HOST} from "../../helper/host";
import Edit from './edit'
import Add from "./add";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import Switch from "react-switch";
import { toast } from "react-toastify";
import "../../assets/css/style.css"
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import ModalDefault from '../menu-guide/modal-default';
import EmptyDataImage from "../../assets/images/data-kosong.png";
import RefreshIcon from "../../assets/icons/refresh.svg";
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import moment from "moment/moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";
import i18n from "i18next";

export class ListShift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            idDel: '',
            isLoading: true,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination: 2,
            sift_name: '',
            sift_late_tolerance: '',
            sift_id: '',
            nama_jabatan: '',
            filter_nama: '',
            add_visible: false,
            visable: false,
            guide: false,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            confirm_modal_default: false,
            importFile: null,
            modalimport: false,
            modalHistory: false,
            dataHistory: [],
            loadingHistory: false,
            currentPageHistory: 0
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData();
        });
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            isLoading: true
        }, () => this.loadData())
    }


    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        isLoading: true,
                        data: []
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("shift/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.props.set_auth(this.props.api_token, response.perusahaan);
                                this.loadData()
                                this.loadCheck()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("shift/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        isLoading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }
    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan,
                        jam_kerja_check: response.jam_kerja
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }

    exportShift = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'shift/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'shift/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.sift_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("shift/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            sift_id: ''
                        });
                    }
                });
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-shift',
                content: 'Anda dapat membuat Shift disini',
            },
            {
                selector: '.daftar-shift',
                content: 'Daftar Shift anda dapat dilihat disini',
            },
        ];
        const style = {
            card_header: {
                backgroundColor: "#FAFDFF",
                color: "#143B5E",
                padding: "12px 24px",
                fontSize: "24px",
                lineHeight: "36px",
                fontWeight: 700
            },
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            card_header: {
                backgroundColor: "#FAFDFF",
                padding: "10px 16px",
                color: "#143B5E",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px"
            },
            card_body: {
                backgroundColor: "#F9F9F9",
                padding: "12px 16px"
            },
            column_card_body: {
                backgroundColor: "#F9F9F9",
                textAlign: "center"
            },
            hari: {
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px"
            },
            time_input: {
                padding: "12px",
                fontSize: "14px",
                color: "#252B2B",
                backgroundColor: "#FFFFFF",
                lineHeight: "16px",
                width: "88px"
            }
        }
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Shift") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Shift - iPresens</title>
                        <meta name="description" content="Daftar Shift" />
                    </Helmet>
                    <Edit
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                        loadCheck={() => this.loadCheck()}
                    />
                    <Breadcrumb parent={i18n.t("manajemenshift")} title={i18n.t("daftarshift")} setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        jam_kerja={this.state.jam_kerja_check}
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                    />
                    <Modal
                        title="Import Shift"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={true}><i className="fa fa-refresh fa-spin"></i> Sedang mengupload data</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                            <a onClick={this.exportShift} style={{ color: "#057CE4" }}>Unduh template disini</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                            <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>Aktivitas</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px', paddingBottom: '16', paddingRight: '12px', paddingLeft: '12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Dibuat
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Diubah
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.sift_name != item.sift_name && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Nama Shift: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.sift_name
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.sift_name ? item.sift_name : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.sift_late_tolerance != item.sift_late_tolerance && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Toleransi Telat: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.sift_late_tolerance
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.sift_late_tolerance ? item.sift_late_tolerance : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>
                    {/*<div className='container-fluid'>*/}
                    {/*    <div className='row'>*/}
                    {/*        <div className='col-md-12 px-0'>*/}
                    {/*            <div className='card border'>*/}
                    {/*                <div className='card-body' style={{ padding: "24px" }}>*/}
                    {/*                    <div className='row mb-3'>*/}
                    {/*                        <div className='col-md-5 pr-1'>*/}
                    {/*                            <input type='text' onChange={this.handleChange} name='filter_nama' value={this.state.filter_nama} className='form-control' placeholder='Cari nama jam kerja ...' style={style.filter_nama} onKeyDown={(e) => {*/}
                    {/*                                if (e.key === "Enter") {*/}
                    {/*                                    this.loadData()*/}
                    {/*                                }*/}
                    {/*                            }}></input>*/}
                    {/*                        </div>*/}
                    {/*                        <div className='col-md-1 tombol-refresh'>*/}
                    {/*                            <button className='hover-pointer' style={style.button_refresh}><img alt="" src={RefreshIcon} onClick={(e) => {*/}
                    {/*                                this.setState({*/}
                    {/*                                    filter_nama: ""*/}
                    {/*                                }, () => this.loadData())*/}
                    {/*                            }} /></button>*/}
                    {/*                        </div>*/}
                    {/*                        <div className='col-md'>*/}
                    {/*                            <button onClick={() => this.setState({ modalAdd: true })} className='btn btn-primary text-capitalize pull-right button-w-full'><img className='mr-2' alt='' src={PlusWhiteIcon} />Tambah Jam Kerja</button>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                    <div className='row'>*/}
                    {/*                        <div className='col-md-12'>*/}
                    {/*                            <div className='table-responsive'>*/}
                    {/*                                <table className='table table-striped table-borderless'>*/}
                    {/*                                    <thead>*/}
                    {/*                                    <tr>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B"*/}
                    {/*                                        }}>#</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "126px"*/}
                    {/*                                        }}>Nama</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "126px"*/}
                    {/*                                        }}>Jadwal Kerja</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "126px"*/}
                    {/*                                        }}>Jam Kerja</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "196px"*/}
                    {/*                                        }} maxWidth={"100px"}>Toleransi Keterlambatan</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "96px"*/}
                    {/*                                        }}>Status</th>*/}
                    {/*                                        <th style={{*/}
                    {/*                                            borderBottom: "0px",*/}
                    {/*                                            padding: "16px 12px",*/}
                    {/*                                            fontWeight: 500,*/}
                    {/*                                            fontSize: "14px",*/}
                    {/*                                            lineHeight: "24px",*/}
                    {/*                                            color: "#252B2B",*/}
                    {/*                                            minWidth: "136px"*/}
                    {/*                                        }}></th>*/}
                    {/*                                    </tr>*/}
                    {/*                                    </thead>*/}
                    {/*                                    <tbody>*/}
                    {/*                                    {this.state.loadingList ?*/}
                    {/*                                        <tr>*/}
                    {/*                                            <td colSpan={7} style={{ textAlign: "center" }}>*/}
                    {/*                                                <Spin size="large" />*/}
                    {/*                                            </td>*/}
                    {/*                                        </tr>*/}
                    {/*                                        :*/}
                    {/*                                        this.state.data.length === 0 ?*/}
                    {/*                                            <tr>*/}
                    {/*                                                <td colSpan={7} style={{ textAlign: "center", backgroundColor: "#FFFFFF" }}>*/}
                    {/*                                                    <img src={EmptyDataImage} alt="icon data kosong" />*/}
                    {/*                                                    <p className='mb-2'>Data Kosong...</p>*/}
                    {/*                                                </td>*/}
                    {/*                                            </tr>*/}
                    {/*                                            :*/}
                    {/*                                            this.state.data.map((item, index) => {*/}
                    {/*                                                no += 1*/}
                    {/*                                                return (*/}
                    {/*                                                    <tr>*/}
                    {/*                                                        <td style={style.td_style}>{no}</td>*/}
                    {/*                                                        <td style={style.td_style}>{item.sift_name}</td>*/}
                    {/*                                                        <td style={style.td_style}>{item.jam_kerja.length} hari kerja</td>*/}
                    {/*                                                        <td style={style.td_style}>{item.jam_kerja.length} hari kerja</td>*/}
                    {/*                                                        /!*<td style={style.td_style}>{item.jamkerja.jam_masuk !== "" ? item.jamkerja.jam_masuk.slice(0, 5) : ""} - {item.jamkerja.jam_keluar !== "" ? item.jamkerja.jam_keluar.slice(0, 5) : ""}</td>*!/*/}
                    {/*                                                        <td style={style.td_style}>{item.sift_late_tolerance} menit</td>*/}
                    {/*                                                        <td style={style.td_style} className='d-flex flex-nowrap'>*/}
                    {/*                                                            <Switch onChange={(e) => this.setState({ status: e, shift_id: item.sift_id }, () => this.updateStatus())} defaultChecked={item.sift_status === "1"}*/}
                    {/*                                                            /> {item.sift_status === "1" ? <div className='flex-fill pl-1' style={{ color: "#057CE4" }}>Aktif</div> : <div className='flex-fill pl-1'>Non-Aktif</div>}*/}
                    {/*                                                        </td>*/}
                    {/*                                                        <td style={style.td_style}>*/}
                    {/*                                                            <>*/}
                    {/*                                                                <a className="mr-3" onClick={() => this.setState({ shift_id: item.sift_id }, () => this.byIdJamKerja())}>*/}
                    {/*                                                                    <img src={PencilIcon} alt='' />*/}
                    {/*                                                                </a>*/}
                    {/*                                                                <a onClick={() => this.setState({ shift_id: item.sift_id }, () => {*/}
                    {/*                                                                    Swal.fire({*/}
                    {/*                                                                        title: 'Apakah anda yakin?',*/}
                    {/*                                                                        text: "Anda tidak dapat mengembalikan data ini setelah dihapus!",*/}
                    {/*                                                                        icon: 'warning',*/}
                    {/*                                                                        showCancelButton: true,*/}
                    {/*                                                                        confirmButtonColor: '#3085d6',*/}
                    {/*                                                                        cancelButtonColor: '#d33',*/}
                    {/*                                                                        confirmButtonText: 'Hapus',*/}
                    {/*                                                                        cancelButtonText: "Batal"*/}
                    {/*                                                                    }).then((result) => {*/}
                    {/*                                                                        if (result.isConfirmed) {*/}
                    {/*                                                                            this.deleteJamKerja()*/}
                    {/*                                                                        }*/}
                    {/*                                                                    })*/}
                    {/*                                                                })}>*/}
                    {/*                                                                    <img src={TrashIcon} alt='' />*/}
                    {/*                                                                </a>*/}
                    {/*                                                            </>*/}
                    {/*                                                        </td>*/}
                    {/*                                                    </tr>*/}
                    {/*                                                )*/}
                    {/*                                            })*/}
                    {/*                                    }*/}
                    {/*                                    </tbody>*/}
                    {/*                                </table>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className='card-footer p-0'>*/}
                    {/*                    <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>*/}
                    {/*                        /!* {this.state.data.length !== 0 && *!/*/}
                    {/*                        <>*/}
                    {/*                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data</div>*/}
                    {/*                            <div className='col'>*/}
                    {/*                                <ReactPaginate*/}
                    {/*                                    previousLabel={<img src={ArrowLeft} alt='' />}*/}
                    {/*                                    nextLabel={<img src={ArrowRight} alt='' />}*/}
                    {/*                                    pageCount={this.state.pageCount}*/}
                    {/*                                    forcePage={this.state.currentPage}*/}
                    {/*                                    marginPagesDisplayed={2}*/}
                    {/*                                    pageRangeDisplayed={3}*/}
                    {/*                                    onPageChange={this.handlePageClick}*/}
                    {/*                                    breakClassName={'page-item'}*/}
                    {/*                                    breakLinkClassName={'page-link custom-page-link'}*/}
                    {/*                                    containerClassName={'pagination my-2'}*/}
                    {/*                                    pageClassName={'page-item'}*/}
                    {/*                                    pageLinkClassName={'page-link custom-page-link'}*/}
                    {/*                                    previousClassName={'page-item'}*/}
                    {/*                                    previousLinkClassName={'page-link custom-prev-next-link'}*/}
                    {/*                                    nextClassName={'page-item'}*/}
                    {/*                                    nextLinkClassName={'page-link custom-prev-next-link'}*/}
                    {/*                                    activeClassName={'active custom-active'}*/}
                    {/*                                />*/}
                    {/*                                /!* catatan.*/}
                    {/*                            custom classname pagination dipanggil dari file css di folder absensi harian*/}
                    {/*                            ga hanya pagination, semua classname dari css absensi harian bisa dipanggil file ini *!/*/}
                    {/*                            </div>*/}
                    {/*                        </>*/}
                    {/*                        /!* // } *!/*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card profile-card">
                                    <div className="card-header pb-0 px-3" style={{borderBottom: 'none', padding: "20px"}}>
                                        <div className="row mx-0" style={{marginLeft: '5px'}}>
                                            <div className="col-md-6 col-srch-shift mb-2">
                                                <div className="input-group btn-srch-shift">
                                                    <input name="filter_nama"
                                                           className="form-control mr-2 input-srch-shift"
                                                           placeholder={i18n.t("namashift") + "..."}
                                                           type="text"
                                                           value={this.state.filter_nama}
                                                           onChange={this.handleChange}
                                                           onKeyUp={(event) => {
                                                               event.preventDefault()
                                                               if (event.which == 13) {

                                                                   this.setState({
                                                                       page: 0,
                                                                       paging: this.state.paging,
                                                                       isLoading: true
                                                                   }, () => {
                                                                       this.loadData()
                                                                   });
                                                               }
                                                           }}/>
                                                    <button
                                                        className="btn ipresens-btn-reset-filter btn-refresh-shift"
                                                        onClick={this.resetFilter}>
                                                        <img src={RefreshIcon} alt=''/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col"></div>
                                            <div className='col-auto px-0 mb-4' style={{textAlign: "right"}}>
                                                <button className="ipresens-import-button btn-import-krywn"
                                                        onClick={() => {
                                                            this.setState({modalimport: true})
                                                        }}><img src={ImportIcon} alt=''/> Import
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <a className="btn ipresens-btn-color tambah-shift btn-add-shift"
                                                   style={{lineHeight: "23px"}}
                                                   onClick={() => {
                                                       this.setState({
                                                           add_visible: true
                                                       })
                                                   }}><i className={"fa fa-plus"}></i> {i18n.t("tambahshift")}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body daftar-shift">
                                        {!this.state.isLoading && this.state.data.length === 0 ?
                                            <Empty description={i18n.t("datakosong")}
                                                   image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                            :
                                            <div>
                                                <Table striped className="mb-5">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="30">#</Th>
                                                            <Th >Shift</Th>
                                                            <Th >{i18n.t('toleransitelat')}</Th>
                                                            <Th className={"text-center"}>Status</Th>
                                                            <Th className={"text-center"} width="200">{i18n.t('aksi')}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.data.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <Tr className="mt-2 mb-2 border-bottom-light">
                                                                    <small>
                                                                        <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                    </small>
                                                                    <Td>{item.sift_name.toUpperCase()}</Td>
                                                                    <Td>{item.sift_late_tolerance} {i18n.t('menit')}</Td>
                                                                    <Td className="item-center" align={"center"}>
                                                                        <Switch
                                                                            id={`detail-${item.sift_id}`}
                                                                            name="sift_status"
                                                                            checked={
                                                                                item.sift_status === '1' ? true : false
                                                                            }
                                                                            onChange={(val) => {
                                                                                this.setState({
                                                                                    loading: true,
                                                                                    data: []
                                                                                })

                                                                                let formData = {
                                                                                    id: item.sift_id,
                                                                                }

                                                                                this.props.request('shift/ubah-status', formData, 'POST')
                                                                                    .then((response) => {
                                                                                        if (response.success) {
                                                                                            Swal.fire({
                                                                                                title: 'Berhasil!',
                                                                                                text: response.message,
                                                                                                icon: 'success',
                                                                                                showConfirmButton: false,
                                                                                                timer: 1500,
                                                                                            })
                                                                                        } else {
                                                                                            if (Array.isArray(response.message)) {
                                                                                                response.message.map(item => {
                                                                                                    toast['error'](item)
                                                                                                    return true
                                                                                                })
                                                                                            } else {
                                                                                                toast['error'](response.message)
                                                                                            }
                                                                                        }
                                                                                        this.loadData()
                                                                                        this.setState({ loading: false })
                                                                                    })
                                                                            }}
                                                                            onColor="#8c2bee"
                                                                            offColor="#eb4034"
                                                                            handleDiameter={18}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                            height={24}
                                                                            width={48}
                                                                            className="mr-1"
                                                                        />
                                                                        <span className="mb-2" style={{ color: item.sift_status === '1' ? "#057CE4" : "#5D6F80" }}>{item.sift_status === '1' ? i18n.t('aktif') : i18n.t('nonaktif')}</span>
                                                                    </Td>
                                                                    <Td style={{ border: "none" }} align="center">
                                                                        <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                                            <div className={'text-dark'}>
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        modalHistory: true,
                                                                                        sift_id: item.sift_id
                                                                                    },()=>{
                                                                                        this.loadHistory(item.sift_id);
                                                                                    })
                                                                                }}>{i18n.t("riwayat")}</a><br/>
                                                                                <a className={'text-dark'} onClick={() => {
                                                                                    this.setState({
                                                                                        sift_id: item.sift_id,
                                                                                        sift_name: item.sift_name,
                                                                                        sift_late_tolerance: item.sift_late_tolerance,
                                                                                        visable: true
                                                                                    })
                                                                                }}>Edit</a><br />
                                                                                <a className={'text-danger'} onClick={() => {
                                                                                    this.setState({
                                                                                        idDel: item.sift_id
                                                                                    })
                                                                                    this.wantDelete()
                                                                                }}>{i18n.t("hapus")}</a>
                                                                            </div>
                                                                        }>
                                                                            <a><i
                                                                                className="fa fa-ellipsis-h" style={{
                                                                                width: 35,
                                                                                fontSize: 18,
                                                                                padding: 11,
                                                                                color: 'rgb(51 51 51)'
                                                                            }}
                                                                            ></i></a>
                                                                        </Tooltip>
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })}
                                                        {this.state.isLoading &&
                                                            <Tr>
                                                                <Td colSpan="3">
                                                                    <Spin style={{ width: '100%' }} size="small" tip="Mohon Tunggu..." />
                                                                </Td>
                                                            </Tr>
                                                        }
                                                    </Tbody>
                                                </Table>
                                                <Paging
                                                    className="float-right"
                                                    handlePageClick={this.handlePageClick}
                                                    pageCount={this.state.pageCount}
                                                    currentPage={this.state.currentPage}
                                                />
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListShift);
