import React, { Component } from 'react'
import { set_auth } from "../../reducers/Auth";
import '../home/landing.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css"
import i18n from "i18next";
import InfoIcon from "../../assets/icons/info-icon-green.svg";
import CrossMarkIcon from "../../assets/icons/cross-mark.svg";

export class hapusKaryawan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hideStep: false,
            auth: this.props.auth,
            message: "",
            email: '',
            invalidEmail: false,
            messageEmail: "",
            time: {},
            seconds: 30,
            timer: 0,
            messageFailed: {
                header: "",
                value: ""
            },
            messageSuccess: {
                header: "",
                value: ""
            },
            secondsSuccess: 10
        }
        this.timer = 0
        this.verifyEmail = this.verifyEmail.bind(this);
        this.handleChange = this.handleChange.bind(this)
    }


    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;

        this.setState({
            [name]: value
        })
    }

    verifyEmail() {
        const form = {
            email: this.state.email
        }

        this.props.request("verifyHapus", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        messageSuccess: {
                            header: "Berhasil",
                            value: response.message
                        },
                        messageFailed: {
                            header: "",
                            value: ""
                        },
                    })
                    this.setState({
                        loading: false,
                        success: true,
                        message: response.message
                    })
                    setInterval(() => {
                        this.setState(state => {
                            return {
                                tick: state.tick -1
                            }
                        })
                    }, 1000)
                } else {
                    this.setState({
                        messageSuccess: {
                            header: "",
                            value: ""
                        },
                        messageFailed: {
                            header: "Gagal",
                            value: response.message
                        }
                    })
                    this.setState({
                        loading: false,
                        success: false,
                        message: response.message
                    })
                }
            });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({ auth: newProps.auth })
        }
    }

    render() {
        return(
            <main style={{ backgroundColor: "#F9F9F9", minHeight: "90vh", marginTop: "110px" }}>
                <div className='container'>
                    <div className='row'>
                        <div className='col'></div>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='card border'>
                                        <div className='card-header border py-2 px-3' style={{ backgroundColor: "#FAFDFF", fontSize: "18px", fontWeight: 700, color: "#143B5E", lineHeight: "24px", letterSpacing: "0px" }}>Hapus Karyawan</div>
                                        <div className="mt-4">
                                            {this.state.messageFailed.value !== "" &&
                                                <div className="row">
                                                    <div className="col-md-12 loginv2-row-form">
                                                        <div className="d-flex flex-row loginv2-failed">
                                                            <div>
                                                                <img alt="" src={CrossMarkIcon} style={{ width: "24px", height: "24px" }} />
                                                            </div>
                                                            <div className="pl-2">
                                                                <div style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 700,
                                                                    lineHeight: "18px"
                                                                }}>{this.state.messageFailed.header}</div>
                                                                <div>{this.state.messageFailed.value}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.messageSuccess.value !== "" &&
                                                <div className="row">
                                                    <div className="col-md-12 loginv2-row-form">
                                                        <div className="d-flex flex-row loginv2-success">
                                                            <div>
                                                                <img alt="" src={InfoIcon} style={{ width: "24px", height: "24px" }} />
                                                            </div>
                                                            <div className="pl-2">
                                                                <div style={{
                                                                    fontSize: "14px",
                                                                    fontWeight: 700,
                                                                    lineHeight: "18px"
                                                                }}>{this.state.messageSuccess.header}</div>
                                                                <div>{this.state.messageSuccess.value}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='card-body px-3 py-3' style={{ backgroundColor: "#FFFFFF" }}>
                                            <div style={{ fontSize: "12px" }}>
                                                <div className="form-group">
                                                    <label className="loginv2-label">Email Karyawan</label>
                                                    <input className={this.state.invalidEmail ? "form-control is-invalid loginv2-input" : "form-control loginv2-input"} placeholder="emailanda@gmail.com" name="email" onChange={this.handleChange} type="text" value={this.state.email} />
                                                    {this.state.invalidEmail &&
                                                        <div className="invalid-feedback loginv2-invalid">
                                                            {this.state.messageEmail}
                                                        </div>
                                                    }
                                                </div>
                                                <br/>
                                                <button style={{ borderRadius: "8px", paddingTop: "12px", paddingBottom: "12px", marginTop: "6px" }} className={"btn ipresens-btn-color btn-block text-capitalize"}
                                                        onClick={this.verifyEmail}
                                                >Verifikai Email</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col'></div>
                    </div>
                </div>
            </main>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default hapusKaryawan