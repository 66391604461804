import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Empty, Modal, Spin, Tooltip } from "antd";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import i18n from "i18next";
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import {APIKEY, BACKEND_API_HOST} from "../../helper/host";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import ModalDefault from '../menu-guide/modal-default';
import SearchIcon from "../../assets/icons/search.svg";
import FilterIcon from "../../assets/icons/filter.svg";
import PlusWhiteIcon from "../../assets/icons/plus-white.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import MapPicker from "react-google-map-picker";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import PencilIcon from "../../assets/icons/pencil.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";
import moment from "moment/moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";

export class List_lokasi_absen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            dataHistory: [],
            modal: false,
            idDel: '',
            filter_nama: '',
            filter_tipe: '',
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            pagination: 2,
            currentPageHistory: 0,
            guide: false,
            modalAdd: false,
            location: {
                lat: -7.865242,
                lng: 110.156070
            },
            nama_lokasi: "",
            tipe_lokasi: "pusat",
            alamat_lokasi: "",
            radius_lokasi: 100,
            shift_check: [],
            lokasi_check: [],
            divisi_check: [],
            karyawan_check: [],
            jam_kerja_check: [],
            confirm_modal_default: false,
            modalHistory: false,
            loadingHistory: false,
            importFile: null,
            modalimport: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadData())
    }
    showModal = (item) => {
        this.setState({
            modal: true,
            latitudeModal: item.latitude,
            longitudeModal: item.longitude,
        });
    };

    handleOk = e => {
        this.setState({
            modal: false,
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modal: false,
            modalimport: false
        });
    };

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            filter_tipe: '',
            absensiStartDate: '',
            absensiEndDate: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    saveLocation = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            nama: this.state.nama_lokasi,
            perusahaan_title: this.state.tipe_lokasi === '' ? 'pusat' : this.state.tipe_lokasi,
            perusahaan_alamat: this.state.alamat_lokasi,
            latitude: this.state.location.lat,
            longitude: this.state.location.lng,
            max_jarak: this.state.radius_lokasi
        }

        this.props.request("lokasi/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                modalAdd: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 0,
                                alamat_lokasi: ""
                            }, () => {
                                this.loadData()
                                this.loadCheck()
                            })
                        } else {
                            this.setState({
                                data: [],
                                modalAdd: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 0,
                                alamat_lokasi: ""
                            }, () => {
                                this.loadData()
                                this.loadCheck()
                            })
                        }
                    })
                    this.props.set_auth(this.props.api_token, response.perusahaan);
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    editLocation = () => {
        this.setState({
            modalEdit: false,
            loading: true,
        })
        let form = {
            id: this.state.id_lokasi,
            nama: this.state.nama_lokasi,
            perusahaan_title: this.state.tipe_lokasi === '' ? 'pusat' : this.state.tipe_lokasi,
            perusahaan_alamat: this.state.alamat_lokasi,
            latitude: this.state.location.lat,
            longitude: this.state.location.lng,
            max_jarak: this.state.radius_lokasi
        }
        this.props.request("lokasi/update", form, "POST")
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.setState({
                        data: [],
                        nama_lokasi: "",
                        location: {
                            lat: -7.865242,
                            lng: 110.156070
                        },
                        tipe_lokasi: "",
                        radius_lokasi: 100,
                        alamat_lokasi: ""
                    }, () => {
                        this.loadData()
                    })
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning'
                    })
                }
            })
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus lokasi presensi ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        data: [],
                        loading: true
                    })
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("lokasi/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.props.set_auth(this.props.api_token, response.perusahaan);
                                this.loadData()
                                this.loadCheck()
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                })
                            }
                        });
                }
            })
    }

    detailLokasi() {
        let form = {
            id: this.state.id_lokasi
        }
        this.props.request("lokasi/detail", form, "POST")
            .then((response) => {
                const data = response.data
                if (response.success) {
                    this.setState({
                        nama_lokasi: data.lokasi_nama,
                        radius_lokasi: data.max_jarak,
                        alamat_lokasi: data.perusahaan_alamat,
                        location: {
                            lng: data.longitude,
                            lat: data.latitude
                        },
                        tipe_lokasi: data.perusahaan_title,
                        modalEdit: true
                    })
                }
            })
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            filter_tipe: this.state.filter_tipe,
            paging: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("lokasi/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false,
                    })
                }
            });
    }

    loadCheck() {
        let form = {
            perusahaan_id: this.props.user.perusahaan_id
        }
        this.props.request("check-guide-data", form, "POST")
            .then((response) => {
                if (response.success) {
                    this.setState({
                        shift_check: response.shift,
                        lokasi_check: response.lokasi,
                        divisi_check: response.divisi,
                        karyawan_check: response.karyawan,
                        jam_kerja_check: response.jam_kerja
                    }, () => {
                        if (this.state.shift_check.length === 0 || this.state.lokasi_check.length === 0 || this.state.divisi_check.length === 0 || this.state.karyawan_check.length === 0 || this.state.jam_kerja_check.length === 0) {
                            this.setState({
                                confirm_modal_default: true
                            })
                        }
                    })
                }
            })
    }

    exportLokasiPresensi = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'lokasi/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'lokasi/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.lokasi_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("lokasi/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            lokasi_id: ''
                        });
                    }
                });
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadCheck()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-lokasi',
                content: 'Anda dapat menambah Lokasi Presensi disini',
            },
            {
                selector: '.daftar-pengumuman',
                content: 'Daftar Lokasi Presensi anda dapat dilihat disini',
            },
        ];
        const style = {
            button_refresh: {
                padding: "8px",
                border: "1px solid #DDDDDD",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF"
            },
            filter_nama: {
                backgroundColor: "#F9F9F9",
                padding: "12px 10px",
                color: "#5D6F80",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px"
            },
            th_style: {
                borderBottom: "0px",
                padding: "16px 12px",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B",
            },
            td_style: {
                padding: "16px 12px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "24px",
                color: "#252B2B"
            }
        }
        const style_add = {
            modal_header: {
                backgroundColor: "#FAFDFF",
                padding: "12px",
            },
            title_header: {
                color: "#143B5E",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "36px"
            },
            label_input: {
                color: "#143B5E",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "24px"
            },
            input_group_text: {
                backgroundColor: "#EFEFEF",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 500,
                padding: "12px"
            },
            input: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            select: {
                backgroundColor: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: "4px",
                padding: "12px",
                minHeight: "44px",
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400
            },
            hari: {
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#252B2",
                textAlign: "left",
                margin: "0px"
            },
        }
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Lokasi Absen") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>List Lokasi Presensi - iPresens</title>
                        <meta name="description" content="Detail Presensi" />
                    </Helmet>
                    <Modal visible={this.state.modalAdd} bodyStyle={{ maxWidth: "1000px", padding: 0 }} width={1000}
                           closeIcon={<></>}
                           footer={null}
                           onCancel={() => this.setState({
                               modalAdd: false,
                               nama_lokasi: "",
                               location: {
                                   lat: -7.865242,
                                   lng: 110.156070
                               },
                               tipe_lokasi: "",
                               radius_lokasi: 100,
                               alamat_lokasi: ""
                           })}>
                        <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                            modalAdd: false,
                            nama_lokasi: "",
                            location: {
                                lat: -7.865242,
                                lng: 110.156070
                            },
                            tipe_lokasi: "",
                            radius_lokasi: 100,
                            alamat_lokasi: ""
                        })}><div style={style_add.title_header}>Tambah Lokasi</div></ModalHeader>
                        <ModalBody className='p-4'>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <MapPicker
                                        key={Math.random}
                                        style={{ position: 'relative', width: '100%', height: '250px' }}
                                        name='location'
                                        defaultLocation={this.state.location}
                                        zoom={10}
                                        onChangeLocation={(lat, lng) => {
                                            this.setState({
                                                location: {
                                                    lat: lat,
                                                    lng: lng
                                                }
                                            })
                                        }}
                                        apiKey={APIKEY} />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4'>
                                    <div className='input-group form-add-lksabsn'>
                                        <div className='input-group-append'>
                                            <span className='input-group-field br-left-4'><small>Latitude</small></span>
                                        </div>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lat}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: parseFloat(value),
                                                        lng: this.state.location.lng
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='input-group form-add-lksabsn'>
                                        <div className='input-group-append'>
                                            <span className='input-group-field br-left-4'><small>Longitude</small></span>
                                        </div>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lng}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: this.state.location.lat,
                                                        lng: parseFloat(value)
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Nama Lokasi</label>
                                    <input className='form-control' type='text' name='nama_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.nama_lokasi} style={style_add.input} placeholder='Masukkan nama lokasi' />
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Tipe Lokasi</label>
                                    <select className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.tipe_lokasi} name="tipe_lokasi" style={style_add.select}>
                                        <option value="">Pilih Tipe Lokasi</option>
                                        <option value="pusat">Pusat</option>
                                        <option value="cabang">Cabang</option>
                                    </select>
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Radius</label>
                                    <div className='input-group form-add-lksabsn'>
                                        <input className='form-control' value={this.state.radius_lokasi} type='number' onChange={(e) => this.handleChange(e)} name='radius_lokasi' style={style_add.input} />
                                        <div className='input-group-append'>
                                            <span className='input-group-text' style={style_add.input_group_text}>Meter</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Alamat Lokasi</label>
                                    <input className='form-control' type='text' name='alamat_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.alamat_lokasi} style={style_add.input} placeholder='Masukkan alamat lengkap' />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button
                                className='btn ipresens-btn-cancel'
                                onClick={() => this.setState({
                                modalAdd: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 100,
                                alamat_lokasi: ""
                            })} style={{ padding: "8px 14px", fontWeight: 800, fontSize: "14px", lineHeight: "24px" }}>
                                Batalkan
                            </button>
                            <button
                                className='btn ipresens-btn-color'
                                onClick={() => this.saveLocation()}
                                disabled={this.state.prosesSimpan}
                                style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>
                                Tambahkan Lokasi
                            </button>
                        </ModalFooter>
                    </Modal>
                    <Modal visible={this.state.modalEdit} bodyStyle={{ maxWidth: "1000px", padding: 0 }} width={1000}
                           closeIcon={<></>}
                           footer={null}
                           onCancel={() => this.setState({
                               modalEdit: false,
                               nama_lokasi: "",
                               location: {},
                               tipe_lokasi: "",
                               radius_lokasi: 100,
                               alamat_lokasi: ""
                           })}>
                        <ModalHeader style={style_add.modal_header} toggle={() => this.setState({
                            modalEdit: false,
                            nama_lokasi: "",
                            location: {},
                            tipe_lokasi: "",
                            radius_lokasi: 100,
                            alamat_lokasi: ""
                        })}><div style={style_add.title_header}>Edit Lokasi</div></ModalHeader>
                        <ModalBody className='p-4'>
                            <div className='row mb-2'>
                                <div className='col-md-12'>
                                    <MapPicker
                                        style={{ position: 'relative', width: '100%', height: '250px' }}
                                        name='location'
                                        defaultLocation={this.state.location}
                                        zoom={10}
                                        onChangeLocation={(lat, lng) => {
                                            this.setState({
                                                location: {
                                                    lat: lat,
                                                    lng: lng
                                                }
                                            })
                                        }}
                                        apiKey={APIKEY} />
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <div className='col-md-4'></div>
                                <div className='col-md-4'>
                                    <div className='input-group form-add-lksabsn'>
                                        <div className='input-group-append'>
                                            <span className='input-group-field br-left-4'><small>Latitude</small></span>
                                        </div>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lat}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: parseFloat(value),
                                                        lng: this.state.location.lng
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='input-group form-add-lksabsn'>
                                        <div className='input-group-append'>
                                            <span className='input-group-field br-left-4'><small>Longitude</small></span>
                                        </div>
                                        <input
                                            className='form-control input-custom'
                                            value={this.state.location.lng}
                                            type='number'
                                            onChange={(event) => {
                                                const target = event.target;
                                                const value = target.value;
                                                this.setState({
                                                    location: {
                                                        lat: this.state.location.lat,
                                                        lng: parseFloat(value)
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Nama Lokasi</label>
                                    <input className='form-control' type='text' name='nama_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.nama_lokasi} style={style_add.input} placeholder='Masukkan nama lokasi' />
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Tipe Lokasi</label>
                                    <select className='form-control' onChange={(e) => this.handleChange(e)} value={this.state.tipe_lokasi} name="tipe_lokasi" style={style_add.select}>
                                        <option value="pusat">Pusat</option>
                                        <option value="cabang">Cabang</option>
                                    </select>
                                </div>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Radius</label>
                                    <div className='input-group form-add-lksabsn'>
                                        <input className='form-control' value={this.state.radius_lokasi} type='number' onChange={(e) => this.handleChange(e)} name='radius_lokasi' style={style_add.input} />
                                        <div className='input-group-append'>
                                            <span className='input-group-text' style={style_add.input_group_text}>Meter</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-md-4'>
                                    <label className='form-label' style={style_add.label_input}>Alamat Lokasi</label>
                                    <input className='form-control' type='text' name='alamat_lokasi' onChange={(e) => this.handleChange(e)} value={this.state.alamat_lokasi} style={style_add.input} placeholder='Masukkan alamat lengkap' />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{
                            backgroundColor: "#F9F9F9",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px"
                        }} className='px-4 py-3'>
                            <button className='btn text-capitalize ipresens-btn-cancel' onClick={() => this.setState({
                                modalEdit: false,
                                nama_lokasi: "",
                                location: {},
                                tipe_lokasi: "",
                                radius_lokasi: 100,
                                alamat_lokasi: ""
                            })} style={{ padding: 10 }}>Batalkan</button>
                            <button className='btn ipresens-btn-color text-capitalize' disabled={this.state.loading} onClick={() => this.editLocation()} style={{ padding: "8px 14px", fontWeight: 500, fontSize: "14px", lineHeight: "24px" }}>Edit Lokasi</button>
                        </ModalFooter>
                    </Modal>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false })}>
                            <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>Aktivitas</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px', paddingBottom: '16', paddingRight: '12px', paddingLeft: '12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Dibuat
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Diubah
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.lokasi_nama != item.lokasi_nama && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Nama Lokasi: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.lokasi_nama
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.lokasi_nama ? item.lokasi_nama : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.perusahaan_title != item.perusahaan_title && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Tipe Lokasi: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.perusahaan_title
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.perusahaan_title ? item.perusahaan_title : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.perusahaan_alamat != item.perusahaan_alamat && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Alamat Lokasi: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.perusahaan_alamat
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.perusahaan_alamat ? item.perusahaan_alamat : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.max_jarak != item.max_jarak && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Radius: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.max_jarak
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.max_jarak ? item.max_jarak : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.latitude != item.latitude && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Latitude: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.latitude
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.latitude ? item.latitude : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.longitude != item.longitude && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Longitude: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.longitude
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.longitude ? item.longitude : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.lokasi_status != item.lokasi_status && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Status Lokasi: Dari{' '}
                                                                                        <b>{item.lokasi_status === '1' ? 'Non-Aktif' : 'Aktif'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.lokasi_status === '1' ? 'Aktif' : 'Non-Aktif'}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>
                    <Breadcrumb title={i18n.t("daftarlokasipresensi")} parent={i18n.t("lokasipresensi")} parentLink="/presensi-location" setGuide={() => { this.setState({ guide: true }) }} />
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <ModalDefault
                        shift={this.state.shift_check}
                        lokasi={this.state.lokasi_check}
                        divisi={this.state.divisi_check}
                        karyawan={this.state.karyawan_check}
                        confirm_modal_default={this.state.confirm_modal_default}
                        setState={(name, val) => this.setState(name, val)}
                        history={this.props.history}
                        jam_kerja={this.state.jam_kerja_check}
                    />
                    <Modal
                        title="Import Lokasi Presensi"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={true}><i className="fa fa-refresh fa-spin"></i> Sedang mengupload data</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                            <a onClick={this.exportLokasiPresensi} style={{ color: "#057CE4" }}>Unduh template disini</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <div className='col-md-6 mb-2'>
                                        <div className='d-flex flex-row'>
                                            <div className='flex-fill pr-2'>
                                                <div className='input-group btn-srch-lksabsn' style={{height: "100%"}}>
                                                    <span className="input-group-text izin-search-karyawan-img"
                                                          id="basic-addon1">
                                                        <img alt='' src={SearchIcon}/>
                                                    </span>
                                                    <input type="text" name='filter_nama' onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            this.setState({
                                                                loading: true,
                                                                data: []
                                                            }, () => this.loadData())
                                                        }
                                                    }} value={this.state.filter_nama} onChange={this.handleChange}
                                                           className="form-control izin-search-karyawan-form"
                                                           placeholder={i18n.t("carinamakaryawan") + "..."}
                                                           aria-label="Username" aria-describedby="basic-addon1"/>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <button
                                                        className='btn primary ipresens-btn-reset-filter width-filter filter-button'
                                                        onClick={() => this.setState({filter: !this.state.filter})}><img
                                                        src={FilterIcon}/> Filter
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col"></div>
                                    <div className='col-auto px-0 mb-4' style={{textAlign: "right"}}>
                                        <button className="ipresens-import-button btn-import-krywn"
                                                onClick={() => {
                                                    this.setState({modalimport: true})
                                                }}><img src={ImportIcon} alt=''/> Import
                                        </button>
                                    </div>
                                    <div className='col-auto'>
                                        {!this.state.loading &&
                                            <>
                                                <button
                                                    className='btn ipresens-btn-color text-capitalize float-right izin-btn-w100'
                                                    onClick={() => {
                                                    this.setState({
                                                        modalAdd: true,
                                                        location: {
                                                            lat: -7.865242,
                                                            lng: 110.156070
                                                        },
                                                    })
                                                }}><img className='mr-2' alt=''
                                                        src={PlusWhiteIcon}/> {i18n.t("tambahlokasi")}</button>
                                            </>
                                        }
                                    </div>
                                </div>
                                {this.state.filter &&
                                    <div className='row mx-0'>
                                        <div className='col-md-2 px-1 mb-2'>
                                            <label className='form-label' style={{fontSize: "12px"}}>Status
                                                Lokasi</label>
                                            <select name="filter_tipe" className='form-control'
                                                    value={this.state.filter_tipe} style={{fontSize: "12px"}}
                                                    onChange={this.handleFilter}>
                                            <option value="">Pilih Status</option>
                                            <option value="pusat">Pusat</option>
                                            <option value="cabang">Cabang</option>
                                        </select>
                                    </div>
                                    <div className='col-auto px-1 d-flex align-items-end justify-content-end pb-2'>
                                        <button className='btn text-capitalize px-2 width-filter btn-reset-filter' onClick={
                                            () => {
                                                this.setState({
                                                    loading: true,
                                                    filter_nama: "",
                                                    filter_tipe: "",
                                                }, () => this.loadData())
                                            }
                                        }><img src={RefreshIcon} alt="icon refresh" /> Reset Filter</button>
                                    </div>
                                </div>
                                }
                            </div>
                            <div className="card-body daftar-lokasi">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className='table table-striped table-borderless'>
                                                <thead>
                                                <tr>
                                                    <th style={{
                                                        borderBottom: "0px",
                                                        padding: "16px 12px",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        lineHeight: "24px",
                                                        color: "#252B2B",
                                                        width: "30px"
                                                    }}>#</th>
                                                    <th style={{
                                                        borderBottom: "0px",
                                                        padding: "16px 12px",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        lineHeight: "24px",
                                                        color: "#252B2B",
                                                        minWidth: "250px",
                                                        maxWidth: "447px"
                                                    }}>{i18n.t("nama")}</th>
                                                    <th style={style.th_style}>{i18n.t("radius")}</th>
                                                    <th style={style.th_style}>{i18n.t("tipelokasi")}</th>
                                                    <th style={style.th_style}>{i18n.t("status")}</th>
                                                    <th style={{
                                                        borderBottom: "0px",
                                                        padding: "16px 12px",
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        lineHeight: "24px",
                                                        color: "#252B2B",
                                                        minWidth: "50px"
                                                    }}></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.loadingList ?
                                                    <tr>
                                                        <td colSpan={5} style={{ textAlign: "center" }}>
                                                            <Spin size="large" />
                                                        </td>
                                                    </tr>
                                                    :
                                                    this.state.data.length === 0 ?
                                                        <tr>
                                                            <td colSpan={5} style={{ textAlign: "center", backgroundColor: "#FFFFFF" }}>
                                                                <img src={EmptyDataImage} className="ipresens-image-empty" alt="icon data kosong" />
                                                                <p className='mb-2'>{i18n.t("datakosong")}...</p>
                                                            </td>
                                                        </tr>
                                                        :
                                                        this.state.data.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <tr>
                                                                    <td style={style.td_style}>{no}</td>
                                                                    <td style={style.td_style}>
                                                                        <div className='row m-0' style={{
                                                                            fontWeight: 700,
                                                                            fontSize: "14px",
                                                                            color: "#252B2B",
                                                                            lineHeight: "24px"
                                                                        }}>{item.lokasi_nama}</div>
                                                                    </td>
                                                                    <td style={style.td_style}>{item.max_jarak}m</td>
                                                                    <td className='text-capitalize' style={style.td_style}>{item.perusahaan_title}</td>
                                                                    <td className='text-capitalize' style={style.td_style}>
                                                                        <Switch
                                                                            id={`detail-${item.lokasi_id}`}
                                                                            name="lokasi_status"
                                                                            checked={
                                                                                item.lokasi_status === '1' ? true : false
                                                                            }
                                                                            onChange={(val) => {
                                                                                this.setState({
                                                                                    loading: true,
                                                                                    data: []
                                                                                })

                                                                                let formData = {
                                                                                    lokasi_id: item.lokasi_id,
                                                                                }

                                                                                this.props.request('lokasi/ubah_status', formData, 'POST')
                                                                                    .then((response) => {
                                                                                        if (response.success) {
                                                                                            Swal.fire({
                                                                                                title: 'Berhasil!',
                                                                                                text: response.message,
                                                                                                icon: 'success',
                                                                                                showConfirmButton: false,
                                                                                                timer: 1500,
                                                                                            })
                                                                                        } else {
                                                                                            if (Array.isArray(response.message)) {
                                                                                                response.message.map(item => {
                                                                                                    toast['error'](item)
                                                                                                    return true
                                                                                                })
                                                                                            } else {
                                                                                                toast['error'](response.message)
                                                                                            }
                                                                                        }
                                                                                        this.loadData()
                                                                                        this.setState({ loading: false })
                                                                                    })

                                                                            }}
                                                                            onColor="#8c2bee"
                                                                            offColor="#eb4034"
                                                                            handleDiameter={18}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                                            height={24}
                                                                            width={48}
                                                                            className="mr-1"
                                                                        />
                                                                    </td>
                                                                    <td className='text-center' style={style.td_style}>
                                                                        <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                                            <div className={'p-2'}>
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        modalHistory: true,
                                                                                        lokasi_id: item.lokasi_id
                                                                                    },()=>{
                                                                                        this.loadHistory(item.lokasi_id);
                                                                                    })
                                                                                }} className={'text-dark'}>Riwayat</a><br />
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        id_lokasi: item.lokasi_id
                                                                                    }, () => {
                                                                                        this.detailLokasi()
                                                                                    })
                                                                                }} className={'text-dark'}>Edit</a><br />
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        idDel: item.lokasi_id
                                                                                    })
                                                                                    this.wantDelete()
                                                                                }} className={'text-danger'}>Hapus</a>
                                                                            </div>
                                                                        }>
                                                                            <a><i
                                                                                className="fa fa-ellipsis-h" style={{
                                                                                width: 35,
                                                                                fontSize: 18,
                                                                                padding: 11,
                                                                                color: 'rgb(51 51 51)'
                                                                            }}
                                                                            ></i></a>
                                                                        </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer p-0'>
                                <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                    {/* {this.state.data.length !== 0 && */}
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} {i18n.t("dari")} {this.state.totalData} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCount}
                                                forcePage={this.state.currentPage}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageClick}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                            {/* catatan.
                                                custom classname pagination dipanggil dari file css di folder absensi harian
                                                ga hanya pagination, semua classname dari css absensi harian bisa dipanggil file ini */}
                                        </div>
                                    </>
                                    {/* // } */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: APIKEY
})(List_lokasi_absen));
