import React, {Component, Fragment} from 'react'
import Breadcrumb from './common/breadcrumb';
import {Helmet} from "react-helmet";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../assets/icons/arrow-left.svg";
import ArrowRight from "../assets/icons/arrow-right.svg"
import {Empty, Spin} from "antd";
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import {pageNumber} from "../functions/functionGeneral";
import i18n from "i18next";
import EmptyDataImage from "../assets/images/data-kosong.png"

export class HistoryAdminList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data                : [],
            history             : [],
            isLoading           : true,
            total               : 0,
            pagination          : 10,
            perPageHistory      : 0,
            currentPageHistory  : 0
        }
        this.handlePageHistory = this.handlePageHistory.bind(this)
    }

    loadAdminHistoryList() {
        this.setState({
            history: [],
            loadingHistory: true
        }, ()=>{
            let form = {
                pagination  : this.state.pagination,
                page        : this.state.currentPageHistory + 1,
            };
            this.props.request("list_history_admin", form, "POST")
                .then((response) => {
                    if (response.success) {
                        this.setState({
                            history             : response.data.data,
                            pageCountHistory    : response.data.last_page,
                            totalHistory        : response.data.total,
                            totalPageHistory    : response.data.to,
                            perPageHistory      : response.data.per_page,
                        })
                    }
                })
        })
    }

    componentDidMount() {
        this.loadAdminHistoryList();
    }

    handlePageHistory(history) {
        let selected = history.selected; // zero based index
        this.setState({ currentPageHistory: selected, history: [], loadingHistory: true }, () => {
            this.loadAdminHistoryList();
        });
    }

    render() {
        let no = 0;
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>List History Admin - Presensi</title>
                    <meta name="description" content="List History Admin" />
                </Helmet>
                <Breadcrumb title="List History Admin" parent="History Admin" parentLink="/history-admin"/>
                <div className="container-fluid pt-4">
                    <div className="card">
                        <div className="card-body pt-3 mt-2">
                            {this.state.loading && this.state.data.length === 0 ?
                                <Empty description={i18n.t("datakosong")}
                                       image={EmptyDataImage} className="ipresens-image-empty "/>
                                :
                                <Table className="table table-borderless mb-5">
                                    <Thead>
                                        <Tr>
                                            <Th className="notifikasi-th-table">#</Th>
                                            <Th className="notifikasi-th-table">User</Th>
                                            <Th className="notifikasi-th-table">Tipe</Th>
                                            <Th className="notifikasi-th-table">Tanggal</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {this.state.loading &&
                                            <Tr>
                                                <Td colSpan={5}>
                                                    <Spin size="large" tip="Loading..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }} />
                                                </Td>
                                            </Tr>
                                        }
                                        {this.state.history.map((item) => {
                                            no += 1
                                            return (
                                                <Tr className="rounded-top">
                                                    <Td>{pageNumber(no, this.state.perPageHistory, this.state.currentPageHistory)}</Td>
                                                    <Td>{item.user ? item.user.p_user_name : "nama admin tidak ada"}</Td>
                                                    <Td>{item.history_id ? item.type.toUpperCase() : "x"}</Td>
                                                    <Td>{item.created_at ? item.created_at : "tidak ada data tanggal"}</Td>
                                                </Tr>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                            }
                        </div>
                        <div className="card-footer">
                            <div className='row mx-0 pull-right' style={{width: "100%"}}>
                                {this.state.history.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{
                                            padding: "auto",
                                            margin: "auto",
                                            fontWeight: 500,
                                            lineHeight: "24px"
                                        }}>{i18n.t("menampilkan")} {this.state.history.length === 0 || this.state.history.length === null ? "0" : this.state.history.length} {i18n.t("dari")} {this.state.totalHistory} {i18n.t("data")}
                                        </div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt=''/>}
                                                nextLabel={<img src={ArrowRight} alt=''/>}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={2}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default HistoryAdminList