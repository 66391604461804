import React, {Component, Fragment} from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swal from "sweetalert2";
import {Helmet} from "react-helmet";
import i18n from "i18next";

const imgMyimageexample = require('../../assets/images/bg2.png');
const divStyle = {
    width: '88%',
    height: '100%',
    backgroundImage: `url(${imgMyimageexample})`,
    backgroundSize: 'cover'
};
export class LupaPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            email: '',
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    onChangeText = ({ target }) => {
        this.setState({
            [target.name]: target.value
        })
    }


    submitLogin = () => {
        this.setState({
            prosesSimpan: true,
        })
        let form = {
            email: this.state.email
        }
        this.props.request("reset_password_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/login')
                }else{
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }


    componentDidMount() {
        this.setState({ auth: this.props.auth })
    }



    render() {

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Lupa Password - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                <div className="page-wrapper">
                    <div className="authentication-box" style={divStyle}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 p-0 card-right">
                                    <div className="card tab2-card">
                                        <div className="card-body">
                                            <div className="card-header">
                                                <h5>Masukkan email anda</h5>
                                            </div>
                                            <div>
                                                <form className="form-horizontal auth-form">
                                                    <div className="form-group">
                                                        <input required="" name="email" onChange={(event) => this.onChangeText(event)} onKeyPress={(event) => {
                                                            if (event.which === 13){
                                                                this.submitLogin()
                                                            }
                                                        }} type="email"
                                                               className="form-control" placeholder="Email" />
                                                    </div>
                                                    <div className="form-button">
                                                        <a className="btn btn-primary" type="submit" style={{ backgroundColor: 'gray !important', color: "white" }}
                                                           onClick={() => {
                                                               this.submitLogin()
                                                           }}
                                                        >Verifikasi</a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default (LupaPassword)
