import React, {Component} from 'react'
import {APIKEY} from "../../helper/host";
import {Spin} from "antd";
import {Button, ButtonGroup, Col, FormGroup, Input, Modal, ModalBody, ModalHeader, Row} from 'reactstrap'
import {Table, Tbody, Td, Th, Thead, Tr} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {toast} from 'react-toastify';
import {GoogleApiWrapper} from 'google-maps-react';
import Swal from "sweetalert2";
import format from "format-number";
import Switch from "react-switch";
import i18n from "i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

export class ModalKompensasi extends Component {
    constructor(props) {
        super(props);

        this.state      = {
            loading                 : false,

        }

    }



    render() {
        let no = 0;
        return (
            <div>
                <Modal isOpen={this.props.visibleKom} toggle={this.props.kompensasiModal}
                       style={{maxWidth: '900px', width: '100%'}}>
                    <ModalHeader>
                       List Kompensasi
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg="12">
                                {this.props.rows.map((data, id) =>{
                                    return(
                                        <Row className="row-md-12">
                                            <Col>
                                                <FormGroup>
                                                    {this.props.inputType == "select" ?
                                                        <Input  type="select" name="p_term_id" value={data.p_term_id}
                                                               onChange={this.props.handleChangeStock(id)}

                                                        >
                                                            <option value="">- Payroll Istillah -</option>
                                                            {
                                                                this.props.dataTerm.map((item, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            value={item.p_term_id}
                                                                            selected={item.p_term_id == this.props.dataTerm.p_term_id}>
                                                                            {item.p_term_name}
                                                                        </option>
                                                                    )
                                                                })
                                                            } <option value="text"  className="text-center"
                                                                      style={{color:"#0080ff"}}>+ Tambah Payroll Istillah</option>
                                                        </Input>
                                                        :
                                                        <div className="form-group">
                                                            <div className="input-group mb-3">
                                                                <input name="p_term_name" style={{fontSize: "12px"}}
                                                                       type="text" onChange={this.props.handleChangeistilah}
                                                                       className="form-control border-right-0 "
                                                                       id="exampleInputEmail1"
                                                                       aria-describedby="emailHelp"
                                                                       placeholder="Masukkan Istillah Baru"
                                                                       autoFocus
                                                                />
                                                                {this.props.p_term_name.length >= 1 ?
                                                                    <div className="input-group-append border-left-0">
                                                                        <span class="input-group-text" style={{
                                                                            fontSize: "12px",
                                                                            backgroundColor: "#FFFFFF"
                                                                        }} id="basic-addon2">
                                                                            <a style={{fontSize: "12px", color: "#057CE4"}}
                                                                               onClick={this.props.handleSubmitIstilah.bind(this,"kompensasi")}
                                                                              >Tambah Istillah</a>
                                                                        </span>
                                                                    </div>
                                                                    :
                                                                    <div className="input-group-append border-left-0">
                                                                        <span className="input-group-text" style={{
                                                                            fontSize: "12px",
                                                                            backgroundColor: "#FFFFFF"
                                                                        }} id="basic-addon2">
                                                                            <a style={{
                                                                                fontSize: "12px",
                                                                                color: "#057CE4"
                                                                            }}
                                                                               onClick={this.props.istillahBatal.bind(this)}
                                                                            >Batal</a>
                                                                        </span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                </FormGroup>

                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Input
                                                        className="form-control text-center"
                                                        name="k_kompensasi_amount"
                                                        id="k_kompensasi_amount"
                                                        type="number"
                                                        onChange={this.props.handleChangeStock(id)}
                                                        value={data.k_kompensasi_amount}
                                                        placeholder="Nominal Kompensasi"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <select
                                                        name="k_kompensasi_type"
                                                        id="k_kompensasi_type"
                                                        className="form-control text-center"
                                                        onChange={this.props.handleChangeStock(id)}
                                                        placeholder="Type Kompensasi"
                                                    >
                                                        <option value={""}>Type Kompensasi</option>
                                                        <option value="jam">Jam</option>
                                                        <option value="hari">Hari</option>
                                                        <option value="bulan">Bulan</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col sm="2">
                                                <ButtonGroup size="sm">
                                                    <div>
                                                        {this.props.rows.length !== 1 &&
                                                        <Button
                                                            onClick={this.props.removeClickKom.bind(this,id)}
                                                            color='danger'
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </Button>}
                                                        &nbsp;
                                                        {this.props.rows.length - 1 === id &&
                                                        <button
                                                            type="button" className="btn ipresens-btn-color"
                                                            onClick={this.props.addClickKom.bind(this)}
                                                            color="info"
                                                        >
                                                            <i className="fa fa-plus"></i>
                                                        </button>
                                                        }
                                                    </div>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>

                                    );
                                })}
                            </Col>
                        </Row>
                        <Row form className="text-center col-md-12 mt-3 mb-3" style={{alignItems: "center", justifyContent: "center"}}>
                            <button className="btn ipresens-btn-color"
                                    onClick={(event) => {
                                        this.props.handleSubmitKom(event, this.props.id)
                                    }}
                            >
                                SAVE
                            </button>
                        </Row>
                        {this.props.kompensasi.length === 0 ?
                            <div className="text-center" colSpan="9">
                                "Tidak Ada Data..."
                            </div> :
                            <Table striped className="mb-5">
                                <Thead>
                                <Tr>
                                    <Th>Nama Kompensasi</Th>
                                    <Th>Nominal Kompensasi</Th>
                                    <Th>Type Kompensasi</Th>
                                    <Th>Status</Th>
                                    <Th>Aksi</Th>
                                </Tr>
                                </Thead>
                                <Tbody>
                                {this.props.loading &&
                                <Tr>
                                    <Td colSpan={9}>
                                        <Spin size="large" tip="Loading..." style={{
                                            width: '100%',
                                            margin: 'auto',
                                            marginBottom: '10px',
                                            marginTop: '10px'
                                        }}/>
                                    </Td>
                                </Tr>
                                }
                                {this.props.kompensasi.map((item, index) => {
                                    no += 1
                                    return (
                                        <Tr>
                                            <Td>
                                                {item.payroll_term.p_term_name}
                                            </Td>
                                            <Td>
                                                {format({prefix: '', integerSeparator: "."})(item.k_kompensasi_amount)}
                                            </Td>
                                            <Td>
                                                {item.k_kompensasi_type}
                                            </Td>
                                            <Td>
                                                <Switch
                                                    id={`detail-${item.k_kompensasi_id}`}
                                                    name="k_kompensasi_status"
                                                    checked={
                                                        item.k_kompensasi_status === '1' ? true : false
                                                    }
                                                    onChange={(val) => {
                                                        this.setState({
                                                            loading: true,
                                                            data: []
                                                        })

                                                        let formData = {
                                                            id: item.k_kompensasi_id,
                                                        }

                                                        this.props.request('kompensasi/ubah_status', formData, 'POST')
                                                            .then((response) => {
                                                                if (response.success) {
                                                                    Swal.fire({
                                                                        title: 'Berhasil!',
                                                                        text: response.message,
                                                                        icon: 'success',
                                                                        showConfirmButton: false,
                                                                        timer: 1500,
                                                                    })
                                                                } else {
                                                                    if (Array.isArray(response.message)) {
                                                                        response.message.map(item => {
                                                                            toast['error'](item)
                                                                            return true
                                                                        })
                                                                    } else {
                                                                        toast['error'](response.message)
                                                                    }
                                                                }
                                                                this.props.loadKompensasi(item.karyawan_id)

                                                            })

                                                    }}
                                                    onColor="#1890ff"
                                                    offColor="#eb4034"
                                                    handleDiameter={16}
                                                    uncheckedIcon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                height: "100%",
                                                                fontSize: 3,
                                                                color: "white",
                                                                paddingRight: -5
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faTimes}
                                                                             style={{fontSize: 15}}/>
                                                        </div>
                                                    }
                                                    checkedIcon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                height: "100%",
                                                                fontSize: 4,
                                                                color: "white",
                                                                paddingLeft: 3
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faCheck}
                                                                             style={{fontSize: 15}}/>
                                                        </div>
                                                    }
                                                    boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                    height={18}
                                                    width={40}
                                                    className="mr-1"
                                                />
                                            </Td>
                                            <Td>
                                                <a onClick={() => {
                                                    this.props.wantDeleteKom(item.k_kompensasi_id, item.karyawan_id)
                                                }}><i className="fa fa-trash" style={{
                                                    width: 35,
                                                    fontSize: 20,
                                                    padding: 11,
                                                    color: 'rgb(255 37 37)'
                                                }}
                                                ></i></a>
                                            </Td>
                                        </Tr>
                                    )
                                })
                                }
                                </Tbody>

                            </Table>

                        }
                    </ModalBody>
                </Modal>
            </div>

        )
    }
}

export default  GoogleApiWrapper({
    apiKey: APIKEY
}) (ModalKompensasi)
