import React, {Component, Fragment} from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Input, Label} from 'reactstrap';
import {Modal, Skeleton} from 'antd';
import format from 'format-number'
import {connect} from "react-redux";
import logo from "../../assets/images/dashboard/logo-header.png";
import './style.css'
import i18n from "i18next";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {Helmet} from "react-helmet";
import {requestData} from "../../helper/request";
import Swal from "sweetalert2";
import Paging from "../paging";

export class TagihanRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: this.props.auth,
            disabledButton: false,
            loading: false,
            pageCount: 0,
            currentPage: 0,
            data: [],
            data_langganan: [],
            langganan: [],
            data_presensi: [],
            perusahaan: [],
            data_perusahaan: [],
            paket: [],
            add_month: '',
            modalPaket: false,
        }

        this.handlePaging = this.handlePaging.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleLogout = this.handleLogout.bind(this);
    }

    handlePaging(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            isLoading: true,
            [name]: value
        })
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({currentPage: selected, data: [], isLoading:true, loading: true}, () => {
            this.loadData();
        });
    }

    handleLogout() {
        this.props.clear_auth();
    }

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleCancel = e => {
        this.setState({
            modalPaket: false,
        });
    };

    bayarTagihan = (item) => {
        let form = {
            invoice_no: item.invoice_no
        }
        this.props.request("paket/pay", form, 'POST')
            .then(response => {
                window.location.replace(response)
            });
    }

    loadDataPresensi() {
        let form = {}
        requestData("home", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_presensi: response.data,
                    })
                }
            });
    }

    loadData(){
        let form = {
            page: this.state.currentPage + 1,
        }
        this.props.request("get_invoice", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        perusahaan: response.perusahaan,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }else {
                    this.setState({
                        data: null
                    })
                }
                this.setState({data_perusahaan: response.perusahaan})
                this.props.set_auth(this.props.api_token, response.perusahaan);
            });
    }

    loadDataLangganan() {
        this.setState({loading: true})
        let form = {}
        this.props.request("dashboard", form, 'POST')
            .then(response => {
                if (response.success) {
                    var langganan = response.expired_date
                    this.setState({
                        data_langganan : response,
                        langganan : langganan
                    })
                }
                this.setState({loading: false})

            });

    }

    loadPaket(){
        let form = {}
        this.props.request("paket/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        paket: response.data.data,
                        loading: false,
                    })
                }

            });
    }

    wantPaket(){
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menganti paket?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        loading: true,
                        data: []
                    })
                    let form = {
                        paket_id: this.state.idPaket,
                        month: this.state.add_month,
                    }
                    this.props.request("paket/update_paket", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    loading: false,
                                    data: []
                                })
                                window.location.reload();
                            }else{
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    showConfirmButton: false,
                                    timer: 1200,
                                })
                                this.setState({
                                    loading: false,
                                    data: []
                                })
                            }
                        });
                }
            })
    }

    componentDidMount() {
        this.loadDataPresensi()
        this.loadData()
        this.loadDataLangganan()
        this.loadPaket()
        this.setState({ auth: this.props.auth })
    }

    componentWillReceiveProps(newProps) {
        if(newProps.auth !== this.state.auth) {
            this.setState({ auth: newProps.auth })
        }
    }

    render() {
        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Tagihan - Presensi</title>
                    <meta name="description" content="Detail Absensi" />
                </Helmet>
                   <div className="page-main-header mt-2">
                    <div className="main-header-right row">
                        <div className="main-header-left d-lg" >
                            <div className="logo-wrapper">
                                <a href={this.props.user.perusahaan.status === 'active' ? '/dashboard' : '#'}>
                                    <img className="blur-up lazyloaded" src={logo} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="nav-right col">
                            {this.props.user.perusahaan.status === 'active' ?
                                <a className="btn btn-primary mr-2 col-md-6" href='/dashboard' style={{color: 'white'}}>Kembali Ke Dashboard</a>
                                :
                                null
                            }
                            <button className="btn btn-danger col-md-3" onClick={this.handleLogout}>Logout</button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-4">
                    <div className="card">
                        <div className="card-header">
                            <strong> Daftar Invoice</strong>
                        </div>
                        {this.state.data !== null ?
                            this.state.data.map((item, index) => (
                            <div className="container">
                                <div className="invoice-box mt-5">
                                    <table cellPadding="0" cellSpacing="0">
                                        <tr className="top">
                                            <td colSpan="2">
                                                <table>
                                                    {this.state.loading ? <Skeleton/> :
                                                        <tr>
                                                            <td className="title">
                                                                <div className="logo-wrapper">
                                                                <img className="blur-up lazyloaded" src={logo}/>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                Invoice : #{item.invoice_no}<br/>
                                                                Tanggal: {item.invoice_create_date}<br/>
                                                                {/*Due: February 1, 2015*/}
                                                            </td>
                                                        </tr>
                                                    }
                                                </table>
                                            </td>
                                        </tr>
                                        <tr className="information">
                                            <td colSpan="2">
                                                <table>
                                                    {this.state.loading ? <Skeleton/> :
                                                        <tr>
                                                            <td width='50%'>
                                                                {this.state.perusahaan && this.state.perusahaan.perusahaan_alamat}
                                                            </td>
                                                            <td>
                                                                {this.state.perusahaan && this.state.perusahaan.perusahaan_nama}<br/>
                                                                {this.state.perusahaan && this.state.perusahaan.perusahaan_email}<br/>
                                                                {this.state.perusahaan && this.state.perusahaan.perusahaan_telp}
                                                            </td>
                                                        </tr>
                                                    }
                                                </table>
                                            </td>
                                        </tr>
                                        {this.state.loading ? <Skeleton/> :
                                            <tr className="heading">
                                                <td>Paket</td>
                                                <td>Harga</td>
                                            </tr>
                                        }
                                        {this.state.loading ? null :
                                            <tr className="item">
                                                <td>{item.paket.paket_nama} ({item.invoice_lama_bulan} Bulan)</td>
                                                <td>{format({
                                                    prefix: 'Rp ',
                                                    integerSeparator: "."
                                                })(item.invoice_nominal)}</td>
                                            </tr>
                                        }
                                        {this.state.loading ? null :
                                            <tr className="total">
                                                <td></td>
                                                <td>Total: {format({
                                                    prefix: 'Rp ',
                                                    integerSeparator: "."
                                                })(item.invoice_nominal)}</td>
                                            </tr>
                                        }
                                        {this.state.loading ? null :
                                            <tr className="total">
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        }
                                        {this.state.loading ? null :
                                            item.invoice_status === 'pending' ? (
                                            <tr>
                                                <td></td>
                                                <td width='40%'>
                                                    <button onClick={() => {this.bayarTagihan(item)}} className="btn btn-success"><i
                                                        className="fa fa-money"></i>&nbsp;Bayar Sekarang
                                                    </button>
                                                </td>
                                            </tr>
                                            ) : item.invoice_status === 'success' ? (
                                                <tr>
                                                    <td></td>
                                                    <td width='40%'>
                                                        <button disabled={true} className="btn btn-disabled">
                                                            Sudah Di Bayar
                                                        </button>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td></td>
                                                    <td width='40%'>
                                                        <button disabled={true} className="btn btn-disabled">
                                                            Invoice Kadaluarsa
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </table>
                                </div>
                            </div>
                            ))
                            :
                            <h4>Anda tidak punya Invoice</h4>
                        }
                        {this.state.loading && (
                            <h4 className="text-center m-4">Memuat data...</h4>
                        )}
                        <div className="float-right mt-4">
                            <Paging
                                handlePageClick={this.handlePageClick}
                                pageCount={this.state.pageCount}
                                currentPage={this.state.currentPage}
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    title="Pilih Bulan Paket"
                    visible={this.state.modalPaket}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <div>
                        <Label>Pilih Bulan Masa Aktif Langganan Paket Perusahaan</Label><br/>
                        <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Bulan Masak Aktif) </Label>
                        <Input bsSize="sm" type="select" name="add_month" style={{width:'inherit',height:'32px'}} value={this.state.add_month} onChange={this.handlePaging}>
                            <option value="">Pilih Bulan</option>
                            <option value="1">1 Bulan</option>
                            <option value="2">2 Bulan</option>
                            <option value="3">3 Bulan</option>
                            <option value="4">4 Bulan</option>
                            <option value="5">5 Bulan</option>
                            <option value="6">6 Bulan</option>
                            <option value="7">7 Bulan</option>
                            <option value="8">8 Bulan</option>
                            <option value="9">9 Bulan</option>
                            <option value="10">10 Bulan</option>
                            <option value="11">11 Bulan</option>
                            <option value="12">12 Bulan</option>
                        </Input>
                        {!this.state.loading ?
                            <a type="button" className="btn btn-primary mb-3 mt-3 text-center"
                               style={{color: 'white', maxWidth: '100%'}}
                               onClick={() => {
                                   this.wantPaket()
                               }}>
                                Pilih Paket
                            </a>
                            :
                            <a type="button" className="btn btn-primary mb-3 mt-3 text-center"
                               style={{color: 'white', maxWidth: '100%'}}>
                                <i className="fa fa-refresh fa-spin mr-2" />
                                Sedang Mengupload Data
                            </a>
                        }
                    </div>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TagihanRegister);
