import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Empty, Spin, Tooltip , Modal} from 'antd';

import Edit from './edit-jabatan-karyawan'
import Add from "./add";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Tour from "reactour";
import { Helmet } from "react-helmet";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import EmptyDataImage from "../../assets/images/data-kosong.png";
import {Col, Modal as ModalRs, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import moment from "moment/moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import i18n from "i18next";
import PencilIcon from "../../assets/icons/pencil.svg";
import TrashIcon from "../../assets/icons/trash.svg";
import ImportIcon from "../../assets/icons/import-icon.svg";
import {BACKEND_API_HOST} from "../../helper/host";

export class List_jabatan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            idDel: '',
            isLoading: true,
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            pagination: 2,
            jabatan_nama: '',
            jabatan_id: '',
            nama_jabatan: '',
            filter_nama: '',
            add_visible: false,
            visable: false,
            guide: false,
            importFile: null,
            modalimport: false,
            currentPageHistory: 0,
            dataHistory: [],
            modalHistory: false,
            loadingHistory: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.importExcel = this.importExcel.bind(this)
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    resetFilter(event) {
        this.setState({
            filter_nama: '',
            data: [],
            isLoading: true
        }, () => this.loadData())
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    simpan = () => {
        let form = {
            name: this.state.nama_jabatan
        }

        this.props.request("jabatan/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        nama_jabatan: ''
                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData();
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected }, () => {
            this.loadData();
        });
    }

    handleOk = e => {
        this.setState({
            modalimport: false
        });
    };

    handleCancel = e => {
        this.setState({
            modalimport: false
        });
    };

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.props.request("jabatan/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    isLoading: true,
                                    data: []
                                })
                                this.props.set_auth(this.props.api_token, response.perusahaan);
                                this.loadData()
                            } else {
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                })
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_nama: this.state.filter_nama,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("jabatan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        isLoading: false,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                    })
                }
            });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.jabatan_id,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("jabatan/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            jabatan_id: ''
                        });
                    }
                });
        })
    }

    exportJabatan = () => {
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        window.open(BACKEND_API_HOST + 'jabatan/export_template?token=' + AuthKey, "_blank")
    }

    importExcel() {
        this.setState({
            importLoading: true
        })

        var AuthKey = localStorage.getItem('Presensi-Auth-Key')

        let formData = new FormData()
        formData.append('file', this.state.importFile)
        formData.append('token', AuthKey)

        fetch(BACKEND_API_HOST + 'jabatan/import', {
            method: 'POST',
            headers: {
                'Authorization': 'bearer ' + AuthKey
            },
            body: formData
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    window.location.reload();
                    this.setState({
                        modalimport: false,
                        loading: true,
                        importFile: null,
                        data: [],
                    }, () => {
                        this.loadData()
                    })
                }
                else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        showConfirmButton: false,
                        timer: 1200,
                    })
                    this.setState({
                        modalimport: false,
                    })
                }

                this.setState({ importLoading: false })
            })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        let no = 0;
        const steps = [
            {
                selector: '.tambah-jabatan',
                content: 'Anda dapat membuat Jabatan disini',
            },
            {
                selector: '.daftar-jabatan',
                content: 'Daftar Jabatan anda dapat dilihat disini',
            },
        ];
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Jabatan") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{i18n.t("daftarjabatan")} - iPresens</title>
                        <meta name="description" content={i18n.t("daftarjabatan")} />
                    </Helmet>
                    <Edit
                        data={this.state}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Add
                        add_visible={this.state.add_visible}
                        request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                        changeState={(state, callback) => {
                            this.setState(state, () => {
                                if (typeof callback != 'undefined')
                                    callback()
                            })
                        }}
                        loadData={() => this.loadData()}
                    />
                    <Breadcrumb parent={i18n.t("manajemenjabatan")} title={i18n.t("daftarjabatan")} setGuide={() => { this.setState({ guide: true }) }} />
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                            <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>Aktivitas</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px', paddingBottom: '16', paddingRight: '12px', paddingLeft: '12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.history_create_date).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Dibuat
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Diubah
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.jabatan_nama != item.jabatan_nama && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Nama Jabatan: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.jabatan_nama
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.jabatan_nama ? item.jabatan_nama : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>
                    <Tour steps={steps} isOpen={this.state.guide === true} onAfterOpen={this.disableBody} onBeforeClose={this.enableBody} onRequestClose={() => this.setState({ guide: false })} />
                    <Modal
                        title="Import Jabatan"
                        visible={this.state.modalimport}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            this.state.importLoading ?
                                <button className={'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={true}><i className="fa fa-refresh fa-spin"></i> Sedang mengupload data</button>
                                :
                                <button className={this.state.importFile === null ? 'btn bg-secondary-custom' : 'btn ipresens-btn-color'} style={{textTransform: "none", width: "100%"}} disabled={this.state.importFile === null} onClick={this.importExcel}>Import Data</button>
                        ]}
                    >
                        <div>
                            <a style={{ color: "#5D6F80", cursor: "default" }}>Anda bisa mengunduh template yang sudah kami sediakan untuk mempermudah pengimportan.</a><br />
                            <a onClick={this.exportJabatan} style={{ color: "#057CE4" }}>Unduh template disini</a>
                            <div style={{ position: 'relative', width: '100%', backgroundColor: '#F9F9F9', padding: 16, borderRadius: 6, marginTop: 8, border: "1px dashed #DDDDDD" }}>
                                <input type='file'
                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                       onChange={(event) => {
                                           this.setState({
                                               importFile: event.target.files[0],
                                           })
                                       }} /><br />
                            </div>
                        </div>
                    </Modal>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card profile-card">
                                    <div className="card-header">
                                        <div className='form-group mb-0'>
                                            <div className="row filter-karyawan" style={{marginLeft: '5px'}}>
                                                <div className="col-md-6 col-search-jbtn">
                                                    <div className="input-group btn-search-jbtn">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text input-group-text-custom"
                                                                  id="basic-addon1"><span
                                                                className="fa fa-search"></span></span>
                                                        </div>
                                                        <input name="filter_nama" className="form-control mr-2"
                                                               placeholder={i18n.t("carinamajabatan") + '...'}
                                                               type="text" required=""
                                                               onChange={this.handleChange}
                                                               onKeyPress={(event) => {
                                                                   if (event.which == 13) {
                                                                       event.preventDefault()
                                                                       this.setState({
                                                                           data: [],
                                                                           isLoading: true
                                                                       }, () => this.loadData())
                                                                   }
                                                               }}/>
                                                        <button className="btn btn-outline-" onClick={this.resetFilter}>
                                                            <span className="fa fa-refresh"></span></button>
                                                    </div>
                                                </div>
                                                <div className="col"></div>
                                                <div className='col-auto px-0 mb-4' style={{textAlign: "right"}}>
                                                    <button className="ipresens-import-button btn-import-krywn"
                                                            onClick={() => {
                                                                this.setState({modalimport: true})
                                                            }}><img src={ImportIcon} alt=''/> Import
                                                    </button>
                                                </div>
                                                <div className="col-auto">
                                                    <a className="btn ipresens-btn-color tambah-jabatan btn-add-jbtn"
                                                       style={{ lineHeight: "23px" }}
                                                       onClick={() => {
                                                           this.setState({
                                                               add_visible: true
                                                           })
                                                       }}><i className={"fa fa-plus"}></i> {i18n.t("tambahjabatan")}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body daftar-jabatan">
                                        {!this.state.isLoading && this.state.data.length === 0 ?
                                            <Empty description={i18n.t("datakosong")}
                                                   image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                            :
                                            <div>
                                                <Table striped className="mb-5">
                                                    <Thead>
                                                        <Tr>
                                                            <Th width="50">#</Th>
                                                            <Th>{i18n.t("namajabatan")}</Th>
                                                            <Th className={"text-center"} width="350">{i18n.t("aksi")}</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {this.state.data.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <Tr>
                                                                    <small>
                                                                        <Th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</Th>
                                                                    </small>
                                                                    <Td>{item.jabatan_nama.toUpperCase()}</Td>
                                                                    <Td align={"center"}>
                                                                        <div className="row col-20 pl-5 py-1">
                                                                            <a onClick={() => {
                                                                                this.setState({
                                                                                    modalHistory: true,
                                                                                    jabatan_id: item.jabatan_id
                                                                                }, () => {
                                                                                    this.loadHistory(item.jabatan_id);
                                                                                })
                                                                            }}
                                                                               className="btn btn-riwayat-custom btn-block-50 mx-1 col-xl-3">{i18n.t("riwayat")}</a>
                                                                            <a onClick={() => {
                                                                                this.setState({
                                                                                    jabatan_id: item.jabatan_id,
                                                                                    jabatan_nama: item.jabatan_nama,
                                                                                    visable: true
                                                                                })
                                                                            }}
                                                                               className="btn btn-outline-custom btn-block-50 mx-2 col-xl-3">Edit</a>
                                                                            <a onClick={() => {
                                                                                this.setState({
                                                                                    idDel: item.jabatan_id
                                                                                })
                                                                                this.wantDelete()
                                                                            }}
                                                                               className="btn btn-danger-custom btn-block-50 mx-1 col-xl-3">{i18n.t("hapus")}</a>
                                                                        </div>
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        })}
                                                        {this.state.isLoading &&
                                                            <Tr>
                                                                <Td colSpan="3">
                                                                    <Spin style={{width: '100%'}} size="small" tip="Mohon Tunggu..." />
                                                                </Td>
                                                            </Tr>
                                                        }
                                                    </Tbody>
                                                </Table>
                                                <Paging
                                                    className="float-right"
                                                    handlePageClick={this.handlePageClick}
                                                    pageCount={this.state.pageCount}
                                                    currentPage={this.state.currentPage}
                                                />
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_jabatan);
