import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import moment from "moment";
import {Helmet} from "react-helmet";
import Select from "react-select";
import TimeInput from '../../helper/time_input';
import { Link } from 'react-router-dom';
import i18n from "i18next";
import SaveIcon from '../../assets/icons/save.svg';
//css ipresens
import "../iPresens-style.css"

export class Add_absensi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prosesSimpan: false,
            isLoading: true,
            pickKaryawan: false,
            karyawan: [],
            shift: [],
            lokasi: [],
            lokasi_in: [],
            lokasi_out: [],
            lokasi_in_name: '',
            lokasi_out_name: '',
            lokasi_in_id : 0,
            lokasi_out_id: 0,
            lokasi_in_loading: false,
            lokasi_out_loading: false,
            sift: '',
            tanggal: (new Date()).toISOString().substr(0,10),
            jam_in: moment().hour() + ':' + moment().minute() + ':00',
            jam_out: moment().hour() + ':' + moment().minute() + ':00',
            status_in: '',
            status_out: '',
            keterangan_in: '',
            keterangan_out: '',
            karyawan_id: '',
            karyawan_name: "",
            karyawan_loading: false,
            karyawan_selected: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.setKaryawan = this.setKaryawan.bind(this)
        this.searchKaryawan = this.searchKaryawan.bind(this)
        this.searchLokasiIn = this.searchLokasiIn.bind(this)
        this.searchLokasiOut = this.searchLokasiOut.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    setKaryawan(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;
        const dataShift = target[target.selectedIndex].getAttribute('child')

        this.setState({
            shift: JSON.parse(dataShift),
            karyawan_id: value
        })

        this.setState({
            pickKaryawan: true,
        })
    }

    loadLokasi(){
        let form = {}
        this.props.request("get_lokasi_perusahaan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        lokasi: response.data,
                    })
                }
            });
    }

    loadKaryawan(){
        let form = {}
        this.props.request("karyawan/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        karyawan: response.data,
                        loading         : false
                    })
                }
            });
    }

    searchKaryawan(){
        let form = {
            karyawan_name : this.state.karyawan_name
        }
        this.props.request("karyawan/search-karyawan", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        karyawan: response.data,
                        karyawan_loading: false
                    })
                }
            })
    }
    searchLokasiIn(){
        let form = {
            lokasi_nama : this.state.lokasi_in_name
        }
        this.props.request("search_lokasi_perusahaan", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        lokasi_in: response.data,
                        lokasi_in_loading: false
                    })
                }
            })
    }
    searchLokasiOut(){
        let form = {
            lokasi_nama : this.state.lokasi_out_name
        }
        this.props.request("search_lokasi_perusahaan", form, "POST")
            .then((response)=> {
                if (response.success){
                    this.setState({
                        lokasi_out: response.data,
                        lokasi_out_loading: false
                    })
                }
            })
    }

    simpanAbsensi = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            karyawan_id: this.state.karyawan_id,
            sift: this.state.sift,
            lokasi_in: this.state.lokasi_in_id,
            lokasi_out: this.state.lokasi_out_id,
            tanggal: this.state.tanggal,
            jam_in: this.state.jam_in,
            jam_out: this.state.jam_out,
            status_in: this.state.status_in,
            status_out: this.state.status_out,
            keterangan_in: this.state.keterangan_in,
            keterangan_out: this.state.keterangan_out,
        }
        this.props.request("presensi/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/presence')
                }else{
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }
    componentDidMount() {
        // this.loadKaryawan()
        // this.loadLokasi()
    }

    render() {
        let formPresensi;
        if (this.state.pickKaryawan) {
            formPresensi =
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='card border' style={{ fontSize: "12px" }}>
                                <div className='card-header py-2 border' style={{backgroundColor: "#FAFDFF"}}>
                                    <b style={{ fontSize: "16px" }}>Masuk</b>
                                </div>
                                <div className='card-body'>
                                    <div className="form-group row">
                                        <div className='col-md-6 px-0'>
                                            <label className="mb-2">Jam Presensi Masuk</label>
                                            <TimeInput
                                                style={{ fontSize: "12px" }}
                                                initTime={moment().format('HH:mm')}
                                                className='s-input -time form-control px-3 py-2'
                                                onTimeChange={(event)=>{
                                                    const value = event;
                                                    this.setState({
                                                        jam_in: value + ':00'
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mb-2">Lokasi Presensi</label>
                                            <select style={{ fontSize: "12px" }} className="form-control" onChange={this.handleChange} name="lokasi_in_id">
                                                <option value="">Pilih Lokasi</option>
                                                {this.state.lokasi_in.length !== 0 ?
                                                    this.state.lokasi_in.map((item, index) => {
                                                        return (
                                                            <option value={item.lokasi_id}>{item.lokasi_nama}</option>
                                                        )
                                                    })
                                                : null}
                                            </select>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mb-2">Status Presensi Masuk</label>
                                        <select style={{ fontSize: "12px" }} name="status_in" className="form-control" onChange={this.handleChange}>
                                            <option value="">Pilih Status</option>
                                            <option value="Masuk Kerja">Masuk Kerja (Tepat Waktu)</option>
                                            <option value="Terlambat">Terlambat</option>
                                            <option value="Izin">Izin</option>
                                            <option value="Sakit">Sakit</option>
                                            <option value="Cuti">Cuti</option>
                                        </select>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mb-2">Keterangan Presensi Masuk</label>
                                        <input style={{ fontSize: "12px" }} type="text" name="keterangan_in" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='card border' style={{ fontSize: "12px" }}>
                                <div className='card-header py-2 border'  style={{backgroundColor: "#FAFDFF"}}>
                                    <b style={{fontSize: "16px"}}>Pulang</b>
                                </div>
                                <div className='card-body'>
                                    <div className="form-group row">
                                        <div className='col-md-6 px-0'>
                                            <label className="mb-2">Jam Presensi Pulang</label>
                                            <TimeInput
                                                style={{ fontSize: "12px" }}
                                                initTime={moment().format('HH:mm')}
                                                className='s-input -time form-control px-3 py-2'
                                                onTimeChange={(event)=>{
                                                    const value = event;
                                                    this.setState({
                                                        jam_out: value + ':00'
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mb-2">Lokasi Presensi</label>
                                            <select style={{ fontSize: "12px" }} className='form-control' name='lokasi_out_id' onChange={this.handleChange}>
                                                <option value="">Pilih Lokasi</option>
                                                {this.state.lokasi_out.length !== 0 ?
                                                    this.state.lokasi_out.map((item, index) => {
                                                        return (
                                                            <option value={item.lokasi_id}>{item.lokasi_nama}</option>
                                                        )
                                                    })
                                                : null}
                                            </select>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mb-2">Status Presensi Pulang</label>
                                        <select style={{ fontSize: "12px" }} name="status_out" className="form-control" onChange={this.handleChange}>
                                            <option value="">Pilih Status</option>
                                            <option value="Pulang Kerja">Pulang Kerja</option>
                                            <option value="Sakit">Sakit</option>
                                            <option value="Izin">Izin</option>
                                        </select>
                                    </div>
                                    <div className="form-group row">
                                        <label className="mb-2">Keterangan Presensi Pulang</label>
                                        <input style={{ fontSize: "12px" }} type="text" name="keterangan_out" className="form-control" placeholder="Keterangan Presensi..." onChange={this.handleChange}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            formPresensi = null
        }
        const rowStyle = {

            '@media only screen and (min-width: 768px)': {
                paddingRight: '100px',
                backgroundColor: 'blue'
            }
        }

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Tambah Presensi - Presensi</title>
                    <meta name="description" content="Detail Presensi" />
                </Helmet>
                <Breadcrumb title="Tambah Presensi" parent="Presensi" parentLink="/presence"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="form needs-validation user-add" noValidate="">
                                <div className="card mb-2">
                                    <div className="card-body">
                                        <div className='row mb-1'>
                                            <div className='col-md-4'>
                                                <h4>Detail Presensi</h4>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='pull-right' style={{fontSize: "14px"}}>
                                                    {/* <a href='presensi' className='btn btn-outline-secondary mr-1'>Batalkan</a> */}
                                                    <Link className='btn btn-outline-secondary mr-1 text-capitalize button-add' style={{padding: "10px 12px"}} to="/presence">Batalkan</Link>
                                                    {this.state.prosesSimpan === false ?
                                                        <button type="button" className="btn ipresens-btn-color text-capitalize" style={{padding: "10px 12px"}}
                                                                onClick={this.simpanAbsensi}><img src={SaveIcon} /> Simpan</button>
                                                            :
                                                        <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row" style={{fontSize: "12px"}}>
                                            <div className='col-md-3'>
                                                <label className='mb-2'>Tanggal Presensi</label>
                                                <input style={{fontSize: "12px"}} type="date" name="tanggal" className="form-control" defaultValue={(new Date()).toISOString().substr(0,10)} onChange={this.handleChange}/>
                                            </div>
                                            <div className='col-md-4'>
                                                <label className="mb-2">Karyawan</label>
                                                <div className="form-control" style={{padding: 0, fontSize: "12px"}}>
                                                    <Select
                                                        placeholder="Nama Karyawan"
                                                        backspaceRemoves={true}
                                                        deleteRemoves={true}
                                                        styles={{paddingLeft: "0px"}}
                                                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null}}
                                                        value={this.state.karyawan_selected}
                                                        isLoading={this.state.karyawan_loading}
                                                        onFocus={() => this.setState({karyawan_loading: true}, ()=> this.searchKaryawan())}
                                                        onBlur={() => this.setState({karyawan_loading: false})}
                                                        onInputChange={(val) => this.setState({karyawan_name :val}, () => this.setState({karyawan_loading: true}, ()=> this.searchKaryawan()))}
                                                        options={
                                                            this.state.karyawan != null ?(
                                                                    this.state.karyawan.map((item, index) =>
                                                                        (
                                                                            {
                                                                                key: index,
                                                                                value: item.karyawan_id,
                                                                                label: item.karyawan_nama,
                                                                                shift: item.divisi_sift
                                                                            }
                                                                        )
                                                                    ))
                                                                :("")
                                                        }
                                                        onChange={
                                                            (karyawan_selected) => {
                                                                if (!karyawan_selected){
                                                                    this.setState({
                                                                        karyawan_selected: '',
                                                                        karyawan_id: 0,
                                                                        pickKaryawan: false,
                                                                        shift: []
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        karyawan_selected,
                                                                        karyawan_id: karyawan_selected.value,
                                                                        pickKaryawan: true,
                                                                        shift: karyawan_selected.shift,
                                                                        karyawan:[]
                                                                    }, () => this.searchLokasiIn(), this.searchLokasiOut())
                                                                }
                                                            }
                                                        }
                                                        isClearable={()=>{
                                                            this.setState({
                                                                isClearable: true,
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                {this.state.pickKaryawan ?
                                                    <div>
                                                        <label className="mb-2">Shift</label>
                                                        <select style={{fontSize: "12px"}} name="sift"
                                                                className="form-control"
                                                                onChange={this.handleChange}>
                                                            <option value="">Pilih Shift</option>
                                                            {this.state.shift.map((item, index) => {
                                                                return (
                                                                    <option value={item.sif.sift_id}>{item.sif.sift_name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                            <div className='col'></div>
                                        </div>
                                        <div className="row form-group">
                                        </div>
                                    </div>
                                </div>
                                {formPresensi}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Add_absensi
