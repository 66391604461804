import React, { Component, Fragment } from 'react';
import { Empty, Spin, Tooltip } from 'antd';
import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Modal as ModalRs} from "reactstrap";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Swal from "sweetalert2";
import Paging from "../../components/paging";
import { Helmet } from "react-helmet";
import { IMG_HOST } from "../../helper/host";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { pageNumber } from "../../functions/functionGeneral";
import { clear_auth, set_auth } from "../../reducers/Auth";
import { connect } from "react-redux";
import { CloudUpload } from "@material-ui/icons";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { API_HOST } from "../../helper/host";
import CKEditor from "react-ckeditor-component";
import SearchIcon from "../../assets/icons/search.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import PlusIcon from "../../assets/icons/plus-white.svg";
import SaveIcon from "../../assets/icons/save.svg";
import ImportIcon from "../../assets/icons/import-icon-blue-light.svg";
import "./style.css"
import EmptyDataImage from "../../assets/images/data-kosong.png";
import i18n from "i18next";
import moment from "moment/moment";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import ReactPaginate from "react-paginate";
import ArrowLeft from "../../assets/icons/arrow-left.svg";
import ArrowRight from "../../assets/icons/arrow-right.svg";

export class List_pengumuman extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            prosesSimpan: false,
            karyawan: [],
            data: [],
            divisi: [],
            idDel: '',
            filter_judul: "",
            pageCount: 0,
            currentPage: 0,
            totalData: 0,
            perPage: 0,
            paging: 20,
            guide: false,
            modalAdd: false,
            judul: '',
            shortdesc: '',
            gambar: '',
            divisi_pengumuman: '',
            fileList: [],
            deskripsi: '',
            modalEdit: false,
            idDetail: 0,
            judulEdit: '',
            divisiEdit: '',
            shortdescEdit: '',
            gambarEdit: null,
            fileListEdit: [],
            deskripsiEdit: '',
            modalDetail: false,
            judulDetail: '',
            shortdescDetail: '',
            gambarDetail: null,
            fileListDetail: [],
            deskripsiDetail: '',
            modalHistory: false,
            dataHistory: [],
            loadingHistory: false,
            currentPageHistory: 0,
            pagination: 2,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageHistory = this.handlePageHistory.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.wantDelete = this.wantDelete.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onEditorStateChangeEdit = this.onEditorStateChangeEdit.bind(this);
    }

    disableBody = target => disableBodyScroll(target)
    enableBody = target => enableBodyScroll(target)

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true }, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage: 0,
            [name]: value
        })
    }

    handlePageHistory(dataHistory) {
        let selected = dataHistory.selected; // zero based index
        this.setState({ currentPageHistory: selected, dataHistory: [], loadingHistory: true }, () => {
            this.loadHistory();
        });
    }

    resetFilter(event) {
        this.setState({
            filter_judul: '',
            data: [],
            loading: true
        }, () => this.loadData())
    }

    wantDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus pengumuman?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                    this.setState({
                        data: [],
                        loading: true
                    })
                    this.props.request("pengumuman/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    loadData() {
        let form = {
            filter_judul: this.state.filter_judul,
            paginate: 10,
            page: this.state.currentPage + 1,
        }
        this.props.request("pengumuman/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data.data,
                        totalData: response.data.total,
                        pageCount: response.data.last_page,
                        perPage: response.data.per_page,
                        loading: false
                    })
                }
            });
    }

    onEditorStateChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            deskripsi: newContent
        })
    }
    onEditorStateChangeEdit(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            deskripsiEdit: newContent
        })
    }

    loadDetail() {
        let form = {
            id: this.state.idDetail
        }
        this.props.request("pengumuman/detail", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        dataDetail: response.data,
                        judulEdit: response.data.pengumuman_judul,
                        divisiEdit: response.data.pengumuman_divisi,
                        shortdescEdit: response.data.pengumuman_shortdesc,
                        deskripsiEdit: response.data.pengumuman_deskripsi,
                        modalEdit: true
                    })
                    if (response.data.pengumuman_gambar !== null) {
                        this.setState({
                            fileListEdit: [
                                {
                                    uid: "-1",
                                    name: response.data.pengumuman_gambar,
                                    status: "done",
                                    url: IMG_HOST + 'mitra/perusahaan/pengumuman/' + response.data.pengumuman_gambar
                                }
                            ],
                        })
                    }
                }
            });
    }

    simpanPengumuman = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            gambar: this.state.gambar,
            judul: this.state.judul,
            divisi: this.state.divisi_pengumuman,
            shortdesc: this.state.shortdesc,
            deskripsi: this.state.deskripsi,
        }

        this.props.request("pengumuman/add", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800,
                    })
                    this.props.history.push('/announcement')
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalAdd: false,
                    judul: '',
                    shortdesc: '',
                    gambar: '',
                    fileList: [],
                    deskripsi: '',
                }, () => this.loadData())
            });
    }

    editPengumuman = () => {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            id: this.state.idDetail,
            judul: this.state.judulEdit,
            divisi: this.state.divisiEdit,
            shortdesc: this.state.shortdescEdit,
            gambar: this.state.gambarEdit === null ? this.state.dataDetail.pengumuman_gambar : this.state.gambarEdit,
            deskripsi: this.state.deskripsiEdit,
        }

        this.props.request("pengumuman/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/announcement')
                } else {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    prosesSimpan: false,
                    modalEdit: false,
                    gambarEdit: null,
                    fileListEdit: []
                }, () => this.loadData())
            });
    }

    loadDivisi() {
        let form = {}
        this.props.request("pengumuman/get_divisi_pengumuman", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: false,
                        divisi: response.data
                    })
                }
            });
    }

    loadHistory(id) {
        this.setState({
            dataHistory: [],
            loadingHistory: true
        }, () => {
            let form = {
                id: id ? id : this.state.id_pengumuman,
                paging: this.state.pagination,
                page: this.state.currentPageHistory + 1,
            };

            this.props.request("pengumuman/history", form, 'POST')
                .then(response => {
                    this.setState({
                        loadingHistory: false
                    })
                    if (response.succes) {
                        this.setState({
                            dataHistory: response.data.data,
                            pageCountHistory: response.data.last_page,
                            totalDataHistory: response.data.total,
                            totalPerpageHistory: response.data.to,
                            perPageHistory: response.data.per_page,
                        });
                    } else {
                        this.setState({
                            modalHistory: false,
                            id_pengumuman: ''
                        });
                    }
                });
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadDivisi()
    }

    render() {
        let no = 0
        const stylefoto = {
            maxHeight: 60,
            borderRadius: "4px"
        }
        const steps = [
            {
                selector: '.tambah-pengumuman',
                content: 'Anda dapat membuat Pengumuman disini',
            },
            {
                selector: '.daftar-pengumuman',
                content: 'Daftar Pengumuman anda dapat dilihat disini',
            },
        ];
        if (this.props.user.user_level.level_page_limited == null || this.props.user.user_level.level_page_limited.search("Pengumuman") >= 0) {
            return (
                <Fragment>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Daftar Pengumuman - iPresens</title>
                        <meta name="description" content="Daftar Pengumuman" />
                    </Helmet>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalAdd} toggle={() => this.setState({
                        modalAdd: false, judul: '',
                        shortdesc: '',
                        gambar: '',
                        fileList: [],
                        deskripsi: '',
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalAdd: false, judul: '',
                            shortdesc: '',
                            gambar: '',
                            fileList: [],
                            deskripsi: '',
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("tambahpengumuman")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <div className="col-md-4 col-sm-12 px-0">
                                            <ImgCrop
                                                width={2000}
                                                height={1000}>
                                                <Upload action={API_HOST}
                                                    listType="picture-card"
                                                    style={{ width: '400px !important' }}
                                                    fileList={this.state.fileList}
                                                    onChange={(file) => {
                                                        this.setState({ fileList: file.fileList })

                                                        if (file.fileList.length > 0) {
                                                            let files = file.file.originFileObj
                                                            let reader = new FileReader()

                                                            reader.readAsDataURL(files)
                                                            reader.onloadend = () => {
                                                                this.setState({
                                                                    gambar: {
                                                                        name: file.file.name,
                                                                        file: reader.result
                                                                    }
                                                                })
                                                            }
                                                        }
                                                        else {
                                                            this.setState({ gambar: [] })
                                                        }
                                                    }}
                                                >
                                                    {this.state.fileList.length >= 1 ? null :
                                                        <div className="p-2">
                                                            <img alt='' src={ImportIcon} style={{ width: "32px", height: "32px" }} /><br />
                                                            <div style={{
                                                                fontSize: "12px",
                                                                lineHeight: "16px",
                                                                color: "#5D6F80",
                                                                marginTop: "8px"
                                                            }}>Upload gambar pengumuman</div><br />
                                                            <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                        </div>
                                                    }
                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("judulpengumuman")}</label>
                                        <div>
                                            <input name="judul" className="form-control pengumuman-input" placeholder="Ketik judul pengumuman disini"
                                                type="text" required="" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("divisi")}</label>
                                        <div>
                                            <select style={{fontSize: "12px"}} name="divisi_pengumuman"
                                                    className="form-control" onChange={this.handleChange}>
                                                <option style={{fontSize: "12px"}}>{i18n.t("semuadivisi")}</option>
                                                {this.state.divisi.map((item, index) => {
                                                    return (
                                                        <option style={{fontSize: "12px"}}
                                                                value={item.divisi_id}>{item.divisi_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("deskripsisingkatpengumuman")}</label>
                                        <div>
                                            <input name="shortdesc" className="form-control pengumuman-input" placeholder="Ketik deksripsi singkat disini"
                                                type="text" required="" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("deskripsipengumuman")}</label>
                                        <div>
                                            <CKEditor
                                                content={this.state.deskripsi}
                                                config={{versionCheck: false}}
                                                events={{
                                                    change: this.onEditorStateChange
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalAdd: false, judul: '',
                                shortdesc: '',
                                gambar: '',
                                fileList: [],
                                deskripsi: '',
                            })} className='ipresens-cancel-button' >{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' disabled={this.state.prosesSimpan} onClick={this.simpanPengumuman}><img alt='' src={SaveIcon} /> {this.state.prosesSimpan ? "Menyimpan.." : i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalEdit} toggle={() => this.setState({
                        modalEdit: false, gambarEdit: null,
                        fileListEdit: []
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalEdit: false, gambarEdit: null,
                            fileListEdit: []
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>{i18n.t("editpengumuman")}</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="form-group">
                                        <div className="col-md-4 col-sm-12 px-0">
                                            <ImgCrop
                                                width={2000}
                                                height={1000}>
                                                <Upload action={API_HOST}
                                                    listType="picture-card"
                                                    style={{ width: '400px !important' }}
                                                    fileList={this.state.fileListEdit}
                                                    onChange={(file) => {
                                                        this.setState({ fileListEdit: file.fileList })

                                                        if (file.fileList.length > 0) {
                                                            let files = file.file.originFileObj
                                                            let reader = new FileReader()

                                                            reader.readAsDataURL(files)
                                                            reader.onloadend = () => {
                                                                this.setState({
                                                                    gambarEdit: {
                                                                        name: file.file.name,
                                                                        file: reader.result
                                                                    }
                                                                })
                                                            }
                                                        }
                                                        else {
                                                            this.setState({ gambarEdit: [] })
                                                        }
                                                    }}
                                                >
                                                    {this.state.fileListEdit.length >= 1 ? null :
                                                        <div className="p-2">
                                                            <img alt='' src={ImportIcon} style={{ width: "32px", height: "32px" }} /><br />
                                                            <div style={{
                                                                fontSize: "12px",
                                                                lineHeight: "16px",
                                                                color: "#5D6F80",
                                                                marginTop: "8px"
                                                            }}>Upload gambar pengumuman</div><br />
                                                            <a className="btn btn-outline-custom tambah-karyawan">Cari Gambar</a>
                                                        </div>
                                                    }
                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            className="mb-2 pengumuman-label-input">{i18n.t("judulpengumuman")}</label>
                                        <div>
                                            <input name="judulEdit" className="form-control pengumuman-input"
                                                   placeholder="Ketik judul pengumuman disini"
                                                   type="text" required="" onChange={this.handleChange}
                                                   value={this.state.judulEdit}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            className="mb-2 pengumuman-label-input">{i18n.t("divisi")}</label>
                                        <div>
                                            <select style={{fontSize: "12px"}} name="divisiEdit"
                                                    className="form-control" onChange={this.handleChange} value={this.state.divisiEdit}>
                                                <option style={{fontSize: "12px"}}>{i18n.t("semuadivisi")}</option>
                                                {this.state.divisi.map((item, index) => {
                                                    return (
                                                        <option style={{fontSize: "12px"}}
                                                                value={item.divisi_id}>{item.divisi_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("deskripsisingkatpengumuman")}</label>
                                        <div>
                                            <input name="shortdescEdit" className="form-control pengumuman-input" placeholder="Ketik deksripsi singkat disini"
                                                type="text" required="" onChange={this.handleChange} value={this.state.shortdescEdit} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 pengumuman-label-input">{i18n.t("deskripsipengumuman")}</label>
                                        <div>
                                            <CKEditor
                                                content={this.state.deskripsiEdit}
                                                config={{versionCheck: false}}
                                                events={{
                                                    change: this.onEditorStateChangeEdit
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='pengumuman-modal-footer'>
                            <button onClick={() => this.setState({
                                modalEdit: false, gambarEdit: null,
                                fileListEdit: []
                            })} className='ipresens-cancel-button'>{i18n.t("batal")}</button>
                            <button className='ipresens-save-button' onClick={this.editPengumuman}><img alt='' src={SaveIcon} /> {i18n.t("simpan")}</button>
                        </ModalFooter>
                    </Modal>
                    <Modal size='lg' zIndex={999} isOpen={this.state.modalDetail} toggle={() => this.setState({
                        modalDetail: false
                    })} style={{ maxWidth: "1000px" }}>
                        <ModalHeader toggle={() => this.setState({
                            modalDetail: false
                        })} className='pengumuman-modal-header'><div className='pengumuman-modal-header-font'>Edit Pengumuman</div></ModalHeader>
                        <ModalBody className='pengumuman-modal-body'>
                            <div className='row mb-3'>
                                <div className='col-md-12' style={{
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    lineHeight: "32px",
                                    color: "#143B5E"
                                }}>{this.state.judulDetail}</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-5 pengumuman-col-filter'>
                                    <img alt='' src={this.state.gambarDetail} style={{ maxWidth: "297px", maxHeight: "148px", borderRadius: "8px" }} />
                                </div>
                                <div className='col-md-7'>
                                    <div className='row mb-4'>
                                        <div className='col-md-12' style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            lineHeight: "16px",
                                            color: "#143B5E"
                                        }}>{this.state.shortdescDetail}</div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12' style={{
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            lineHeight: "16px",
                                            color: "#143B5E",
                                            wordWrap: "break-word"
                                        }} dangerouslySetInnerHTML={{ __html: this.state.deskripsiDetail }}></div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <ModalRs size="lg" style={{ maxWidth: "1000px" }} isOpen={this.state.modalHistory} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                        <ModalHeader style={{ backgroundColor: "#FAFDFF" }} toggle={() => this.setState({ modalHistory: false, currentPageHistory: 0 })}>
                            <div className='modal-header-reactstrap'>Riwayat Perubahan</div>
                        </ModalHeader>
                        <ModalBody>
                            <div className="list-group">
                                <div>
                                    {this.state.loadingHistory && (
                                        <div style={{ textAlign: 'center', margin: '10px auto' }}>
                                            <Spin
                                                size="large"
                                                tip="Loading..."
                                                style={{
                                                    width: '100%',
                                                    margin: 'auto',
                                                }}
                                            />
                                        </div>
                                    )}

                                    {this.state.dataHistory.length !== 0 && (
                                        <div>
                                            <Row>
                                                <Col xs={0} md={0} className="text-left" style={{ marginLeft: '20px' }}>
                                                    <h4>User</h4>
                                                </Col>
                                                <Col xs={0} md={0} className="text-left" style={{ marginRight: '20px' }}>
                                                    <h4>Aktivitas</h4>
                                                </Col>
                                            </Row>

                                            {this.state.dataHistory.map((item, index) => {
                                                return(
                                                    <div key={index} style={{ marginBottom: '20px' }}>
                                                        <Row>
                                                            <Col xs={12} md={6} className="text-left">
                                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '16px', paddingBottom: '16', paddingRight: '12px', paddingLeft: '12px' }}>
                                                                    <div style={{ marginRight: '16px' }}>
                                                                        <img
                                                                            src="https://servicesdev.presensi.co.id/media/logo-default-user.png"
                                                                            alt=""
                                                                            style={{
                                                                                width: '48x',
                                                                                marginBottom: '25px',
                                                                                height: '48px'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <h6
                                                                            className="mb-0 font-13"
                                                                            style={{
                                                                                fontWeight: 500,
                                                                                fontSize: '16px',
                                                                                lineHeight: '24px',
                                                                            }}
                                                                        >
                                                                            {item.p_user_name} | {item.level_name}
                                                                        </h6>
                                                                        <p className="m-0 font-12">
                                                                            <span className="col-green font-weight-bold">
                                                                                {moment(item.created_at).format('lll')}
                                                                            </span>
                                                                        </p>
                                                                        <div style={{ marginTop: 12 }}>
                                                                            {item.history_status === "created" ? (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#057CE4',
                                                                                        borderColor: '#057CE4',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Dibuat
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    className="m-0"
                                                                                    style={{
                                                                                        border: '1px solid #000',
                                                                                        borderRadius: '25px',
                                                                                        width: '50%',
                                                                                        color: '#E6462E',
                                                                                        borderColor: '#E6462E',
                                                                                    }}
                                                                                >
                                                                                    <div className="d-flex justify-content-center" style={{
                                                                                        paddingTop: '6px',
                                                                                        paddingBottom: '6px'
                                                                                    }}>
                                                                                        Diubah
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} md={6} className="text-left" style={{ paddingTop: '16px',paddingBottom:'16px', paddingRight:'12px', paddingLeft:'12px' }}>
                                                                {item.hitory_status !== 'created' && (
                                                                    <div>
                                                                        {item.parent_history ?
                                                                            item.parent_history.pengumuman_judul != item.pengumuman_judul && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Judul Pengumuman: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.pengumuman_judul
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.pengumuman_judul ? item.pengumuman_judul : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.pengumuman_shortdesc != item.pengumuman_shortdesc && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Deskripsi Singkat Pengumuman: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.pengumuman_shortdesc
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.pengumuman_shortdesc ? item.pengumuman_shortdesc : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.pengumuman_deskripsi != item.pengumuman_deskripsi && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Deskripsi Pengumuman: Dari{' '}
                                                                                        <b>{item.parent_history
                                                                                            ? item.parent_history.pengumuman_deskripsi
                                                                                            : 'N/A'}</b>{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: <b>{item.pengumuman_deskripsi ? item.pengumuman_deskripsi : ''}</b>
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                        {item.parent_history ?
                                                                            item.parent_history.pengumuman_gambar != item.pengumuman_gambar && (
                                                                                <div>
                                                                                    <h6
                                                                                        className="mb-3 font-13 text-left"
                                                                                        style={{
                                                                                            fontWeight: 500,
                                                                                            fontSize: '16px',
                                                                                            lineHeight: '24px',
                                                                                        }}
                                                                                    >
                                                                                        Gambar: Dari{' '}
                                                                                        {item.parent_history
                                                                                            ? item.parent_history.pengumuman_gambar
                                                                                            : 'N/A'}{' '}
                                                                                        <img
                                                                                            src={ArrowRightIcon}
                                                                                            alt=""
                                                                                            style={{
                                                                                                width: '20x',
                                                                                                marginBottom: '3px',
                                                                                                height: '20px'
                                                                                            }}
                                                                                        /> Jadi: {item.pengumuman_gambar ? item.pengumuman_gambar : ''}
                                                                                    </h6>
                                                                                </div>
                                                                            ):null
                                                                        }
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ backgroundColor: "#F5F5F5" }}>
                            <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                {this.state.dataHistory.length !== 0 &&
                                    <>
                                        <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>{i18n.t("menampilkan")} {this.state.dataHistory.length === 0 || this.state.dataHistory.length === null ? "0" : this.state.dataHistory.length} {i18n.t("dari")} {this.state.totalDataHistory} {i18n.t("data")}</div>
                                        <div className='col'>
                                            <ReactPaginate
                                                previousLabel={<img src={ArrowLeft} alt='' />}
                                                nextLabel={<img src={ArrowRight} alt='' />}
                                                pageCount={this.state.pageCountHistory}
                                                forcePage={this.state.currentPageHistory}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={3}
                                                onPageChange={this.handlePageHistory}
                                                breakClassName={'page-item'}
                                                breakLinkClassName={'page-link custom-page-link'}
                                                containerClassName={'pagination my-2'}
                                                pageClassName={'page-item'}
                                                pageLinkClassName={'page-link custom-page-link'}
                                                previousClassName={'page-item'}
                                                previousLinkClassName={'page-link custom-prev-next-link'}
                                                nextClassName={'page-item'}
                                                nextLinkClassName={'page-link custom-prev-next-link'}
                                                activeClassName={'active custom-active'}
                                            />
                                        </div>
                                    </>
                                }
                            </div>

                        </ModalFooter>
                    </ModalRs>
                    <div className="container-fluid pt-4">
                        <div className='row'>
                            <div className='col-md-12' style={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#5D6F80"
                            }}>iPresens Admin Panel</div>
                        </div>
                        <div className='row mb-4'>
                            <div className='col-md-12' style={{
                                fontWeight: 700,
                                fontSize: "24px",
                                lineHeight: "36px",
                                color: "#143B5E"
                            }}>{i18n.t("daftarpengumuman")}</div>
                        </div>
                        <div className="card">
                            <div className="card-header border-0 p-4">
                                <div className='row'>
                                    <div className='col-md-6 pengumuman-col-filter'>
                                        <div className='flex-row flex-nowrap'>
                                            <div className='input-group btn-srch-pengumuman  mr-1'>
                                                <span className='input-group-text pengumuman-filter-nama-input-text'>
                                                    <img alt='' src={SearchIcon} />
                                                </span>
                                                <input className='form-control mr-2 pengumuman-filter-nama-input' type='text' placeholder={i18n.t("carijudulpengumuman")} onKeyUp={(event) => {
                                                    if (event.which === 13) {
                                                        event.preventDefault()
                                                        this.setState({
                                                            page: 0,
                                                            paging: this.state.paging,
                                                            loading: true,
                                                            data: []
                                                        }, () => {
                                                            this.loadData()
                                                        });
                                                    }
                                                }} onChange={this.handleChange} name="filter_judul" value={this.state.filter_judul} />
                                                <button className='btn ipresens-btn-reset-filter' onClick={this.resetFilter}>
                                                    <img alt='' src={RefreshIcon} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <button className='ipresens-btn-add-pengumuman btn-add-pengumuman' onClick={() => this.setState({
                                            modalAdd: true
                                        })}><img alt='' src={PlusIcon} /> {i18n.t("tambahpengumuman")}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body daftar-pengumuman pt-3">
                                {!this.state.loading && this.state.data.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table className="table table-borderless mb-5">
                                        <Thead>
                                            <Tr>
                                                <Th className="pengumuman-th-table">#</Th>
                                                <Th className="pengumuman-th-table">{i18n.t("gambar")}</Th>
                                                <Th className="pengumuman-th-table" style={{ minWidth: '120px'}}>{i18n.t("divisi")}</Th>
                                                <Th className="pengumuman-th-table">{i18n.t("judulpengumuman")}</Th>
                                                <Th className="pengumuman-th-table">{i18n.t("deskripsisingkat")}</Th>
                                                <Th className="pengumuman-th-table"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                                <Tr>
                                                    <Td colSpan={5}>
                                                        <Spin size="large" tip="Loading..." style={{
                                                            width: '100%',
                                                            margin: 'auto',
                                                            marginBottom: '10px',
                                                            marginTop: '10px'
                                                        }} />
                                                    </Td>
                                                </Tr>
                                            }
                                            {this.state.data.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr>
                                                        <Td className="pengumuman-td-table">
                                                            {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                        </Td>
                                                        <Td><img style={stylefoto}
                                                            src={item.pengumuman_gambar !== null ? IMG_HOST + 'mitra/perusahaan/pengumuman/' + item.pengumuman_gambar : IMG_HOST + "user-profile.png"}
                                                            alt="" /></Td>
                                                        <Td className="pengumuman-td-table">{item.divisi && item.divisi.divisi_name ? item.divisi.divisi_name : i18n.t("semuadivisi")}</Td>
                                                        <Td className="pengumuman-td-table">{item.pengumuman_judul}</Td>
                                                        <Td className="pengumuman-td-table">{item.pengumuman_shortdesc}</Td>
                                                        <Td className="pengumuman-td-table">
                                                            <Tooltip trigger={'click'} placement="bottom" color={'#fff'} zIndex={900}
                                                                title={
                                                                    <div className={'p-2'}>
                                                                        <a className={'text-dark'}
                                                                           onClick={() => {this.setState({
                                                                               modalHistory: true,
                                                                               id_pengumuman: item.id_pengumuman
                                                                           },() => {
                                                                               this.loadHistory(item.id_pengumuman);
                                                                           })
                                                                           }}>Riwayat</a><br/>
                                                                        <a className={'text-dark'}
                                                                           onClick={() => this.setState({
                                                                               judulDetail: item.pengumuman_judul,
                                                                               shortdescDetail: item.pengumuman_shortdesc,
                                                                               deskripsiDetail: item.pengumuman_deskripsi,
                                                                               gambarDetail: item.pengumuman_gambar !== null ? IMG_HOST + 'mitra/perusahaan/pengumuman/' + item.pengumuman_gambar : IMG_HOST + "user-profile.png",
                                                                               modalDetail: true
                                                                           })}>Detail</a><br/>
                                                                        <a onClick={() => this.setState({
                                                                            idDetail: item.id_pengumuman
                                                                        }, () => this.loadDetail())}
                                                                            className={'text-dark'}>Edit</a><br />
                                                                        <a onClick={() => {
                                                                            this.setState({
                                                                                idDel: item.id_pengumuman
                                                                            })
                                                                            this.wantDelete()
                                                                        }} className={'text-danger'}>Hapus</a>
                                                                    </div>
                                                                }>
                                                                <a><i
                                                                    className="fa fa-ellipsis-v" style={{
                                                                        width: 35,
                                                                        fontSize: 18,
                                                                        padding: 11,
                                                                        color: 'rgb(51 51 51)'
                                                                    }}
                                                                ></i></a>
                                                            </Tooltip>

                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <h5 className="text-center pt-4">{i18n.t('tidakadaakseshalaman')}</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_pengumuman);
