import React, { Component, Fragment } from 'react'
import { APIKEY } from "../../../helper/host";
import { Empty, Spin } from "antd";
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Paging from "../../paging";
import { pageNumber } from "../../../functions/functionGeneral";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Switch from "react-switch";
import Add from "../add-kunjungan";
import { GoogleApiWrapper } from 'google-maps-react';
import i18n from "i18next";
import EmptyDataImage from "../../../assets/images/data-kosong.png";

export class outlet extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("1 2 3 4 5 6 7 8 9 10 11 12");
        namabulan = namabulan.split(" ");
        var tgl = new Date();
        var bulan = tgl.getMonth();
        var tahun = tgl.getFullYear();
        this.state = {
            loading: false,
            data_karyawan: [],
            absen: [],
            laporan: [],
            jabatan_karyawan: [],
            data_kunjungan: [],
            divisi_karyawan: [],
            lokasi_karyawan: [],
            kunjungan: [],
            outlet: [],
            outlet_karyawan: null,
            year: tahun,
            month: namabulan[bulan],
            add_visible: false,
            modalmaps: false,
            pageCount: 0,
            pagCount: 0,
            pageCountKunjungan: 0,
            currentPage: 0,
            currentPageKunjungan: 0,
            totalData: 0,
            totalDataKunjungan: 0,
            perPage: 0,
            pePage: 0,
            perPageKunjungan: 0,
            kunjunganStartDate: '',
            kunjunganEndDate: '',
            location: {
                lat: -7.865242,
                lng: 110.156070
            },

            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: [],
        }
        this.handleChange = this.handleChange.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handlePageClickKunjungan = this.handlePageClickKunjungan.bind(this)
        this.handleFilter = this.handleFilter.bind(this)
        this.handleDate = this.handleDate.bind(this)
        this.resetFilter = this.resetFilter.bind(this)
        this.maps = this.maps.bind(this);
        this.list = this.list.bind(this);


    }

    resetFilter(event) {
        this.setState({

            kunjunganStartDate: '',
            kunjunganEndDate: '',
            data_kunjungan: [],
            loading: true
        }, () => this.loadDataKaryawan())
    }

    handleFilter(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            data: [],
            loading: true
        }, () => this.loadDataKaryawan())
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    maps() {
        this.setState({ modalmaps: true, visible: false });
    }
    list() {
        this.setState({ modalmaps: false, visible: true });
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, absen: [] }, () => {
            this.loadDataKaryawan();
        });
    }

    handlePageClickOutlet(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPage: selected, loading: true, data_kunjungan: [] }, () => {
            this.loadDataKaryawan();
        });
    }

    handlePageClickKunjungan(data) {
        let selected = data.selected; // zero based index
        this.setState({ currentPageKunjungan: selected, loading: true, kunjungan: [] }, () => {
            this.loadKunjungan();
        });
    }

    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;

        this.setState({
            data: [],
            loading: true,
            kunjunganStartDate: start,
            kunjunganEndDate: end
        }, () => {
            this.loadDataKaryawan()
        });
    }

    loadDataKaryawan() {
        this.setState({ loading: true })
        let form = {
            slug: this.props.match.params.id,
        }
        this.props.request("karyawan/get_karyawan_by_slug", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_karyawan: response.data,
                        visible: true
                    })
                }
            });
    }


    kunDelete() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus outlet?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        karyawan_outlet_id: this.state.idDel
                    }
                    this.setState({
                        loading: true,
                        data: [],
                    })
                    this.props.request("karyawan/delete_outlet", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadKunjungan()
                            }
                        });
                }
            })
    }

    loadKunjungan() {
        this.setState({ loading: true })
        let form = {
            karyawan_id: this.props.match.params.id,
            page: this.state.currentPageKunjungan + 1,
        }
        this.props.request("karyawan/outlet_by_karyawan", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        kunjungan: response.data.data,
                        totalDataKunjungan: response.data.total,
                        pageCountKunjungan: response.data.last_page,
                        perPageKunjungan: response.data.per_page,
                    })
                }
                this.setState({ loading: false })
            });
    }

    componentDidMount() {
        this.loadDataKaryawan()
        this.loadKunjungan()
    }

    render() {

        let no = 0;

        return (
            <Fragment>
                <Add
                    add_visible={this.state.add_visible}
                    data={this.state}
                    isLoading={this.state.loading}
                    request={(action, data, method = 'GET') => this.props.request(action, data, method)}
                    changeState={(state, callback) => {
                        this.setState(state, () => {
                            if (typeof callback != 'undefined')
                                callback()
                        })
                    }}
                    loadData={() => this.loadKunjungan()}
                />

                {/*Daftar outlet*/}
                <div className="card">
                    {this.state.loading ?
                        <Spin size="large" tip="Sedang memuat data..." style={{
                            width: '100%',
                            margin: 'auto',
                            marginBottom: '10px',
                            marginTop: '10px'
                        }}/> :
                        <div>
                            <div className="card-header d-flex justify-content-between">
                                <div style={{marginTop: 8}} className="row align-items-center">
                                    <div className="mr-2">Kunci Lokasi Kunjungan</div>
                                    <Switch
                                        id={`detail-${this.state.data_karyawan.karyawan_id}`}
                                        name="kunci_lokasi"
                                        checked={
                                            this.state.data_karyawan.kunci_kunjungan === '1' ? true : false
                                        }
                                        onChange={(val) => {
                                            this.setState({
                                                loading: true,
                                                data: []
                                            })

                                            let formData = {
                                                karyawan_id: this.state.data_karyawan.karyawan_id,
                                            }

                                            this.props.request('karyawan/karyawan_kunjungan', formData, 'POST')
                                                .then((response) => {
                                                    if (response.success) {
                                                        Swal.fire({
                                                            title: 'Berhasil!',
                                                            text: response.message,
                                                            icon: 'success',
                                                            showConfirmButton: false,
                                                            timer: 1500,
                                                        })
                                                    } else {
                                                        if (Array.isArray(response.message)) {
                                                            response.message.map(item => {
                                                                toast['error'](item)
                                                                return true
                                                            })
                                                        } else {
                                                            toast['error'](response.message)
                                                        }
                                                    }
                                                    this.loadDataKaryawan()
                                                    this.setState({loading: false})
                                                })

                                        }}
                                        onColor="#8c2bee"
                                        offColor="#eb4034"
                                        handleDiameter={16}
                                        uncheckedIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 3,
                                                    color: "white",
                                                    paddingRight: -5
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTimes}
                                                                 style={{fontSize: 15}}/>
                                            </div>
                                        }
                                        checkedIcon={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "100%",
                                                    fontSize: 4,
                                                    color: "white",
                                                    paddingLeft: 3
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCheck}
                                                                 style={{fontSize: 15}}/>
                                            </div>
                                        }
                                        boxShadow="0px 1px 1px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={18}
                                        width={40}
                                        className="mr-1"

                                    />
                                </div>
                                <div>
                                    <a className="btn ipresens-btn-color tambah-kunjungan" style={{color: "white" }} onClick={() => {
                                        let outlet = this.state.kunjungan.map((item, index) =>
                                            item.outlet_id
                                        )
                                        this.setState({
                                            add_visible: true,
                                            outlet_karyawan: outlet,
                                            karyawan_id: this.props.match.params.id
                                        })
                                    }}><i className={"fa fa-plus"}></i> Tambah
                                    </a>
                                </div>
                            </div>
                            <div className="card-body daftar-outlet">
                                {!this.state.loading && this.state.kunjungan.length === 0 ?
                                    <Empty description={i18n.t("datakosong")}
                                           image={EmptyDataImage} className="ipresens-image-empty mb-5"/>
                                    :
                                    <Table striped className="mb-5">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th>Lokasi Kunjungan</Th>
                                                <Th>No Telepon</Th>
                                                <Th className="text-center">Nama Pemilik</Th>
                                                <Th className="text-center">Alamat</Th>
                                                <Th className="text-center">Latitude</Th>
                                                <Th className="text-center">Longitude</Th>
                                                <Th className="text-center" width={80}>Aksi</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {this.state.loading &&
                                            <Tr>
                                                <Td colSpan={6}>
                                                    <Spin size="large" tip="Loading..." style={{
                                                        width: '100%',
                                                        margin: 'auto',
                                                        marginBottom: '10px',
                                                        marginTop: '10px'
                                                    }} />
                                                </Td>
                                            </Tr>
                                            }
                                            {this.state.kunjungan.map((item, index) => {
                                                no += 1
                                                return (
                                                    <Tr>
                                                        <small>
                                                            <Th scope="row">{pageNumber(no, this.state.perPageKunjungan, this.state.currentPageKunjungan)}</Th>
                                                        </small>
                                                        <Td>{item.outlet_nama}</Td>
                                                        <Td>{item.outlet_no_hp}</Td>
                                                        <Td align="center">{item.outlet_nama_pemilik !== null ? item.outlet_nama_pemilik : '-'}</Td>
                                                        <Td align="center">{item.outlet_alamat !== null ? item.outlet_alamat : '-'}</Td>
                                                        <Td align="center">{item.outlet_latitude}</Td>
                                                        <Td align="center">{item.outlet_longitude}</Td>
                                                        <Td align="center">
                                                            <a onClick={() => {
                                                                this.setState({
                                                                    idDel: item.karyawan_outlet.karyawan_outlet_id
                                                                })
                                                                this.kunDelete()
                                                            }}><i className="fa fa-trash" style={{
                                                                width: 35,
                                                                fontSize: 20,
                                                                padding: 11,
                                                                color: 'rgb(255 37 37)'
                                                            }}
                                                            ></i></a>
                                                        </Td>
                                                    </Tr>
                                                )
                                            })}
                                        </Tbody>
                                    </Table>
                                }
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClickKunjungan}
                                    pageCount={this.state.pageCountKunjungan}
                                    currentPage={this.state.currentPageKunjungan}
                                />
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: APIKEY
})(outlet)
